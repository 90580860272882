<template>
	<section class="b-content b-content_notes">
		<div class="b-content__header">
			<h2 class="b-content__title">Уведомления</h2>
			<div v-if="new_count > 0" class="b-content__header-aside">
				<button class="btn btn_transp" v-on:click="readAll"><span class="btn-icon">&times;</span> Прочитать все</button>
			</div>
		</div>
		<div class="b-content__top">
			<div class="first-line">
				<div class="control_group">
					<div class="control_toggle-order">
						<label class="js-hidden-toggle" v-on:click="setSort">
							<input type="hidden" name="order" data-toggle="asc|desc" v-bind:value="sortOrder">
							<i></i><span>Дата</span>
						</label>
					</div>
					<div class="control_checkbox">
						<label>
							<input type="checkbox" name="status" value="1" v-on:change="setFilter">
							<i></i><span>Только непрочитанные</span>
						</label>
					</div>
				</div>
				<form action="" class="control_search" v-on:submit.prevent="setSearch">
					<input type="text" value="" placeholder="Искать" name="seaech" id="items_search" v-on:input.prevent="setSearch">
					<button type="submit">
						<svg width="17" height="16">
							<use xlink:href="/images/sprite.svg#search"></use>
						</svg>
					</button>
				</form>
			</div>
			<div class="second-line">
				<ul class="control_nav">
					<li v-bind:class="{'is-active': type == ''}"><router-link :to="'/notifications/'">Все</router-link></li>
					<li v-bind:class="{'is-active': type == 'warning'}"><router-link :to="'/notifications_warning/'">Важные</router-link></li>
					<li v-bind:class="{'is-active': type == 'attention'}"><router-link :to="'/notifications_attention/'">Требуют внимания</router-link></li>
					<li v-bind:class="{'is-active': type == 'info'}"><router-link :to="'/notifications_info/'">Оповещения</router-link></li>
				</ul>
			</div>
		</div>
		
		<div class="b-content__body">
		
			<div v-bind:class="{ show: loading }" class="component-loading"></div>
			
			<div v-if="emptyResult" class="empty-result-container animated zoomIn">
				<div v-if="!loading" class="empty-result-content">
					<div class="empty-result-text" v-html="emptyMess"></div>
					<div class="empty-result-btn">
						<router-link :to="'/catalog/'" class="btn">В каталог</router-link>
					</div>
				</div>
			</div>
			
			<div v-if="!emptyResult" class="note-list">
				<div 
					v-if="loaded" 
					@click="read(notification)" 
					v-for="notification in notifications" 
					v-bind:data-id="notification.id" 
					v-bind:class="[{'note_new': notification.status == '1'}, 'note_' + notification.type]" 
					class="note animated">
					<div class="note__header">
						<div class="note__title" v-html="notification.title"></div>
						<div class="note__date" v-html="notification.date_format"></div>
					</div>
					<div class="note__body">
						<div class="note__text" v-html="notification.preview"></div>
						<router-link v-if="notification.link" :to="notification.link" class="note__read"><span>Подробнее</span></router-link>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!emptyResult" class="b-content__footer">
			<component-pagination></component-pagination>
		</div>
		
		
	</section>

</template>

<script>
import paginationComponent from '../components/paginationComponent';
export default {
	name: 'NotificationsComponent',
	data() {
		var self = this;
		return {
			emptyResult: false,
			loading: true,
			loaded: false,
			showMore: false,
			showTimer: null,
			searchTimer: null,
			search: "",
			emptyTypeMess: {
				warning: "<span>У Вас нет <b>важных</b> уведомлений</span>", 
				attention: "<span>У Вас нет <b>требующих внимания</b> уведомлений</span>", 
				info: "<span>У Вас нет <b>оповещений</b></span>",
			},
			type: self.$route.params.type || "",
			navPageNum: self.$route.params.navPageNum || 1,
			navPageSize: self.$route.params.NavPageSize || 10,
			navPageCount: 1,
			notifications: [],
			navParam: {},
			filter: {type: self.type},
			sortOrder: "desc",
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
				{ link: '/notifications/', name: 'Уведомления' },
			]
		};
	},
	computed: {
		notifications_count() {return this.notifications.length},
		new_count() {return this.$root.arUser["notifications"].count},
		emptyMess() {
			if(this.type != "") return this.emptyTypeMess[this.type];
			else return "У вас нет уведомлений";
		},
		route_notifications_params(){
			return this.$route.params;
		},
	},
	components: {
		'component-pagination': paginationComponent,
	},
	watch: {
		route_notifications_params: function (newVal, oldVal) {
			var self = this;
			self.loading = true;
			self.navPageNum = newVal.navPageNum || 1;
			self.type = newVal.type != undefined ? newVal.type : "";
			
			if(self.showMore) {
				self.loadNotifications();
			}
			else{
				setTimeout(function(){
					self.loadNotifications();
				}, 10);
			}
		},
		sortOrder: function (newVal) {
			var self = this;
			self.loading = true;
			setTimeout(function(){
				self.loadNotifications();
			}, 10);
		},
	},
	methods:{
		read: function(notification){
			var self = this;
			$.getJSON("/data/components/notifications.php", {read: notification.id}, function(json){
				self.$root.arUser["notifications"] = json;
				$(".note[data-id=" + notification.id + "]").removeClass("note_new");
			})
		},
		readAll: function(){
			var self = this;
			$.getJSON("/data/components/notifications.php", {readAll: "Y"}, function(json){
				self.$root.arUser["notifications"] = json;
				$(".note").removeClass("note_new");
			})
		},
		setSearch: function(){
			var self = this;
			
			if(!!self.searchTimer) clearTimeout(self.searchTimer);
			self.searchTimer = setTimeout(function(){
				var query = $("#items_search").val();
				self.search = query;
				self.loadNotifications();
			}, 1000);
		},
		setFilter: function(e){
			var self = this;
			if(e.target.type == "checkbox") {
				if(e.target.checked) self.filter[e.target.name] = e.target.value;
				else delete self.filter[e.target.name];
			}
			self.loadNotifications();
		},
		setSort: function(){
			var self = this;
			if(self.sortOrder == "desc") self.sortOrder = "asc";
			else self.sortOrder = "desc";
		},
		checkEmpty: function(){
			var self = this;
			if(self.notifications_count <= 0) {
				setTimeout(function(){self.loading = false}, 200);
				self.emptyResult = true;
				self.filter = {};
			}else{
				setTimeout(function(){
					self.showNotifications();
					self.loading = false;
				}, 200);
				self.emptyResult = false;
			}
		},
		loadNotifications: function(){
			var self = this;
			self.loaded = false;
			self.loading = true;
			if(self.type != "") self.filter.type = self.type;
			else delete self.filter.type;
			
			var urlData = {
				"page"	: self.navPageNum,
				"sort"	: self.sortOrder,
				"filter": self.filter,
			};
			if(self.search != "") urlData.search = self.search;
			
			$.getJSON("/data/components/notifications.php", urlData, function(json){
				self.loaded = true;
				self.navParam = json.nav;
				self.navPageCount = json.nav.NavPageCount;
				
				var newData = [];
				
				if(!self.showMore){
					for(var i in json.notifications) newData.push(json.notifications[i]);
					self.notifications = newData;
					self.$root.$emit('triggerScroll');
				}else{
					for(var i in json.notifications) newData.push(json.notifications[i]);
					self.notifications = self.notifications.concat(newData);
				}
				
				self.$nextTick(function(){
					self.$emit('sectionLoaded', json);
					self.checkEmpty();
				})
			});
		},
		showNotifications: function(){
			var self = this;
			if(!!self.showTimer) clearInterval(self.showTimer);
			
			var items = $(".note:not(.showed)");
			
			var i = 0;
			self.showTimer = setInterval(function(){
				items.eq(i).addClass("fadeInUp showed");
				if (++i >= items.length) clearInterval(self.showTimer)
			}, 50);
		},
		onWebSocketCommand: function(data){
			var self = this;
			if((data.command == "user_list_action" && data.params.list == "notifications")){
				self.loadNotifications();
			}
		},
	},
	mounted: function(){
		var self = this;
		self.$root.breadcrumbs = self.breadcrumbs;
		self.loadNotifications();
		
		self.$root.$on('notificationsUpdated', self.checkEmpty);	
		self.$root.$on('wsCommand', self.onWebSocketCommand);
		
	},
};
</script>

<style scoped>
.b-content__body{
	min-height: 300px;
    display: block;
    width: 100%;
}
.b-content__body .note:not(.showed){opacity:0}
.b-content__body .component-loading.show{
    width: 100%;
	background-color: #f6f8fb;
    opacity: 0.8;
}
.empty-result-container{height: 100%;}
.b-content_notes .animated{
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
}
.b-content_notes .animated.note{
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}
</style>
