var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.nextPage
    ? _c("span", { staticClass: "next_page", class: _vm.itemData.pageClass }, [
        _c("span", [_vm._v(_vm._s(_vm.itemData.name))])
      ])
    : _c(
        "div",
        {
          staticClass: "animated ",
          class: [_vm.itemData.pageClass, _vm.itemClass]
        },
        [
          _c(
            "div",
            {
              staticClass: "product",
              class: {
                product_lite:
                  _vm.itemData.arhive && !_vm.isCatalog && _vm.can_delete,
                "product_lite product_deleted": _vm.itemData.deleted
              }
            },
            [
              _c("div", { staticClass: "product__tools" }, [
                _c(
                  "button",
                  {
                    staticClass: "js-fav",
                    class: {
                      "is-active": _vm.$root.itemInUserList("favorite", _vm.id)
                    },
                    attrs: { "data-pid": _vm.itemData.id, title: "Избранное" },
                    on: {
                      click: function($event) {
                        return _vm.$root.userItemsListsAction(
                          "favorite",
                          _vm.id
                        )
                      }
                    }
                  },
                  [
                    _c("svg", { attrs: { width: "18", height: "18" } }, [
                      _c("use", {
                        attrs: { "xlink:href": "/images/sprite.svg#star" }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "js-comp",
                    class: {
                      "is-active": _vm.$root.itemInUserList("compare", _vm.id)
                    },
                    attrs: { "data-pid": _vm.id, title: "Сравнить" },
                    on: {
                      click: function($event) {
                        return _vm.$root.userItemsListsAction("compare", _vm.id)
                      }
                    }
                  },
                  [
                    _c("svg", { attrs: { width: "22", height: "18" } }, [
                      _c("use", {
                        attrs: { "xlink:href": "/images/sprite.svg#chart-bar" }
                      })
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "product__header" },
                [
                  _vm.itemData.ratio_info != ""
                    ? _c("div", { staticClass: "product__ratio_info" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.itemData.ratio_info) +
                            "\n      "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.itemData.deleted
                    ? _c("div", { staticClass: "product__labels" }, [
                        _c(
                          "span",
                          {
                            staticClass: "label label_red",
                            attrs: { class: "label label_red" }
                          },
                          [_vm._v("Удален из списка")]
                        )
                      ])
                    : _c(
                        "div",
                        { staticClass: "product__labels" },
                        _vm._l(_vm.itemData.labels, function(label) {
                          return _c("span", {
                            staticClass: "label",
                            class: label.class,
                            domProps: { innerHTML: _vm._s(label.text) }
                          })
                        }),
                        0
                      ),
                  _vm._v(" "),
                  _c("div", { staticClass: "product__stock" }, [
                    this.itemData.can_buy
                      ? _c("span", { staticClass: "stock stock_green" }, [
                          _vm._v(_vm._s(_vm.itemData.product.QUANTITY_TEXT))
                        ])
                      : _c("span", { staticClass: "stock stock_red" }, [
                          _vm._v("Нет в наличии")
                        ])
                  ]),
                  _vm._v(" "),
                  !_vm.itemData.deleted
                    ? [
                        _vm.can_delete
                          ? _c("button", {
                              staticClass: "product__remove js-wlist-remove",
                              attrs: { title: "Удалить из списка" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.userItemsListsAction.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "product__img", attrs: { to: _vm.detailPage } },
                [
                  _c("router-link", { attrs: { to: _vm.detailPage } }, [
                    _c("img", { attrs: { src: _vm.itemData.image, alt: "" } })
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn product__quick-view js-quick-view-btn",
                      on: { click: _vm.quickViewAction }
                    },
                    [_vm._v("Быстрый просмотр")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.itemData.properties
                ? _c(
                    "div",
                    { staticClass: "product__body" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "product__art",
                          attrs: { to: _vm.detailPage }
                        },
                        [
                          _vm._v("Арт. " + _vm._s(_vm.itemData.article) + " "),
                          _c("small", [
                            _vm._v(
                              "уп. " +
                                _vm._s(
                                  _vm.itemData.properties
                                    .KOLICHESTVO_V_UPAKOVKAKH.value
                                ) +
                                " " +
                                _vm._s(_vm.itemData.measure)
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("router-link", {
                        staticClass: "product__name",
                        attrs: { to: _vm.detailPage, div: "" },
                        domProps: { innerHTML: _vm._s(_vm.itemData.name) }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "product__footer" },
                [
                  _c(
                    "div",
                    { staticClass: "product__price" },
                    [
                      _c("div", { staticClass: "product__stock" }, [
                        this.itemData.can_buy
                          ? _c("span", { staticClass: "stock stock_green" }, [
                              _vm._v(_vm._s(_vm.itemData.product.QUANTITY_TEXT))
                            ])
                          : _c("span", { staticClass: "stock stock_red" }, [
                              _vm._v("Нет в наличии")
                            ])
                      ]),
                      _vm._v(" "),
                      _vm.itemData.can_buy
                        ? [
                            _vm.priceData.print_old
                              ? _c("span", {
                                  staticClass: "old-price",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.priceData.print_old)
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "price",
                              domProps: {
                                innerHTML: _vm._s(_vm.priceData.print)
                              }
                            })
                          ]
                        : [
                            _c("span", {
                              staticClass: "last-price",
                              domProps: {
                                innerHTML: _vm._s(_vm.priceData.print)
                              }
                            })
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.itemData.deleted
                    ? [
                        _c("div", { staticClass: "product__form" }, [
                          _c(
                            "form",
                            {
                              staticClass: "js-product-form",
                              attrs: { action: "" }
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn__analogs",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.userItemsListsAction.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Восстановить")]
                              )
                            ]
                          )
                        ])
                      ]
                    : [
                        this.itemData.can_buy
                          ? _c("div", { staticClass: "product__form" }, [
                              _c(
                                "form",
                                {
                                  staticClass: "js-product-form",
                                  attrs: { action: "" }
                                },
                                [
                                  _c("input", {
                                    attrs: { type: "hidden", name: "pid" },
                                    domProps: { value: _vm.itemData.id }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "counter js-counter" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn decrease",
                                          attrs: { type: "button" },
                                          on: { click: _vm.counterMinus }
                                        },
                                        [_vm._v("-")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass: "count",
                                        attrs: {
                                          type: "text",
                                          name: "quantity",
                                          autocomplete: "off"
                                        },
                                        domProps: { value: _vm.animatedNumber },
                                        on: { input: _vm.counterInput }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn increase",
                                          attrs: { type: "button" },
                                          on: { click: _vm.counterPlus }
                                        },
                                        [_vm._v("+")]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.$root.itemInUserList("basket", _vm.id)
                                    ? _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "btn btn_transp btn__submit",
                                          attrs: { to: "/cart/" }
                                        },
                                        [_vm._v("Добавлено")]
                                      )
                                    : _c(
                                        "button",
                                        {
                                          staticClass: "btn btn__submit",
                                          attrs: {
                                            type: "submit",
                                            name: "action",
                                            value: "buy"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.addToOrder(
                                                _vm.itemData
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Добавить к заказу")]
                                      )
                                ],
                                1
                              )
                            ])
                          : _c("div", { staticClass: "product__form" }, [
                              _c(
                                "form",
                                {
                                  staticClass: "js-product-form",
                                  attrs: { action: "" }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn__analogs js-modal-analogs",
                                      class: {
                                        disabled: _vm.itemData.hide_analogs
                                      },
                                      attrs: { type: "button" },
                                      on: { click: _vm.analogsAction }
                                    },
                                    [_vm._v("Аналоги")]
                                  ),
                                  _vm._v(" "),
                                  _vm.showSubscribe
                                    ? [
                                        _vm.$root.itemInUserList(
                                          "waiting",
                                          _vm.id
                                        )
                                          ? _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "btn btn_transp btn__submit",
                                                attrs: { to: "/waiting/" }
                                              },
                                              [_vm._v("В листе ожидания")]
                                            )
                                          : _c(
                                              "button",
                                              {
                                                staticClass: "btn btn__submit",
                                                attrs: {
                                                  type: "submit",
                                                  value: "waiting-list",
                                                  name: "action"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.$root.userItemsListsAction(
                                                      "waiting",
                                                      _vm.id
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("В лист ожидания")]
                                            )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ])
                      ]
                ],
                2
              )
            ]
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }