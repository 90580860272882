var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "b-content b-content_order",
      attrs: { id: "printableArea" }
    },
    [
      _c("div", {
        staticClass: "component-loading",
        class: { show: _vm.loading }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "b-content__header" }, [
        _c("h2", { staticClass: "b-content__title" }, [
          _vm._v(
            "Заказ " +
              _vm._s(_vm.result.ACCOUNT_NUMBER) +
              " от " +
              _vm._s(_vm.result.DATE_INSERT_FORMATED) +
              " "
          ),
          _vm.result.CANCELED == "Y"
            ? _c("span", { staticClass: "order-canceled" }, [
                _vm._v("(Отменен)")
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "b-content__header-aside" }, [
          _c("div", { staticClass: "order-entity" }, [
            _c("div", {
              staticClass: "order-entity__name",
              domProps: {
                innerHTML: _vm._s(_vm.result.ORDER_PROPS.COMPANY.VALUE)
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "order-entity__user" }, [
              _vm._v("Оформил  пользователь: "),
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.result.ORDER_PROPS.CONTACT_PERSON.VALUE)
                }
              })
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "b-content__body" }, [
        _c("div", { staticClass: "order-detail" }, [
          _vm.loaded
            ? _c("div", { staticClass: "resp-table-wrap" }, [
                _c("table", { staticClass: "t-orders" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", {
                        domProps: {
                          innerHTML: _vm._s(_vm.result.ACCOUNT_NUMBER)
                        }
                      }),
                      _vm._v(" "),
                      _c("td", {
                        domProps: {
                          innerHTML: _vm._s(_vm.result.DATE_INSERT_FORMATED)
                        }
                      }),
                      _vm._v(" "),
                      _c("td", {
                        domProps: {
                          innerHTML: _vm._s(_vm.result.PRICE_FORMATED)
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.result.ORDER_PROPS.srok_rezerva.VALUE
                            )
                          }
                        },
                        [_vm._v("-")]
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", {
                          staticClass: "status",
                          style: "color:" + _vm.result.STATUS.COLOR,
                          domProps: {
                            innerHTML: _vm._s(_vm.result.STATUS.NAME)
                          }
                        })
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "order-section" }, [
            _c("h2", { staticClass: "order-section__title" }, [
              _vm._v("Перечень товаров")
            ]),
            _vm._v(" "),
            _vm.loaded
              ? _c(
                  "div",
                  {
                    staticClass: "cutter js-cutter",
                    class: {
                      "small-basket": _vm.result.BASKET.length < 8,
                      "is-open": _vm.showAllOrder
                    }
                  },
                  [
                    _c("div", { staticClass: "cutter__target" }, [
                      _c("div", { staticClass: "resp-table-wrap" }, [
                        _c("table", { staticClass: "product-table" }, [
                          _vm._m(1),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.result.BASKET, function(item) {
                              return _c("tr", [
                                _c("td", [_vm._v(_vm._s(item.NUM))]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "item-img",
                                      staticStyle: { width: "40px" }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "t-prod-pic",
                                        attrs: {
                                          width: "40",
                                          src: item.PREVIEW_PICTURE,
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("router-link", {
                                      staticClass: "t-prod-art",
                                      attrs: { to: item.DETAIL_PAGE_URL },
                                      domProps: {
                                        innerHTML: _vm._s(item.ARTICLE)
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: { innerHTML: _vm._s(item.NAME) }
                                }),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "white-space": "nowrap" },
                                    domProps: {
                                      innerHTML: _vm._s(item.PROPS.STORE.VALUE)
                                    }
                                  },
                                  [_vm._v("-")]
                                ),
                                _vm._v(" "),
                                _c("td", [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(item.QUANTITY) +
                                        _vm._s(item.MEASURE_TEXT)
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticStyle: { "white-space": "nowrap" } },
                                  [
                                    _c("b", {
                                      domProps: {
                                        innerHTML: _vm._s(item.PRICE_FORMATED)
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticStyle: { "white-space": "nowrap" } },
                                  [
                                    _c("b", {
                                      domProps: {
                                        innerHTML: _vm._s(item.FORMATED_SUM)
                                      }
                                    })
                                  ]
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "cutter__trigger js-cutter-trigger noPrint",
                        class: { "is-open": _vm.showAllOrder },
                        attrs: {
                          "data-open": "Показать весь список",
                          "data-close": "Свернуть"
                        },
                        on: {
                          click: function($event) {
                            _vm.showAllOrder = !_vm.showAllOrder
                          }
                        }
                      },
                      [
                        _vm.showAllOrder
                          ? _c("span", [_vm._v("Скрыть")])
                          : _c("span", [_vm._v("Показать весь список")])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn_light btn_download noPrint",
                        attrs: {
                          href: "javascript:;",
                          onclick: "app.printDiv('printableArea')"
                        }
                      },
                      [
                        _c("span", { staticClass: "btn_text" }, [
                          _vm._v("Бланк заказа")
                        ]),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            staticClass: "svg-icon",
                            attrs: { width: "13", height: "13" }
                          },
                          [
                            _c("use", {
                              attrs: {
                                "xlink:href": "/images/sprite.svg#download"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.loaded && _vm.result.DEL_BASKET.length > 0
            ? _c(
                "div",
                {
                  staticClass: "order-section",
                  staticStyle: { background: "#eee", "border-bottom": "0" }
                },
                [
                  _c("h2", { staticClass: "order-section__title" }, [
                    _vm._v("Отмененные товары")
                  ]),
                  _vm._v(" "),
                  _vm.loaded
                    ? _c("div", [
                        _c("div", {}, [
                          _c("div", { staticClass: "resp-table-wrap" }, [
                            _c("table", { staticClass: "product-table" }, [
                              _vm._m(2),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.result.DEL_BASKET, function(item) {
                                  return _c("tr", [
                                    _c("td", [_vm._v(_vm._s(item.NUM))]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item-img",
                                          staticStyle: { width: "40px" }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "t-prod-pic",
                                            attrs: {
                                              src: item.PREVIEW_PICTURE,
                                              alt: ""
                                            }
                                          })
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c("router-link", {
                                          staticClass: "t-prod-art",
                                          attrs: { to: item.DETAIL_PAGE_URL },
                                          domProps: {
                                            innerHTML: _vm._s(item.ARTICLE)
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: { innerHTML: _vm._s(item.NAME) }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            item.PROPS.STORE.VALUE
                                          )
                                        }
                                      },
                                      [_vm._v("-")]
                                    ),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(item.QUANTITY) +
                                            _vm._s(item.MEASURE_TEXT)
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "white-space": "nowrap" }
                                      },
                                      [
                                        _c("b", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              item.PRICE_FORMATED
                                            )
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "order-section" }, [
            _c("h2", { staticClass: "order-section__title" }, [
              _vm._v("Оплата")
            ]),
            _vm._v(" "),
            _vm.loaded
              ? _c("div", { staticClass: "bill" }, [
                  _c(
                    "div",
                    { staticClass: "bill__sum" },
                    [
                      _vm._v("\n\t\t\t\t\t\tК оплате: "),
                      _c("b", {
                        domProps: {
                          innerHTML: _vm._s(_vm.result.PRICE_FORMATED)
                        }
                      }),
                      _vm._v(" "),
                      _c("nobr", [_vm._v("включая НДС")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.result.ORDER_PROPS.BB.VALUE < 0
                    ? _c("div", { staticClass: "bill-bonus" }, [
                        _c("span", { staticClass: "bill-bonus__label" }, [
                          _vm._v("Списано:")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "bill-bonus__value bg_red t_red" },
                          [
                            _c("b", [
                              _vm._v(
                                "- " + _vm._s(_vm.result.ORDER_PROPS.BB.VALUE)
                              )
                            ]),
                            _vm._v(" "),
                            _c("small", [_vm._v("бонусов")])
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.result.ORDER_PROPS.BB.VALUE > 0
                    ? _c("div", { staticClass: "bill-bonus" }, [
                        _c("span", { staticClass: "bill-bonus__label" }, [
                          _vm._v("Начислено:")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "bill-bonus__value bg_green t_green" },
                          [
                            _c("b", [
                              _vm._v(
                                "+ " + _vm._s(_vm.result.ORDER_PROPS.BB.VALUE)
                              )
                            ]),
                            _vm._v(" "),
                            _c("small", [_vm._v("бонусов")])
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "noPrint" },
                    [
                      _vm.shipDateIsSet
                        ? [
                            _c(
                              "div",
                              { staticClass: "btn btn_light disabled" },
                              [
                                _c("span", {
                                  staticClass: "btn_text",
                                  style: "color:" + _vm.result.STATUS.COLOR,
                                  domProps: {
                                    innerHTML: _vm._s(_vm.result.STATUS.NAME)
                                  }
                                })
                              ]
                            )
                          ]
                        : [
                            _vm.enableDate && _vm.result.STATUS.ID == "RE"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "btn btn_lblue",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        _vm.dateModalOpen = true
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "btn_text" }, [
                                      _vm._v("Отправить на сборку")
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                      _vm._v(" "),
                      _vm.result.PAYED == "Y"
                        ? _c("div", [_vm._m(3)])
                        : _vm.result.STATUS.ID == "F"
                        ? _c("div", [_vm._m(4)])
                        : _vm.result.STATUS.ID == "N"
                        ? _c("div", [_vm._m(5)])
                        : _vm.result.IS_ALLOW_PAY == "Y" && _vm.result.PAYMENT
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn_light btn_bill ",
                              attrs: {
                                href:
                                  _vm.result.PAYMENT.PAY_SYSTEM
                                    .PSA_ACTION_FILE + "&pdf=1&DOWNLOAD=Y",
                                target: "_blank"
                              }
                            },
                            [
                              _c("span", { staticClass: "btn_text" }, [
                                _vm._v("Счет на оплату")
                              ]),
                              _vm._v(" "),
                              _c(
                                "svg",
                                {
                                  staticClass: "svg-icon",
                                  attrs: { width: "13", height: "13" }
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/images/sprite.svg#download"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        : _c("div", { staticClass: "noPrint" }, [_vm._m(6)])
                    ],
                    2
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.loaded
            ? _c(
                "div",
                { staticClass: "order-section order-section_shipment" },
                [
                  _c("div", { staticClass: "state noPrint" }, [
                    _vm._v("Статус:  "),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.result.SHIPMENT.STATUS_NAME)
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("h2", { staticClass: "order-section__title" }, [
                    _vm._v("Отгрузка")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "shipment" }, [
                    _c("div", { staticClass: "shipment__grid" }, [
                      _c("div", { staticClass: "shipment__col" }, [
                        _c("label", [_vm._v("Способ доставки:")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "x-input",
                          domProps: {
                            innerHTML: _vm._s(_vm.result.SHIPMENT.DELIVERY_NAME)
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "shipment__col" }, [
                        _c("label", [_vm._v("Адрес доставки:")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "x-input",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.result.ORDER_PROPS.ADDRESS.VALUE
                            )
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _vm.result.ORDER_PROPS.PL.VALUE
                        ? _c("div", { staticClass: "shipment__col" }, [
                            _c("label", [_vm._v("Дата отгрузки:")]),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "x-input",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.result.ORDER_PROPS.PL.VALUE
                                )
                              }
                            })
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.result.SHIPMENT.TRACKING_NUMBER != null
                    ? _c("div", { staticClass: "shipment-track" }, [
                        _c("div", { staticClass: "shipment-track__info" }, [
                          _vm._v(
                            "Треккод отслеживается на сайте транспортной компании"
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "shipment-track__num",
                          attrs: { id: "trackNum" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.result.SHIPMENT.TRACKING_NUMBER
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "shipment-track__copy",
                            on: {
                              click: function($event) {
                                return _vm.$root.copyToClipboard("trackNum")
                              }
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "32",
                                  height: "32",
                                  viewBox: "0 0 32 32",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M18.9215 11.6407V7.94434C18.9215 7.39205 18.4738 6.94434 17.9215 6.94434H7.99023C7.43795 6.94434 6.99023 7.39205 6.99023 7.94434V17.8121C6.99023 18.3644 7.43795 18.8121 7.99023 18.8121H11.5385",
                                    stroke: "#3363C9"
                                  }
                                }),
                                _vm._v(" "),
                                _c("rect", {
                                  attrs: {
                                    x: "13.0801",
                                    y: "13.125",
                                    width: "11.9308",
                                    height: "11.9308",
                                    rx: "1",
                                    stroke: "#3363C9"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.loaded && _vm.result.DOCS.length
            ? _c(
                "div",
                { staticClass: "order-section order-section_files noPrint" },
                [
                  _c("h2", { staticClass: "order-section__title" }, [
                    _vm._v("Документы")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "files" },
                    _vm._l(_vm.result.DOCS, function(document) {
                      return _c(
                        "a",
                        {
                          staticClass: "file",
                          attrs: { target: "_blank", href: document.url }
                        },
                        [
                          _c("svg", { attrs: { width: "21", height: "21" } }, [
                            _c("use", {
                              attrs: {
                                "xlink:href": "/images/sprite.svg#pdf-file"
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(document.name)),
                            _c("br"),
                            _vm._v(" Файл " + _vm._s(document.size))
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "order-detail__footer noPrint" },
            [
              _c(
                "router-link",
                { staticClass: "btn btn_back", attrs: { to: "/orders/" } },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "svg-icon",
                      attrs: { width: "7", height: "13" }
                    },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "/images/sprite.svg#caret-left" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "btn_text" }, [_vm._v("Назад")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn btn_lblue", on: { click: _vm.copyOrder } },
                [
                  _c("span", { staticClass: "btn_text" }, [
                    _vm._v("Повторить заказ")
                  ])
                ]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal modal_date",
          class: { "is-open": _vm.dateModalOpen },
          attrs: { id: "modal-date" }
        },
        [
          _c("div", { staticClass: "modal__wrap" }, [
            _c("div", { staticClass: "modal__inner animated zoomIn" }, [
              _c("button", {
                staticClass: "modal__close",
                on: {
                  click: function($event) {
                    _vm.to_hide = true
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(7),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "modal__container select-date__body __row",
                  staticStyle: { position: "relative" }
                },
                [
                  _c("div", { staticClass: "col__50" }, [
                    _c("div", { staticClass: "__col" }, [
                      _c("h6", [_vm._v("Внимание")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "subtext" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tЗабрать заказ можно через 2,5 рабочих часа после отправки на сборку. Доступные даты самовывоза отмечены в календаре."
                        ),
                        _c("br"),
                        _vm._v(
                          "\n\t\t\t\t\t\t\tПо всем вопросам обращайтесь к менеджеру: "
                        ),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.$root.arUser.manager.name)
                          }
                        }),
                        _vm._v(" - "),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.$root.arUser.manager.phone)
                          }
                        })
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._m(8)
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "justify-content": "flex-end",
                    "text-align": "right",
                    "padding-top": "10px"
                  }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setShipDate()
                        }
                      }
                    },
                    [_vm._v("Отправить на сборку")]
                  )
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal modal_date_set ",
          attrs: { id: "modal-date-set" }
        },
        [
          _c("div", { staticClass: "modal__wrap" }, [
            _c("div", { staticClass: "modal__inner animated zoomIn" }, [
              _c("button", {
                staticClass: "modal__close",
                on: {
                  click: function($event) {
                    return _vm.closeShipInfo()
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(9),
              _vm._v(" "),
              _c("div", { staticStyle: { "text-align": "center" } }, [
                _vm._v("\n\t\t\t\t\tДата самомывоза: "),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.result.ORDER_PROPS.SHIP_DATE.VALUE)
                  }
                }),
                _c("br"),
                _vm._v("\n\t\t\t\t\tВремя: "),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.result.ORDER_PROPS.SHIP_TIME.VALUE)
                  }
                }),
                _c("br"),
                _c("br")
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "justify-content": "flex-end",
                    "text-align": "center",
                    "padding-top": "10px"
                  }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.closeShipInfo()
                        }
                      }
                    },
                    [_vm._v("Понятно")]
                  )
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("№ заказа")]),
        _vm._v(" "),
        _c("th", [_vm._v("Дата заказа")]),
        _vm._v(" "),
        _c("th", [_vm._v("Сумма заказа")]),
        _vm._v(" "),
        _c("th", [_vm._v("Резерв до")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "130" } }, [_vm._v("Статус заказа")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("№")]),
        _vm._v(" "),
        _c("th", [_vm._v("Фото")]),
        _vm._v(" "),
        _c("th", [_vm._v("Арт.")]),
        _vm._v(" "),
        _c("th", [_vm._v("Наименование")]),
        _vm._v(" "),
        _c("th", [_vm._v("Склад")]),
        _vm._v(" "),
        _c("th", [_vm._v("Кол-во")]),
        _vm._v(" "),
        _c("th", [_vm._v("Цена за ед.")]),
        _vm._v(" "),
        _c("th", [_vm._v("Стоимость")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("№")]),
        _vm._v(" "),
        _c("th", [_vm._v("Фото")]),
        _vm._v(" "),
        _c("th", [_vm._v("Арт.")]),
        _vm._v(" "),
        _c("th", [_vm._v("Наименование")]),
        _vm._v(" "),
        _c("th", [_vm._v("Склад")]),
        _vm._v(" "),
        _c("th", [_vm._v("Кол-во")]),
        _vm._v(" "),
        _c("th", [_vm._v("Цена за ед.")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn btn_light disabled" }, [
      _c("span", { staticClass: "btn_text" }, [_vm._v("Счет оплачен")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn btn_light disabled" }, [
      _c("span", { staticClass: "btn_text" }, [_vm._v("Заказ выполнен")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn btn_light disabled" }, [
      _c("span", { staticClass: "btn_text" }, [_vm._v("Заказ обрабатывается")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn btn_light disabled" }, [
      _c("span", { staticClass: "btn_text" }, [_vm._v("Оплата недоступна")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal__header" }, [
      _c("h4", [_vm._v("Выберите дату отгрузки")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col__50" }, [
      _c("div", { attrs: { id: "shipment_date" } }),
      _vm._v(" "),
      _c("input", {
        attrs: {
          type: "hidden",
          name: "shipment_time",
          id: "shipment_time",
          value: "00:00"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modal__header", staticStyle: { "text-align": "center" } },
      [_c("h4", [_vm._v("Заказ успешно отправлен на сборку")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }