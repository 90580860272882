<template>
	<div id="not-found">
		<h1>404</h1>
	</div>
</template>

<script>
export default {
	name: 'NotFound',
	data() {
		return {
			
		}
	},
	methods:{
		
	},
	mounted: function(){
		var self = this;
	},
};
</script>

<style scoped>

</style>
