var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "b-content b-content_comparison compare-page" },
    [
      _vm.emptyResult
        ? _c("div", { staticClass: "empty-result-container" }, [
            _c("div", { staticClass: "empty-result-content" }, [
              _c("div", {
                staticClass: "empty-result-text",
                domProps: { innerHTML: _vm._s(_vm.emptyMess) }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "empty-result-btn" },
                [
                  _c(
                    "router-link",
                    { staticClass: "btn", attrs: { to: "/catalog/" } },
                    [_vm._v("В каталог")]
                  )
                ],
                1
              )
            ])
          ])
        : _c("div", {}, [
            _c("div", { staticClass: "b-content__header" }, [
              _c("h2", { staticClass: "b-content__title" }, [
                _vm._v(_vm._s(_vm.title_text))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "b-content__top" }, [
              _c("div", { staticClass: "first-line" }, [
                _c("div", { staticClass: "control_group lbl_top" }, [
                  _c("label", [_vm._v("Показать:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "control_list" }, [
                    _c("div", { staticClass: "control_button" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn_lblue all_props",
                          on: {
                            click: function($event) {
                              _vm.propsToShow = _vm.allProps.display
                              _vm.setActiveBtn("all_props")
                            }
                          }
                        },
                        [_vm._v("Все характеристики")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control_button" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn diff_props",
                          on: {
                            click: function($event) {
                              _vm.propsToShow = _vm.allProps.diff
                              _vm.setActiveBtn("diff_props")
                            }
                          }
                        },
                        [_vm._v("Показать различия")]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "control_group lbl_top" }, [
                  _c("label", [_vm._v("В категориях:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "control_list" }, [
                    _c(
                      "div",
                      {
                        staticClass: "control_dropdown js-dropdown",
                        class: { "is-open": _vm.dropdownOpen },
                        attrs: { "data-mode": "multiselect" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "js-dropdown__trigger",
                            on: {
                              click: function($event) {
                                _vm.dropdownOpen = !_vm.dropdownOpen
                              }
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Все категории (" +
                                  _vm._s(_vm.compare_count) +
                                  ")"
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "list" },
                          [
                            _c("li", { staticClass: "control_checkbox" }, [
                              _c("label", [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    id: "select-all-sections"
                                  },
                                  domProps: { checked: _vm.allSelected },
                                  on: { change: _vm.setSectionFilterAll }
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "Все категории (" +
                                      _vm._s(_vm.compare_count) +
                                      ")"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("i")
                              ])
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.sections, function(section) {
                              return _c(
                                "li",
                                { staticClass: "control_checkbox" },
                                [
                                  _c("label", [
                                    _c("input", {
                                      attrs: {
                                        type: "checkbox",
                                        name: "sections"
                                      },
                                      domProps: {
                                        checked: section.checked,
                                        value: section.id
                                      },
                                      on: { change: _vm.setSectionFilter }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(section.name) +
                                          " (" +
                                          _vm._s(section.cnt) +
                                          ")"
                                      )
                                    ]),
                                    _c("i")
                                  ])
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "control_button" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn_sb-filter js-open-modal",
                          on: {
                            click: function($event) {
                              _vm.filterOpen = true
                            }
                          }
                        },
                        [
                          _c("svg", { attrs: { width: "22", height: "19" } }, [
                            _c("use", {
                              attrs: { "xlink:href": "/images/sprite.svg#tune" }
                            })
                          ])
                        ]
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "modal modal_sb-filter",
                  class: { "is-open show": _vm.filterOpen },
                  attrs: { id: "modal-sb-filter" }
                },
                [
                  _c("aside", { staticClass: "modal__aside right" }, [
                    _c("div", { staticClass: "modal__header" }, [
                      _c("div", { staticClass: "modal__title" }, [
                        _vm._v("Параметры сравнения")
                      ]),
                      _vm._v(" "),
                      _c("button", {
                        staticClass: "modal__close",
                        on: {
                          click: function($event) {
                            _vm.filterOpen = false
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal__container" }, [
                      _c(
                        "div",
                        { staticClass: "sb-filter js-sb-filter" },
                        _vm._l(_vm.filterProps, function(prop) {
                          return _c(
                            "div",
                            {
                              staticClass: "control_checkbox-list",
                              attrs: { id: "filter_block_" + prop.code }
                            },
                            [
                              _c("div", { staticClass: "control_checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    attrs: {
                                      type: "checkbox",
                                      name: prop.code,
                                      value: "Y"
                                    },
                                    domProps: { checked: prop.checked },
                                    on: { change: _vm.setPropsToShow }
                                  }),
                                  _vm._v(" "),
                                  _c("i"),
                                  _c("span", {
                                    domProps: { innerHTML: _vm._s(prop.name) }
                                  })
                                ])
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal__footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn_white",
                          on: { click: _vm.setAllChecked }
                        },
                        [_vm._v("Сбросить")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn_blue",
                          on: {
                            click: function($event) {
                              _vm.filterOpen = false
                            }
                          }
                        },
                        [_vm._v("Применить")]
                      )
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "b-content__body" }, [
              _c("div", {
                staticClass: "component-loading",
                class: { show: _vm.loading }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "comparison-content" }, [
                _c("div", { staticClass: "slider-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "comparison-slider owl-carousel " },
                    _vm._l(_vm.itemsShow, function(item) {
                      return _c(
                        "div",
                        {
                          staticClass: "product-slider__item",
                          attrs: { "data-product-id": item.id }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "product",
                              attrs: { to: item.link, "data-id": item.id }
                            },
                            [
                              _c("div", { staticClass: "product__header" }, [
                                _c(
                                  "div",
                                  { staticClass: "product__labels" },
                                  _vm._l(item.labels, function(label) {
                                    return _c(
                                      "span",
                                      {
                                        staticClass: "label",
                                        class: label.class
                                      },
                                      [_vm._v(_vm._s(label.text))]
                                    )
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _c("button", {
                                  staticClass: "product__remove",
                                  attrs: { title: "Удалить из списка" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.$root.userItemsListsAction(
                                        "compare",
                                        item.id
                                      )
                                      _vm.deletCompareItem(item.id)
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "product__img" }, [
                                _c("img", {
                                  attrs: { src: item.image, alt: "" }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "product__art" }, [
                                _vm._v("Арт. " + _vm._s(item.article))
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "product__name",
                                domProps: { innerHTML: _vm._s(item.name) }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "product__price" }, [
                                item.ratio_price.print_old
                                  ? _c("span", {
                                      staticClass: "old-price",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          item.ratio_price.print_old
                                        )
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "price",
                                  domProps: {
                                    innerHTML: _vm._s(item.ratio_price.print)
                                  }
                                })
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _vm.showTable
                  ? _c(
                      "div",
                      { staticClass: "table-wrapper animated fadeIn" },
                      [
                        _c(
                          "table",
                          {
                            staticClass: "comparison-table js-comparison-table"
                          },
                          [
                            _c("tfoot", [
                              _c(
                                "tr",
                                [
                                  _c("td", [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn owl-prev",
                                        attrs: { type: "button" }
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              role: "img",
                                              height: "13",
                                              width: "14"
                                            }
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "/images/sprite.svg#caret-left"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.displayIDs, function(itemID) {
                                    return _c("td", [
                                      _c("button", {
                                        staticClass:
                                          "btn btn__remove js-comp-remove",
                                        attrs: {
                                          "data-product-id": itemID,
                                          title: "Удалить из сравнения"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            _vm.$root.userItemsListsAction(
                                              "compare",
                                              itemID
                                            )
                                            _vm.deletCompareItem(itemID)
                                          }
                                        }
                                      })
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn owl-next",
                                        attrs: { type: "button" }
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              role: "img",
                                              height: "13",
                                              width: "14"
                                            }
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "/images/sprite.svg#caret-right"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.propsTable, function(prop) {
                                return _c(
                                  "tr",
                                  [
                                    _c("th", [_vm._v(_vm._s(prop.name))]),
                                    _vm._v(" "),
                                    _vm._l(prop.items, function(item) {
                                      return _c("td", {
                                        key: prop.code + "__" + item.id,
                                        class: {
                                          change_state: _vm.propsCalculate
                                        },
                                        attrs: { "data-product-id": item.id },
                                        domProps: {
                                          innerHTML: _vm._s(item.value)
                                        }
                                      })
                                    }),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(" ")]),
                                    _vm._v(" "),
                                    prop.items.length == 2
                                      ? _c("td", [_vm._v(" ")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    prop.items.length == 1
                                      ? _c("td", [_vm._v(" ")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    prop.items.length == 1
                                      ? _c("td", [_vm._v(" ")])
                                      : _vm._e()
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "comparison-content__footer" }, [
                  _c("span", [_vm._v(_vm._s(_vm.title_text))]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn",
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.userItemsListsClearAction.apply(
                            null,
                            arguments
                          )
                        }
                      }
                    },
                    [_vm._v("Удалить все товары")]
                  )
                ])
              ])
            ])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }