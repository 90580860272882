<template>
	<div class="modal" v-bind:class="[{'is-open ': show, 'loading': loading}, modalClass]" v-bind:id="modalId">
		<div class="modal__wrap">
			<div class="modal__inner animated zoomIn">
				<button class="modal__close" v-on:click="to_hide = true"></button>
				<div class="modal__container">
					<div v-bind:class="{ show: loading }" class="component-loading"></div>
					<div class="left-side">
						<div class="product-one__image js-prod-photo">
							<div class="is-active photo-container animated loading-photo">
								<img src="/images/no-photo.png" alt="">
							</div>
							<div v-for="img in productData.images" v-bind:class="{'is-active': img.active}" class="photo-container animated">
								<img v-bind:src="img.big.src" alt="">
							</div>
						</div>
						<ul class="product__gallery owl-carousel" >
							<li v-for="img in productData.images" v-bind:class="{'is-active': img.active}" v-on:click="setGalleryPhoto(img)">
								<a href="javascript:;"><img v-bind:src="img.thumb.src" alt=""></a>
							</li>
						</ul>
					</div>
					
					<div class="right-side">
						<div class="product__header">
							<div class="product__labels">
								<span v-for="label in productData.labels" v-bind:class="label.class" class="label" v-html="label.text"></span>
							</div>
							
							<div class="product__stock">
								<span v-if="productData.can_buy" class="stock stock_green">{{ productData.product.QUANTITY_TEXT }}</span>
								<span v-else class="stock stock_red">Нет в наличии</span>
							</div>
							
							<div class="product__tools">
								<button v-on:click="$root.userItemsListsAction('favorite', productData.id)" v-bind:class="{'is-active': $root.itemInUserList('favorite', productData.id)}" class="js-fav" title="Избранное">
									<svg width="18" height="18">
										<use xlink:href="/images/sprite.svg#star"></use>
									</svg>
								</button>
								<button v-on:click="$root.userItemsListsAction('compare', productData.id)" v-bind:class="{'is-active': $root.itemInUserList('compare', productData.id)}" class="js-comp" title="Сравнить">
									<svg width="22" height="18">
										<use xlink:href="/images/sprite.svg#chart-bar"></use>
									</svg>
								</button>
							</div>
						</div>
						<div class="product__art">Арт. {{ productData.article }}</div>
						<div class="product__name" v-html="productData.name"></div>
						
						<div class="cutter js-cutter" v-bind:class="{'is-open': props_open}">
							<div class="cutter__target">
								<div class="product__char">
									<h4 class="">Характеристики:</h4>
									<div v-for="prop in productData.display_props" class="dl">
										<div class="dt" v-html="prop.name"></div><div class="dd" v-html="prop.value"></div>
									</div>
								</div>
							</div>
							<router-link class="cutter__trigger js-cutter-trigger" :to="productData.link" >
								<span v-if="props_open">Свернуть</span>
								<span v-on:click="to_hide = true" v-else>Подробнее</span>
							</router-link>
						</div>
						
						<form class="product__form">
							<div class="product-one__price">
								<template v-if="productData.can_buy">
									<span v-if="priceData.print_old" v-html="priceData.print_old" class="old-price"></span>
									<span v-html="priceData.print" class="price"></span>
								</template>
								<template v-else>
									<span v-html="priceData.print" class="last-price"></span>
								</template>
							</div>
							<div v-if="productData.can_buy" class="product-one__order">
								<div class="counter js-counter">
									<button v-on:click.prevent="counterMinus" type="button" class="btn decrease">-</button>
									<span class="count">{{curCount}}</span>
									<button v-on:click.prevent="counterPlus" type="button" class="btn increase">+</button>
								</div>
								<div v-if="$root.itemInUserList('basket', ELEMENT_ID)" class="btn btn_transp btn__submit">Добавлено</div>
								<button v-else v-on:click.prevent="$root.userItemsListsAction('basket', ELEMENT_ID, curCount)" type="submit" name="action" value="buy" class="btn btn__submit">В корзину</button>
							</div>
							<div v-else-if="productData.arhive" class="product-one__order">
								<button v-on:click.prevent="analogsAction" v-bind:class="{'disabled': productData.hide_analogs}" type="button" class="btn btn__analogs js-modal-analogs">Аналоги</button>
							</div>
							<div v-else class="product-one__order">
								<button v-on:click.prevent="analogsAction" v-bind:class="{'disabled': productData.hide_analogs}" type="button" class="btn btn__analogs js-modal-analogs">Аналоги</button>
								
								<span v-if="$root.itemInUserList('waiting', ELEMENT_ID)" class="btn btn_transp btn__submit">В листе ожидания</span>
								<button v-else type="submit" v-on:click.prevent="$root.userItemsListsAction('waiting', ELEMENT_ID)" value="waiting-list" name="action" class="btn btn__submit">В лист ожидания</button>
							</div>
						</form>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		modalClass: {type: String, default: 'modal_product'},
		modalId: {type: String, default: 'modal-product'},
	},
	data: function() {
		return {
			loading: true,
			loaded: false,
			show: false,
			to_hide: false,
			props_open: false,
			productData: { files: {}, link: "" },
			priceData: {},
			maxCount: 0,
			curCount: 1,
			showClass: "zoomIn",
			hideClass: "zoomOut",
		}
	},
	computed: {
		ELEMENT_ID() {return this.$root.popupQuickViewData.ELEMENT_ID},
	},
	components: {
		
	},
	mounted: function(){
		var self = this;
		
	},
	watch: {
		ELEMENT_ID: function(ELEMENT_ID){
			var self = this;
			if(ELEMENT_ID != false) {
				self.show = true;
				this.loadProduct();
			}
		},
		show: function(){
			var self = this;
			if(this.show) $("#modal-overlay").fadeIn();
			else {
				self.$root.popupListData.filter = false;
				$("#modal-overlay").fadeOut();
			}
		},
		to_hide:function(to_hide){
			var self = this;
			if(to_hide){
				$("#" + self.modalId + " .modal__inner").addClass("zoomOut");
				setTimeout(function(){
					self.show = false;
					self.to_hide = false;
					self.$root.popupQuickViewData.ELEMENT_ID = false;
					$("#" + self.modalId + " .modal__inner").removeClass("zoomOut");
				}, 400);
			}
		},
	},
	methods:{
		loadProduct: function(){
			var self = this,
				urlData = {
				ELEMENT_ID: self.ELEMENT_ID,
			};
			
			//self.productData = {};
			self.productData.images = "";
			$('.product__gallery.owl-carousel').owlCarousel('destroy');
			
			self.loading = true;
			$.getJSON("/data/components/product.php", urlData, function(data){
				
				self.productData = data.item;
				self.priceData = data.item.ratio_price;
				self.maxCount = parseInt(self.productData.product.QUANTITY);	
				
				
				self.$nextTick(function(){
					$('.product-one__form .options a').blur();
					
					setTimeout(function(){
						self.loading = false;
						
						$('.product__gallery.owl-carousel').owlCarousel({
							margin: 2,
							nav: true,
							dots: false,
							items: 4
						});
					}, 200);
					
					if(self.$root.arUser.basket.ids_product[self.ELEMENT_ID] != undefined){
						self.curCount = parseFloat(self.$root.arUser.basket.ids_product[self.ELEMENT_ID]);
					}else{
						self.curCount = parseFloat(self.productData.ratio);
					}
				})
				
			});
		},
		counterPlus: function(){
			var self = this;
			var newValue = self.curCount + self.productData.ratio;
			if(newValue > self.maxCount) newValue = self.maxCount;
			self.curCount = newValue;
			
			if(self.$root.itemInUserList('basket', self.ELEMENT_ID)){
				if(!!self.timer) clearTimeout(this.timer);
				self.timer = setTimeout(function(){
					self.$root.userItemsListsAction("basket", self.ELEMENT_ID, self.curCount);
				}, 300);
			}
		},
		counterMinus: function(){
			var self = this;
			var newValue = self.curCount - self.productData.ratio;
			if(newValue <= 0) newValue = 0;
			self.curCount = newValue;
			
			if(self.$root.itemInUserList('basket', self.ELEMENT_ID)){
				if(!!self.timer) clearTimeout(this.timer);
				self.timer = setTimeout(function(){
					self.$root.userItemsListsAction("basket", self.ELEMENT_ID, self.curCount);
				}, 300);
			}
		},
		setGalleryPhoto(img){
			var self = this;
			for(var i in self.productData.images) self.productData.images[i].active = false;
			img.active = true;
		},
	},
};
</script>

<style>
.modal{
    overflow-y: auto;
}
.modal__inner.animated{
	-webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}
.modal .component-loading{
	z-index: 20;
    opacity: 0.8;
}
.modal .js-prod-photo, 
.modal .product__gallery{
	transition: all 0.3s;
}
.owl-carousel.owl-hidden.product__gallery{
	opacity: 1;
}

.modal.loading .js-prod-photo{
	opacity: 1;
} 
.modal.loading .photo-container{
	opacity: 0;
}
.modal.loading .product__gallery{
	opacity: 0;
}
.modal .js-prod-photo .loading-photo{opacity: 0;transition: all 0.3s;}
.modal.loading .js-prod-photo .loading-photo{opacity: 1;}
.modal .js-prod-photo .loading-photo img{width: 80%;}


</style>