<template>
	<div style="position:relative;">
		<div v-bind:class="{ 'show': loading }" class="component-loading"></div>
		<div v-bind:id="slider_id" v-bind:class="{ inited: inited, 'loading': loading }" class="large-product-slider owl-carousel">
			<component-list-item
			  v-for="productItem in items" 
			  v-bind:itemClass="'large-product-slider__item'" 
			  v-bind:data-page="navPageNum" 
			  v-bind:key="productItem.id" 
			  v-bind="productItem"
			></component-list-item>
		</div>
	</div>
</template>

<script>
import owlCarousel from 'owl.carousel';
import listItemComponent from '../components/listItemComponent';
export default {
	name: 'productSliderFullComponent',
	props: {
		slider_id: {type: String, default: 'product_slider'},
	},
	data: function() {
		return {
			init: true,
			loading: true,
			inited: false,
			active: true,
			disabled: false,
			navPageNum: 1,
			items: {},
			filter: {},
		}
	},
	computed: {
		activeTab() {return this.$parent.activeTab},
	},
	components: {
		'component-list-item': listItemComponent,
	},
	mounted: function(){
		var self = this;
		self.filter = self.$attrs.filter;
		self.init = self.$attrs.init;
		self.disabled = self.$attrs.disabled;
		
		if(self.init) self.loadProducts();
	},
	watch: {
		activeTab: function(){
			var self = this;
			self.init = self.activeTab.data.slider_id == self.slider_id;
			if(self.init) self.loadProducts();
		},
		disabled: function(disabled){
			var self = this;
			if(disabled && self.$parent.tabs[self.$attrs.id] != undefined) {
				
			}
		},
	},
	methods:{
		loadProducts: function(){
			var self = this;
			self.disabled = false;
			
			if(self.inited) return;
			
			var urlData = {filter: self.filter};
			$.getJSON("/data/components/sectionFiltered.php", urlData, function(data){
				self.items = data.items;
				
				self.$nextTick(function(){
					
					self.initOwl();
					self.loading = false;
					
					$("#" + self.slider_id + " .owl-item").each(function(){
						var el = $(this);
						setTimeout(function(){
							el.addClass("animated zoomIn showed");
						}, el.index() * 100);
						setTimeout(function(){
							el.removeClass("animated zoomIn");
						}, (el.index() * 100) + 1000);
					});
					
					if(self.items.length == 0) {
						var activated = false;
						for(var i in self.$parent.tabs){
							if(i == self.$attrs.id){
								self.$parent.tabs[i].disabled = true;
								self.$parent.tabs[i].active = false;
							}else {
								self.$parent.tabs[i].active = true;
								self.$parent.setActiveTab(self.$parent.tabs[i]);
								activated = true;
								break;
							}
						}
					}else{
						self.inited = true;
						/**/
						$("#tab___" + self.$attrs.id).parent().find("li").removeClass("is-active");
						$("#tab___" + self.$attrs.id).addClass("is-active");
						$("#" + self.$attrs.id).parents(".js-tab").find(".js-tab-contents>div").removeClass("is-active");
						$("#" + self.$attrs.id).addClass("is-active");
						
					}
				})
			})
		},
		initOwl: function(){
			var self = this;
			$('#' + self.slider_id).owlCarousel({
				margin: 30,
				navText: [
					'<svg role="img" height="13" width="14"><use xlink:href="/images/sprite.svg#arrow-left"></use></svg>',
					'<svg role="img" height="13" width="14"><use xlink:href="/images/sprite.svg#arrow-right"></use></svg>'
				],
				nav: true,
				responsive: {
					0: {
						items: 1
					},
					480: {
						items: 2
					},
					1200: {
						items: 3
					}
				}
			});
		},
		
	},
};
</script>

<style>
	.is-active .owl-carousel{
		min-height: 230px;
		display: block;
		flex-wrap: nowrap;
	}
	.large-product-slider.owl-carousel.owl-hidden{opacity:1;}
	.large-product-slider.owl-carousel .owl-stage{display: flex;flex-wrap: nowrap;}
	.large-product-slider.owl-carousel .owl-item{display: flex;}
	.large-product-slider.owl-carousel .owl-item .large-product-slider__item{width: 100%;}
	
</style>