import { render, staticRenderFns } from "./productListComponent.vue?vue&type=template&id=0cbf5190&scoped=true&"
import script from "./productListComponent.vue?vue&type=script&lang=js&"
export * from "./productListComponent.vue?vue&type=script&lang=js&"
import style0 from "./productListComponent.vue?vue&type=style&index=0&id=0cbf5190&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cbf5190",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bitrix/ext_www/personal.feron.ru/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0cbf5190')) {
      api.createRecord('0cbf5190', component.options)
    } else {
      api.reload('0cbf5190', component.options)
    }
    module.hot.accept("./productListComponent.vue?vue&type=template&id=0cbf5190&scoped=true&", function () {
      api.rerender('0cbf5190', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "custom/vue/components/productListComponent.vue"
export default component.exports