var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "b-content b-content_favorites" }, [
    _c("div", {
      staticClass: "component-loading",
      class: { show: _vm.loading }
    }),
    _vm._v(" "),
    !_vm.emptyResult
      ? _c("div", { staticClass: "b-content__header" }, [
          _c("h2", { staticClass: "b-content__title" }, [
            _vm._v(_vm._s(_vm.title_text))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "b-content__header-aside" }, [
            _c(
              "button",
              {
                staticClass: "btn btn_lblue",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.userItemsListsBascetAction.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Добавить все в корзину")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.userItemsListsClearAction.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Очистить список")]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.emptyResult
      ? _c(
          "div",
          { staticClass: "b-content__body" },
          [
            _c("component-product-list", {
              attrs: {
                showViewChanger: false,
                emptyMess: _vm.emptyMess,
                parentLoading: _vm.parentLoading,
                navPageSize: 24,
                filter: _vm.filter
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.emptyResult
      ? _c("div", { staticClass: "empty-result-container" }, [
          _c("div", { staticClass: "empty-result-content" }, [
            _c("div", {
              staticClass: "empty-result-text",
              domProps: { innerHTML: _vm._s(_vm.emptyMess) }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "empty-result-btn" },
              [
                _c(
                  "router-link",
                  { staticClass: "btn", attrs: { to: "/catalog/" } },
                  [_vm._v("В каталог")]
                )
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }