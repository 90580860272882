var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal",
      class: [{ "is-open ": _vm.show }, _vm.modalClass],
      attrs: { id: _vm.modalId }
    },
    [
      _c("div", { staticClass: "modal__wrap" }, [
        _c("div", { staticClass: "modal__inner animated zoomIn" }, [
          _c("div", { staticClass: "modal__header" }, [
            _c("div", {
              staticClass: "modal__title",
              domProps: { innerHTML: _vm._s(_vm.title) }
            }),
            _vm._v(" "),
            _c("button", {
              staticClass: "modal__close",
              on: {
                click: function($event) {
                  _vm.to_hide = true
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal__container" },
            [
              _c("component-product-list", {
                attrs: {
                  emptyMess: _vm.emptyMess,
                  showPager: false,
                  filter: _vm.filter
                }
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }