var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeTab
    ? _c("div", { staticStyle: { position: "relative" } }, [
        _c("div", { staticClass: "popular js-tab" }, [
          _c(
            "div",
            {
              staticClass: "popular__header",
              attrs: { id: "active_" + _vm.activeTab.id }
            },
            [
              _vm.title
                ? _c("h2", { staticClass: "popular__title" }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "js-tab-controls", class: _vm.classes.header },
                _vm._l(_vm.tabs, function(tab) {
                  return _c(
                    "li",
                    {
                      class: {
                        "is-active": tab.active,
                        disabled: tab.disabled
                      },
                      attrs: { id: "tab___" + tab.id },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setActiveTab(tab)
                        }
                      }
                    },
                    [
                      _c("a", { attrs: { href: "#" + tab.id } }, [
                        _vm._v(_vm._s(tab.name))
                      ])
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "js-tab-contents", class: _vm.classes.body },
            _vm._l(_vm.tabs, function(tab) {
              return _c(
                "div",
                {
                  staticClass: "popular__tab",
                  class: { "is-active": tab.active, disabled: tab.disabled },
                  attrs: { id: tab.id }
                },
                [
                  tab.mode == "product_slider"
                    ? _c(
                        "div",
                        [
                          _c(
                            "component-product-slider",
                            _vm._b(
                              { attrs: { id: tab.id } },
                              "component-product-slider",
                              tab.data,
                              false
                            )
                          )
                        ],
                        1
                      )
                    : tab.mode == "product_slider_full"
                    ? _c(
                        "div",
                        [
                          _c(
                            "component-product-slider-full",
                            _vm._b(
                              { attrs: { id: tab.id } },
                              "component-product-slider-full",
                              tab.data,
                              false
                            )
                          )
                        ],
                        1
                      )
                    : tab.mode == "property_list"
                    ? _c(
                        "div",
                        _vm._l(tab.data.properties, function(arProp) {
                          return _c("div", { staticClass: "dl" }, [
                            _c("div", {
                              staticClass: "dt",
                              domProps: { innerHTML: _vm._s(arProp.name) }
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "dd",
                              domProps: { innerHTML: _vm._s(arProp.value) }
                            })
                          ])
                        }),
                        0
                      )
                    : _c("div", { domProps: { innerHTML: _vm._s(tab.html) } })
                ]
              )
            }),
            0
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }