<template>
	<div class="b-content b-content_favorites">
		<div v-bind:class="{ show: loading }" class="component-loading"></div>
	
		<div v-if="!emptyResult" class="b-content__header">
			<h2 class="b-content__title">{{title_text}}</h2>
			<div class="b-content__header-aside">
				<button v-on:click.prevent="userItemsListsBascetAction" class="btn btn_lblue">Добавить все в корзину</button>
				<button v-on:click.prevent="userItemsListsClearAction" class="btn">Очистить список</button>
			</div>
		</div>
		<div v-if="!emptyResult" class="b-content__body">
			<component-product-list 
				v-bind:showViewChanger="false" 
				v-bind:emptyMess="emptyMess" 
				v-bind:parentLoading="parentLoading" 
				v-bind:navPageSize="24" 
				v-bind:filter="filter"></component-product-list>
		</div>
		<div v-if="emptyResult" class="empty-result-container">
			<div class="empty-result-content">
				<div class="empty-result-text" v-html="emptyMess"></div>
				<div class="empty-result-btn">
					<router-link :to="'/catalog/'" class="btn">В каталог</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import productListComponent from '../components/productListComponent';


export default {
	name: 'FavoritsComponent',
	data() {
		return {
			emptyResult: false,
			loading: false,
			can_delete: true,
			parentLoading: false,
			emptyMess: "У вас нет товаров в избранном",
			filter: {},
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
				{ link: '/favorits/', name: 'Избранное' },
			]
		};
	},
	computed: {
		title_text() {
			var self = this;
			return self.favorite_count + " " + self.$root.declOfNum(self.favorite_count, ['позиция', 'позиции', 'позиций']) + " в избранном";
		},
		favorite_ids() {return this.$root.arUser.favorite.items},
		favorite_count() {return this.$root.arUser.favorite.count},
	},
	components: {
		'component-product-list': productListComponent,
	},
	methods:{
		userItemsListsBascetAction: function(){
			//this.loading = true;
			this.$root.userItemsListsBascetAction('favorite');
		},
		userItemsListsClearAction: function(){
			this.loading = true;
			this.$root.userItemsListsClearAction('favorite');
		},
		checkEmpty: function(){
			var self = this;
			if(self.favorite_count <= 0) {
				setTimeout(function(){
					self.loading = false;
					self.emptyResult = true;
					self.filter = {};
				}, 500);
			}
		},
	},
	mounted: function(){
		var self = this;
		self.$root.breadcrumbs = self.breadcrumbs;
		if(self.favorite_count <= 0) {
			self.emptyResult = true;
			self.filter = {};
		}else self.filter = {"ID": this.favorite_ids, IBLOCK_ID: 56};
		
		self.$root.$on('favoriteUpdated', self.checkEmpty);	
	},
};
</script>

<style scoped>

</style>
