var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "b-content b-content_ordering" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "b-content__body" }, [
      _c("div", {
        staticClass: "component-loading",
        class: { show: _vm.loading }
      }),
      _vm._v(" "),
      !_vm.order_error
        ? _c("div", { staticClass: "order-result" }, [
            _vm.result.ORDER.CANCELED != "Y"
              ? _c("div", { staticClass: "order-result__header" }, [
                  _c("h2", [_vm._v("Ваш заказ успешно сформирован!")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("Статус заказа: "),
                    _c("b", [
                      _vm._v('"' + _vm._s(_vm.result.ORDER.STATUS.NAME) + '"')
                    ]),
                    _vm._v(".")
                  ]),
                  _vm._v(" "),
                  _vm.result.ORDER.STATUS.DESCRIPTION != ""
                    ? _c("span", { staticClass: "status-descr" }, [
                        _vm._v(_vm._s(_vm.result.ORDER.STATUS.DESCRIPTION))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _c("br")
                ])
              : _c("div", { staticClass: "order-result__header" }, [
                  _c("h2", [
                    _vm._v("Ваш заказ "),
                    _c("a", { attrs: { href: "" } }, [
                      _vm._v("№ " + _vm._s(_vm.order_id))
                    ]),
                    _vm._v(" отменен!")
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("Статус заказа: "),
                    _c("b", [
                      _vm._v('"' + _vm._s(_vm.result.ORDER.STATUS.NAME) + '"')
                    ]),
                    _vm._v(".")
                  ])
                ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-result__footer" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn",
                    attrs: { to: "/orders/order/" + _vm.order_id + "/" }
                  },
                  [_vm._v("Перейти к заказу")]
                )
              ],
              1
            )
          ])
        : _c("div", { staticClass: "order-result" }, [
            _c("div", { staticClass: "order-result__header" }, [
              _c("h2", [
                _vm._v("Заказ "),
                _c("a", { attrs: { href: "" } }, [
                  _vm._v("№ " + _vm._s(_vm.order_id))
                ]),
                _vm._v(" не найден!")
              ]),
              _vm._v(" "),
              _c("span", [_vm._v("Заказ был удален или оформлен не Вами.")])
            ])
          ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b-content__header" }, [
      _c("h2", { staticClass: "b-content__title" }, [
        _c("small", [_vm._v("Корзина")]),
        _c("span", [_vm._v("Оформление заказа")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }