<template>
	<div id="login-component">
		<div class="login-window animated">
			<div class="left-side ">
			
				<div v-bind:class="{ show: loading }" class="component-loading"></div>
				
				<div class="left-side-login animated"  v-bind:class="{'fadeOutLeft': !loginState, 'fadeInLeft': loginState}">
					<template v-if="changePassword">
						<template v-if="passSuccessChange">
							<div class="forgot-send-info animated zoomIn" style="padding-bottom:50px;">
								<img src="/images/check.svg" />
								<div class="login-head-label" style="text-align: center;">
									<span>Пароль успешно изменен<br><br></span>
								</div>
								<router-link class="btn" :to="'/login/'">
									<svg class="svg-icon" width="14" height="14">
										<use xlink:href="/images/sprite.svg#login-btn"></use>
									</svg>
									<span class="btn_text">Вход</span>
								</router-link>
							</div>
						</template>
						<template v-else>
							<label class="login-head-label chpass-label">Смена пароля</label>
							<form class="chpass-form">
								<input type="hidden" name="AUTH_ACTION" value="Изменить пароль">
								<div v-bind:class="{'control_error': errors.login}" class="control_text">
									<label>E-mail</label>
									<input v-on:input="checkFieldsChPass" id="login-input" v-bind:value="login" type="text" name="USER_LOGIN" required="" placeholder="E-mail">
									<span v-if="errors.login" class="control_error_info animated heartBeat">{{ errorText.login }}</span>
								</div>
								<div v-bind:class="{'control_error': errors.checkword}" class="control_text">
									<label>Контрольная строка</label>
									<input v-on:input="checkFieldsChPass" id="checkword-input"v-bind:value="checkword" type="text" name="USER_CHECKWORD" required="" placeholder="Контрольная строка">
									<span v-if="errors.checkword" class="control_error_info animated heartBeat">{{ errorText.checkword }}</span>
								</div>
								<div v-bind:class="{'control_error': errors.new_password}" class="control_text">
									<label>Пароль</label>
									<input v-if="showPass.new_password" v-on:input="checkFieldsChPass" v-bind:value="new_password" id="new_password-input" type="text" name="USER_PASSWORD" required="" placeholder="Пароль">
									<input v-else v-on:input="checkFieldsChPass" id="new_password-input" v-bind:value="new_password" type="password" name="USER_PASSWORD" required="" placeholder="Пароль">
									
									<button class="pass-toggle" type="button" v-on:click.prevent="showPass.new_password = !showPass.new_password">
										<svg width="14" height="8">
											<use xlink:href="/images/sprite.svg#eye"></use>
										</svg>
									</button>
									<span v-if="errors.new_password" class="control_error_info animated heartBeat">{{ errorText.new_password }}</span>
								</div>
								<div v-bind:class="{'control_error': errors.confirm_password}" class="control_text">
									<label>Подтверждение пароля</label>
									<input v-if="showPass.confirm_password" v-on:input="checkFieldsChPass" v-bind:value="confirm_password" id="confirm_password-input" type="text" name="USER_CONFIRM_PASSWORD" required="" placeholder="Подтверждение пароля">
									<input v-else v-on:input="checkFieldsChPass" id="confirm_password-input" v-bind:value="confirm_password" type="password" name="USER_CONFIRM_PASSWORD" required="" placeholder="Подтверждение пароля">
									
									<button class="pass-toggle" type="button" v-on:click.prevent="showPass.confirm_password = !showPass.confirm_password">
										<svg width="14" height="8">
											<use xlink:href="/images/sprite.svg#eye"></use>
										</svg>
									</button>
									<span v-if="errors.confirm_password" class="control_error_info animated heartBeat">{{ errorText.confirm_password }}</span>
								</div>
							</form>
							<span v-if="chPasswordError" class="auth_error_info animated fadeIn" v-html="chPasswordErrorText"></span>
							<div class="control_btn">
								<button v-on:click="submitChangePass" class="btn">
									<svg class="svg-icon" width="14" height="14">
										<use xlink:href="/images/sprite.svg#login-btn"></use>
									</svg>
									<span class="btn_text">Поменять</span>
								</button>
							</div>
						</template>
					</template>
					<template v-else>
						<label class="login-head-label">Добро пожаловать<br>в личный кабинет!</label>
						<form class="login-form">
							<div v-bind:class="{'control_error': errors.login}" class="control_text">
								<label>E-mail</label>
								<input v-on:input="checkFields" id="login-input" type="text" name="login" required="" placeholder="E-mail">
								<span v-if="errors.login" class="control_error_info animated heartBeat">{{ errorText.login }}</span>
							</div>
							<div v-bind:class="{'control_error': errors.password}" class="control_text">
								<label>Пароль</label>
								
								<a class="remind-pass-link" href="" v-on:click.prevent="loginState = false">Забыли пароль?</a>
								
								<input v-if="showPass.password" v-on:input="checkFields" v-bind:value="password" id="password-input" type="text" name="password" required="" placeholder="Пароль">
								<input v-else v-on:input="checkFields" id="password-input" v-bind:value="password" type="password" name="password" required="" placeholder="Пароль">
								
								<button class="pass-toggle" type="button" v-on:click.prevent="showPass.password = !showPass.password">
									<svg width="14" height="8">
										<use xlink:href="/images/sprite.svg#eye"></use>
									</svg>
								</button>
								
								<span v-if="errors.password" class="control_error_info animated heartBeat">{{ errorText.password }}</span>
							</div>
						</form>
						<span v-if="authError" class="auth_error_info animated fadeIn" v-html="authErrorText"></span>
						<div class="control_btn">
							<button v-on:click="submitLogin" class="btn">
								<svg class="svg-icon" width="14" height="14">
									<use xlink:href="/images/sprite.svg#login-btn"></use>
								</svg>
								<span class="btn_text">Войти</span>
							</button>
						</div>
						<div class="login_description">
							<b>Доступ в кабинет возможен только для действующих клиентов.</b>
							<span>Если Вы являетесь  постоянным клиентом компании Ферон, запросите данные доступа в личный кабинет у персонального менеджера.</span>
						</div>
					</template>
				</div>
				
				<div class="left-side-forgot animated" v-bind:class="{'fadeInRight': !loginState, 'fadeOutRight' : !!loginState}">
					<a class="back-link" href="" v-on:click.prevent="loginState = true">
						<svg class="svg-icon" width="6" height="12">
							<use xlink:href="/images/sprite.svg#back"></use>
						</svg>
						<span class="btn_text">Назад</span>
					</a>
					
					<template v-if="forgotSuccessSend">
						<div class="forgot-send-info animated zoomIn">
							<img src="/images/send.svg" />
							<div class="login_description">
								<span>Данные для восстановления пароля будут<br>отправлены на указанный E-mail</span>
								<b>{{forgot}}</b>
							</div>
							<button class="btn"><span v-on:click.prevent="loginState = true" class="btn_text">Понятно</span></button>
						</div>
					</template>
					<template v-else>
						<label class="login-head-label">Восстановление<br>пароля</label>
						<form class="login-form">
							<div v-bind:class="{'control_error': errors.login}" class="control_text">
								<label>E-mail</label>
								<input v-on:input="checkFields" id="forgot-login-input" type="text" name="forgot_login" required="" placeholder="E-mail">
								<span v-if="errors.login" class="control_error_info animated heartBeat">{{ errorText.login }}</span>
							</div>
						</form>
						<span v-if="forgotError" class="auth_error_info animated fadeIn" v-html="forgotErrorText"></span>
						<div class="control_btn">
							<button v-on:click="submitForgot" class="btn">
								<span class="btn_text">Воccтановить</span>
							</button>
						</div>
						<div class="login_description">
							<span>Данные для восстановления пароля будут<br>отправлены на указанный E-mail</span>
						</div>
					</template>
				</div>
			</div>
			<div class="right-side">
				<img class="login-logo" src="/images/white-logo.png" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LoginComponent',
	data() {
		var self = this;
		return {
			login: self.$route.query.USER_LOGIN != undefined ? self.$route.query.USER_LOGIN : "",
			password: "",
			forgot: "",
			checkword: self.$route.query.USER_CHECKWORD != undefined ? self.$route.query.USER_CHECKWORD : "",
			new_password: "",
			confirm_password: "",
			loading: true,
			authError: false,
			forgotError: false,
			chPasswordError: false,
			forgotSuccessSend: false,
			passSuccessChange: false,
			loginState: true,
			authErrorText: "",
			forgotErrorText: "",
			chPasswordErrorText: "",
			errors: {login: false, password: false, forgot: false, checkword: false, new_password: false, confirm_password: false},
			showPass: {password: false, new_password: false, confirm_password: false },
			errorText: {login: "", password: "", forgot: "", checkword: "", new_password: "", confirm_password: ""},
		};
	},
	computed: {
		changePassword() {return this.$route.name == "cpass"},
	},
	watch: {
		loginState: function(){
			this.authError = false;
			this.forgotError = false;
			this.forgotSuccessSend = false;
			this.passSuccessChange = true;
			this.errors = {login: false, password: false, forgot: false, checkword: false, new_password: false, confirm_password: false};
		},
		changePassword: function(val){
			this.authError = false;
			this.forgotError = false;
			this.forgotSuccessSend = false;
			this.passSuccessChange = true;
			this.errors = {login: false, password: false, forgot: false, checkword: false, new_password: false, confirm_password: false};
		},
	},
	methods:{
		submitLogin: function(){
			var self = this;
			self.setFields();
			
			if(self.login.length < 3){
				self.errors.login = true;
				self.errorText.login = "Укажите E-mail";
			}else self.errors.login = false;
			
			if(self.password.length < 3){
				self.errors.password = true;
				self.errorText.password = "Укажите пароль";
			}else self.errors.password = false;
			
			if(!self.errors.login && !self.errors.password){
				self.loading = true
				var data = $(".login-form").serialize();
				$.getJSON(self.$root.ajaxUrl + "auth.php", data, function(json){
					self.loading = false
					if(json.status) {
						$(".login-window").addClass("flipOutY");
						window.isAuthorized = true;
						setTimeout(function(){
							self.$root.$router.push({ path: '/' });
							self.$root.arUser = json.arUser;
							window.isAuthorized = json.arUser.authorized;
							window.location.reload();
						}, 1000);
					}
					else {
						self.authError = true;
						self.authErrorText = json.error;
					}
				})
			}
			//
		},
		submitForgot: function(){
			var self = this;
			self.setFields();
			
			if(self.forgot.length < 3){
				self.errors.forgot = true;
				self.errorText.forgot = "Укажите E-mail";
			}else if(!self.$root.checkEmail(self.forgot)){
				self.errors.forgot = true;
				self.errorText.forgot = "Укажите корректный E-mail";
			}else  self.errors.forgot = false;
			
			if(!self.errors.forgot){
				self.loading = true
				var data = {
					AUTH_FORGOOT: "Y",
					AUTH_ACTION: "Выслать",
					USER_LOGIN: $("#forgot-login-input").val(),
					USER_EMAIL: $("#forgot-login-input").val(),
				};
				$.post(self.$root.ajaxUrl + "auth.php", data, function(json){
					var json = JSON.parse(json);
					self.loading = false
					if(json.status){
						self.forgotSuccessSend = true;
					}else{
						self.forgotError = true;
						self.forgotErrorText = json.error;
					}
				})
			}
		},
		submitChangePass: function(){
			var self = this;
			var errorsCnt = 0;
			self.setFields();
			
			if(self.login.length < 3){
				errorsCnt++;
				self.errors.login = true;
				self.errorText.login = "Укажите E-mail";
			}else  self.errors.login = false;
			
			if(self.checkword.length < 3){
				errorsCnt++;
				self.errors.checkword = true;
				self.errorText.checkword = "Укажите контрольную строку";
			}else self.errors.checkword = false;
			
			if(self.new_password.length < 3){
				errorsCnt++;
				self.errors.new_password = true;
				self.errorText.new_password = "Укажите пароль";
			}else self.errors.new_password = false;
			
			if(self.confirm_password.length < 3){
				errorsCnt++;
				self.errors.confirm_password = true;
				self.errorText.confirm_password = "Укажите подтверждение пароля";
			}else self.errors.confirm_password = false;
			
			if(errorsCnt == 0){
				self.loading = true;
				var data = $(".chpass-form").serialize()
				$.post(self.$root.ajaxUrl + "auth.php?chpass=Y", data, function(json){
					var json = JSON.parse(json);
					self.loading = false
					if(json.status){
						self.passSuccessChange = true;
					}else{
						self.chPasswordError = true;
						self.chPasswordErrorText = json.error;
					}
				})
			}
		},
		checkFieldsChPass: function(){
			var self = this;
			self.setFields();
		},
		checkFields: function(){
			var self = this;
			self.setFields();
			self.authError = false;
			
			if(self.login.length > 0) self.errors.login = false;
			if(self.password.length > 0) self.errors.password = false;
		},
		setFields: function(){
			var self = this,
				loginInput = $("#login-input"),
				passInput = $("#password-input"),
				forgotInput = $("#forgot-login-input");
				
			self.login = loginInput.val();
			self.password = passInput.val();
			self.forgot = forgotInput.val();
			
			self.checkword = $("#checkword-input").val();
			self.new_password = $("#new_password-input").val();
			self.confirm_password = $("#confirm_password-input").val();
		},
	},
	mounted: function(){
		var self = this;
		self.loading = false
		if(self.$root.$route.query.logout){
			$.getJSON(self.$root.ajaxUrl + "auth.php?logout=yes", function(json){
				self.$root.arUser = json.arUser;
				window.isAuthorized = false;
			})
		}
		$(".login-window").addClass("flipInY");
	},
};
</script>

<style scoped>
#login-component{
	display: flex;
    min-height: calc(100vh - 90px);
    align-items: center;
    justify-content: center;
}
.login-window{    
    background: #fff;
    box-shadow: 0 0 25px rgb(0 0 0 / 15%);
    border-radius: 5px;
	text-align: center;   
	max-width: 790px;
	display: flex;

}
.login-window .left-side{
	padding: 30px;
	width:320px;
	display: flex;
	overflow: hidden;
	position: relative;
	flex-direction: column;
}    
.login-window .right-side{
	display: flex;
	position: relative;
	width:470px;
	background: url(/images/login.jpg) 50% 50% no-repeat;
	background-size: cover;
}    
.login-window .right-side .login-logo{
	position: absolute;
	bottom: 30px;
	right: 30px;
}
.login-window .login-head-label{
	display: block;
    font-family: 'Museo Sans Cyrl 500', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 28px;
	letter-spacing: -0.408px;
	color: #000000;    
	text-align: left;
    margin-bottom: 30px;
}
.login-window .login-head-label.chpass-label{
    margin-bottom: 20px;
}
.login-window .control_text{
    min-width: 220px;
	margin-bottom: 10px;
	position: relative;
	text-align: left;
}
.login-window .control_text .pass-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    width: 32px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: transparent;
    border: 0;
    color: #C4C4C4;
}
.login-window .control_text input[type=text] ~ .pass-toggle {
    color: #3363C9;
}

.login-window .control_btn{
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px;
}
.login-window .control_text label{
	font-size: 10px;
	line-height: 18px;
	text-align: left;
	color: #52504C;
}
.login-window .control_error_info{
	display: block;
    position: absolute;
    font-size: 10px;
    top: 100%;
    left: 0;
    color: red;
    line-height: 18px;
}
.auth_error_info{
    color: red;
    line-height: 18px;
    font-size: 12px;
    text-align: left;
    margin-bottom: -12px;
	display: block;
}

.login-window input{
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    height: 32px;
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Museo Sans Cyrl 300', sans-serif;
    padding: 10px 12px;
	transition: all 0.3s ease;
}
.login-window .login_description{
	font-style: normal;
	font-size: 10px;
	line-height: 14px;
	color: #52504C;
}
.login-window .login_description b{
	font-family: 'Museo Sans Cyrl 700', sans-serif;    
	display: flex;
    text-align: left;
    margin-top: 20px;
}
.login-window .login_description span{
	font-family: 'Museo Sans Cyrl 300', sans-serif;   
	display: flex;
	text-align: left;
	margin-top: 20px;
}
.login-window .remind-pass-link{
	font-style: normal;
    font-size: 10px;
    line-height: 18px;
    text-align: right;
    color: #3363C9;
    position: absolute;
    top: 5px;
    right: 0;
}
.control_text.control_error input{
	color: red;
    border-color: red;
}

.control_text.control_error input::-webkit-input-placeholder {color:red;}
.control_text.control_error input::-moz-placeholder          {color:red;}
.control_text.control_error input:-moz-placeholder           {color:red;}/* Firefox 18- */
.control_text.control_error input:-ms-input-placeholder      {color:red;}

.left-side-login,
.left-side-forgot{
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
}
.left-side-forgot{
	position: absolute;
    top: 30px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    text-align: left;
	opacity:0;
}
.left-side-forgot .login-head-label{
	margin-top:45px;
}

.login-window .back-link{
	font-family: 'Museo Sans Cyrl 300', sans-serif;   
	font-style: normal;
	font-size: 12px;
	line-height: 22px;
	text-align: left;
	letter-spacing: -0.408px;
	color: #3363C9;    
}
.login-window .back-link span{
	padding-left: 5px;
	vertical-align: middle;
}

.forgot-send-info{text-align:center;}
.forgot-send-info img{
	width: 60px;
    margin: 50px auto 20px;
    display: block;
}
.forgot-send-info .login_description{
	
}
.forgot-send-info .login_description span{ 
	text-align:center; display: block;
}

.forgot-send-info .login_description b{ 
	text-align:center; display: block;margin: 10px auto 30px;
}

@media (max-width:800px){
	.login-window .right-side{
		display: none;
	}  
}
</style>
