var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "catalog" }, [
    _c("div", { staticClass: "search-result" }, [
      _c("p", { staticClass: "search-result__text search-result__text_base" }, [
        _vm._v("\n      Каталог товаров   |   Результаты по запросу\n      "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.query.length > 0,
                expression: "query.length > 0"
              }
            ],
            staticClass:
              "search-result__text search-result__text_lg search-result__font_600"
          },
          [_vm._v("'" + _vm._s(_vm.query) + "'")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "search-result__group" }, [
        _c(
          "p",
          {
            staticClass:
              "search-result__text search-result__text_sm search-result__font_400"
          },
          [
            _vm._v("\n        найдено: "),
            _c(
              "span",
              {
                staticClass:
                  "search-result__text search-result__text_base search-result__font_600 search-result__text_blue"
              },
              [_vm._v(_vm._s(_vm.NavRecordCount))]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "svg",
          {
            staticClass: "search-result__icon",
            attrs: {
              width: "16",
              height: "15",
              viewBox: "0 0 16 15",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            },
            on: { click: _vm.resetResult }
          },
          [
            _c("path", {
              attrs: {
                d: "M1.00195 1L14.0019 13.9999",
                stroke: "#3363C9",
                "stroke-width": "2"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M14.0002 1.00012L1.00023 14",
                stroke: "#3363C9",
                "stroke-width": "2"
              }
            })
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "environ environ_hr" }, [
      _c(
        "div",
        {
          staticClass: "environ__group",
          class: { hidden: _vm.profiles.count == 1 }
        },
        [
          _c("label", { staticClass: "environ__label" }, [
            _vm._v("Активный договор")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "environ__select" }, [
            _c(
              "select",
              {
                staticClass: "select-profile select-profile-home select",
                attrs: { name: "" }
              },
              _vm._l(_vm.profiles.list, function(profile) {
                return _c("option", {
                  class: { active: profile.active },
                  domProps: {
                    value: profile.id,
                    selected: profile.active,
                    innerHTML: _vm._s(profile.props.ORDER_PROP_75.value)
                  }
                })
              }),
              0
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            { hidden: _vm.activeProfile.stores.count == 1 },
            "environ__group"
          ]
        },
        [
          _c("label", { staticClass: "environ__label" }, [
            _vm._v("Активный склад")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "environ__select" }, [
            _c(
              "select",
              {
                staticClass: "select-store select-store-home select",
                attrs: { name: "" }
              },
              _vm._l(_vm.activeProfile.stores.list, function(store) {
                return _c("option", {
                  class: { active: store.selected },
                  domProps: {
                    value: store.ID,
                    selected: store.selected,
                    innerHTML: _vm._s(store.TITLE)
                  }
                })
              }),
              0
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "catalog__filter" },
      [
        _c("search-page-filters", {
          attrs: {
            article: _vm.article,
            model: _vm.model,
            "is-list-view-mode": _vm.isListViewMode,
            sort: _vm.sort,
            checkboxes: _vm.checkboxes
          },
          on: {
            "update:article": function($event) {
              _vm.article = $event
            },
            "update:checkbox": function($event) {
              return _vm.onUpdateCheckbox($event)
            },
            "update:isListViewMode": function($event) {
              _vm.isListViewMode = $event
            },
            "update:model": function($event) {
              _vm.model = $event
            },
            "update:sort": function($event) {
              return _vm.updateSort($event)
            },
            "change:query": _vm.getData
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "product-list", staticStyle: { "margin-top": "45px" } },
      [
        _c("div", { staticClass: "component-loading" }),
        _vm._v(" "),
        _c("div", { staticClass: "catalog__grid" }, [
          _c(
            "div",
            {
              class: [
                "product-grid",
                _vm.isListViewMode ? "list-mode" : "grid-mode"
              ]
            },
            _vm._l(_vm.results, function(productItem) {
              return _c(
                "list-item-component",
                _vm._b(
                  {
                    key: "list_product_" + productItem.id,
                    attrs: { "data-page": _vm.navigation }
                  },
                  "list-item-component",
                  productItem,
                  false
                )
              )
            }),
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _vm.results.length
      ? _c("div", { staticClass: "b-content__footer" }, [
          _c("div", { staticClass: "pagination-center" }, [
            _c(
              "span",
              {
                class: [
                  { loading: _vm.isLoadingMore, disabled: !_vm.isLastPage },
                  "btn btn_transp js-catalog-load-more"
                ],
                on: { click: _vm.loadMore }
              },
              [_vm._v("\n        Показать еще 12\n      ")]
            ),
            _vm._v(" "),
            _vm.navParam.navPages.length
              ? _c("div", { staticClass: "pagination" }, [
                  _c(
                    "ul",
                    _vm._l(_vm.navParam.navPages, function(page) {
                      return _c(
                        "li",
                        {
                          class: {
                            "is-active": page.active,
                            disabled: page.disabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.goToPage(page.navPageNum)
                            }
                          }
                        },
                        [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(page.text) }
                          })
                        ]
                      )
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "pages-info" }, [
              _vm._v(
                "Страница " +
                  _vm._s(_vm.navPageNum) +
                  " из " +
                  _vm._s(_vm.navPageCount)
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }