import HomeComponent from './pages/home.vue';
import LoginComponent from './pages/login.vue';
import CatalogComponent from './pages/catalog.vue';
import SectionPageComponent from './pages/section.vue';
import ProductPageComponent from './pages/product.vue';
import NotFoundComponent from './pages/notFound.vue';
import FavoritsComponent from './pages/favorits.vue';
import WaitingComponent from './pages/waiting.vue';
import CartComponent from './pages/cart.vue';
import OrderComponent from './pages/order.vue';
import OrderClientComponent from './pages/order_client.vue';
import PersonalComponent from './pages/personal.vue';
import OrderConfirmComponent from './pages/order_confirm.vue';
import OrderClientConfirmComponent from './pages/order_client_confirm.vue';
import CompareComponent from './pages/compare.vue';
import OrdersComponent from './pages/orders.vue';
import OrdersClientComponent from './pages/orders_client.vue';
import OrderDetailComponent from './pages/order_detail.vue';
import OrderClientDetailComponent from './pages/orders_client_detail.vue';
import MessagesComponent from './pages/messages.vue';
import MessageDetailComponent from './pages/message.vue';
import NotificationsComponent from './pages/notifications.vue';
import BonusComponent from './pages/bonus.vue';
import BonusCoditionComponent from './pages/condition.vue';
import DownloarComponent from './pages/download.vue';
import SearchPage from './pages/search';

const routes = [
	{
		name: 'home',
		path: '/',
		component: HomeComponent
	},
	{
		name: 'search',
		path: '/search',
		component: SearchPage
	},
	{
		name: 'login',
		path: '/login/',
		component: LoginComponent
	},
	{
		name: 'cpass',
		path: '/login/cpass/',
		component: LoginComponent
	},
	{
		name: 'catalog',
		path: '/catalog/',
		meta: {catalog: true, baseUrl: "/catalog/"},
		component: SectionPageComponent
	},
	{
		name: 'catalog_page',
		path: '/catalog/:navPageNum/',
		meta: {catalog: true, baseUrl: "/catalog/"},
		component: SectionPageComponent
	},
	{
		name: 'catalog_filter',
		path: '/catalog/filter/*/apply/',
		meta: {catalog: true},
		component: SectionPageComponent
	},
	{
		name: 'catalog_page_filter',
		path: '/catalog/filter/*/apply/:navPageNum/',
		meta: {catalog: true},
		component: SectionPageComponent
	},
	{
		name: 'section',
		path: '/catalog/section/:section_id/',
		meta: {catalog: true},
		component: SectionPageComponent
	},
	{
		name: 'section_page',
		path: '/catalog/section/:section_id/:navPageNum/',
		meta: {catalog: true},
		component: SectionPageComponent
	},
	{
		name: 'section_filter',
		path: '/catalog/section/:section_id/filter/*/apply/',
		meta: {catalog: true},
		component: SectionPageComponent
	},
	{
		name: 'section_page_filter',
		path: '/catalog/section/:section_id/filter/*/apply/:navPageNum/',
		meta: {catalog: true},
		component: SectionPageComponent
	},
	{
		name: 'product',
		path: '/catalog/section/:section_id/product/:product_id/',
		meta: {catalog: true},
		component: ProductPageComponent
	},
	{
		name: 'favorite',
		path: '/favorits/',
		meta: {baseUrl: "/favorits/"},
		component: FavoritsComponent
	},
	{
		name: 'favorits_pages',
		path: '/favorits/:navPageNum/',
		meta: {baseUrl: "/favorits/"},
		component: FavoritsComponent
	},
	{
		name: 'waiting',
		path: '/waiting/',
		meta: {baseUrl: "/waiting/", hideSubscribe: true},
		component: WaitingComponent
	},
	{
		name: 'waiting_pages',
		path: '/waiting/:navPageNum/',
		meta: {baseUrl: "/waiting/", hideSubscribe: true},
		component: WaitingComponent
	},
	{
		name: 'cart',
		path: '/cart/',
		component: CartComponent
	},
	{
		name: 'order',
		path: '/order/',
		component: OrderComponent
	},
	{
		name: 'order_client',
		path: '/order_client/',
		component: OrderClientComponent
	},
	{
		name: 'order_confirm',
		path: '/order/:order_id/',
		component: OrderConfirmComponent
	},
	{
		name: 'order_client_confirm',
		path: '/order_client_confirm/:order_id/',
		component: OrderClientConfirmComponent
	},
	{
		name: 'personal',
		path: '/personal/',
		component: PersonalComponent
	},
	{
		name: 'compare',
		path: '/compare/',
		component: CompareComponent
	},
	{
		name: 'orders',
		path: '/orders/',
		component: OrdersComponent
	},
	{
		name: 'orders_client',
		path: '/orders_client/',
		component: OrdersClientComponent
	},
	{
		name: 'order_pages',
		path: '/orders/:navPageNum/',
		component: OrdersComponent
	},
	{
		name: 'order_detail',
		path: '/orders/order/:order_id/',
		component: OrderDetailComponent
	},
	{
		name: 'order_client_detail',
		path: '/orders_client/order/:order_id/',
		component: OrderClientDetailComponent
	},
	{
		name: 'messages',
		path: '/messages/',
		component: MessagesComponent
	},
	{
		name: 'messages_pages',
		path: '/messages/:navPageNum/',
		component: MessagesComponent
	},
	{
		name: 'message',
		path: '/message/:message_id/',
		component: MessageDetailComponent
	},
	{
		name: 'notifications',
		path: '/notifications/',
		component: NotificationsComponent
	},
	{
		name: 'notifications_type_pages',
		path: '/notifications_:type/',
		component: NotificationsComponent
	},
	{
		name: 'notifications_type',
		path: '/notifications_:type/:navPageNum/',
		component: NotificationsComponent
	},
	{
		name: 'notifications_pages',
		path: '/notifications/:navPageNum/',
		component: NotificationsComponent
	},
	{
		name: 'bonus',
		path: '/bonus/',
		component: BonusComponent
	},
	{
		name: 'condition',
		path: '/bonus/condition/',
		component: BonusCoditionComponent
	},
	{
		name: 'download',
		path: '/download/',
		component: DownloarComponent
	},
	/*
	{
		name: 'download',
		path: '/download/',
		component: DownloadComponent
	},*/
	{
		path: '*',
		component: NotFoundComponent
	},
];
export default routes;
