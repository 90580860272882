var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "catalog" }, [
    _c(
      "div",
      { staticClass: "catalog__filter " },
      [_c("component-section-filter")],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "product-list" }, [
      _c("div", {
        staticClass: "component-loading",
        class: { show: _vm.loading }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "animated-page" }, [
        !_vm.isShowSearch
          ? _c("div", { staticClass: "catalog__grid" }, [
              _c(
                "div",
                {
                  staticClass: "product-grid",
                  class: _vm.viewMode,
                  attrs: {
                    "data-page-size": _vm.navPageSize,
                    "data-page": _vm.navPageNum
                  }
                },
                _vm._l(_vm.products, function(productItem) {
                  return _c(
                    "component-list-item",
                    _vm._b(
                      {
                        key: "list_product_" + productItem.id,
                        attrs: {
                          can_delete: _vm.can_delete,
                          "data-page": _vm.navPageNum
                        }
                      },
                      "component-list-item",
                      productItem,
                      false
                    )
                  )
                }),
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isShowSearch
          ? _c("div", { staticClass: "catalog-search animated fadeIn" }, [
              !_vm.emptyResult
                ? _c("div", { staticClass: "catalog__grid" }, [
                    _c(
                      "div",
                      {
                        staticClass: "product-grid",
                        class: _vm.viewMode,
                        attrs: {
                          "data-page-size": _vm.navPageSize,
                          "data-page": _vm.navPageNum
                        }
                      },
                      _vm._l(_vm.searchResult, function(productItem) {
                        return _c(
                          "component-list-item",
                          _vm._b(
                            {
                              key: "search_product_" + productItem.id,
                              attrs: {
                                can_delete: _vm.can_delete,
                                "data-page": _vm.navPageNum
                              }
                            },
                            "component-list-item",
                            productItem,
                            false
                          )
                        )
                      }),
                      1
                    )
                  ])
                : _c("div", { staticClass: "empty-result" }, [
                    _c("h4", { domProps: { innerHTML: _vm._s(_vm.emptyMess) } })
                  ])
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    !_vm.isShowSearch
      ? _c(
          "div",
          { staticClass: "b-content__footer" },
          [_c("component-pagination")],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }