<template>
	<div class="component-container">
		<div v-bind:class="{ show: loading }" class="component-loading"></div>
		
		<div v-if="!emptyResult" class="b-content__top">
			<div v-if="showViewChanger" v-bind:class="viewMode" class="control_view-toggle">
				<button v-bind:class="{ 'is-active': viewMode == 'list-mode' }" class="btn btn_view-toggle js-view-toggle" v-on:click.prevent="viewMode='list-mode'" value="list-mode">
					<svg class="svg-icon" width="18" height="18">
						<use xlink:href="/images/sprite.svg#list"></use>
					</svg>
				</button>
				<button v-bind:class="{ 'is-active': viewMode == 'grid-mode' }" class="btn btn_view-toggle js-view-toggle" v-on:click.prevent="viewMode='grid-mode'" value="grid-mode">
					<svg class="svg-icon" width="20" height="21">
						<use xlink:href="/images/sprite.svg#grid"></use>
					</svg>
				</button>
			</div>
		</div>
		<div v-else class="empty-result">
			<h4 v-html="emptyMess"></h4>
		</div>
		
		<div class="animated-page">
			<div v-bind:data-page-size="navPageSize" v-bind:class="[viewMode, { 'loading': loading }]" class="product-grid" v-bind:data-page="navPageNum">
				
				<component-list-item
				  v-for="productItem in items" 
				  v-bind:can_delete="can_delete" 
				  v-bind:data-page="navPageNum" 
				  v-bind:key="'list_component_'+productItem.id" 
				  v-bind="productItem"
				></component-list-item>
				
			</div>
		</div>
		
		<div v-if="showPager" class="b-content__footer">
			<component-pagination></component-pagination>
		</div>
	</div>
</template>

<script>
import listItemComponent from '../components/listItemComponent';
import paginationComponent from '../components/paginationComponent';
export default {
	props: {
		emptyMess: {type: String, default: 'Здесь пока пусто...'},
		showViewChanger: {type: Boolean, default: true},
		showPager: {type: Boolean, default: true},
		navPageSize: {type: Number, default: 12},
		parentLoading: {type: Boolean, default: false},
	},
	data: function() {
		return {
			loading: true,
			showMore: false,
			section: [],
			emptyResult: false,
			viewMode: "list-mode",
			showClass: "fadeInUp showed",
			hideClass: "fadeOutDown",
			animationClass: {
				out: "slide-fade-page", 
				in: ""
			},
			navPageCount: 1,
			navParam: {},
			items: {},
			sort: {
				by: "sort",
				order: "asc"
			},
		}
	},
	components: {
		'component-list-item': listItemComponent,
		'component-pagination': paginationComponent,
	},
	computed: {
		can_delete(){
			var self = this;
			if(self.$parent.can_delete === true) return true;
			else return false;
		},
		to_hide() {return this.$parent.to_hide},
		filter() {return this.$parent.filter},
		navPageNum() {return parseInt(this.$route.params.navPageNum) || 1},
	},
	mounted: function(){
		var self = this;
		
	},
	watch: {
		parentLoading: function(parentLoading){
			var self = this;
			if(parentLoading) self.loading = true;
		},
		filter: function(newFileter){
			var self = this;
			self.loading = true;
			if(newFileter != false){
				self.loadProducts();
			}
		},
		navPageNum: function(navPageNum){
			var self = this;
			
			if(self.loading) return;
			self.loading = true;
			
			console.log("navPageNum => " + navPageNum);
			
			if(self.showMore) {
				self.loadProducts();
			}
			else{
				$(".animated-page").removeClass(self.animationClass.out).removeClass(self.animationClass.in).addClass(self.animationClass.out);
				setTimeout(function(){
					self.loadProducts();
				}, 10);
			}
		},
		to_hide:function(hideItems){
			var self = this;
			if(hideItems){
				self.hideAnimateItems();
			}
		},
	},
	methods:{
		loadProducts: function(){
			var self = this,
				baseUrl = (self.$route.meta.baseUrl ? self.$route.meta.baseUrl : self.$route.path),
				urlData = {
					filter: self.filter, 
					size: self.navPageSize, 
					baseUrl: baseUrl,
					SORT: self.sort.by,
					ORDER: self.sort.order,
				};
				urlData["PAGEN_1"] = self.navPageNum;
				
				self.loading = false;
				
			$.getJSON("/data/components/sectionFiltered.php", urlData, function(data){
				
				self.emptyResult = data.items.length <= 0;
				self.loading = false;
				self.navParam = data.nav;
				self.navPageCount = data.nav.NavPageCount;
				
				var newData = [];
				if(!self.showMore){
					for(var i in data.items) newData.push(data.items[i]);
					self.items = newData;
				}else{
					for(var i in data.items) newData.push(data.items[i]);
					self.items = self.items.concat(newData);
				}
				
				self.$emit('sectionLoaded', data);
				self.$nextTick(function(){
					self.showAnimateItems();
					self.$root.$emit('triggerScroll');
					
					if($(".animated-page").hasClass(self.animationClass.out)){
						$(".animated-page").removeClass(self.animationClass.out).addClass(self.animationClass.in);
						setTimeout(function(){
							$(".animated-page").removeClass(self.animationClass.in);
						}, 200);
					}
					
				})
			})
		},
		showAnimateItems: function(){
			var self = this,
				nextIndex = 0,
				tmout = 100;
				
			if(self.showMore){
				var pageSize = parseInt(self.navParam.NavPageSize),
					totalItemsCnt = $(self.$el).find(".product-grid__item").length,
					pagesShowed = totalItemsCnt/pageSize,
					nextIndex = (pagesShowed - 1) * pageSize;
			}
				
			$(self.$el).find(".product-grid__item").each(function(){
				var el = $(this);
				if(el.index() >= nextIndex){
					setTimeout(function(){
						el.addClass(self.showClass);
					}, tmout * (el.index() - nextIndex));
				}
			});
		},
		hideAnimateItems: function(){
			var self = this,
				modalId = self.$parent.modalId,
				totalItemsCnt = $(".modal .product-grid__item").length,
				nextIndex = 0,
				tmout = 70;
			
			
			$(".modal .product-grid__item").each(function(){
				var el = $(this);
				setTimeout(function(){
					el.removeClass(self.showClass);
					el.addClass(self.hideClass);
				}, tmout * (totalItemsCnt - el.index()));
			});
			setTimeout(function(){
				$("#" + modalId + " .modal__inner").addClass("zoomOut");
			}, tmout * totalItemsCnt + 300);
			setTimeout(function(){
				self.$parent.show = false;
				self.$parent.to_hide = false;
				$(".modal .product-grid__item").removeClass(self.hideClass);
				$("#" + modalId + " .modal__inner").removeClass("zoomOut");
			}, (tmout * totalItemsCnt) + 400);
		}
	},
};
</script>

<style scoped>
.product-grid__item{
	opacity:0;
	-webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}
.modal .product-grid.loading{
	opacity:0;
}
.modal .product-grid{
	transition: all 0.2s;
	opacity:1;
}
.component-loading{    z-index: 10;}
.component-container{
	position: relative;
    min-height: 300px;
}
.empty-result{
	display: flex;
    align-items: center;
    justify-content: center;
    min-height: 280px;
}
.empty-result h4{
	font-family: Museo Sans Cyrl;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.2px;
	color: #000000;
}
.modal .product-grid__item{
	opacity:0;
	-webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}
.modal .product-grid__item.showed{opacity:1;}
</style>