<template>
  <form
    id="catalog_filter"
    class="pre_filter b-content__top"
    enctype="multipart/form-data"
    method="GET"
  >
    <div class="component-loading"></div>

    <div class="first-line">
      <div class="control_radio-order" style="margin-right: 0;">
        <label
          v-if="sort.by === 'price'"
          @click.prevent="$emit('update:sort', 'price')"
        >
          <input
            type="radio"
            class="js-radio-toggle"
            name="order_1"
            :value="sort.order"
          >
          <i></i>
          <span>По цене</span>
        </label>

        <label
          v-else
          @click.prevent="$emit('update:sort', 'price')"
        >
          <input
            type="radio"
            class="js-radio-toggle"
            name="order_2"
            value=""
          >
          <i></i>
          <span>По цене</span>
        </label>
      </div>

      <div class="control_radio-order" style="margin-right: 0;">
        <label
          v-if="sort.by === 'article'"
          @click.prevent="$emit('update:sort', 'article')"
        >
          <input
            type="radio"
            class="js-radio-toggle"
            name="order_3"
            :value="sort.order"
          >

          <i></i>
          <span>По артикулу</span>
        </label>

        <label
          v-else
          @click.prevent="$emit('update:sort', 'article')"
        >
          <input
            type="radio"
            class="js-radio-toggle"
            name="order_4"
            value=""
          >

          <i></i>
          <span>По артикулу</span>
        </label>
      </div>

      <div
        v-for="(item, index) in checkboxes"
        :key="index"
        :class="[
          'control_checkbox',
          { 'disabled': item.disabled, 'checked': item.checked }
        ]"
        style="margin-right: 0;"
      >
        <label :for="item.control_id">
          <input
            v-if=item.checked
            type="checkbox"
            :name="item.control_name"
            :id="item.control_id"
            :value="item.control_value"
            :checked="item.checked"
            @change="$emit('update:checkbox', { value: $event.target.checked, index })"
          />

          <input
            v-else-if=item.disabled
            :name="item.control_name"
            :id="item.control_id"
            :value="item.control_value"
            type="checkbox"
          />

          <input
            v-else
            type="checkbox"
            :name="item.control_name"
            :id="item.control_id"
            :value="item.control_value"
            :checked="item.checked"
            @change="$emit('update:checkbox', { value: $event.target.checked, index })"
          />

          <i></i>
          <span>{{ item.value }}</span>
        </label>
      </div>

      <div class="control_view-toggle">
        <button
          :class="[
            { 'is-active': isListViewMode },
            'btn btn_view-toggle js-view-toggle'
          ]"
          value="list-mode"
          @click.prevent="$emit('update:isListViewMode', true)"
        >
          <svg class="svg-icon" width="18" height="18">
            <use xlink:href="/images/sprite.svg#list"></use>
          </svg>
        </button>

        <button
          :class="[
            { 'is-active': !isListViewMode },
            'btn btn_view-toggle js-view-toggle'
          ]"
          value="grid-mode"
          @click.prevent="$emit('update:isListViewMode', false)"
        >
          <svg class="svg-icon" width="20" height="21">
            <use xlink:href="/images/sprite.svg#grid"></use>
          </svg>
        </button>
      </div>
    </div>

    <div class="second-line">
      <div class="control_file">
        <label @click="$root.importExcelModal = true">
          <span>Загрузить из Exсel</span>
          <svg width="13" height="13">
            <use xlink:href="/images/sprite.svg#download"></use>
          </svg>
        </label>
      </div>

      <div
        :class="['control_search articul_select_control', { 'not-empty' : article.length }]"
      >
        <input
          :value="article"
          type="text"
          placeholder="Артикул"
          autocomplete="off"
          name="article"
          @input="$emit('update:article', $event.target.value)"
          @change="$emit('change:query')"
        >

        <span
          class="clear-field"
          @click="resetQuery('article')"
        >&times;</span>
      </div>

      <div
        :class="['control_search articul_select_control', { 'not-empty' : model.length }]"
      >
        <input
          :value="model"
          type="text"
          placeholder="Модель"
          autocomplete="off"
          name="model"
          @input="$emit('update:model', $event.target.value)"
          @change="$emit('change:query')"
        >

        <span
          class="clear-field"
          @click="resetQuery('model')"
        >&times;</span>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'searchPageFilters',
  props: {
    article: {
      type: String,
      required: true
    },
    isListViewMode: {
      type: Boolean,
      required: true
    },
    model: {
      type: String,
      required: true
    },
    sort: {
      type: Object,
      required: true
    },
    checkboxes: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      sortProductsByPrice: '',
      sortProductsByArticles: ''
    }
  },
  methods: {
    resetQuery (property) {
      this.$emit(`update:${property}`, '')
      this.$emit('change:query')
    }
  }
}
</script>

<style scoped>
.control_cat-filter{position: relative;}
.control_cat-filter .badge {
  font-size: 8px;
  background: #EA4B67;
  color: #fff;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -6px;
  z-index: 2;
}
.filter-closer{
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.selected-items{
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
}
.selected-items label{
  font-size: 12px;
  border: 1px solid #dae0ed;
  padding: 0 5px;
  border-radius: 4px;
  cursor:pointer;
  margin-right: 5px;
  background-color: #dae0ed;
  color: #3363C9;
  transition: all 0.5s ease;
  opacity:1;
}
.pre_filter.loading .selected-items label{opacity: 0;}
.selected-items label .del-selected-item{
  font-weight: 600;color:red;
}
.checkboxes-dropdown .control_checkbox label .cnt{
  display: block;
  position: absolute;
  right: 0;
  padding-right: 20px;
  top: 50%;
  color: #3363c9;
  font-size: 10px;
  line-height: 14px;
  margin-top: -6px;
  text-align: right;
  width: 44px;
}
.checkboxes-dropdown .control_checkbox.disabled label .cnt {
  color: red;
}

.pre_filter.loading .second-line{overflow: hidden;}

.articul_select_control{position: relative;}
.articul_select_control .checkboxes-dropdown{
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 10;
  border: 1px solid #E5E5E5;
  border-top: 0;
  margin-top: -3px;
  padding: 10px 10px 10px 15px;
  border-radius: 0 0 4px 4px;
  max-height: 300px;
  overflow: auto;
  display: none;
}
.articul_select_control .checkboxes-dropdown .control_checkbox.hidden{
  display: none;
}
.articul_select_control.opened .checkboxes-dropdown{
  display: block;
}
.articul_select_control .checkboxes-dropdown::-webkit-scrollbar {
  width: 2px;
}
.articul_select_control .checkboxes-dropdown::-webkit-scrollbar-track {
  width: 2px;
}
.articul_select_control .checkboxes-dropdown::-webkit-scrollbar-thumb {
  width: 2px;
  background-color: #86adff;
}
#catalog_filter{position: relative;}
#catalog_filter>.component-loading{
  background-color: #f6f8fb;
  opacity: 0.8;
  z-index: 10;
  left: -10px;
  right: -10px;
  width: auto;
}
.catalog__filter .control_radio-order input[value="desc"] ~ i {
  background: transparent url(/images/sort_asc.svg) no-repeat center center;
}
.catalog__filter .control_radio-order input[value="asc"] ~ i {
  background: transparent url(/images/sort_desc.svg) no-repeat center center;
}
#modal-cat-filter{
  transition: all 0.5s ease 0.3s;
  display:block;
  background: rgb(0 0 0 / 0%);
  overflow: hidden;
  width:0;
  left:100%;
  opacity:1;
}
#modal-cat-filter aside{
  transition: right 0.3s ease;
  right:-300px;
}
#modal-cat-filter.show{
  background: rgb(0 0 0 / 30%);
  width:100%;
  left:0;
  transition: background 0.5s ease;
}
#modal-cat-filter.show aside{
  right:0;
}
.cat-filter__dropdown{
  cursor:pointer;
  transition: all 0.3s;
}
.cat-filter__dropdown:hover{
  background: rgb(235 238 245 / 34%);
}
.cat-filter__control{
  transition: all 0.3s;
  display:block;
  overflow: hidden;
}
.cat-filter__control .control_range{
  transition: all 0.3s;
  opacity:0;
  height:0;
  overflow: hidden;
}
.cat-filter__control .filter_control_item{
  display:none;
  opacity:0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.cat-filter__control .filter_control_item.checked{
  display:block;
  opacity:1;
}
.cat-filter .control_checkbox-list{
  padding-bottom:0;
}
.cat-filter__control .filter_control_item:last-child{
  padding-bottom:7px;
}
.cat-filter__group .filter_control_item.animated{display:block;}
.cat-filter__group.is-open .cat-filter__control{height:auto;}

.cat-filter__group.is-open .cat-filter__control .control_range{
  opacity:1;
  height:auto;
}

.cat-filter .control_checkbox{position:relative;}
.cat-filter .control_checkbox label{position:relative;}
.cat-filter .control_checkbox label .cnt{
  display: block;
  position: absolute;
  right: 0;
  padding-right: 20px;
  top: 50%;
  color: #3363c9;
  font-size: 10px;
  line-height: 14px;
  margin-top: -6px;
  text-align: right;
  width: 44px;
}
.cat-filter .control_checkbox.disabled label .cnt{
  color: red;
}
.control_checkbox.disabled{
  pointer-events: none;
  opacity: 0.5;
}

.cat-filter .control_checkbox.checked:after{
  display: block;
  position: absolute;
  right: 100%;
  content: "";
  width: 5px;
  height: 5px;
  background: #3363c9;
  top: 50%;
  margin-top: -2px;
  margin-right: 12px;
  border-radius: 50%;
}
.modal__footer{position:relative;}
#filter_result{
  cursor: pointer;
  position: absolute;
  top: -35px;
  opacity: 0;
  background: rgb(235 238 245 / 81%);
  padding: 5px 20px;
  white-space: nowrap;
  font-size: 12px;
  color: #000;
  display: block;
  margin: auto;
  width: calc(100% - 40px);
  text-align: center;
  border-radius: 4px;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
#filter_result .show_result{
  color: #3363c9;
  border-bottom: 1px dashed;
}
.articul_select_control .clear-field{
  display: none;
  position: absolute;
  cursor: pointer;
  color: #b0b0b0;
  top: 7px;
  z-index: 1;
  right: 10px;
}
.articul_select_control.not-empty .clear-field{
  display: block;
}
.first-line.isShowSearch .control_checkbox{display:none;}
</style>
