<template>
	 <tr v-bind:class="{'out-of-manufacture': itemData.arhive, 'out-of-stock': !itemData.can_buy, 'thrown': itemData.deleted, 'is_delay': is_delay}">
		<td colspan="5">
			<div class="t-prod-meta">
				<span>Товар</span>
				<b v-html="itemData.name"></b>
				<span v-if="itemData.arhive">снят с производства</span>
				<span v-else-if="!itemData.can_buy">{{itemData.stock_date}}</span>
				<span v-else-if="itemData.deleted">товар был удален</span>
			</div>
		</td>
		<td>
			<template v-if="itemData.arhive">&nbsp;</template>
			<button v-else-if="!itemData.can_buy" v-on:click.prevent="$root.userItemsListsAction('waiting', id)" class="t-prod-link">{{waiting_text}}</button>
			<template v-else-if="itemData.deleted">&nbsp;</template>
		</td>
		<td>
			<button v-if="itemData.arhive" v-bind:class="{'disabled': itemData.hide_analogs}" class="t-prod-link" v-on:click.prevent="analogsAction">Аналоги</button>
			<button v-else-if="!itemData.can_buy" v-bind:class="{'disabled': itemData.hide_analogs}" class="t-prod-link" v-on:click.prevent="analogsAction">Аналоги</button>
			<button v-else-if="itemData.deleted" class="t-prod-link js-cart-restore" v-on:click.prevent="$root.userItemsListsAction('basket', id)">Восстановить</button>
		</td>
		<td v-if="itemData.can_buy">
			<div class="control_checkbox">
				<label>
					<input type="checkbox" v-bind:checked="!is_delay" v-on:change="is_delay = !is_delay" name="enable"><i></i>
				</label>
			</div>
		</td>
		<td v-if="itemData.can_buy">{{row_num}}</td>
		<td v-if="itemData.can_buy"><img class="t-prod-pic" v-bind:src="itemData.image" alt=""></td>
		<td v-if="itemData.can_buy">
			<router-link v-if="itemData.link" class="t-prod-art" v-bind:to="itemData.link">{{ itemData.article }}</router-link>
		</td>
		<td v-if="itemData.can_buy"><div class="t-prod-name" v-html="itemData.name"></div></td>
		<td v-if="itemData.can_buy">
			<div class="counter js-counter">
				<button @click="counterMinus" type="button" class="btn decrease">-</button>
				<input class="count" type="text" name="quantity" :value="animatedNumber" autocomplete="off" v-on:input="counterInput"/>
				<button @click="counterPlus" type="button" class="btn increase">+</button>
			</div>
		</td>
		<td v-if="itemData.can_buy && !itemData.deleted"><b v-html="animatedPriceSum"></b></td>
		<td>
			<button v-if="itemData.can_buy && !itemData.deleted" v-on:click.prevent="$root.userItemsListsAction('basket', id, 0)" class="t-prod-remove js-cart-remove"></button>
			<button v-else v-on:click.prevent="deleteItem" class="t-prod-remove js-cart-remove"></button>
		</td>
	</tr>
</template>

<script>
import listItemComponent from '../components/listItemComponent';
export default {
	name: 'basketItemComponent',
	extends: listItemComponent,
	props: {
		can_delete: true,
		row_num: 0,
	},
	data() {
		var self = this;
		return {
			itemData: self.$attrs,
			tweenedSum: 0,
			arBasketElement: self.$attrs.arBasketElement,
			is_delay: self.$attrs.arBasketElement.is_delay,
		}
	},
	computed: {
		listType() { return 'basket' },
		all_checked() { return this.$parent.all_checked },
		waiting_text() { return  this.$root.itemInUserList('waiting', this.id) ? "В листе ожидания" : "В лист ожидания"},
		animatedPriceSum(){
			var self = this;
			return self.$root.number_format(parseFloat(this.tweenedSum), 2, ".", " ") + ' <span class="rub">₽</span>';
		},
	},
	watch: {
		is_delay: function(is_delay){
			var self = this;
			if(is_delay) {
				this.$root.arUser.basket.has_delayed = true;
			}else{
				if($(this.$el).siblings(".is_delay").length == 0) this.$root.arUser.basket.has_delayed = false;
			}
			var DELAY = is_delay ? "Y" : "N";
			$.getJSON(self.$root.ajaxUrl, {action: "setBasketDelay", product_id: self.id, DELAY: DELAY}, function(json){
				if(json.status){
					self.$root.arUser.basket = json.basket;
					self.updateBasketElement();
				}else{
					console.error(json.error);
				}
			})
			this.$parent.setAllChecked(false);
		},
	},
	mounted: function(){
		var self = this;
		self.updateBasketElement();
		self.$root.$on('basketUpdated', self.updateBasketElement);
		self.$root.$on('checkedAllSelect', self.checkedAllSelect);
	},
	methods: {
		deleteItem: function(){
			var self = this;
			this.$root.userItemsListsAction('basket', self.id, 0);
			this.$el.remove();
		},
		analogsAction: function(){
			this.$root.analogsAction(this.itemData);
		},
		checkedAllSelect: function(val){
			this.is_delay = !val;
		},
		updateBasketElement: function(){
			var self = this;
			//if(self.arBasketElement.is_delay) self.$parent.all_checked = false;
			var productSum = parseFloat(self.$root.arUser.basket.sums[self.id]);
			//gsap.to(self.$data, { duration: 0.5, tweenedSum: productSum });
			this.tweenedSum = productSum;
		},
	},
};
</script>

<style scoped>
.b-content__body{
	position: relative;
    min-height: 65vh;
}
.b-content__body .component-loading{
    z-index: 10;
    background-color: transparent;
}
.b-content__body .component-loading.show{
    opacity: 1;
}
.b-content_cart .product-table img.t-prod-pic{
	height: unset;
    width: unset;
    max-height: 38px;
    max-width: 38px;
}
.b-content_cart .product-table .t-prod-link.disabled{
    color: #d4d4d4;
    pointer-events: none;
}
</style>
