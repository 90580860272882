<template>
	<section v-bind:class="{'empty': emptyResult}" class="b-content b-content_cart">
		<div class="b-content__header">
			<h2 class="b-content__title"><span>Корзина</span> <small>Оформление заказа</small></h2>
			<div class="b-content__header-aside">
				<button class="btn btn_light" v-on:click="$root.importExcelModal = true">
					<span class="btn_text">Загрузить из Exсel</span>
					<svg class="svg-icon" width="13" height="13">
						<use xlink:href="/images/sprite.svg#download"></use>
					</svg>
				</button>
			</div>
		</div>
		
		<div v-if="!emptyResult" class="b-content__top">
			<div class="first-line">
				<div class="control_checkbox">
					<label>
						<input type="checkbox" v-bind:checked="!has_delayed" v-on:change="setAllChecked" class="js-check-all">
						<i></i><span>Выбрать все</span>
					</label>
				</div>
				<div class="total-amount" v-html="title_text"></div>
			</div>
		</div>
		<div v-if="!emptyResult" class="b-content__body">
			<div v-bind:class="{ show: loading }" class="component-loading"></div>
			<div class="resp-table-wrap">
				<table class="product-table">
					<thead>
						<tr>
							<th width="28">&nbsp;</th>
							<th width="40">№</th>
							<th width="40">Фото</th>
							<th width="80"><button class="sort" data-field="art">Арт.</button></th>
							<th class="left">Наименование</th>
							<th width="100">Количество</th>
							<th width="120">Стоимость</th>
							<th width="30">&nbsp;</th>
						</tr>
					</thead>
					<tbody v-if="loaded">
					
						<component-basket-item
						  v-for="productItem in items" 
						  v-bind:can_delete="can_delete" 
						  v-bind:data-page="navPageNum" 
						  v-bind:key="'list_basket_' + productItem.id"
						  v-bind="productItem"
						></component-basket-item>
					
					</tbody>
				</table>
			</div>
		</div>
		<div v-if="!emptyResult" class="b-content__footer">
			<div class="tools">
				 <button class="btn btn_lblue" v-on:click="clearBasket"><span class="btn_text">Очистить корзину</span></button>
				 <!--button class="btn">
					<svg class="svg-icon" width="14" height="18">
						 <use xlink:href="/images/sprite.svg#share"></use>
					 </svg>
					 <span class="btn_text">Поделиться корзиной</span>
				</button-->
			</div>
			<div class="checkout">
				<div class="total-amount" v-html="title_total_text"></div>
				<div class="sum">К оплате:<b>{{ this.$root.animatedSelectedPrice }} ₽</b></div>
				
				<div v-if="$root.arUser.subclient" @click="showClientOrder = true" class="btn">Оформить заказ</div>
				<router-link v-else :to="'/order/'" class="btn">Оформить заказ</router-link>
			</div>
		</div>
		
		<div v-if="emptyResult" class="empty-result-container">
			<div class="empty-result-content">
				<div class="empty-result-text" v-html="emptyMess"></div>
				<div class="empty-result-btn">
					<router-link :to="'/catalog/'" class="btn">В каталог</router-link>
				</div>
			</div>
		</div>
		
		<div v-bind:class="{ 'is-open': showClientOrder }"  class="modal modal_client-order " id="modal-client-order">
			<div class="modal__wrap">
				<div class="modal__inner animated zoomIn">
					<button class="modal__close" v-on:click="to_hide = true"></button>
					<div class="modal__header">Оформление заказа</div>
					<div class="total-amount" v-html="title_text"></div>
					<div class="modal__container">
						<form class="save_order_form">
							<input type="hidden" name="FIELDS[USER_ID]" v-bind:value="$root.arUser.id"/>
							<input type="hidden" name="action" value="save_order"/>
							<div class="modal_row">
								<div class="modal_col-6">
									<div class="block_header">Контактное лицо:</div>
									<div class="control_text">
										<input placeholder="Фамилия, имя" type="text" name="FIELDS[NAME]" v-bind:value="$root.arUser.name">
									</div>
									<div class="control_text">
										<input placeholder="E-mail" type="text" name="FIELDS[EMAIL]" v-bind:value="$root.arUser.email">
									</div>
									<div class="control_text">
										<input placeholder="Контактный телефон" type="text" name="FIELDS[PHONE]" v-bind:value="$root.arUser.phone">
									</div>
									<div class="blue_text">Поля не обязательны для заполнения</div>
								</div>
								<div class="modal_col-6">
									<div class="block_header">Добавьте комментарий к заказу</div>
									<div class="control_text">
										<textarea name="FIELDS[COMMENT]" ></textarea>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="modal_footer">
						<div class="btn checkout-btn" @click="saveClientOrder">Оформить заказ</div>
					</div>
				</div>
			</div>
		</div>
		
	</section>
</template>

<script>
import basketItemComponent from '../components/basketItemComponent';

export default {
	name: 'CartComponent',
	data() {
		var self = this;
		return {
			loading: true,
			showClientOrder: false,
			to_hide: false,
			all_checked: false,
			select_all_checked: !self.$root.arUser.basket.has_delayed,
			loaded: false,
			can_delete: true,
			emptyResult: false,
			emptyMess: "У вас нет товаров в корзине",
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
				{ link: '/cart/', name: 'Корзина' },
			],
			items: {},
			navPageSize: 50,
			navPageCount: 1,
			navParam: {},
			filter: {},
			sort: {
				by: "sort",
				order: "asc"
			},
		};
	},
	computed: {
		has_delayed: {
			get: function() {
				return this.$root.arUser.basket.has_delayed;
			},
			set: function(value) {
				this.$root.arUser.basket.has_delayed = value; 
			},
		},
		navPageNum() {return parseInt(this.$route.params.navPageNum) || 1},
		basket_items() { return this.$root.arUser.basket.items },
		basket_count() { return this.$root.arUser.basket.count },
		basket_total_count() { return this.$root.arUser.basket.qty_products },
		basket_sum() { return this.$root.arUser.basket.print_price },
		title_text() {
			var self = this;
			return self.basket_count + " " + self.$root.declOfNum(self.basket_count, ['позиция', 'позиции', 'позиций']) + " на сумму <b>" + self.basket_sum + " ₽</b>";
		},
		title_total_text() {
			var self = this;
			return "Всего " + self.basket_total_count + " " + self.$root.declOfNum(self.basket_total_count, ['товар', 'товара', 'тоааров']) + "</b>";
		},
	},
	components: {
		'component-basket-item': basketItemComponent,
	},
	watch: {
		filter: function(newFileter){
			var self = this;
			
			if(this.emptyResult){
				self.loading = false;
				self.items = {};
				self.navParam = {};
			}else{
				self.loading = true;
				if(newFileter != false){
					self.loadProducts();
				}
			}
		},
		basket_count: function(basket_count, old_basket_count){
			var self = this;
			if(basket_count == 0 && old_basket_count > 0){
				self.emptyResult = true;
				self.loading = false;
				self.items = {};
				self.navParam = {};
			}
		},
		showClientOrder: function(){
			var self = this;
			if(self.showClientOrder) {
				$("#modal-overlay").fadeIn();
			}else {
				$("#modal-overlay").fadeOut();
			}
		},
		to_hide:function(to_hide){
			var self = this;
			if(to_hide){
			
				$("#modal-client-order .modal__inner").addClass("zoomOut");
				setTimeout(function(){
					self.showClientOrder = false;
					self.to_hide = false;
					
					$("#modal-client-order .modal__inner").removeClass("zoomOut");
				}, 400);
				
				self.passSuccessChangeSubUser = false;
			}
		},
		select_all_checked: function(val){
			
		}
	},
	methods:{
		clearBasket: function(e){
			var self = this;
			if(confirm("Вы действительно хотите удалить все товары?")) self.$root.userItemsListsClearAction('basket')
		},
		setAllChecked: function(e){
			var self = this;
			var has_delayed = self.$root.arUser.basket.has_delayed;
			
			if(e === false){
				self.select_all_checked = !has_delayed;
			}else{
				self.all_checked = e.target.checked;
				self.$root.arUser.basket.has_delayed = !e.target.checked;
				self.$root.$emit('checkedAllSelect', e.target.checked);
			}
		},
		importCoplete: function(data){
			var self = this;
			
			if(self.basket_count > 0){
				self.filter = {"ID": this.basket_items, IBLOCK_ID: 56};
				self.emptyResult = false;
				self.loading = true;
				self.loadProducts();
			}else if(basket_count == 0){
				self.filter = {};
				self.emptyResult = true;
				self.loading = false;
				self.items = {};
				self.navParam = {};
			}
		},
		loadProducts: function(){
			var self = this,
				baseUrl = (self.$route.meta.baseUrl ? self.$route.meta.baseUrl : self.$route.path),
				urlData = {
					filter: self.filter, 
					size: self.navPageSize, 
					baseUrl: baseUrl,
					SORT: self.sort.by,
					ORDER: self.sort.order,
				};
				urlData["PAGEN_1"] = self.navPageNum;
				
			self.items = {};
				
			$.getJSON("/data/components/sectionFiltered.php", urlData, function(data){
				
				self.emptyResult = data.items.length <= 0;
				self.loading = false;
				self.navParam = data.nav;
				self.navPageCount = data.nav.NavPageCount;
				var newData = [],
					tmpData = [];
					
				if(!self.showMore){
					for(var i in data.items) newData.push(data.items[i]);
					tmpData = newData;
					//self.items = newData;
				}else{
					for(var i in data.items) newData.push(data.items[i]);
					tmpData = self.items.concat(newData);
					//self.items = self.items.concat(newData);
				}
				
				for(var i in tmpData) {
					tmpData[i].row_num = parseInt(i) + 1;
				}
				self.items = tmpData;
				
				self.loading = false;
				self.loaded = true;
				self.$emit('sectionLoaded', data);
			})
		},
		saveClientOrder: function(){
			var self = this;
			var urlData = $(".save_order_form").serialize();
			$.getJSON("/data/ajax/client_order.php", urlData, function(json){
				if(json.status){
					self.$root.userItemsListsClearAction('basket');
					self.to_hide = true;
					$("#modal-overlay").fadeOut();
					self.$router.push({ 
						path: '/order_client_confirm/' + json.order_id + "/" 
					});
				}
			})
		},
	},
	mounted: function(){
		var self = this;
		self.$root.breadcrumbs = self.breadcrumbs;
		
		if(self.basket_count <= 0) {
			self.emptyResult = true;
			self.filter = {};
		}else self.filter = {"ID": this.basket_items, IBLOCK_ID: 56};
		
		var has_delayed = self.$root.arUser.basket.has_delayed;
		self.select_all_checked = !has_delayed;
		
		self.$root.$on('basketImport', self.importCoplete);
	},
};
</script>

<style scoped>
#modal-client-order .modal__wrap{max-width: 600px;}
#modal-client-order .modal__container{padding-bottom:20px;}
#modal-client-order .checkout-btn{float:right;}
#modal-client-order .modal__header{
	font-style: normal;text-align: center;
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 20px;
	line-height: 26px;
	letter-spacing: 0.2px;
	color: #000000;
}
.b-content__footer .total-amount{
	text-align: right;
	font-size: 12px;
	line-height: 22px;
	letter-spacing: -0.408px;
	color: #bcbcbc;
	margin-bottom: 5px;
}
#modal-client-order .total-amount{
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 22px;
	letter-spacing: -0.408px;
	color: #000000;
	border-bottom: 1px solid #E5E5E5;
	padding-bottom:12px;
	margin-bottom:12px;
}
#modal-client-order .block_header {
	font-family:'Museo Sans Cyrl 700', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #000000;
    margin-bottom: 8px;
}
#modal-client-order .control_text label {
    color: #989898;
    font-size: 12px;
    line-height: 18px;
}
#modal-client-order .control_text input,
#modal-client-order .control_text textarea {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    height: 38px;
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Museo Sans Cyrl 300', sans-serif;
    padding: 10px 12px;
	margin-bottom: 8px;
}
#modal-client-order .control_text textarea {
    height: 129px;
}
#modal-client-order .blue_text {
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 18px;
	color: #3363C9;
}
.modal_row{
	display:flex;
	margin-left:-15px;
	margin-right:-15px;
}
.modal_row .modal_col-6{
	padding-left:15px;
	padding-right:15px;
	width:50%;
}
</style>
