<template>
  <div
    v-if="productsCount && isOpen"
    class="waiting-goods"
  >
    <div class="waiting-goods__col">
      <div class="waiting-goods__icon">
        <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.981 32.2831H6.02454C6.02454 28.3512 6.02454 26.3192 6.02454 23.2313V8.56006H23.1763V27.0878M17.981 32.2831L23.1763 27.0878M17.981 32.2831V27.0878H23.1763"
            stroke="#52504C" stroke-width="1.4" />
          <line x1="9.69907" y1="13.6513" x2="19.5013" y2="13.6513" stroke="#52504C" stroke-width="1.4" />
          <line x1="9.69907" y1="17.2525" x2="19.5013" y2="17.2525" stroke="#52504C" stroke-width="1.4" />
          <line x1="9.69907" y1="20.8536" x2="19.5013" y2="20.8536" stroke="#52504C" stroke-width="1.4" />
          <line x1="9.69907" y1="24.4547" x2="14.3948" y2="24.4547" stroke="#52504C" stroke-width="1.4" />
          <path
            d="M0 8.507C0.69853 8.47899 1.28676 8.21989 1.77206 7.73669C2.25735 7.2535 2.49265 6.67927 2.49265 6C2.49265 6.67227 2.73529 7.2465 3.22059 7.72969C3.70588 8.21289 4.30147 8.47199 5 8.5C4.30147 8.52801 3.70588 8.78712 3.22059 9.27031C2.73529 9.7535 2.49265 10.3277 2.49265 11C2.49265 10.3277 2.25 9.7535 1.77206 9.27031C1.28676 8.79412 0.69853 8.53501 0 8.507Z"
            fill="#00BB61" />
          <path
            d="M7 4.9895C8.38919 4.92647 9.56891 4.41177 10.5391 3.44538C11.5094 2.47899 11.9945 1.33403 11.9945 0C11.9945 1.33403 12.4796 2.47899 13.4498 3.44538C14.4201 4.41177 15.5998 4.91597 17 4.9895C16.0849 5.03151 15.247 5.27311 14.4752 5.73529C13.7144 6.18697 13.108 6.79622 12.656 7.55252C12.215 8.30882 11.9945 9.11765 11.9945 10C11.9945 8.66597 11.5094 7.5105 10.5391 6.54412C9.56891 5.56723 8.38919 5.05252 7 4.9895Z"
            fill="#00BB61" />
          <path
            d="M26 27.5098C26.8382 27.4706 27.5441 27.1078 28.1265 26.4314C28.7088 25.7549 28.9912 24.951 28.9912 24C28.9912 24.9412 29.2824 25.7451 29.8647 26.4216C30.4471 27.098 31.1618 27.4608 32 27.5C31.1618 27.5392 30.4471 27.902 29.8647 28.5784C29.2824 29.2549 28.9912 30.0588 28.9912 31C28.9912 30.0588 28.7 29.2549 28.1265 28.5784C27.5441 27.9118 26.8382 27.549 26 27.5098Z"
            fill="#00BB61" />
          <path
            d="M2 31.5098C2.83824 31.4706 3.54412 31.1078 4.12647 30.4314C4.70882 29.7549 4.99118 28.951 4.99118 28C4.99118 28.9412 5.28235 29.7451 5.86471 30.4216C6.44706 31.098 7.16176 31.4608 8 31.5C7.16176 31.5392 6.44706 31.902 5.86471 32.5784C5.28235 33.2549 4.99118 34.0588 4.99118 35C4.99118 34.0588 4.7 33.2549 4.12647 32.5784C3.54412 31.9118 2.83824 31.549 2 31.5098Z"
            fill="#00BB61" />
        </svg>

        <div class="waiting-goods__count">{{ productsCount }}</div>
      </div>

      <div class="waiting-goods__text">
        Некоторые товары из листа ожидания поступили на склад
      </div>
    </div>

    <div class="waiting-goods__action">
      <router-link to="/waiting/" class="waiting-goods__more">
        Подробнее
        <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.477073 7L3.52292 3.94642L0.477073 1" stroke="#52504C" />
        </svg>
      </router-link>

      <button @click="onClose" class="waiting-goods__close">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.8194 11.8193L20.181 20.1809" stroke="#52504C" stroke-width="2" />
          <path d="M20.1816 11.8193L11.8201 20.1809" stroke="#52504C" stroke-width="2" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaitingGoods',
  data () {
    return {
      filter: {},
      isOpen: true,
      products: []
    }
  },
  computed: {
    productsCount () {
      return this.products.filter(item => item.can_buy).length
    }
  },
  mounted () {
    this.setFilters()
    this.getProducts(this.checkProducts)
  },
  methods: {
    checkProducts () {
      const wlist = JSON.parse(localStorage.getItem('personla.feron.notificationCount') || null)
      if (wlist === null) return
      this.isOpen = wlist !== this.productsCount;
    },
    setFilters () {
      if (this.$root.arUser.waiting.count > 0) {
        this.filter = {
          ID: this.$root.arUser.waiting.items,
          IBLOCK_ID: 56
        }
      }
    },
    getProducts (callback) {
      const url = (this.$route.meta.baseUrl ? this.$route.meta.baseUrl : this.$route.path)
      const data = {
        filter: this.filter,
        size: 12,
        baseUrl: url,
        SORT: "sort",
        ORDER: "asc"
      }

      $.getJSON("/data/components/sectionFiltered.php", data, (response) => {
        this.products = response.items
        callback()
      })
    },
    onClose () {
      localStorage.setItem('personla.feron.notificationCount', JSON.stringify(this.productsCount))

      this.isOpen = false
    }
  }
}
</script>

<style scoped>
.waiting-goods {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 8px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  position: sticky;
  top: 90px;
  z-index: 100;
}
.waiting-goods__col {
  display: flex;
  align-items:center;
}
.waiting-goods__icon {
  position: relative;
}
.waiting-goods__count {
  display: flex;
  align-items:center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  background: #00BB61;
  width: 20px;
  height: 20px;
  border-radius:50%;
  color: #FFF;
  text-align: center;
  font-size: 10px;
  font-family: Museo Sans Cyrl 500,sans-serif;
  line-height: 20px;
}
.waiting-goods__text {
  margin-left: 16px;
  margin-right: 16px;
  color: #52504C;
  font-size: 14px;
  font-family: Museo Sans Cyrl 500,sans-serif;
  line-height: 16px;
}
.waiting-goods__action {
  display: flex;
  align-items:center;
  margin-left: auto;
}
.waiting-goods__more {
  display: flex;
  align-items:center;
  color: #52504C;
  font-size: 12px;
  font-family: Museo Sans Cyrl 500,sans-serif;
  line-height: 22px;
  letter-spacing: -0.408px;
  text-decoration: none;
}
.waiting-goods__more svg{
  display: block;
  margin-left: 10px;
}
.waiting-goods__close {
  cursor: pointer;
  margin-left: 10px;
  background:transparent;
  border-radius:0;
  border:none;
  box-shadow:none;
  min-width: 32px;
}
@media(max-width:736px){
  .waiting-goods{
    padding-top:10px;
    padding-bottom:10px;
    height:auto;
  }
  .waiting-goods__text{
    font-size:12px;
  }
}
</style>
