<template>
	<form v-bind:class="[sort.by, {'loading': loading}]" class="pre_filter b-content__top" id="catalog_filter" enctype="multipart/form-data" method="GET">

		<div v-bind:class="{ show: loading }" class="component-loading"></div>

		<div v-bind:class="{'isShowSearch': isShowSearch}" class="first-line">
			<div class="control_radio-order">
				<label v-if="sort.by == 'price'" v-on:click="setSort('price')">
					<input type="radio" class="js-radio-toggle" name="order_1" v-bind:value="sort.order">
					<i></i><span>По цене</span>
				</label>
				<label v-else v-on:click="setSort('price')">
					<input type="radio" class="js-radio-toggle" name="order_2" value="">
					<i></i><span>По цене</span>
				</label>
			</div>
			<div class="control_radio-order">
				<label v-if="sort.by == 'articul'" v-on:click="setSort('articul')">
					<input type="radio" class="js-radio-toggle" name="order_3" v-bind:value="sort.order">
					<i></i><span>По артикулу</span>
				</label>
				<label v-else v-on:click="setSort('articul')">
					<input type="radio" class="js-radio-toggle" name="order_4" value="">
					<i></i><span>По артикулу</span>
				</label>
			</div>
			<div v-for="item in filterTopItems" class="control_checkbox" v-bind:class="{'disabled': item.disabled, 'checked': item.checked}">
				<label v-bind:for="item.control_id" >
					<input v-if=item.checked
						checked
						v-bind:name="item.control_name"
						v-bind:id="item.control_id"
						v-bind:value="item.control_value"
						v-on:change="refreshFilter(item)"
						type="checkbox" />
					<input v-else-if=item.disabled
						v-bind:name="item.control_name"
						v-bind:id="item.control_id"
						v-bind:value="item.control_value"
						type="checkbox" />
					<input v-else
						v-bind:name="item.control_name"
						v-bind:id="item.control_id"
						v-bind:value="item.control_value"
						v-on:change="refreshFilter(item)"
						type="checkbox" />
					<i></i><span>{{item.value}}</span>
				</label>
			</div>

			<div v-bind:class=viewMode class="control_view-toggle">
				<button v-bind:class="{ 'is-active': isList }" class="btn btn_view-toggle js-view-toggle" v-on:click.prevent="parent.viewMode='list-mode'" value="list-mode">
					<svg class="svg-icon" width="18" height="18">
						<use xlink:href="/images/sprite.svg#list"></use>
					</svg>
				</button>
				<button v-bind:class="{ 'is-active': isGrid }" class="btn btn_view-toggle js-view-toggle" v-on:click.prevent="parent.viewMode='grid-mode'" value="grid-mode">
					<svg class="svg-icon" width="20" height="21">
						<use xlink:href="/images/sprite.svg#grid"></use>
					</svg>
				</button>
			</div>
		</div>
		<div class="second-line">
			<div class="control_file">
				<label v-on:click="$root.importExcelModal = true">
					<span>Загрузить из Exсel</span>
					<svg width="15px" height="15px">
						<use xlink:href="/images/sprite.svg#download"></use>
					</svg>
				</label>
			</div>
			<div v-for="item in filterMidItems" class="control_search articul_select_control">
				<input type="text" value="" v-bind:placeholder="item.name" autocomplete="off" v-bind:name="item.code" v-on:input="searchItems" v-on:focus="$parent.focusSearchInput(item.code)" v-on:blur="$parent.blurSearchInput(item.code)">
				<span class="clear-field" v-on:click="$parent.searchClear">&times;</span>
				<svg class="select-icon">
					<use xlink:href="/images/sprite.svg#search"></use>
				</svg>
				<!--div class="checkboxes-dropdown animated fadeIn">
					<div v-for="value in item.values" v-bind:key="value.control_id" v-bind:class="{'disabled': value.disabled}" class="control_checkbox">
						<label v-bind:for="value.control_id" >
							<input
								v-bind:checked="value.checked"
								v-bind:name="value.control_name"
								v-bind:id="value.control_id"
								v-bind:value="value.control_value"
								v-on:change="showDrop[item.code] = false; refreshFilter(value);"
								type="checkbox" />
							<i></i><span>{{value.value}}</span><span class="cnt">{{ value.el_count }}</span>
						</label>
					</div>
				</div>
				<div class="selected-items">
					<template v-for="value in item.values">
						<label v-if="value.checked" class="selected-item" v-bind:for="value.control_id" v-on:click="hideSelectedItem">
							<span>{{value.value}} <span class="del-selected-item">&times;</span></span>
						</label>
					</template>
				</div-->
			</div>

			<div class="control_cat-filter">
				<span v-if="filtered" class="badge">!</span>
				<button class="btn btn_sb-filter js-cat-filter" v-on:click.prevent="openFilter = true">
					<svg width="22" height="19" >
						<use xlink:href="/images/sprite.svg#tune"></use>
					</svg>
				</button>
			</div>
		</div>
		<div v-bind:class="{'show': openFilter}" class="modal modal_sb-filter" id="modal-cat-filter">
			<div class="filter-closer" v-on:click.prevent="openFilter=false"></div>
			<aside class="modal__aside right">
				<div class="modal__header">
					<div class="modal__title">Фильтры</div>
					<button class="modal__close" v-on:click.prevent="openFilter = false"></button>
				</div>
				<div class="modal__container" >
					<div v-bind:class="{ show: loading }" class="component-loading"></div>
					<div class="sb-filter js-cat-filter cat-filter">
						<input type="hidden" name="bxajaxid" value="cabinet_filter" />
						<input type="hidden" name="ajax" value="y" />
						<input type="hidden" name="AJAX_CALL" value="Y" />
						<input v-for="item in filterData.HIDDEN" type="hidden" v-bind:name="item.CONTROL_NAME" v-bind:value="item.HTML_VALUE" />

						<div v-for="item in filterItems" class="sb-filter__group" v-bind:class="{'is-open': item.opened}" v-bind:id="'filter_block_' + item.id">
							<button class="sb-filter__dropdown" v-on:click.prevent="toggleFilterBlock(item)">
								<span>{{ item.name }}</span>
							</button>
							<div v-if="item.type == 'list'" class="sb-filter__control ">
								<div class="control_checkbox-list">
									<div v-for="value in item.values" v-bind:key="value.control_id" v-bind:class="{'disabled': value.disabled, 'checked': value.checked, 'animated fadeInUp': item.opened}" class="control_checkbox filter_control_item">
										<label v-bind:for="value.control_id">
											<input v-if=value.checked
												checked
												v-bind:name="value.control_name"
												v-bind:id="value.control_id"
												v-bind:key="value.control_id"
												v-bind:value="value.control_value"
												v-on:change="refreshFilter(item)"
												type="checkbox" />
											<input v-else-if=value.disabled
												v-bind:name="value.control_name"
												v-bind:id="value.control_id"
												v-bind:key="value.control_id"
												v-bind:value="value.control_value"
												type="checkbox" />
											<input v-else
												v-bind:name="value.control_name"
												v-bind:id="value.control_id"
												v-bind:key="value.control_id"
												v-bind:value="value.control_value"
												v-on:change="refreshFilter(item)"
												type="checkbox" />
											<i></i><span class="cnt">{{ value.el_count }}</span><span>{{ value.value }}</span>
										</label>
									</div>
								</div>
							</div>
							<div v-if="item.type == 'range'" class="sb-filter__control ">
								<div class="control_range">
									<div class="extra-controls">
										<label>
											<span>От</span>
											<input type="text"
												class="js-input-from"
												v-bind:id="item.values.min.control_id"
												v-bind:name="item.values.min.control_name"
												v-bind:value="item.values.min.control_value"
												v-bind:placeholder="item.values.min.value"/>
										</label>
										<label>
											<span>До</span>
											<input type="text"
												class="js-input-to"
												v-bind:id="item.values.max.control_id"
												v-bind:name="item.values.max.control_name"
												v-bind:value="item.values.max.control_value"
												v-bind:placeholder="item.values.max.value"/>
										</label>

									</div>
									<div class="range-slider">
										<input type="text"
											v-on:change="refreshFilter()"
											v-bind:data-min="item.values.min.value"
											v-bind:data-max="item.values.max.value"
											v-bind:data-from="item.values.min.control_value"
											v-bind:data-to="item.values.max.control_value"
											class="js-range-slider" value="" />
									</div>
								</div>
							</div>
						</div>
						<div v-for="item in filterPriceItems" class="sb-filter__group" v-bind:class="{'is-open': item.opened}" v-bind:id="'filter_block_' + item.id">
							<div class="sb-filter__dropdown" v-on:click.prevent="toggleFilterBlock(item)">
								<span>Цена</span>
							</div>
							<div class="sb-filter__control">
								<div class="control_range">
									<div class="extra-controls">
										<label>
											<span>От</span>
											<input type="text"
												class="js-input-from"
												v-bind:id="item.values.min.control_id"
												v-bind:name="item.values.min.control_name"
												v-bind:value="item.values.min.control_value"
												v-bind:placeholder="item.values.min.value"/>
										</label>
										<label>
											<span>До</span>
											<input type="text"
												class="js-input-to"
												v-bind:id="item.values.max.control_id"
												v-bind:name="item.values.max.control_name"
												v-bind:value="item.values.max.control_value"
												v-bind:placeholder="item.values.max.value"/>
										</label>

									</div>
									<div class="range-slider">
										<input type="text"
											v-on:change="refreshFilter()"
											v-bind:data-min="item.values.min.value"
											v-bind:data-max="item.values.max.value"
											v-bind:data-from="item.values.min.control_value"
											v-bind:data-to="item.values.max.control_value"
											class="js-range-slider" value="" />
									</div>
								</div>
							</div>
						</div>
						<br>
						<br>
					</div>
				</div>
				<div class="modal__footer">
					<div id="filter_result" v-on:click.prevent="applyFilter; openFilter=false" v-bind:class="{'animated fadeInUp' : filter_count > 0, 'animated fadeOutDown': filter_count == 0}">
						<span>Найдено товаров: <b>{{filter_count}}.</b></span>
					</div>
					<button class="btn btn_white" v-on:click.prevent="resetFilter">Сбросить</button>
					<button v-bind:class="{'btn-loading': loading}" v-on:click.prevent="applyFilter; openFilter=false" class="btn btn_blue">Закрыть</button>
				</div>
			</aside>
		</div>
	</form>
</template>

<script>
import {ionRangeSlider} from 'ion-rangeslider';
export default {
	name: 'filterComponent',
	data: function() {
		return {
			inited: false,
			loading: true,
			isList: false,
			isGrid: true,
			openFilter: false,
			showDrop: {},
			INSTANT_RELOAD: false,
			parent: this.$parent,
			filtered: false,
			filterData: {ITEMS: {}},
			filterItems: {},
			filterPriceItems: {},
			filterTopItems: {},
			filterMidItems: {},
			FILTER_URL: "/data/components/section.php",
			SEF_URL: this.$route.path,
			CLEAR_URL: this.$route.path,
			filter_count: 0,
			timer: null,
			showClass: "animated fadeInUp",
			hideClass: "animated fadeOutRight",
		}
	},
	computed:{
		section_id(){ return this.$route.params.section_id; },
		curUrl(){ return this.$route.path },
		viewMode(){
			this.isList = (this.$parent.viewMode == "list-mode");
			this.isGrid = (this.$parent.viewMode == "grid-mode");
			return this.$parent.viewMode;
		},
		sort(){ return this.$parent.sort; },
		isShowSearch(){ return this.$parent.isShowSearch; },
	},
	mounted: function(){
		var self = this;
		self.$parent.$on('sectionLoaded', self.init);
	},
	watch: {
		section_id: function(filterData) {
			var self = this;
			self.filterTopItems = {};
			//console.log(self.section_id);
		},
		openFilter: function(val){
			if(val) $("body").addClass("no-scroll");
			else $("body").removeClass("no-scroll");

			if(val){
				this.initRanges();
			}
		}
	},
	updated: function(){
		var self = this;
	},
	methods:{
		showSearch(e){
			var self = this;
			self.$parent.showSearch(e);
		},
		hideSearch(e){
			var self = this;
			self.$parent.hideSearch(e);
		},
		searchItems(e){
			var self = this;
			self.$parent.searchItems(e);
		},
		searchDropItems(e){
			var self = this;
			var text = e.target.value;
			/*
			self.refreshFilter(e.target);
			*/
			var items = $(e.target).parents(".control_search").find("label");
			items.each(function(){
				var val = $(this).text();
				if(val.indexOf(text) < 0) $(this).parent().addClass("hidden");
				else $(this).parent().removeClass("hidden");
			})

		},
		hideSelectedItem(e){
			var item = $(e.target).parents(".selected-item");
		},
		showDropDownProps(propCode){
			var self = this;
			$("input[name=" + propCode + "]").parent().addClass("opened");
			$("input[name=" + propCode + "]").parent().find(".checkboxes-dropdown").scrollTop(0);

		},
		hideDropdown(propCode){
			var self = this;
			setTimeout(function(){
				$("input[name=" + propCode + "]").parent().removeClass("opened");
				$("input[name=" + propCode + "]").parent().find(".checkboxes-dropdown").scrollTop(0);
			}, 100)
		},
		applyFilter(){
			var self = this;
			self.$router.push(self.SEF_URL);
		},
		resetFilter(){
			var self = this;
			self.$router.push(self.CLEAR_URL);
		},
		refreshFilter(activeItem){
			var self = this;
			self.loading = true;
			self.parent.loading = true;
			self.filter_count = 0;
			if(!!self.timer){
				clearTimeout(this.timer);
			}
			self.timer = setTimeout(function(){
				var filterData = $("#catalog_filter").serialize();
				self.execFilter(filterData);
			}, 500);
		},
		execFilter(filterData){
			var self = this;

			$.getJSON(self.FILTER_URL, filterData, function(json){
				self.filterData = json;
				self.filterItems = json.ITEMS;
				self.filterPriceItems = json.PRICE_ITEMS;
				self.SEF_URL = json.SEF_SET_FILTER_URL;
				self.CLEAR_URL = json.SEF_DEL_FILTER_URL;
				self.INSTANT_RELOAD = json.INSTANT_RELOAD;
				self.filter_count = parseInt(json.ELEMENT_COUNT);

				self.filtered = (json.SEF_DEL_FILTER_URL != json.SEF_SET_FILTER_URL);

				if(self.INSTANT_RELOAD) self.applyFilter();

				self.loading = false;
			});
		},
		toggleFilterBlock(item){
			var self = this;
			var timeout = 10;
			var block = $("#filter_block_" + item.id);
			block.toggleClass("is-open");

			if(!block.hasClass("is-open")){
				var elCount = block.find(".filter_control_item").length;
				block.find(".filter_control_item").each(function(){
					var controlEl = $(this);
					if(!controlEl.hasClass("checked")){
						setTimeout(function(){
							controlEl.removeClass(self.showClass);
							controlEl.addClass(self.hideClass);
							setTimeout(function(){controlEl.removeClass("animated")}, 300);
						}, timeout * controlEl.index());
					}
				});
			}else{
				block.find(".filter_control_item").each(function(){
					var controlEl = $(this);
					if(!controlEl.hasClass("checked")){
						controlEl.removeClass(self.hideClass);
						setTimeout(function(){
							controlEl.addClass(self.showClass);
						}, timeout * controlEl.index());
					}
				})
			}
		},
		setSort(by){
			var self = this;
			var order = "asc";
			if(self.sort.by == by){
				if(self.sort.order == "asc") order = "desc";
				else order = "asc";
			}
			var newSort = {by: by, order: order};
			this.$parent.setSort(newSort);
		},
		init(data){
			var self = this;
			self.filterData = data.filterData;
			self.filterItems = data.filterData.ITEMS;
			self.filterPriceItems = data.filterData.PRICE_ITEMS;
			self.filterTopItems = data.filterData.TOP_ITEMS;
			self.filterMidItems = data.filterData.MIDDLE_ITEMS;

			self.filtered = (data.filterData.SEF_DEL_FILTER_URL != data.filterData.SEF_SET_FILTER_URL);

			for(var i in self.filterMidItems) self.showDrop[self.filterMidItems[i].code] = false;

			self.SEF_URL = data.filterData.SEF_SET_FILTER_URL;
			self.CLEAR_URL = data.filterData.SEF_DEL_FILTER_URL;

			self.inited = true;
			self.loading = false;

			self.$nextTick(function(){
				var linePagging = 0;
				$(".selected-items").each(function(){
					var selContHeight = parseInt($(this).outerHeight());
					if(selContHeight > linePagging) linePagging = selContHeight;
				})
				$("#catalog_filter .second-line").css({"margin-bottom": linePagging + 24 + "px"});
			})

		},
		initRanges(){
			var self = this;
			$('.js-cat-filter .control_range').each(function () {
				var $range = $(this).find(".js-range-slider"),
					$inputFrom = $(this).find(".js-input-from"),
					$inputTo = $(this).find(".js-input-to");

				$range.ionRangeSlider({
					skin: "round",
					type: "double",
					onStart: updateInputs,
					onChange: applyRangeFilter,
					onUpdate: applyRangeFilter,
				});
				var instance = $range.data("ionRangeSlider");

				function updateInputs(data) {
					var from = data.from;
					var to = data.to;

					if(from == data.min) from = "";
					if(to == data.max) to = "";
					$inputFrom.prop("value", from);
					$inputTo.prop("value", to);
				}

				function applyRangeFilter(data){
					updateInputs(data);
					var from = data.from;
					var to = data.to;

					if(!!self.timer){
						clearTimeout(self.timer);
					}
					self.timer = setTimeout(function(){
						self.loading = true;
						self.parent.loading = true;
						var filterData = $("#catalog_filter").serialize();
						self.execFilter(filterData);
					}, 1000);
				}

				$inputFrom.on("input", function () {
					var val = $(this).prop("value");
					if (val < instance.options.min) {
						val = instance.options.min;
					} else if (val > instance.options.to) {
						val = instance.options.to;
					}
					instance.update({
						from: val
					});
				});

				$inputTo.on("input", function () {
					var val = $(this).prop("value");
					if (val < instance.options.from) {
						val = instance.options.from;
					} else if (val > instance.options.max) {
						val = instance.options.max;
					}
					instance.update({
						to: val
					});
				});
			});
		},
	},
};
</script>

<style scoped>
.control_cat-filter{position: relative;}
.control_cat-filter .badge {
    font-size: 8px;
    background: #EA4B67;
    color: #fff;
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    right: -6px;
	z-index: 2;
}
.filter-closer{
	display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.selected-items{
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
}
.selected-items label{
	font-size: 12px;
    border: 1px solid #dae0ed;
    padding: 0 5px;
    border-radius: 4px;
	cursor:pointer;
	margin-right: 5px;
	background-color: #dae0ed;
    color: #3363C9;
	transition: all 0.5s ease;
	opacity:1;
}
.pre_filter.loading .selected-items label{opacity: 0;}
.selected-items label .del-selected-item{
	font-weight: 600;color:red;
}
.checkboxes-dropdown .control_checkbox label .cnt{
	display: block;
    position: absolute;
    right: 0;
    padding-right: 20px;
    top: 50%;
    color: #3363c9;
    font-size: 10px;
    line-height: 14px;
    margin-top: -6px;
    text-align: right;
    width: 44px;
}
.checkboxes-dropdown .control_checkbox.disabled label .cnt {
    color: red;
}

.pre_filter.loading .second-line{overflow: hidden;}

.articul_select_control{position: relative;}
.articul_select_control .checkboxes-dropdown{
	position: absolute;
    width: 100%;
    background: #fff;
    z-index: 10;
    border: 1px solid #E5E5E5;
    border-top: 0;
    margin-top: -3px;
    padding: 10px 10px 10px 15px;
    border-radius: 0 0 4px 4px;
    max-height: 300px;
    overflow: auto;
	display: none;
}
.articul_select_control .checkboxes-dropdown .control_checkbox.hidden{
	display: none;
}
.articul_select_control.opened .checkboxes-dropdown{
	display: block;
}
.articul_select_control .checkboxes-dropdown::-webkit-scrollbar {
	width: 2px;
}
.articul_select_control .checkboxes-dropdown::-webkit-scrollbar-track {
	width: 2px;
}
.articul_select_control .checkboxes-dropdown::-webkit-scrollbar-thumb {
	width: 2px;
	background-color: #86adff;
}
#catalog_filter{
	position: relative;
	margin-top: 17px;
}
#catalog_filter>.component-loading{
	background-color: #f6f8fb;
	opacity: 0.8;
	z-index: 10;
	left: -10px;
    right: -10px;
    width: auto;
}
.catalog__filter .control_radio-order input[value="desc"] ~ i {
    background: transparent url(/images/sort_asc.svg) no-repeat center center;
}
.catalog__filter .control_radio-order input[value="asc"] ~ i {
    background: transparent url(/images/sort_desc.svg) no-repeat center center;
}
#modal-cat-filter{
	transition: all 0.5s ease 0.3s;
	display:block;
	background: rgb(0 0 0 / 0%);
	overflow: hidden;
	width:0;
	left:100%;
	opacity:1;
}
#modal-cat-filter aside{
	transition: right 0.3s ease;
	right:-300px;
}
#modal-cat-filter.show{
	background: rgb(0 0 0 / 30%);
	width:100%;
	left:0;
	transition: background 0.5s ease;
}
#modal-cat-filter.show aside{
	right:0;
}
.cat-filter__dropdown{
	cursor:pointer;
	transition: all 0.3s;
}
.cat-filter__dropdown:hover{
	background: rgb(235 238 245 / 34%);
}
.cat-filter__control{
	transition: all 0.3s;
	display:block;
	overflow: hidden;
}
.cat-filter__control .control_range{
	transition: all 0.3s;
	opacity:0;
	height:0;
	overflow: hidden;
}
.cat-filter__control .filter_control_item{
	display:none;
	opacity:0;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}
.cat-filter__control .filter_control_item.checked{
	display:block;
	opacity:1;
}
.cat-filter .control_checkbox-list{
	padding-bottom:0;
}
.cat-filter__control .filter_control_item:last-child{
	padding-bottom:7px;
}
.cat-filter__group .filter_control_item.animated{display:block;}
.cat-filter__group.is-open .cat-filter__control{height:auto;}

.cat-filter__group.is-open .cat-filter__control .control_range{
	opacity:1;
	height:auto;
}

.cat-filter .control_checkbox{position:relative;}
.cat-filter .control_checkbox label{position:relative;}
.cat-filter .control_checkbox label .cnt{
	display: block;
    position: absolute;
    right: 0;
    padding-right: 20px;
    top: 50%;
    color: #3363c9;
    font-size: 10px;
    line-height: 14px;
    margin-top: -6px;
    text-align: right;
    width: 44px;
}
.cat-filter .control_checkbox.disabled label .cnt{
    color: red;
}
.control_checkbox.disabled{
	pointer-events: none;
	opacity: 0.5;
}

.cat-filter .control_checkbox.checked:after{
    display: block;
    position: absolute;
    right: 100%;
    content: "";
    width: 5px;
    height: 5px;
    background: #3363c9;
    top: 50%;
    margin-top: -2px;
    margin-right: 12px;
    border-radius: 50%;
}
.modal__footer{position:relative;}
#filter_result{
	cursor: pointer;
	position: absolute;
    top: -35px;
    opacity: 0;
	background: rgb(235 238 245 / 81%);
    padding: 5px 20px;
    white-space: nowrap;
    font-size: 12px;
    color: #000;
    display: block;
    margin: auto;
    width: calc(100% - 40px);
    text-align: center;
    border-radius: 4px;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}
#filter_result .show_result{
    color: #3363c9;
    border-bottom: 1px dashed;
}
.articul_select_control .clear-field{
	display: none;
    position: absolute;
    cursor: pointer;
    color: #b0b0b0;
    top: 7px;
    z-index: 1;
    right: 36px;
}
.select-icon{
    position: absolute;
    top: 11px;
    z-index: 1;
    right: 11px;
	width: 15px;
	height: 15px;
}
.control_file label {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.articul_select_control.not-empty .clear-field{
	display: block;
}
.first-line.isShowSearch .control_checkbox{display:none;}
</style>
