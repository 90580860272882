var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card_stats-lines" }, [
    _c("div", {
      staticClass: "component-loading",
      class: { show: _vm.loading }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "card__header" }, [
      _c("h2", { staticClass: "card__title" }, [_vm._v(_vm._s(_vm.title))])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "stats-legend" },
      _vm._l(_vm.legendItems, function(item) {
        return _c("div", { staticClass: "legend" }, [
          _c("i", { style: { background: item.color } }),
          _vm._v(" " + _vm._s(item.name) + "\n            ")
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card__body" }, [
      _c("div", {
        style: "height:" + _vm.graph_height + "px",
        attrs: { id: _vm.element_id }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }