const ComponentUtils = {
    methods: {

        getParentComponentByName(componentName) {

            function getPrentRecursive(current) {
                if (current.$options.name
                    && current.$options.name.toLowerCase() === componentName.toLowerCase()) {
                    return current;
                }

                if (current.$parent) {
                    return getPrentRecursive(current.$parent);
                }
                return null;
            }

            return getPrentRecursive(this);
        },
    }
};
export default ComponentUtils;
