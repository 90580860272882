<template>
	<div class="product-detail">
		<div v-bind:class="{ show: loading }" class="component-loading"></div>
		<div class="environ environ_hr">
			<div v-bind:class="{'hidden': $root.arUser.profiles.count == 1}" class="environ__group">
				<label class="environ__label">Активный договор</label>
				<div class="environ__select environ__select--order">
					<select class="select-profile select-profile-home select" name="">
						<option 
							v-for="profile in $root.arUser.profiles.list" 
							v-bind:value="profile.id" 
							v-bind:class="{'active': profile.active}" 
							v-bind:selected="profile.active"  
							v-html="profile.props.ORDER_PROP_75.value"></option>
					</select>
				</div>
			</div>
			<div v-bind:class="{'hidden': activeProfile.stores.count == 1}" class="environ__group ">
				<label class="environ__label">Активный склад</label>
				<div class="environ__select">
					<select class="select-store select-store-home select" name="">
						<option 
							v-for="store in activeProfile.stores.list" 
							v-bind:value="store.ID" 
							v-bind:class="{'active': store.selected}" 
							v-bind:selected="store.selected"  
							v-html="store.TITLE"></option>
					</select>
				</div>
			</div>
		</div>
		<section class="product-one ">
			<div class="left-side" v-bind:class="{ 'loading': loading }">
				<div class="product-one__image js-prod-photo">
					<div v-for="img in productData.images" v-bind:class="{'is-active': img.active}" class="photo-container animated">
						<img v-bind:src="img.big.src" alt="">
					</div>
				</div>
				<ul class="product-one__gallery js-prod-gallery owl-carousel" >
					<li v-for="img in productData.images" v-bind:class="{'is-active': img.active}" v-on:click="setGalleryPhoto(img)">
						<a href="javascript:;"><img v-bind:src="img.thumb.src" alt="" /></a>
					</li>
				</ul>
				<div v-if="loaded" class="product-one__descr js-tab">
					<component-tabs v-bind="infoTabsData"></component-tabs>
				</div>
			</div>
			<div class="right-side">
				<div class="product-one__labels">
					<span v-for="label in productData.labels" v-bind:class="label.class" class="label" v-html="label.text"></span>
				</div>
				<div class="product-one__tools">
					<button v-on:click.prevent="favoriteAction" class="js-fav" v-bind:class="{'is-active': inFavorits}" title="Избранное">
						<svg width="18" height="18">
							<use xlink:href="/images/sprite.svg#star"></use>
						</svg>
					</button>
					<button v-on:click.prevent="compareAction" v-bind:class="{'is-active': inCompare}" class="js-comp" title="Сравнить">
						<svg width="22" height="18">
							<use xlink:href="/images/sprite.svg#chart-bar"></use>
						</svg>
					</button>
				</div>
				
				<div class="product-one__stock">
					<div v-if="productData.can_buy" class="stock-dropdown js-dropdown" v-bind:class="{'is-open': storesOpened}">
						<button class="stock-dropdown__trigger js-dropdown__trigger" v-on:click="storesOpened = !storesOpened">В наличии на складах</button>
						<ul class="stock-dropdown__list">
						   <li v-for="store in productData.amount">{{ store.store_title }} - {{ store.product_amount }}</li>
						</ul>
					</div>
					<span v-else class="stock stock_red">Нет в наличии</span>
				</div>
				
				<div class="product-one__art">Арт. {{ productData.article }}</div>
				<div class="product-one__name" v-html="productData.name"></div>
				<form v-if="loaded" class="product-one__form">
					<div v-if="productData.has_offers" class="options">
						<div v-for="prop in productData.sku" class="dl">
							<div class="dt">{{ prop.name }}:</div>
							<div class="dd">
								<div class="control_radio-option">
									<router-link :to="variant.link" v-for="variant in prop.variants" v-bind:title="variant.title" v-bind:class="{'is-active': variant.active, 'disabled': variant.disabled}">
										<span>{{ variant.name }}</span>
									</router-link>
								</div>
							</div>
						</div>
					</div>
					
					<div class="product-one__price">
						<template v-if="productData.can_buy">
							<span v-if="priceData.print_old" v-html="priceData.print_old" class="old-price"></span>
							<span v-html="priceData.print" class="price"></span>
						</template>
						<template v-else>
							<span v-html="priceData.print" class="last-price"></span>
						</template>
					</div>
					<div v-if="productData.ratio_info != ''" class="product__ratio_info">
						{{productData.ratio_info}}
					</div>
					
					<template v-if="!productData.basketDisabled">
						<div v-if="productData.can_buy" class="product-one__order">
							<div class="counter js-counter">
								<button v-on:click.prevent="counterMinus" type="button" class="btn decrease">-</button>
								<input class="count" type="text" name="quantity" :value="curCount" autocomplete="off" v-on:input="counterInput"/>
								<button v-on:click.prevent="counterPlus" type="button" class="btn increase">+</button>
							</div>
							<div v-if="inCart" class="btn btn__submit">Добавлено</div>
							<button v-else v-on:click.prevent="basketAction" type="submit" name="action" value="buy" class="btn btn__submit">В корзину</button>
						</div>
						<div v-else-if="productData.arhive" class="product-one__order">
							<button v-on:click.prevent="analogsAction" v-bind:class="{'disabled': productData.hide_analogs}" type="button" class="btn btn__analogs js-modal-analogs">Аналоги</button>
						</div>
						<div v-else class="product-one__order">
							<button v-on:click.prevent="analogsAction" v-bind:class="{'disabled': productData.hide_analogs}" type="button" class="btn btn__analogs js-modal-analogs">Аналоги</button>
							
							<router-link v-if="inSubscribe" :to="'/waiting/'" class="btn btn__submit">{{subscribeText}}</router-link>
							<button v-else type="submit" v-on:click.prevent="subscribeAction" value="waiting-list" name="action" class="btn btn__submit">{{subscribeText}}</button>
						</div>
					</template>
				</form>
				<div v-if="loaded" class="product-one__docs">
					<h3>Основная документация</h3>
					<div class="files">
						<a v-for="fileItem in productData.files" v-bind:href="fileItem.value" target="_blank" class="file">
							<svg width="21" height="21">
								<use xlink:href="/images/sprite.svg#pdf-file"></use>
							</svg>
							<span>{{ fileItem.name }}<br><span v-if="fileItem.size > 0">Файл {{ fileItem.size }} Мб</span></span>
						</a>
					</div>
				</div>
				<div class="product-one__alt-price">
					<div v-for="price in productData.info_prices" class="dl">
						<div class="dt" v-html="price.name"></div><div class="dd" v-html="price.value"></div>
					</div>
				</div>
			</div>
		</section>
		<section v-if="loaded" class="similar-products">
		
			<component-tabs v-bind="similarTabsData"></component-tabs>
			
		</section>
	</div>
</template>

<script>
import tabsComponent from '../components/tabsComponent';
import owlCarousel from 'owl.carousel';
export default {
	name: 'ProductPageComponent',
	data() {
		return {
			loading: true,
			loaded: false,
			breadcrumbs: [],
			productData: { files: {} },
			priceData: {},
			maxCount: 0,
			curCount: 1,
			timer: null,
			inCart: false,
			storesOpened: true,
			arUser: this.$root.arUser,
			animationClass: {
				out: "slide-fade-page", 
				in: ""
			},
			infoTabsData: {classes: {}},
			similarTabsData: {classes: {}},
		};
	},
	computed:{
		subscribeText(){
			var self = this;
			if(self.inSubscribe) return "В листе ожидания";
			else return "В лист ожидания";
		},
		product_id(){
			return parseInt(this.$route.params.product_id);
		},
		section_id(){
			return parseInt(this.$route.params.section_id);
		},
		inCompare(){
			var self = this;
			var inCompare = false;
			for(var i in self.arUser.compare.items){
				var item = self.arUser.compare.items[i];
				if(parseInt(item) == parseInt(self.product_id)) inCompare = true;
			}
			return inCompare;
		},
		inFavorits(){
			var self = this;
			var inFavorits = false;
			for(var i in self.arUser.favorite.items){
				var item = self.arUser.favorite.items[i];
				if(parseInt(item) == parseInt(self.product_id)) inFavorits = true;
			}
			return inFavorits;
		},
		inSubscribe(){
			var self = this;
			var inSubscribe = false;
			for(var i in self.arUser.waiting.items){
				var item = self.arUser.waiting.items[i];
				if(parseInt(item) == parseInt(self.product_id)) inSubscribe = true;
			}
			return inSubscribe;
		},
		activeProfile() {
			var self = this;
			return self.$root.arUser.profiles.active;
		},
	},
	components: {
		'component-tabs': tabsComponent,
	},
	mounted: function(){
		var self = this;
		self.loadProduct();
		self.$root.$on('settingsUpdated', function(){
			setTimeout(function(){ 
				self.loading = true;
				self.loadProduct();
			}, 500);
		});

		self.$nextTick(function(){
			$('.select').select2({
				minimumResultsForSearch: -1,
				width: "100%",
				selectionCssClass: "select2-results__option--selected",
			}).on('select2:select', function (e) {
				setTimeout(function(){
					var selectedProfileId = $(".select-profile-home option:selected").attr("value");
					var selectedStoreId = $(".select-store-home option:selected").attr("value");
					
					self.$root.setActiveProfile(selectedProfileId, selectedStoreId);
					self.loading = true;
				}, 300);
			});
		})
	},
	watch: {
		product_id: function(newVal){
			var self = this;
			self.loaded = false;
			self.loading = true;
			
			$(".animated-page").removeClass(self.animationClass.out).removeClass(self.animationClass.in).addClass(self.animationClass.out);
			setTimeout(function(){
				self.loadProduct();
			}, 200);
		},
		curCount: function(newValue, oldValue) {
			var self = this;
			if(self.loaded){
				if(newValue == 0){
					self.$root.basketAction(self.product_id, 0);
					self.inCart = false;
					setTimeout(function(){
						self.curCount = self.productData.ratio;
					}, 500);
				}else{
					
				}
			}
		}
	},
	methods:{
		counterInput: function(e){
			var self = this;
			
			if(!!self.timer) clearTimeout(this.timer);
			self.timer = setTimeout(function(){
				var inputVal = e.target.value;
				var ratio = parseFloat(self.productData.ratio);
				var newValue = Math.ceil(inputVal/ratio)*ratio;
				if(newValue > self.maxCount) newValue = self.maxCount;
				self.curCount = newValue;
				
				self.basketAction();
			}, 1000);
		},
		counterPlus: function(){
			var self = this;
			var newValue = self.curCount + self.productData.ratio;
			if(newValue > self.maxCount) newValue = self.maxCount;
			self.curCount = newValue;
			
			if(self.inCart) self.basketAction();
		},
		counterMinus: function(){
			var self = this;
			var newValue = self.curCount - self.productData.ratio;
			if(newValue <= 0) newValue = 0;
			self.curCount = newValue;
			
			if(self.inCart) self.basketAction();
		},
		basketAction: function(){
			var self = this;
			if(self.curCount > 0) self.inCart = true;
			self.$root.basketAction(self.product_id, self.curCount);
		},
		favoriteAction: function(){
			var self = this;
			self.$root.favoriteAction(self.product_id);
		},
		subscribeAction: function(){
			var self = this;
			self.$root.subscribeAction(self.product_id);
		},
		compareAction: function(){
			var self = this;
			self.$root.compareAction(self.product_id);
		},
		analogsAction: function(){
			var self = this;
			self.$root.analogsAction(self.productData);
		},
		loadProduct: function(){
			var self = this,
				urlData = {
				SECTION_ID: self.section_id,
				ELEMENT_ID: self.product_id,
			};
			
			//self.productData = {};
			self.infoTabsData = {classes: {}};
			self.similarTabsData = {classes: {}};
			
			self.loading = true;
			$.getJSON("/data/components/product.php", urlData, function(data){
				
				self.productData = data.item;
				self.priceData = data.item.ratio_price;
				self.$root.breadcrumbs = data.breadcrumbs;
				self.$root.catalog.activeSections = data.active_sections;
				self.maxCount = parseInt(self.productData.product.QUANTITY);	
				
				self.infoTabsData = {
					title: false,
					classes: {
						header: 'product-one__tabs',
						body: 'product-one__body',
					},
					tabsData: {
						tab_descr: {
							id: "tab_descr",
							name: "Описание",
							html: self.productData.detail_text,
							mode: '',
							active: true,
							data: {
								init: true,
							},
						},
						tab_charact: {
							id: "tab_charact",
							name: "Характеристики",
							html: '',
							mode: 'property_list',
							active: false,
							data: {
								properties: self.productData.display_props,
								init: false,
							},
						},
						tab_inf: {
							id: "tab_inf",
							name: "Информация об упаковке",
							html: '',
							mode: 'property_list',
							active: false,
							data: {
								properties: self.productData.pack,
								init: false,
							},
						},
					}
				};
				self.similarTabsData = {
					title: false,
					classes: {
						header: 'popular__tabs',
						body: 'similar__body',
					},
					tabsData: {
						tab_series: {
							id: "tab_series",
							name: "Все товары серии",
							html: '',
							mode: 'product_slider_full',
							active: true,
							disabled: false,
							data: {
								filter: {
									"!ID": self.product_id, 
									"!=PROPERTY_ARCHIVE": "1", 
									"PROPERTY_NAIMENOVANIE_MODELI": self.productData.properties.NAIMENOVANIE_MODELI.value, 
									IBLOCK_ID: 56
								},
								slider_id: "products_series",
								init: true,
							},
						},
					}
				};
				
				if(self.productData.properties.ANALOGI_NOMENKLATURY.value != ""){
					var analogArticles = self.productData.properties.ANALOGI_NOMENKLATURY.value.split(",");
					self.similarTabsData.tabsData["tab_alter"] = {
						id: "tab_alter",
						name: "Аналоги",
						html: '',
						mode: 'product_slider_full',
						active: false,
						disabled: false,
						data: {
							filter: {
								"!ID": self.product_id, 
								//"!=PROPERTY_ARCHIVE": "1", 
								"PROPERTY_CML2_ARTICLE": analogArticles, 
								IBLOCK_ID: 56
							},
							slider_id: "products_alter",
							init: false,
						},
					};
				}
				if(self.productData.properties.SOPUTSTVUYUSHCHIE_TOVARY.value != ""){
					var relatedArticles = self.productData.properties.SOPUTSTVUYUSHCHIE_TOVARY.value.split(",");
					self.similarTabsData.tabsData["tab_attend"] = {
						id: "tab_attend",
						name: "Сопутствующие товары",
						html: '',
						mode: 'product_slider_full',
						active: false,
						disabled: false,
						data: {
							filter: {
								"!ID": self.product_id, 
								"!=PROPERTY_ARCHIVE": "1", 
								"PROPERTY_CML2_ARTICLE": relatedArticles, 
								IBLOCK_ID: 56
							},
							slider_id: "products_attend",
							init: false,
						},
					};
				}
				
				self.loaded = true;
				
				self.$nextTick(function(){
					$('.product-one__form .options a').blur();
					$('.js-prod-gallery.owl-carousel').owlCarousel({
						margin: 2,
						nav: true,
						dots: false,
						items: 4
					});
					if($(".animated-page").hasClass(self.animationClass.out)){
						$(".animated-page").removeClass(self.animationClass.out).addClass(self.animationClass.in);
						setTimeout(function(){
							$(".animated-page").removeClass(self.animationClass.in);
						}, 200);
					}
					setTimeout(function(){self.loading = false;}, 200);
					
					if(self.arUser.basket.ids_product[self.product_id] != undefined){
						self.curCount = parseFloat(self.arUser.basket.ids_product[self.product_id]);
						self.inCart = true;
					}else{
						self.curCount = parseFloat(self.productData.ratio);
						self.inCart = false;
					}
					
				})
				
				
				

				var cats = self.arUser.fields.UF_CATS;
				if ( cats.indexOf( 'i:2;s:1:"N";' ) !== -1 )
				{
					self.productData.basketDisabled = true;
				}
				
			})
		},
		setGalleryPhoto(img){
			var self = this;
			for(var i in self.productData.images) self.productData.images[i].active = false;
			img.active = true;
		},
	},
};
</script>

<style>
.product-detail .product__ratio_info{
	text-align: right;
    font-size: 12px;
}
.product-detail .component-loading{
    z-index: 10;
}
.product-detail .left-side{
	transition: all 0.3s ease;
}
.product-detail .left-side.loading{
    opacity:0;
}
.product-one__tabs{
	list-style: none;
}
.product-one__gallery{
	padding:0; list-style:none;
}
.product-one__gallery.owl-carousel .owl-stage{
	margin: auto;
}
.product-one__gallery.owl-carousel .owl-stage-outer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-one__image{
    max-width: 400px;
    display: flex;
	height: 400px;
    justify-content: center;
    align-items: center;
	position:relative;
}
.product-one__image img {
    max-width: 100%;
    max-height: 100%;
	width: auto;
}
.photo-container{
	position: absolute;
	transition: all 0.5s ease;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
	opacity:0;
}
.photo-container.is-active{
	opacity:1;
}
.product-one__form .options .is-active span{
    color: #3363C9;
    border-color: #3363C9;
}

.product-one__form .options a:not(.is-active):focus span{
	position: relative;
	background:#fff;
	color: #3363C9;
	border-color: #3363C9;
	border: 1px solid #3363C9;
	border-radius: 4px;
}
.product-one__form .options a:not(.is-active):focus span:after{
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(/images/preloader.svg);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: 15px 20px;
	background-color: #fff;
	transition: all 0.5s;
	opacity: 0.7;
}
.product-one__gallery .owl-stage{
	flex-wrap: nowrap;
    display: flex;
}
</style>
