<template>
	<section class="" style="max-width: 100%; width: 100%;">
		<div class="bonus-program">
			<div class="bonus-program__header">
				<h2 class="bonus-program__title" v-html="name"></h2>
			</div>
			<div v-bind:class="{'is-open' : showMore}" class="cutter js-cutter">
				<section class="sect-bonus-program">
					<div class="bonus-program__body" v-html="content"></div>
				</section>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	data: function () {
		var self = this;
		return {
			showMore: false,
			name: "Правила участия в программе",
			content: "",
			breadcrumbs: [],
		}
	},
	components: {
		
	},
	computed:{
		
	},
	watch: {
		
	},
	methods: {
		
	},
	mounted: function(){
		var self = this;
		
		$.getJSON("/data/components/page_content.php", {page: "condition"}, function(json){
			if(json.status) {
				self.name = json.name;
				self.content = json.content;
				
				self.breadcrumbs = [
					{ link: '/', name: 'Главная' },
					{ link: '/bonus/', name: 'Feron-бонус' },
					{ link: '/bonus/condition/', name: self.name },
				];
				self.$root.breadcrumbs = self.breadcrumbs;
			}
			
			self.$nextTick(function(){
				
			})
		});
		
	}
};
</script>

<style scoped>

</style>