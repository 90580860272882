var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        staticClass: "component-loading",
        class: { show: _vm.loading }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "environ environ_hr" }, [
        _c(
          "div",
          {
            staticClass: "environ__group",
            class: { hidden: _vm.$root.arUser.profiles.count == 1 }
          },
          [
            _c("label", { staticClass: "environ__label" }, [
              _vm._v("Активный договор")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "environ__select environ__select--order" },
              [
                _c(
                  "select",
                  {
                    staticClass: "select-profile select-profile-home select",
                    attrs: { name: "" }
                  },
                  _vm._l(_vm.$root.arUser.profiles.list, function(profile) {
                    return _c("option", {
                      class: { active: profile.active },
                      domProps: {
                        value: profile.id,
                        selected: profile.active,
                        innerHTML: _vm._s(profile.props.ORDER_PROP_75.value)
                      }
                    })
                  }),
                  0
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "environ__group ",
            class: { hidden: _vm.activeProfile.stores.count == 1 }
          },
          [
            _c("label", { staticClass: "environ__label" }, [
              _vm._v("Активный склад")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "environ__select" }, [
              _c(
                "select",
                {
                  staticClass: "select-store select-store-home select",
                  attrs: { name: "" }
                },
                _vm._l(_vm.activeProfile.stores.list, function(store) {
                  return _c("option", {
                    class: { active: store.selected },
                    domProps: {
                      value: store.ID,
                      selected: store.selected,
                      innerHTML: _vm._s(store.TITLE)
                    }
                  })
                }),
                0
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("component-section")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }