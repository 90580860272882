import { render, staticRenderFns } from "./filterComponent.vue?vue&type=template&id=28131dd5&scoped=true&"
import script from "./filterComponent.vue?vue&type=script&lang=js&"
export * from "./filterComponent.vue?vue&type=script&lang=js&"
import style0 from "./filterComponent.vue?vue&type=style&index=0&id=28131dd5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28131dd5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bitrix/ext_www/personal.feron.ru/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('28131dd5')) {
      api.createRecord('28131dd5', component.options)
    } else {
      api.reload('28131dd5', component.options)
    }
    module.hot.accept("./filterComponent.vue?vue&type=template&id=28131dd5&scoped=true&", function () {
      api.rerender('28131dd5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "custom/vue/components/filterComponent.vue"
export default component.exports