<template>
	<section class="b-content b-content_orders js-orders" data-source="demo-data/orders.json">
		<div class="b-content__header">
			<h2 class="b-content__title">Список заказов</h2>
			<div class="b-content__header-aside">
				<button class="btn js-open-modal" v-on:click="creatOrderShow = true">Новый заказ</button>
			</div>
		</div>
		<div class="b-content__top">
			<div class="first-line">
				<!--div class="control_group lbl_top">
					<label>История заказов для</label>
					<div class="control_select">
						<select class="select js-owner-select" name="owner">
							<option value="" selected>Все организации</option>
							<option 
								v-for="profile in $root.arUser.profiles.list" 
								v-bind:value="profile.name" 
								v-html="profile.name"></option>
						</select>
					</div>
				</div-->

				<div class="control_group lbl_top">
					<label>Диапазон дат</label>
					<div class="control_period">
						<input type="text" class="js-period-picker" name="date_range" placeholder="" v-bind:value="startDate + ' - ' + endDate">
						<button type="button" class="js-clear-input-btn" v-on:click="clearPeriodFilter"></button>
					</div>
				</div>

				<div class="control_search">
					<form v-on:submit.prevent="searchOrders">
						<input type="text" value="" placeholder="Поиск" name="text" id="searchOrders">
						<button type="submit" @click="searchOrders">
							<svg width="17" height="16">
								<use xlink:href="/images//sprite.svg#search"></use>
							</svg>
						</button>
					</form>
				</div>
			</div>
		</div>
		<div class="b-content__body">
			<div v-bind:class="{ show: loading }" class="component-loading"></div>
			<div class="resp-table-wrap orders__table">
				<table class="t-orders">
					<thead>
						<tr>
							<th><button class="sort" data-field="ID" v-on:click.prevent="setOrderSort">№ заказа</button></th>
							<th><button class="sort" data-field="DATE_INSERT" v-on:click.prevent="setOrderSort">Дата заказа</button></th>
							<th><button class="sort" data-field="PROPERTY_SUMM" v-on:click.prevent="setOrderSort">Сумма заказа</button></th>
							<th>Резерв до</th>
							<th width="130"><button class="sort" data-field="PROPERTY_STATUS" v-on:click.prevent="setOrderSort">Статус заказа</button></th>
							<th width="28">&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="showOrders" v-for="order in orders">
							<td>{{order.PROPS.BUH_NUM.VALUE}}</td>
							<td>{{order.DATE_INSERT_FORMAT}}</td>
							<td><b class="" v-html="order.PRICE"></b></td>
							<td>{{order.PROPS.srok_rezerva.VALUE}}</td>
							<td class="status_tr"><span class="order-status-name" v-bind:style="'color:' + order.STATUS.COLOR">{{order.STATUS.NAME}}</span></td>
							<td>
								<router-link :to="'/orders_client/order/' + order.ACCOUNT_NUMBER + '/'" class="detail-btn">
									<svg class="arrow" width="7" height="12">
										<use xlink:href="/images/sprite.svg#caret-right"></use>
									</svg>
								</router-link>
							</td>
						</tr>
						<tr v-if="!showOrders">
							<td colspan="6"><span class="empty-orders">Заказы отсутствуют</span></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		
		<div class="b-content__footer">
			<component-pagination></component-pagination>
		</div>
		
		<div v-bind:class="{'is-open': creatOrderShow}" class="modal modal_place-order" v-bind:id="modalId">
			<div class="modal__wrap">
				<div class="modal__inner animated zoomIn">
					<button class="modal__close" v-on:click="to_hide = true"></button>
					<div class="modal__container">
						<div class="steps js-steps">
							<div class="step step_1 is-active">
								<h4>Как вы хотите сформировать заказ?</h4>
								<div class="tools">
									<button class="btn btn_transp js-goto-step" v-on:click="importRedirect"><span>Загрузить из Exсel</span></button>
									<div v-on:click="catalogRedirect" class="btn">Выбрать товары в каталоге</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		
	</section>
</template>

<script>
import paginationComponent from '../components/paginationComponent';
import daterangepicker from 'daterangepicker';
import '/node_modules/daterangepicker/daterangepicker.css';

export default {
	data: function () {
		var self = this;
		var endDate = new Date();
		var starDate = new Date();
		var d = starDate.setMonth(starDate.getMonth() - 1);
		
		return {
			name: "orders",
			loading: true,
			showMore: false,
			modalId: "modal-place-order",
			creatOrderShow: false,
			to_hide: false,
			show: false,
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
				{ link: '/orders_client/', name: 'Мои заказы' },
			],
			showOrders: true,
			orders: [],
			filterOrders: {},
			sortOrders: {by: "ID", order: "DESC"},
			navPageNum: self.$route.params.navPageNum || 1,
			navPageSize: self.$route.params.NavPageSize || 20,
			navPageCount: 1,
			navParam: {},
			dateFilterEl: {},
			startDate: starDate.toLocaleDateString(),
			endDate: endDate.toLocaleDateString(),
		}
	},
	components: {
		'component-pagination': paginationComponent,
	},
	computed:{
		route_params(){
			return this.$route.params;
		},
	},
	watch: {
		to_hide:function(to_hide){
			var self = this;
			if(to_hide){
				$("#" + self.modalId + " .modal__inner").addClass("zoomOut");
				setTimeout(function(){
					self.creatOrderShow = false;
					self.to_hide = false;
					
					$("#" + self.modalId + " .modal__inner").removeClass("zoomOut");
				}, 400);
			}
		},
		creatOrderShow: function(){
			var self = this;
			if(self.creatOrderShow) $("#modal-overlay").fadeIn();
			else $("#modal-overlay").fadeOut();
		},
		filterOrders: function(newVal, oldVal){
			var self = this;
			
			self.loadOrders();
		},
		sortOrders: function(){
			this.loadOrders();
		},
		route_params: function (newVal, oldVal) {
			var self = this;
			self.loading = true;
			self.navPageNum = newVal.navPageNum || 1;
			
			if(self.showMore) {
				self.loadOrders();
			}
			else{
				setTimeout(function(){
					self.loadOrders();
				}, 10);
			}
		},
		showMore: function(){
			var self = this;
		},
	},
	methods: {
		searchOrders: function(){
			var self = this;
			var sortBy = self.sortOrders.by;
			self.filterOrders = {"ID": $("#searchOrders").val()};
			
		},
		catalogRedirect: function(){
			var self = this;
			self.to_hide = true;
			setTimeout(function(){
				self.$root.$router.push({path: "/catalog"});
			}, 450);
		},
		importRedirect: function(){
			var self = this;
			self.to_hide = true;
			setTimeout(function(){
				self.$root.importExcelModal = true;
			}, 450);
		},
		setOrderSort(e){
			var self = this;
			var sortByParam = e.target.dataset.field;
			var sortOrder = "asc";
			
			if(self.sortOrders.by == sortByParam && self.sortOrders.order == "asc") sortOrder = "desc";
			else if(self.sortOrders.by == sortByParam && self.sortOrders.order == "desc")sortOrder = "asc";
			
			self.sortOrders = {by: sortByParam, order: sortOrder};
			
			$(".sort").removeClass("sort_asc sort_desc");
			$(e.target).addClass("sort_" + sortOrder);
		},
		loadOrders: function(){
			var self = this;
			var sortBy = self.sortOrders.by;
			
			var urlData = {
				"filter": self.filterOrders,
				"nav": {
					"nPageSize": self.navPageSize, 
					"iNumPage": self.navPageNum,
				}
			};
			
			urlData.sort = {};
			urlData.sort[sortBy] = self.sortOrders.order;
			
			$.getJSON("/data/components/orders_client.php", urlData, function(json){
				if(json.status) {
					
					self.navParam = json.nav;
					self.navPageCount = json.nav.NavPageCount;
					
					var newData = [];
					
					if(!self.showMore){
						for(var i in json.orders) newData.push(json.orders[i]);
						self.orders = newData;
						self.$root.$emit('triggerScroll');
					}else{
						for(var i in json.orders) newData.push(json.orders[i]);
						self.orders = self.orders.concat(newData);
					}
					
					self.showOrders = self.orders.length > 0;
					
					self.$nextTick(function(){
						var maxDate = new Date().toLocaleDateString();
						self.dateFilterEl = jQuery('.js-period-picker').daterangepicker({
							"maxDate": maxDate,
							"autoApply": true,
							"showDropdowns": false,
							"locale": {
								"format": "DD.MM.YYYY",
								"separator": " - ",
								"applyLabel": "Сохранить",
								"cancelLabel": "Назад",
								"daysOfWeek": [
									"Вс",
									"Пн",
									"Вт",
									"Ср",
									"Чт",
									"Пт",
									"Сб"
								],
								"monthNames": [
									"Январь",
									"Февраль",
									"Март",
									"Апрель",
									"Май",
									"Июнь",
									"Июль",
									"Август",
									"Сентябрь",
									"Октябрь",
									"Ноябрь",
									"Декабрь"
								],
								"firstDay": 1
							}
						});
					
						self.dateFilterEl.on('cancel.daterangepicker', function(ev, picker) {
							self.clearPeriodFilter();
						});
						self.dateFilterEl.on('apply.daterangepicker', function(ev, picker) {
							var filterStartDate = picker.startDate.format('DD.MM.YYYY')
							var filterEndDate = picker.endDate.format('DD.MM.YYYY')
							self.startDate = filterStartDate;
							self.endDate = filterEndDate;
							self.filterOrders[">=DATE_CREATE"] = filterStartDate;
							self.filterOrders["<DATE_CREATE"] = filterEndDate;
							self.loading = true;
							self.loadOrders();
						});
						
						self.showMore = false;
					})
					
					self.$emit('sectionLoaded', json);
					setTimeout(function(){
						self.loading = false;
					}, 500)
				}
			});
		 },
		 clearPeriodFilter: function(){
			var self = this;
			self.dateFilterEl.val("");
			self.filterOrders[">=DATE_CREATE"] = false;
			self.filterOrders["<DATE_CREATE"] = false;
			
			self.loading = true;
			self.loadOrders();
		 },
	},
	mounted: function(){
		var self = this;
		self.$root.breadcrumbs = self.breadcrumbs;
		self.loadOrders();
		
		self.$nextTick(function(){
			$('.js-owner-select').select2({
				minimumResultsForSearch: -1,
				width: "100%",
			}).on("change", function(){
				var owner = $("select[name=owner]").val();
				if(owner != "") self.filterOrders.PROPERTY_VAL_BY_CODE_COMPANY = owner;
				else self.filterOrders.PROPERTY_VAL_BY_CODE_COMPANY = false;
				
				self.loading = true;
				self.loadOrders();
			});
		});
	}
};
</script>

<style scoped>
.status_tr{
	padding: 7px 5px 6px;
}
.empty-orders{
	display: block;
    text-align: center;
    padding: 20px;
    font-size: 14px;
}
.b-content__body{
	min-height:300px;
}
.orders__table th button.sort_desc:before {
    background: transparent url(/images/sort_asc.svg) no-repeat center center;
}
.orders__table th button.sort_asc:before {
    background: transparent url(/images/sort_desc.svg) no-repeat center center;
}
</style>