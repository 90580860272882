<template>
	<div class="sidebar">
		<nav v-bind:class="[isCatalog ? '' : 'transitioning' , menuClass]" class="menu">
			<div v-bind:class="{ show: loading }" class="component-loading"></div>
			<router-link :to="'/personal/'" class="sb-user">
				<div class="sb-user__pic">
					<img v-bind:src="$root.arUser.avatar" alt="">
				</div>
				<div class="sb-user__info">
					<div>ID: <b>{{$root.arUser.fields.XML_ID}}</b></div>
					
					<div class="profile-popup" v-if="$root.arUser.profiles.count != 0" v-html="$root.arUser.profiles.active.props.ORDER_PROP_75.value"></div>
					<div class="profile-popup" v-else>{{$root.arUser.fields.LOGIN}}</div>
				</div>
			</router-link>
			<ul class="sb-menu">
				<template v-for="item in menu.menu">
					<li class="separ"></li>

					<li v-bind:key="item.key">
						<router-link v-bind:to=item.link v-bind:data-sort="item.sort" exact-active-class="is-active">
							<div class="sb-menu__icon">
								<span v-html="item.icon"></span>
								<span v-if="badges[item.badge_counter]" v-bind:class="item.badge" class='badge'>{{ badges[item.badge_counter] }}</span>
							</div>
							<span class="sb-menu__text">{{ item.text }}</span>
						</router-link>
					</li>
				</template>
			</ul>
			<router-link to="/login/?logout=yes" class="btn sb-exit">
				<svg class="svg-icon" width="14" height="14">
					<use xlink:href="/images/sprite.svg#sb-exit"></use>
				</svg>
				<span class="btn_text">Выйти</span>
			</router-link>
		</nav>

		<div class="scroll-wrapper">
			<ul v-bind:class="[isCatalog ? '' : 'transitioning' , menuClass]" class="sb-tree">
				<li v-for="catalog_item in menu.catalog" class="menu_item" v-bind:class="{'is-active showed': isActiveSection(catalog_item), 'is-open': isOpenSection(catalog_item)}" v-bind:data-level="catalog_item.level" v-bind:data-id="catalog_item.key">
					<div class="sb-tree__unit">
						<router-link v-bind:to=catalog_item.link exact-active-class="is-active-route">{{ catalog_item.text }}</router-link>
						<button v-if="catalog_item.is_parent" class="caret" v-bind:data-id="catalog_item.key" v-on:click=showSubmenu></button>
					</div>
					<ul v-if="catalog_item.is_parent" v-bind:data-id="catalog_item.key" class="submenu_item">
						<li v-for="sub_item in catalog_item.subs" class="menu_item animated " v-bind:class="{'is-active showed': isActiveSection(sub_item), 'is-open': isOpenSection(sub_item), 'showed': isOpenSection(catalog_item)}" v-bind:data-level="sub_item.level" v-bind:data-id="sub_item.key">
							<div class="sb-tree__unit">
								<router-link v-bind:to=sub_item.link exact-active-class="is-active-route" v-html=sub_item.text>{{ sub_item.text }}</router-link>
								<button v-if="sub_item.is_parent" class="caret" v-bind:data-id="sub_item.key" v-on:click=showSubmenu></button>
							</div>
							<ul v-if="sub_item.is_parent" v-bind:data-id="sub_item.key" class="submenu_item">
								<li v-for="sub_sub_item in sub_item.subs" class="menu_item animated" v-bind:data-sort="sub_sub_item.sort" v-bind:class="{'is-active showed': isActiveSection(sub_sub_item), 'showed': isOpenSection(sub_item)}" v-bind:data-level="sub_sub_item.level" v-bind:data-id="sub_sub_item.key">
									<div class="sb-tree__unit">
										<router-link v-bind:to=sub_sub_item.link exact-active-class="is-active-route" v-html=sub_sub_item.text></router-link>
									</div>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'component-menu',
	data: function() {
		return {
			menuTemplate: "left",
			menu: [],
			menuBigClass: "menu_big",
			menuSmallClass: "menu_small",
			loading: true,
		}
	},
	computed:{
		arSettings(){ return this.$root.arSettings; },
		isCatalog(){ return this.$root.isCatalog; },
		activeSections(){ return this.$root.catalog.activeSections || {} },
		badges(){ return this.$root.badges; },
		menuClass(){
			var mClass = this.isCatalog ? this.menuSmallClass : this.menuBigClass;
			return mClass;
		},
		section_id(){ return this.$route.params.section_id; },
	},
	mounted: function(){
		var self = this;
		//self.$parent.$on('sectionLoaded', self.init);
		//self.$root.$on('settingsUpdated', self.init);
		self.init();
	},
	ready: function(){
		var self = this;
	},
	watch: {
		arSettings: function (newVal, oldVal) {
			this.init();
		},
		isCatalog: function (newVal, oldVal) {
			$(".sb-tree").on("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function(){
				$(".sb-tree").removeClass("transitioning");
			});
			$(".menu").on("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function(){
				$(".menu").removeClass("transitioning");
			});
		},
		section_id: function (section_id) {
			var self = this;
			var curSecId = this.section_id;
			var newActiveSections = [];
			newActiveSections.push(curSecId);
			$(".menu_item[data-id=" + curSecId + "]").parents(".menu_item").each(function(){
				var menu_item = $(this);
				newActiveSections.push(menu_item.data("id"));
			});
			self.$root.catalog.activeSections = newActiveSections;
		},
		activeSections: function (activeSections) {
			// console.log(activeSections)
		},
	},
	methods:{
		showSubmenu: function(e){
			var menuId = $(event.target).data("id");
			var menuItem = $(event.target).parent().parent();
			var menuLevel = parseInt(menuItem.data("level"));
			
			var showClass = "slideInUp showed"
			$(".menu_item[data-level=" + menuLevel + "]").each(function(){
				if($(this).find("button").data("id") != menuId){
					$(this).removeClass('is-open');
					$(this).find(".menu_item").removeClass('is-open');
					$(this).find(".menu_item").removeClass(showClass);
				}
			});
			menuItem.toggleClass('is-open');
			
			var tmout = 70;
			menuItem.find(".menu_item[data-level=" + (menuLevel + 1) + "]").each(function(){
				var el = $(this);
				setTimeout(function(){
					el.addClass(showClass);
				}, tmout * el.index());
			});
		},
		init: function(){
			var self = this;
			self.loading = true;
			
			$(".menu").removeClass("transitioning");
			$.getJSON("/data/components/menu.php?tpl=" + self.menuTemplate + "&SECTION_ID=" + self.section_id, function(json){
				app.menu = json;
				self.menu = json;
				self.$root.catalog.activeSections = json.active_sections;
				self.loading = false;
			});
		},
		isActiveSection: function(item){
			var self = this;
			var secId = item.key;
			
			if(!self.isCatalog) return false;
			
			var isActive = false;
			for(var i in self.activeSections){
				if(parseInt(self.activeSections[i]) == parseInt(secId)) isActive = true;
			}
			return isActive;
		},
		isOpenSection: function(item){
			var self = this;
			
			if(!self.isCatalog) return false;
			
			var secLevel = parseInt(item.level);
			var maxLevel = parseInt(self.activeSections.length);
			return self.isActiveSection(item) && maxLevel > secLevel;
		},
		setActiveSections: function(){
			var self = this;
			var sectionId = parseInt(self.section_id);
			
		},
	},
	
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.sb-tree > li > ul > li > .sb-tree__unit a, 
	.sb-tree > li > ul > li > .sb-tree__unit span{
		padding: 8px 15px 8px 21px;
	}
	.sb-tree .submenu_item>.menu_item[data-level="3"] a{
		padding-left: 26px;
	}
	.menu .sb-user__info{
		position: relative;
	}
	.profile-popup{
		display: none;
		position: absolute;
		background: #fff;
		box-shadow: 0 0 10px -2px #000;
		padding: 10px;
		color: #000;
		top: 100%;
		left: 0;
		margin-top: 15px;
	}
	.menu .sb-user__info:hover .profile-popup{
		display: block;
	}
</style>
