var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "component-container" }, [
    _c("div", {
      staticClass: "component-loading",
      class: { show: _vm.loading }
    }),
    _vm._v(" "),
    !_vm.emptyResult
      ? _c("div", { staticClass: "b-content__top" }, [
          _vm.showViewChanger
            ? _c(
                "div",
                { staticClass: "control_view-toggle", class: _vm.viewMode },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn_view-toggle js-view-toggle",
                      class: { "is-active": _vm.viewMode == "list-mode" },
                      attrs: { value: "list-mode" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.viewMode = "list-mode"
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "svg-icon",
                          attrs: { width: "18", height: "18" }
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "/images/sprite.svg#list" }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn_view-toggle js-view-toggle",
                      class: { "is-active": _vm.viewMode == "grid-mode" },
                      attrs: { value: "grid-mode" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.viewMode = "grid-mode"
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "svg-icon",
                          attrs: { width: "20", height: "21" }
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "/images/sprite.svg#grid" }
                          })
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e()
        ])
      : _c("div", { staticClass: "empty-result" }, [
          _c("h4", { domProps: { innerHTML: _vm._s(_vm.emptyMess) } })
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "animated-page" }, [
      _c(
        "div",
        {
          staticClass: "product-grid",
          class: [_vm.viewMode, { loading: _vm.loading }],
          attrs: {
            "data-page-size": _vm.navPageSize,
            "data-page": _vm.navPageNum
          }
        },
        _vm._l(_vm.items, function(productItem) {
          return _c(
            "component-list-item",
            _vm._b(
              {
                key: "list_component_" + productItem.id,
                attrs: {
                  can_delete: _vm.can_delete,
                  "data-page": _vm.navPageNum
                }
              },
              "component-list-item",
              productItem,
              false
            )
          )
        }),
        1
      )
    ]),
    _vm._v(" "),
    _vm.showPager
      ? _c(
          "div",
          { staticClass: "b-content__footer" },
          [_c("component-pagination")],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }