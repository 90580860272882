var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "b-content b-content_order",
      attrs: { id: "printableArea" }
    },
    [
      _c("div", {
        staticClass: "component-loading",
        class: { show: _vm.loading }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "b-content__header" }, [
        _c("h2", { staticClass: "b-content__title" }, [
          _vm._v(
            "Заказ " +
              _vm._s(_vm.result.ACCOUNT_NUMBER) +
              " от " +
              _vm._s(_vm.result.DATE_INSERT_FORMATED) +
              " "
          ),
          _vm.result.CANCELED == "Y"
            ? _c("span", { staticClass: "order-canceled" }, [
                _vm._v("(Отменен)")
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "b-content__header-aside" }, [
          _c("div", { staticClass: "order-entity" }, [
            _c("div", { staticClass: "order-entity__user" }, [
              _vm._v("Оформил  пользователь: "),
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.result.ORDER_PROPS.CONTACT_PERSON.VALUE)
                }
              })
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "b-content__body" }, [
        _c("div", { staticClass: "order-detail" }, [
          _vm.loaded
            ? _c("div", { staticClass: "resp-table-wrap" }, [
                _c("table", { staticClass: "t-orders" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", {
                        domProps: {
                          innerHTML: _vm._s(_vm.result.ACCOUNT_NUMBER)
                        }
                      }),
                      _vm._v(" "),
                      _c("td", {
                        domProps: {
                          innerHTML: _vm._s(_vm.result.DATE_INSERT_FORMATED)
                        }
                      }),
                      _vm._v(" "),
                      _c("td", {
                        domProps: {
                          innerHTML: _vm._s(_vm.result.PRICE_FORMATED)
                        }
                      }),
                      _vm._v(" "),
                      _c("td", [
                        _c("div", { staticClass: "status-selector" }, [
                          _c(
                            "div",
                            {
                              staticClass: "current-status",
                              attrs: {
                                onclick:
                                  "$(this).parent().toggleClass('opened')"
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "status",
                                style: "color:" + _vm.result.STATUS.COLOR,
                                domProps: {
                                  innerHTML: _vm._s(_vm.result.STATUS.NAME)
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "svg",
                                {
                                  staticClass: "svg-icon",
                                  attrs: { width: "8", height: "5" }
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/images/sprite.svg#arrow_down"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "statuses-list" },
                            _vm._l(_vm.result.STATUSES, function(item) {
                              return _c(
                                "div",
                                {
                                  staticClass: "statuses-list-option",
                                  class: { selected: item.SELECTED },
                                  on: {
                                    click: function($event) {
                                      return _vm.setStatus(item.ID)
                                    }
                                  }
                                },
                                [
                                  _c("span", { staticClass: "radio-icon" }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { attrs: { "data-color": item.COLOR } },
                                    [_vm._v(_vm._s(item.NAME))]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "order-detail-params" }, [
            _c("h2", { staticClass: "b-content__title" }, [
              _vm._v("Параметры заказа")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal_row" }, [
              _c("div", { staticClass: "modal_col-6" }, [
                _c("h4", { staticClass: "b-content__subtitle" }, [
                  _vm._v("Контактное лицо:")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "control_text" }, [
                  _c("input", {
                    attrs: {
                      placeholder: "Фамилия, имя",
                      type: "text",
                      name: "FIELDS[NAME]",
                      readonly: ""
                    },
                    domProps: {
                      value: _vm.result.ORDER_PROPS.CONTACT_PERSON.NAME
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "control_text" }, [
                  _c("input", {
                    attrs: {
                      placeholder: "E-mail",
                      type: "text",
                      name: "FIELDS[EMAIL]",
                      readonly: ""
                    },
                    domProps: {
                      value: _vm.result.ORDER_PROPS.CONTACT_PERSON.EMAIL
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "control_text" }, [
                  _c("input", {
                    attrs: {
                      placeholder: "Контактный телефон",
                      type: "text",
                      readonly: "",
                      name: "FIELDS[PHONE]"
                    },
                    domProps: {
                      value: _vm.result.ORDER_PROPS.CONTACT_PERSON.PHONE
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal_col-6" }, [
                _c("h4", { staticClass: "b-content__subtitle" }, [
                  _vm._v("Комментарий к заказу")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "control_text" }, [
                  _c("textarea", {
                    attrs: { name: "FIELDS[COMMENT]" },
                    domProps: { value: _vm.result.COMMENT }
                  })
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "order-section" }, [
            _c("h2", { staticClass: "order-section__title" }, [
              _vm._v("Перечень товаров")
            ]),
            _vm._v(" "),
            _vm.loaded
              ? _c(
                  "div",
                  {
                    class: [
                      "cutter js-cutter",
                      { "small-basket": _vm.result.BASKET.length < 8 },
                      {
                        "is-open":
                          _vm.showAllOrder || _vm.result.BASKET.length < 10
                      }
                    ]
                  },
                  [
                    _c(
                      "div",
                      {
                        ref: "cart-items",
                        staticClass: "cutter__target",
                        style: _vm.getMaxHeight
                      },
                      [
                        _c("div", { staticClass: "resp-table-wrap" }, [
                          _c("table", { staticClass: "product-table" }, [
                            _c("thead", { ref: "cart-header" }, [_vm._m(1)]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.result.BASKET, function(item) {
                                return _c(
                                  "tr",
                                  { ref: "cart-item", refInFor: true },
                                  [
                                    _c("td", [_vm._v(_vm._s(item.NUM))]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item-img",
                                          staticStyle: { width: "40px" }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "t-prod-pic",
                                            attrs: {
                                              width: "40",
                                              src: item.PREVIEW_PICTURE,
                                              alt: ""
                                            }
                                          })
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c("router-link", {
                                          staticClass: "t-prod-art",
                                          attrs: { to: item.DETAIL_PAGE_URL },
                                          domProps: {
                                            innerHTML: _vm._s(item.ARTICLE)
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: { innerHTML: _vm._s(item.NAME) }
                                    }),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(item.QUANTITY) +
                                            _vm._s(item.MEASURE_TEXT)
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "white-space": "nowrap" }
                                      },
                                      [
                                        _c("b", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              item.PRICE_FORMATED
                                            )
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "white-space": "nowrap" }
                                      },
                                      [
                                        _c("b", {
                                          domProps: {
                                            innerHTML: _vm._s(item.FORMATED_SUM)
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.result.BASKET.length > 10,
                            expression: "result.BASKET.length > 10"
                          }
                        ],
                        class: [
                          "cutter__trigger js-cutter-trigger noPrint",
                          { "is-open": _vm.showAllOrder }
                        ],
                        attrs: {
                          "data-open": "Показать весь список",
                          "data-close": "Свернуть"
                        },
                        on: {
                          click: function($event) {
                            _vm.showAllOrder = !_vm.showAllOrder
                          }
                        }
                      },
                      [
                        _vm.showAllOrder
                          ? _c("span", [_vm._v("Скрыть")])
                          : _c("span", [_vm._v("Показать весь список")])
                      ]
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "order-detail__footer noPrint" }, [
            _c("div", { staticClass: "send_form" }, [
              _c("input", {
                staticClass: "email_input",
                attrs: {
                  type: "email",
                  required: "",
                  name: "EMAIL",
                  placeholder: "Введите e-mail ..."
                }
              }),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "send_btn", on: { click: _vm.sendEmailOrder } },
                [
                  _c("span", { staticClass: "btn_text" }, [
                    _vm._v("Отправить заказ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      staticClass: "svg-icon",
                      attrs: { width: "16", height: "14" }
                    },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "/images/sprite.svg#send" }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.send_error
                ? _c("div", { staticClass: "order-send_result error" }, [
                    _vm._m(2)
                  ])
                : _vm.send_success
                ? _c("div", { staticClass: "order-send_result success" }, [
                    _c("span", [
                      _vm._v(
                        "Заказ №" +
                          _vm._s(_vm.order_id) +
                          " успешно отправлен на ваш e-mail"
                      ),
                      _c("br"),
                      _vm._v(_vm._s(_vm.email))
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn_light btn_download",
                attrs: {
                  href:
                    "/data/ajax/client_order.php?action=print&ID=" +
                    _vm.order_id,
                  target: "_blank"
                }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "svg-icon",
                    attrs: { width: "22", height: "20" }
                  },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "/images/sprite.svg#excel" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "btn_text" }, [
                  _vm._v("Скачать заказ в Exсel")
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "order-detail__footer noPrint" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn_back",
                  attrs: { to: "/orders_client/" }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "svg-icon",
                      attrs: { width: "7", height: "13" }
                    },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "/images/sprite.svg#caret-left" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "btn_text" }, [_vm._v("Назад")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn btn_lblue", on: { click: _vm.editOrder } },
                [
                  _c("span", { staticClass: "btn_text" }, [
                    _vm._v("Редактировть заказ")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn btn_lblue", on: { click: _vm.copyOrder } },
                [
                  _c("span", { staticClass: "btn_text" }, [
                    _vm._v("Повторить заказ")
                  ])
                ]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal modal_edit-order ",
          class: { "is-open": _vm.showEditOrder },
          attrs: { id: "modal-edit-order" }
        },
        [
          _c("div", { staticClass: "modal__wrap" }, [
            _c("div", { staticClass: "modal__inner animated zoomIn" }, [
              _c("button", {
                staticClass: "modal__close",
                on: {
                  click: function($event) {
                    _vm.to_hide = true
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "modal_edit-header",
                  class: { loading: _vm.loadingSearch }
                },
                [
                  _c("div", { staticClass: "modal_edit-search" }, [
                    _c("input", {
                      attrs: {
                        type: "text",
                        name: "item_art",
                        id: "modal_edit_input",
                        placeholder: "Добавьте товар в заказ по артикулу..."
                      },
                      on: {
                        input: function($event) {
                          return _vm.searchProiduct()
                        },
                        focus: function($event) {
                          return _vm.searchProiduct()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("svg", { attrs: { width: "17", height: "16" } }, [
                      _c("use", {
                        attrs: { "xlink:href": "/images/sprite.svg#search" }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal_edit-search_result" }, [
                    _c("div", {
                      staticClass: "component-loading",
                      class: { show: _vm.loadingSearch }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "product-grid",
                        class: [_vm.viewMode, { loading: _vm.loadingSearch }],
                        attrs: {
                          "data-page-size": _vm.navPageSize,
                          "data-page": _vm.navPageNum
                        }
                      },
                      _vm._l(_vm.searchItems, function(productItem) {
                        return _c(
                          "component-list-item",
                          _vm._b(
                            {
                              key: "list_component_" + productItem.id,
                              attrs: {
                                can_delete: _vm.can_delete,
                                "data-page": _vm.navPageNum
                              }
                            },
                            "component-list-item",
                            productItem,
                            false
                          )
                        )
                      }),
                      1
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal_edit-container" }, [
                _c("form", { staticClass: "modal_edit-form" }, [
                  _c("div", { staticClass: "modal_edit-order_data" }, [
                    _c("div", { staticClass: "modal_col-4" }, [
                      _c("h4", [_vm._v("Заказ № " + _vm._s(_vm.order_id))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control_text" }, [
                        _c("input", {
                          attrs: {
                            placeholder: "Фамилия, имя",
                            type: "text",
                            name: "FIELDS[NAME]"
                          },
                          domProps: {
                            value: _vm.result.ORDER_PROPS.CONTACT_PERSON.NAME
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control_text" }, [
                        _c("input", {
                          attrs: {
                            placeholder: "E-mail",
                            type: "text",
                            name: "FIELDS[EMAIL]"
                          },
                          domProps: {
                            value: _vm.result.ORDER_PROPS.CONTACT_PERSON.EMAIL
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control_text" }, [
                        _c("input", {
                          attrs: {
                            placeholder: "Контактный телефон",
                            type: "text",
                            name: "FIELDS[PHONE]"
                          },
                          domProps: {
                            value: _vm.result.ORDER_PROPS.CONTACT_PERSON.PHONE
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal_col-8" }, [
                      _c("label", { staticClass: "b-content__subtitle" }, [
                        _vm._v("Комментарий к заказу")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control_text" }, [
                        _c("textarea", {
                          attrs: { name: "FIELDS[COMMENT]" },
                          domProps: { value: _vm.result.COMMENT }
                        })
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "b-content_cart" }, [
                  _c("div", { staticClass: "resp-table-wrap" }, [
                    _c("table", { staticClass: "product-table" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _vm.loaded
                        ? _c(
                            "tbody",
                            _vm._l(_vm.items, function(productItem) {
                              return _c("tr", {}, [
                                _c("td", { attrs: { colspan: "5" } }, [
                                  _c("div", { staticClass: "t-prod-meta" }, [
                                    _c("span", [_vm._v("Товар")]),
                                    _vm._v(" "),
                                    _c("b", {
                                      domProps: {
                                        innerHTML: _vm._s(productItem.name)
                                      }
                                    }),
                                    _vm._v(" "),
                                    productItem.arhive
                                      ? _c("span", [
                                          _vm._v("снят с производства")
                                        ])
                                      : !productItem.can_buy
                                      ? _c("span", [
                                          _vm._v(_vm._s(productItem.stock_date))
                                        ])
                                      : productItem.deleted
                                      ? _c("span", [_vm._v("товар был удален")])
                                      : _vm._e()
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(productItem.num))]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("img", {
                                    staticClass: "t-prod-pic",
                                    attrs: { src: productItem.image, alt: "" }
                                  })
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    productItem.link
                                      ? _c(
                                          "router-link",
                                          {
                                            staticClass: "t-prod-art",
                                            attrs: { to: productItem.link }
                                          },
                                          [_vm._v(_vm._s(productItem.article))]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                productItem.can_buy
                                  ? _c("td", [
                                      _c("div", {
                                        staticClass: "t-prod-name",
                                        domProps: {
                                          innerHTML: _vm._s(productItem.name)
                                        }
                                      })
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                productItem.can_buy
                                  ? _c("td", [
                                      _c(
                                        "div",
                                        { staticClass: "counter js-counter" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn decrease",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.counterMinus(
                                                    productItem
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("-")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            staticClass: "count",
                                            attrs: {
                                              type: "text",
                                              name: "quantity",
                                              "data-id": productItem.id,
                                              "data-ratio": productItem.ratio,
                                              "data-price":
                                                productItem.ratio_price
                                                  .discount,
                                              autocomplete: "off"
                                            },
                                            domProps: {
                                              value:
                                                productItem.arBasketElement
                                                  .QUANTITY
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.counterInput(
                                                  productItem
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn increase",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.counterPlus(
                                                    productItem
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("+")]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                productItem.can_buy && !productItem.deleted
                                  ? _c("td", [
                                      _c("b", {
                                        staticClass: "product-total-price",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            productItem.arBasketElement
                                              .SUMM_FORMATTED
                                          )
                                        }
                                      })
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("td", [
                                  _c("button", {
                                    staticClass: "t-prod-remove js-cart-remove",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteProduct(productItem.id)
                                      }
                                    }
                                  })
                                ])
                              ])
                            }),
                            0
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal_edit-footer" }, [
                _c("div", {
                  staticClass: "total-amount",
                  domProps: { innerHTML: _vm._s(_vm.title_text) }
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn",
                    attrs: { href: "javascript:;" },
                    on: { click: _vm.saveOrder }
                  },
                  [_vm._v("Сохранить изменения")]
                )
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("№ заказа")]),
        _vm._v(" "),
        _c("th", [_vm._v("Дата заказа")]),
        _vm._v(" "),
        _c("th", [_vm._v("Сумма заказа")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "130" } }, [_vm._v("Статус заказа")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("№")]),
      _vm._v(" "),
      _c("th", [_vm._v("Фото")]),
      _vm._v(" "),
      _c("th", [_vm._v("Арт.")]),
      _vm._v(" "),
      _c("th", [_vm._v("Наименование")]),
      _vm._v(" "),
      _c("th", [_vm._v("Кол-во")]),
      _vm._v(" "),
      _c("th", [_vm._v("Цена за ед.")]),
      _vm._v(" "),
      _c("th", [_vm._v("Стоимость")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Упс, не получилось доставить."),
      _c("br"),
      _vm._v("Проверьте корректность введенных данных")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { width: "28" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "40" } }, [_vm._v("№")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "40" } }, [_vm._v("Фото")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "80" } }, [
          _c(
            "button",
            { staticClass: "sort", attrs: { "data-field": "art" } },
            [_vm._v("Арт.")]
          )
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "left" }, [_vm._v("Наименование")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "100" } }, [_vm._v("Количество")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "120" } }, [_vm._v("Стоимость")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "30" } }, [_vm._v(" ")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }