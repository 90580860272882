<template>
	<section class="message-one">
		<div class="message-one__header">
			<div v-on:click="$router.go(-1)" class="btn">
				<svg class="svg-icon" width="7" height="13">
					<use xlink:href="/images/sprite.svg#caret-left"></use>
				</svg>
				<span class="btn_text">Назад</span>
			</div>
			<a href="#" v-on:click.prevent="downloadZip" class="btn btn_transp">
				<span class="btn_text">Скачать вложения</span>
				<svg class="svg-icon" width="13" height="13">
					<use xlink:href="/images/sprite.svg#download"></use>
				</svg>
			</a>
		</div>
		<div class="message-box">
			<div v-bind:class="{ show: loading }" class="component-loading"></div>
			<div v-if="loaded" class="message-box__header">
				<div v-for="tag in message.tags" v-if="message.tags.length" class="message-box__tag">{{tag}}</div>
				<div class="message-box__title">Тема: <span v-html="message.title"></span></div>
				<div class="message-box__datetime" v-html="message.date_format"></div>
			</div>
			<div v-if="loaded" class="message-box__attach">
				<template v-if="message.files">
					<div v-for="file in message.files" class="file">
						<div class="file__name">
							<svg  width="13" height="17">
								<use xlink:href="/images/sprite.svg#file"></use>
							</svg>
							<span>{{file.ORIGINAL_NAME}}</span>
						</div>
						<a v-bind:href="file.SRC" target="_blank" download class="btn file__download">
							<svg class="svg-icon" width="13" height="13">
								<use xlink:href="/images/sprite.svg#download"></use>
							</svg>
						</a>
					</div>
				</template>
			</div>
			<div v-if="loaded" v-html="message.text" class="message-box__body">
				
			</div>
			<template v-if="loaded">
				<div v-if="message.element" class="message-box__meta">
					От: {{message.element.from_email}}<br>
					Дата: {{message.date_format}}<br>
					Тема: {{message.title}}
				</div>
			</template>
		</div>
		<div class="message-one__footer">
			<div v-on:click="$router.go(-1)" class="btn btn_lblue">
				<svg class="svg-icon" width="7" height="13">
					<use xlink:href="/images/sprite.svg#caret-left"></use>
				</svg>
				<span class="btn_text">Назад</span>
			</div>
		</div>

	</section>
</template>

<script>
export default {
	name: 'MessageDetailComponent',
	data() {
		var self = this;
		return {
			loading: true,
			loaded: false,
			message: [],
			message_id: parseInt(self.$route.params.message_id),
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
				{ link: '/messages/', name: 'Сообщения' },
			]
		};
	},
	computed: {
		
	},
	watch: {
		
	},
	components: {
		
	},
	methods:{
		downloadZip: function(){
			var self = this;
			window.location.href = "/data/ajax/?mess_zip=" + self.message_id;
			/*$.getJSON("/data/ajax/", {mess_zip: self.message_id}, function(json){
			
			})*/
		},
	},
	mounted: function(){
		var self = this;
		
		var urlData = {message_id: self.message_id};
		$.getJSON("/data/components/message.php", urlData, function(json){
			if(json.status) {
				self.message = json.message;
				self.$root.arUser.messages = json.messages;
				self.$root.arUser.notifications = json.notifications;
				
				self.breadcrumbs.push({ link: '/message/' + self.message.id + "/", name: self.message.title });
				self.$root.breadcrumbs = self.breadcrumbs;
				
				self.$nextTick(function(){
					self.loaded = true;
				});
			}
			self.loading = false;
		});
	},
};
</script>

<style scoped>
.message-one{width: 100%;}
.message-box{position:relative;}
</style>
