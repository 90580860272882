var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { position: "relative" } }, [
    _c("div", {
      staticClass: "component-loading",
      class: { show: _vm.loading }
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "product-slider owl-carousel",
        class: { inited: _vm.inited, loading: _vm.loading },
        attrs: { id: _vm.slider_id }
      },
      _vm._l(_vm.items, function(item) {
        return _c(
          "div",
          { staticClass: "product-slider__item" },
          [
            _c(
              "router-link",
              { staticClass: "product", attrs: { to: item.link } },
              [
                _c("div", { staticClass: "product__header" }, [
                  _c(
                    "div",
                    { staticClass: "product__labels" },
                    _vm._l(item.labels, function(label) {
                      return label.class == _vm.labelClass
                        ? _c(
                            "span",
                            { staticClass: "label", class: label.class },
                            [_vm._v(_vm._s(label.text))]
                          )
                        : _vm._e()
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "product__img" }, [
                  _c("img", { attrs: { src: item.image, alt: "" } })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "product__art" }, [
                  _vm._v("Арт. " + _vm._s(item.article))
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "product__name",
                  domProps: { innerHTML: _vm._s(item.name) }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "product__price" }, [
                  item.ratio_price.print_old
                    ? _c("span", {
                        staticClass: "old-price",
                        domProps: {
                          innerHTML: _vm._s(item.ratio_price.print_old)
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "price",
                    domProps: { innerHTML: _vm._s(item.ratio_price.print) }
                  })
                ])
              ]
            )
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }