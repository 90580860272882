<template>
	
	<section class="b-content b-content_ordering">
	
	
		<div v-if="noProfileError" class="empty-result-container">
			<div class="empty-result-content">
				<div class="empty-result-text">У Вас нет активных Юр. лиц. <br>Обратитесь к Вашему менеджеру.</div>
			</div>
		</div>
		<template v-else>
			<div class="b-content__header">
				<h2 class="b-content__title"><small>Корзина</small><span>Оформление заказа</span></h2>
			</div>
			<div class="b-content__body">
				<div v-bind:class="{ show: loading }" class="component-loading"></div>
				<div class="order-form">
					<div class="row">
						<div class="col">
							<div class="control_group lbl_top person_type">
								<label>Тип плательщика</label>
								<div class="control_select">
									<select class="select js-owner-select order-props" name="PERSON_TYPE">
										<option v-for="type in result.PERSON_TYPE" v-bind:selected="type.CHECKED == 'Y'" v-bind:value="type.ID" v-html="type.NAME"></option>
									</select>
								</div>
							</div>
							<div class="control_group lbl_top">
								<label>Юридическое лицо</label>
								<div class="control_select">
								
									<div class="environ__select">
										<select class="select-profile select-profile-home select order-props" name="PROFILE_ID">
											<option 
												v-for="profile in $root.arUser.profiles.list" 
												v-bind:value="profile.id" 
												v-bind:class="{'active': profile.active}" 
												v-bind:selected="profile.active"  
												v-html="profile.props.ORDER_PROP_75.value"></option>
										</select>
									</div>
								
									<div class="environ__group hidden">
										<div class="environ__select">
											<select class="select-store select-store-home select order-props" name="STORE_ID">
												<option 
													v-for="store in activeProfile.stores.list" 
													v-bind:value="store.ID" 
													v-bind:class="{'active': store.selected}" 
													v-bind:selected="store.selected"  
													v-html="store.TITLE"></option>
											</select>
										</div>
									</div>
									
								</div>
							</div>
							<div class="control_group lbl_top">
								<label>Способ оплаты</label>
								<div v-for="payment in result.PAY_SYSTEM" class="control_radio control_xtext">
									<label>
										<input name="PAY_SYSTEM_ID" type="radio" class="order-props" v-bind:value="payment.ID" v-bind:checked="payment.CHECKED == 'Y'">
										<i></i><span v-html="payment.NAME"></span>
									</label>
								</div>
								<div v-if="$root.arUser.loyalty && balls > 0" class="control_checkbox">
									<label>
										<input type="checkbox" value="Y" name="ORDER_PROP_84" @change="payBalls=!payBalls" class="js-spend-bonus order-props" data-target="#bonus-tip">
										<i></i><span>Списать Feron-бонусы</span>
									</label>
								</div>
								<small v-if="payBalls" class="tip" id="bonus-tip">* бонусами можно оплатить до 10% заказа. В счете бонус будет отражен как скидка на товар. Счет со скидкой станет доступен для скачивания после подтверждения заказа менеджером</small>
							</div>
							<div class="control_group lbl_top">
								<label>Контактное лицо:</label>
								
								<div v-for="property in result.ORDER_PROP.properties" v-if="showContactPersonProperty(property)" v-bind:class="{'required': property.REQUIRED == 'Y'}" class="control_text">
									<input 
										type="text" 
										class="order-props" 
										v-bind:class="{'is-email': property.IS_EMAIL == 'Y', 'is-phone': property.IS_PHONE == 'Y'}" 
										v-on:input="checkInput" 
										v-bind:name="'ORDER_PROP_' + property.ID" 
										v-bind:value="property.VALUE[0]" 
										v-bind:required="property.REQUIRED == 'Y'" 
										v-bind:placeholder="property.NAME">
								</div>
								<small class="tip">* Поля, обязательные для заполнения</small>
							</div>
						</div>
						<div class="col">
							<div class="control_group lbl_top">
								<label>Реквизиты:</label>
								<div class="control_xtextarea">
									<div>
										<template v-for="property in result.ORDER_PROP.properties" v-if="showCompanyProperty(property)">
											{{ property.NAME }}: <span v-html="property.VALUE[0]"></span><br>
										</template>
									</div>
								</div>
								<div class="control_xtextarea">
									<div>
										<template v-for="property in result.ORDER_PROP.properties" v-if="showBankProperty(property)">
											{{ property.NAME }}: <span v-html="property.VALUE[0]"></span><br>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<div class="control_group lbl_top">
								<label>Ваш город</label>
								<div class="control_city-select" id="city-select-btn">
									<button class="js-open-modal" v-on:click.prevent="selectCityModalOpen = true">
										<svg width="11" height="15">
											<use xlink:href="/images/sprite.svg#balloon"></use>
										</svg>
										<span>{{locationProp.LOCATION_NAME}}</span>
									</button>
									<input 
										type="hidden" 
										class="order-props" 
										v-on:change="submitOrder()" 
										v-bind:name="'ORDER_PROP_' + locationProp.ID" 
										v-bind:value="locationProp.VALUE">
								</div>
							</div>

							<div class="control_group lbl_top js-delivery-specify">
								<label>Способ доставки</label>
								
								<div v-if="result.WARNING.DELIVERY" v-for="mess in result.WARNING.DELIVERY" class="notice notice_warning animated fadeIn">
									<div class="notice__body">
										<div class="notice__text" v-html="mess"></div>
									</div>
								</div>
								
								<div v-for="delivery in result.DELIVERY" class="control_radio">
									<label class="delivery-item" v-bind:class="{'width-price': delivery.PRICE > 0}">
										<input 
											name="DELIVERY_ID" 
											class="order-props" 
											type="radio" 
											v-on:change="submitOrder()" 
											v-bind:value="delivery.ID" 
											v-bind:checked="delivery.CHECKED == 'Y'"
										>
										<i></i><span v-html="delivery.NAME" class="delivery-name"></span><abbr v-if="delivery.PRICE > 0" class="delivery-price" v-html="delivery.PRICE_FORMATED"></abbr>
									</label>
								</div>
							</div>
							<div class="control_group lbl_top">
								<label>Комментарий к заказу</label>
								<div class="control_textarea">
									<textarea v-if="result.ORDER_DESCRIPTION != false" cols="30" rows="2" class="order-props" name="ORDER_DESCRIPTION" v-html="result.ORDER_DESCRIPTION"></textarea>
									<textarea v-else cols="30" rows="2" class="order-props" name="ORDER_DESCRIPTION" ></textarea>
								</div>
							</div>
						</div>
						<div class="col">
							<div class="control_group control_address lbl_top js-address-specify" lang="ru">
								
								<div v-if="!isPickup" class="control_search" id="address_search" lang="ru">
									<label for="address_search_select">Адрес:</label>
									<label for="address_search_select" class="address_search_label">
										<select id="address_search_select" tabindex="-1" aria-hidden="true" class="js-data-ajax order-props" v-bind:name="'ORDER_PROP_' + addressProp.ID">
											<option v-bind:value="addressProp.VALUE" v-bind:html="addressProp.VALUE" selected="selected"></option>
										</select>
										<button type="button">
											<svg width="17" height="16">
												<use xlink:href="/images/sprite.svg#search"></use>
											</svg>
										</button>
									</label>
								</div>
							
								<div v-if="result.ERROR.PROPERTY" v-for="mess in result.ERROR.PROPERTY" class="notice notice_warning animated fadeIn">
									<div class="notice__body">
										<div class="notice__text" v-html="mess"></div>
									</div>
								</div>
								<div v-if="isPickup" class="control_text">
									<input type="text" v-bind:name="'ORDER_PROP_' + addressProp.ID" class="order-props" v-bind:value="addressProp.VALUE" required readonly placeholder="Выберите пункт самовывоза">
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<router-link :to="'/cart/'" class="btn btn_lblue">
								<svg class="svg-icon" width="7" height="13">
									<use xlink:href="/images/sprite.svg#caret-left"></use>
								</svg>
								<span class="btn_text">Назад</span>
							</router-link>
						</div>
						<div class="col">
							<div class="consent-div">
								<label v-bind:class="{'has-error': !consent.checked}" class="main-user-consent-request">
									<input type="checkbox" value="Y" v-bind:checked="consent.checked" name="consent">
									<a href="javascript:;" v-html="consent.LABEL" v-on:click.prevent="consentModalOpen = true" 
										class="main-user-consent-request-announce-link"></a>
								</label>
							</div>
							<button class="btn" v-bind:class="{'disabled': !consent.checked}" v-on:click.prevent="submitOrder('save')">Сформировать счёт</button>
						</div>
					</div>
				</div>
			</div>
		</template>
		
		<div class="modal modal_city-select" id="modal-city-select" v-bind:class="{'is-open': selectCityModalOpen}">
			<div class="modal__wrap">
				<div class="modal__inner animated zoomIn">
					<button class="modal__close" v-on:click="to_hide = true"></button>
					<div class="modal__header">
						<div class="control_search">
							<input 
								v-on:input="searchLocation" 
								type="text" 
								name="city" 
								autocomplete="off" 
								placeholder="Введите название города доставки ... " />
							<button type="button">
								<svg width="17" height="16">
									<use xlink:href="/images/sprite.svg#search"></use>
								</svg>
							</button>
						</div>
					</div>
					<div class="modal__container" style="position: relative;">
						<div v-bind:class="{ show: loading }" class="component-loading"></div>
						<ul class="city-list nice-v-scroll " >
							<li 
								class="city" 
								v-for="location in popup_locations" 
								v-bind:data-id="location.ID" 
								v-bind:data-code="location.CODE"
								v-on:click="setLocation(location)">
								<div class="city__name">{{location.NAME}}</div>
								<div class="city__region">{{location.PARENT}}</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		
		<div class="modal modal_consent" id="modal-consent" v-bind:class="{'is-open': consentModalOpen}">
			<div class="modal__wrap">
				<div class="modal__inner bonus-program animated zoomIn">
					<button class="modal__close" v-on:click="to_hide = true"></button>
					<div class="modal__header bonus-program__header">
						<h4>Согласие на обработку персональных данных</h4>
					</div>
					<div class="modal__container bonus-program__body " style="position: relative;" v-html="consent.CONFIG.text"></div>
					
					<div class="bonus-program__header" style="justify-content: flex-end;">
						<button type="button" class="btn btn_transp" v-on:click.prevent="consentModalOpen = false; consent.checked = false">Отклонить</button>
						<button type="button" class="btn" v-on:click.prevent="consentModalOpen = false; consent.checked = true">Принять</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import MaskedInput from 'vue-masked-input';
import YmapPlugin from 'vue-yandex-maps';

export default {
	name: 'OrderComponent',
	data() {
		var self = this;
		return {
			loading: true,
			to_hide: false,
			payBalls: false,
			noProfileError: false,
			consentModalOpen: false,
			selectCityModalOpen: false,
			isPickup: false,
			timer: null,
			contactPersonProps: ["12", "13", "14"],
			companyProps: ["10", "11", "21", "9"],
			bankProps: ["25", "26", "27", "28"],
			orderData: {},
			locationProp: {LOCATION_NAME: ""},
			popup_locations: {},
			def_locations: {},
			curDelivery: {},
			curPaySystem: {},
			addressProp: {VALUE: "", ID: "17"},
			mapInited: false,
			profile_change: false,
			qty_products: parseInt(self.$root.arUser.basket.qty_products),
			balls: parseInt(self.$root.arUser.balls),
			consent: {checked: true, CONFIG: {}},
			mapObj: {},
			mapData: {
				coords: [54.8289665, 39.8318938],
				zoom: 12,
				markers: [],
				controls: [
					'fullscreenControl', 
					'zoomControl', 
					'geolocationControl'
				],
			},
			mapSettings: {
				apiKey: '74f5a292-e4d5-4dd3-8a0a-67de76f347fa',
				lang: 'ru_RU',
				coordorder: 'latlong',
				version: '2.1',
				options: {
					autoFitToViewport: true,
					maxZoom: 14,
				},
			},
			showAllMarkers: false,
			changeDelivery: false,
			profiles: self.$root.arUser.profiles.list,
			result: {
				ORDER_PROP: {},
				ERROR: {},
				WARNING: {}
			},
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
				{ link: '/cart/', name: 'Корзина' },
				{ link: '/order/', name: 'Оформление заказа' },
			],
		};
	},
	components: {
		'masked-input': MaskedInput,
		'yandex-map': YmapPlugin,
	},
	computed: {
		activeProfile() {
			var self = this;
			return self.$root.arUser.profiles.active;
		},
	},
	watch: {
		selectCityModalOpen(show) {
			if(show) {
				$("body").addClass('no-scroll');
				$("#modal-overlay").fadeIn();
			}else{
				$("body").removeClass('no-scroll');
				$("#modal-overlay").fadeOut();
			}
		},
		consentModalOpen(show) {
			if(show) {
				$("body").addClass('no-scroll');
				$("#modal-overlay").fadeIn();
			}else{
				$("body").removeClass('no-scroll');
				$("#modal-overlay").fadeOut();
			}
		},
		locationProp: function(newLoation){
			
		},
		to_hide:function(to_hide){
			var self = this;
			if(to_hide){
				$("#modal-city-select .modal__inner").addClass("zoomOut");
				$("#modal-consent .modal__inner").addClass("zoomOut");
				setTimeout(function(){
					self.selectCityModalOpen = false;
					self.consentModalOpen = false;
					self.to_hide = false;
					self.$root.popupQuickViewData.ELEMENT_ID = false;
					$("#modal-city-select .modal__inner").removeClass("zoomOut");
					$("#modal-consent .modal__inner").removeClass("zoomOut");
				}, 300);
			}
		},
		mapObj: function(mapObj){
			var self = this;
			self.mapInited = true;
		},
	},
	methods:{
		loadOrderData: function(){
			var self = this;
			var urlData = {};
			
			self.loading = false;
		},
		searchLocation: function(e){
			var self = this;
			var input = e.target;
			
			if(!!self.timer) clearTimeout(self.timer);
			
			self.timer = setTimeout(function(){
				if(input.value.length > 3){
					self.loading = true;
					$.getJSON(self.$root.ajaxUrl + "locations.php", {query: input.value}, function(json){
						self.loading = false;
						self.popup_locations = json;
					})
				}
			}, 500);
		},
	},
	mounted: function(){
		$.fn.select2.defaults.set("language", "es");
		var self = this;
		
		if(self.qty_products <= 0) self.$router.push({ path: '/cart/' });
		
		self.$root.breadcrumbs = self.breadcrumbs;
		
		setTimeout(function(){
			self.loadOrderData();
			self.$nextTick(function(){
				
			})
		}, 1000);
		
	},
};
</script>

<style>
.bonus-program__header .btn{margin-left:10px;}
.modal__inner.bonus-program{
	padding:0;
}
.consent-div label{
	display:flex;
}
.main-user-consent-request-announce-link{
	font-size: 12px;
	line-height: 12px;
	display: block;
	text-align: left;
	padding-left: 10px;
	padding-bottom: 20px;
}
.b-content__body{position:relative;}
.person_type{display:none;}
.delivery-item{position:relative;}
.b-content_ordering .order-form .control_radio .delivery-item.width-price .delivery-name{
    padding-right: 62px;
}
.delivery-item .delivery-price{
    position: absolute;
    right: 30px;
    z-index: 10;
    top: 9px;
    font-size: 12px;
}
.control_text{
	transition: all 0.3s;
}
.control_text.has-error:after{
	color:red;
}
.main-user-consent-request.has-error input,
.control_text.has-error input{
	color:red;
	border-color:red !important;
}
.main-user-consent-request.has-error a,
.control_text.has-error a{
	color:red;
}
.control_address .select2-selection{
	padding:0;
	height: 34px;
}
.control_address .select2-selection__arrow{
	display:none;
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
	line-height: 34px;
}
#address_search .select2-container{
	width:100%;
	top: 100% !important;
}
#address_search .select2-container .select2-dropdown{
	opacity:0;
	transition: all 0.5s;
}
#address_search .select2-container .select2-dropdown.show{
	opacity:1;
	box-shadow: 0 0 20px rgb(0 0 0 / 9%);
}
#address_search .select2-dropdown{
    margin-top: -38px;
    width: calc(100% + 8px) !important;
    margin-left: -4px;
    border: 1px solid #E5E5E5;
	border-radius: 4px;
}

#address_search .select2-results__option .region{
	clear: both;
    display: block;	
	line-height: 12px;
	color: #86adff;
}
#address_search .select2-results__option .address{
	clear: both;
    display: block;
	line-height: 12px;
}
#address_search .select2-container--default .select2-results > .select2-results__options {
    max-height: 300px;
}
#address_search .select2-results__option {
	transition: all 0.5s;
	cursor:pointer;
}
#address_search .select2-results__option.select2-results__option--highlighted {
	background: #f6f8fb;
}
.b-content_ordering .order-form .control_group.lbl_top label.address_search_label{
	position: relative;
	font-family: 'Museo Sans Cyrl 300', sans-serif;
}

#address_search .select2-results__options::-webkit-scrollbar {
	width: 2px;
}
#address_search .body::-webkit-scrollbar-track {
	width: 2px;
}
#address_search .body::-webkit-scrollbar-thumb {
	width: 2px;
	background-color: #86adff;
}
.store_baloon .service{
	font-family: 'Museo Sans Cyrl 700', sans-serif;
    font-size: 14px;
    font-weight: 500;
	margin: 0;
}
.store_baloon .title{
    font-family: 'Museo Sans Cyrl 300', sans-serif;
    color: #52504C;
    font-size: 12px;
    line-height: 20px;
}
.store_baloon .btn{
    margin-top: 5px;
}


.order-form .environ__select{width:100%; max-width: 100%;}
</style>
