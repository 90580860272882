var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "b-content b-content_ordering" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "b-content__body" }, [
      _c("div", {
        staticClass: "component-loading",
        class: { show: _vm.loading }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "order-result" }, [
        _vm.send_error
          ? _c(
              "div",
              { staticClass: "order-result__header order-send_result" },
              [
                _c("div", { staticClass: "send_result-icon" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "svg-icon",
                      attrs: { width: "97", height: "74" }
                    },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "/images/sprite.svg#send_err" }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm._m(1)
              ]
            )
          : _vm.send_success
          ? _c(
              "div",
              { staticClass: "order-result__header order-send_result" },
              [
                _c("div", { staticClass: "send_result-icon" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "svg-icon",
                      attrs: { width: "97", height: "74" }
                    },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "/images/sprite.svg#send_ok" }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("Заказ №12 успешно отправлен на ваш e-mail"),
                  _c("br"),
                  _vm._v(_vm._s(_vm.email))
                ])
              ]
            )
          : _c("div", { staticClass: "order-result__header" }, [
              _c("h2", [
                _vm._v("Ваш заказ "),
                _c("a", { attrs: { href: "" } }, [
                  _vm._v("№ " + _vm._s(_vm.order_id))
                ]),
                _vm._v(" успешно сформирован!")
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v("Скачайте заказ в Excel или оправьте на e-mail.")
              ])
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "order-result__body" }, [
          _c("div", { staticClass: "send_form" }, [
            _c("input", {
              staticClass: "email_input",
              attrs: {
                type: "email",
                required: "",
                name: "EMAIL",
                placeholder: "Введите e-mail ..."
              }
            }),
            _vm._v(" "),
            _c(
              "a",
              { staticClass: "send_btn", on: { click: _vm.sendEmailOrder } },
              [
                _c("span", { staticClass: "btn_text" }, [
                  _vm._v("Отправить заказ")
                ]),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "svg-icon",
                    attrs: { width: "16", height: "14" }
                  },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "/images/sprite.svg#send" }
                    })
                  ]
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "order-result__footer" },
          [
            _c(
              "a",
              {
                staticClass: "btn btn_light btn_download",
                attrs: {
                  href:
                    "/data/ajax/client_order.php?action=print&ID=" +
                    _vm.order_id,
                  target: "_blank"
                }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "svg-icon",
                    attrs: { width: "22", height: "20" }
                  },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "/images/sprite.svg#excel" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "btn_text" }, [
                  _vm._v("Скачать заказ в Exсel")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "btn",
                attrs: { to: "/orders_client/order/" + _vm.order_id + "/" }
              },
              [_vm._v("Перейти к заказу")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b-content__header" }, [
      _c("h2", { staticClass: "b-content__title" }, [
        _c("small", [_vm._v("Корзина")]),
        _c("span", [_vm._v("Оформление заказа")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Упс, не получилось доставить."),
      _c("br"),
      _vm._v("Проверьте корректность введенных данных")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }