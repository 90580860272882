var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar" }, [
    _c(
      "nav",
      {
        staticClass: "menu",
        class: [_vm.isCatalog ? "" : "transitioning", _vm.menuClass]
      },
      [
        _c("div", {
          staticClass: "component-loading",
          class: { show: _vm.loading }
        }),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "sb-user", attrs: { to: "/personal/" } },
          [
            _c("div", { staticClass: "sb-user__pic" }, [
              _c("img", { attrs: { src: _vm.$root.arUser.avatar, alt: "" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "sb-user__info" }, [
              _c("div", [
                _vm._v("ID: "),
                _c("b", [_vm._v(_vm._s(_vm.$root.arUser.fields.XML_ID))])
              ]),
              _vm._v(" "),
              _vm.$root.arUser.profiles.count != 0
                ? _c("div", {
                    staticClass: "profile-popup",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$root.arUser.profiles.active.props.ORDER_PROP_75
                          .value
                      )
                    }
                  })
                : _c("div", { staticClass: "profile-popup" }, [
                    _vm._v(_vm._s(_vm.$root.arUser.fields.LOGIN))
                  ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "sb-menu" },
          [
            _vm._l(_vm.menu.menu, function(item) {
              return [
                _c("li", { staticClass: "separ" }),
                _vm._v(" "),
                _c(
                  "li",
                  { key: item.key },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: item.link,
                          "data-sort": item.sort,
                          "exact-active-class": "is-active"
                        }
                      },
                      [
                        _c("div", { staticClass: "sb-menu__icon" }, [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(item.icon) }
                          }),
                          _vm._v(" "),
                          _vm.badges[item.badge_counter]
                            ? _c(
                                "span",
                                { staticClass: "badge", class: item.badge },
                                [_vm._v(_vm._s(_vm.badges[item.badge_counter]))]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "sb-menu__text" }, [
                          _vm._v(_vm._s(item.text))
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "btn sb-exit", attrs: { to: "/login/?logout=yes" } },
          [
            _c(
              "svg",
              { staticClass: "svg-icon", attrs: { width: "14", height: "14" } },
              [
                _c("use", {
                  attrs: { "xlink:href": "/images/sprite.svg#sb-exit" }
                })
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "btn_text" }, [_vm._v("Выйти")])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "scroll-wrapper" }, [
      _c(
        "ul",
        {
          staticClass: "sb-tree",
          class: [_vm.isCatalog ? "" : "transitioning", _vm.menuClass]
        },
        _vm._l(_vm.menu.catalog, function(catalog_item) {
          return _c(
            "li",
            {
              staticClass: "menu_item",
              class: {
                "is-active showed": _vm.isActiveSection(catalog_item),
                "is-open": _vm.isOpenSection(catalog_item)
              },
              attrs: {
                "data-level": catalog_item.level,
                "data-id": catalog_item.key
              }
            },
            [
              _c(
                "div",
                { staticClass: "sb-tree__unit" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: catalog_item.link,
                        "exact-active-class": "is-active-route"
                      }
                    },
                    [_vm._v(_vm._s(catalog_item.text))]
                  ),
                  _vm._v(" "),
                  catalog_item.is_parent
                    ? _c("button", {
                        staticClass: "caret",
                        attrs: { "data-id": catalog_item.key },
                        on: { click: _vm.showSubmenu }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              catalog_item.is_parent
                ? _c(
                    "ul",
                    {
                      staticClass: "submenu_item",
                      attrs: { "data-id": catalog_item.key }
                    },
                    _vm._l(catalog_item.subs, function(sub_item) {
                      return _c(
                        "li",
                        {
                          staticClass: "menu_item animated ",
                          class: {
                            "is-active showed": _vm.isActiveSection(sub_item),
                            "is-open": _vm.isOpenSection(sub_item),
                            showed: _vm.isOpenSection(catalog_item)
                          },
                          attrs: {
                            "data-level": sub_item.level,
                            "data-id": sub_item.key
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "sb-tree__unit" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: sub_item.link,
                                    "exact-active-class": "is-active-route"
                                  },
                                  domProps: { innerHTML: _vm._s(sub_item.text) }
                                },
                                [_vm._v(_vm._s(sub_item.text))]
                              ),
                              _vm._v(" "),
                              sub_item.is_parent
                                ? _c("button", {
                                    staticClass: "caret",
                                    attrs: { "data-id": sub_item.key },
                                    on: { click: _vm.showSubmenu }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          sub_item.is_parent
                            ? _c(
                                "ul",
                                {
                                  staticClass: "submenu_item",
                                  attrs: { "data-id": sub_item.key }
                                },
                                _vm._l(sub_item.subs, function(sub_sub_item) {
                                  return _c(
                                    "li",
                                    {
                                      staticClass: "menu_item animated",
                                      class: {
                                        "is-active showed": _vm.isActiveSection(
                                          sub_sub_item
                                        ),
                                        showed: _vm.isOpenSection(sub_item)
                                      },
                                      attrs: {
                                        "data-sort": sub_sub_item.sort,
                                        "data-level": sub_sub_item.level,
                                        "data-id": sub_sub_item.key
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "sb-tree__unit" },
                                        [
                                          _c("router-link", {
                                            attrs: {
                                              to: sub_sub_item.link,
                                              "exact-active-class":
                                                "is-active-route"
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                sub_sub_item.text
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }