<template>
    <div class="card settings-graph-sidebar__wrapper">
        <div class="card__header row-header">
            <div :class="{ show: loading }" class="component-loading"></div>
            <div class="settings-graph-button" @click="hideSettingsGraph">
                <img :src="settingsIconActive" alt="Скрыть настройки"/>
            </div>
            <h6 class="settings-graph-header__title">Настройки</h6>
        </div>
        <div class="card__body">
            <div class="settings-graph__label">
                <span>Единицы значений</span>
            </div>
            <div class="settings-graph__button-list">
                <a
                    class="btn custom ruble"
                    :class="{'active': activeUnitButton === 'ruble'}"
                    @click="setActiveUnitButton('ruble')"
                >
                    Рубли
                </a>
                <a
                    class="btn custom unit"
                    :class="{'active': activeUnitButton === 'unit'}"
                    @click="setActiveUnitButton('unit')"
                >
                    Штуки
                </a>
            </div>

            <div class="settings-graph__label mt-1em">
                Тип периода
            </div>
            <div class="settings-graph__button-list">
                <a
                    class="btn custom month"
                    :class="{'active': activePeriodType === 'last-six-month'}"
                    @click="setPeriodButton('last-six-month')"
                >
                   Последние 6 мес.
                </a>
                <a
                    class="btn custom quarter"
                    :class="{'active': activePeriodType === 'quarter'}"
                    @click="setPeriodButton('quarter')"
                >
                    Квартал
                </a>
                <a
                    class="btn custom half-of-year"
                    :class="{'active': activePeriodType === 'half-of-year'}"
                    @click="setPeriodButton('half-of-year')"
                >
                    Полугодие
                </a>
                <a
                    class="btn custom year"
                    :class="{'active': activePeriodType === 'year'}"
                    @click="setPeriodButton('year')"
                >
                    Год
                </a>
            </div>
            <div v-if="activePeriodType === 'quarter'" class="settings-graph__label mt-1em">
                Квартал
            </div>
            <div
                class="settings-graph__button-list"
                v-if="activePeriodType === 'quarter'">
                <a
                    v-for="quarterNum in [1,2,3,4]"
                    :key="quarterNum"
                    class="btn custom month"
                    :class="{'active': activePeriodNumber === quarterNum + 'й'}"
                    @click="setPeriodNumberButton(quarterNum + 'й')"
                >
                    {{ quarterNum + 'й' }}
                </a>
            </div>
            <div v-if="activePeriodType === 'half-of-year'" class="settings-graph__label mt-1em">
                Полугодие
            </div>
            <div
                class="settings-graph__button-list"
                v-if="activePeriodType === 'half-of-year'">
                <a
                    v-for="quarterNum in [1,2]"
                    :key="quarterNum"
                    class="btn custom month"
                    :class="{'active': activePeriodNumber === quarterNum + 'е'}"
                    @click="setPeriodNumberButton(quarterNum + 'е')"
                >
                    {{ quarterNum + 'е' }}
                </a>
            </div>
        </div>
        <div class="card__footer">
            <div class="settings-graph__button-list--commands">
                <a class="btn custom cancel-btn">Очистить</a>
                <a class="btn custom active apply-btn">Применить</a>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentUtils from "../mixins/ComponentUtils";

export default {
    name: "SettingsGraphSideBar",
    mixins: [ComponentUtils],
    data() {
        return {
            loading: true,
            settingsIcon: require("./../assets/images/settings-button.svg"),
            settingsIconActive: require("./../assets/images/settings-button-active.svg"),
            activeUnitButton: 'ruble',
            activePeriodType: 'last-six-month',
            activePeriodNumber: '1й',
        }
    },

    mounted() {
        //NIY заглушка получения данных
        setTimeout(()=>{
            this.loading = false;
        }, 1000)
    },

    methods: {
        hideSettingsGraph() {
            let homeComponent = this.getParentComponentByName("HomeComponent");
            if (homeComponent) {
                homeComponent.$emit('show-hide-settings-sidebar');
            }
        },
        setActiveUnitButton(unit) {
            this.activeUnitButton = unit;
        },
        setPeriodButton(periodType) {
            this.activePeriodType = periodType;
        },
        setPeriodNumberButton(periodNumber) {
            this.activePeriodNumber  = periodNumber;
        }
    }
};
</script>

<style scoped>
.settings-graph-sidebar__wrapper {
    width: 100%;
    /*height: 100%;*/
    display: flex;
    flex-flow: column;
    position: absolute;
    max-width: 320px;
    top: 0;
    right: 0;
    background-color: #F6F8FB;
    z-index: 1000;
}
.row-header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    /*height: 1em;*/
    width: 100%;
    margin-top: -10px;
}

.settings-graph-header__title {
    margin-top: 5px;
}

.settings-graph__label {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    color: #52504C;
    width: 100%;
}
.settings-graph-button {
    cursor: pointer;
}

.btn.ruble {
    border: 1px solid #3363C9;
    border-radius: 2px 0px 0 2px;
}

.btn.unit {
    border: 1px solid #3363C9;
    border-radius: 0px 2px 2px 0px;
}
/*.btn.custom {*/
/*    padding: 5px 30px;*/
/*}*/
.btn.custom:not(.ruble):not(.unit):not(:last-child):not(:nth-last-child(3)) {
    margin-right: 3px;
}
.btn.custom:not(.ruble):not(.unit):not(.cancel-btn):not(.apply-btn) {
    margin-top: 3px;
    padding: 5px 15px;
}
.btn.custom:not(.ruble):not(.unit):not(.active) {
    /*border: 1px solid #3363C9;*/
    /*border-radius: 2px;*/
}
.settings-graph__button-list {
    display: flex;
    flex-flow: row wrap;
}
.btn:not(.active) {
    background: #F6F8FB;
    box-shadow: -4px 0 4px rgba(0, 0, 0, 0.1);


    color: #3363C9;
}

.settings-graph__button-list--commands {
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    bottom: 36px;
    left: 24px;
}

.settings-graph__button-list--commands > a.btn.custom {
    padding: 8px 22px ;
}
.mt-1em {
    margin-top: 1em;
}
</style>
