var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticStyle: { "max-width": "100%", width: "100%" } },
    [
      _c("div", { staticClass: "bonus-program" }, [
        _c("div", { staticClass: "bonus-program__header" }, [
          _c("h2", {
            staticClass: "bonus-program__title",
            domProps: { innerHTML: _vm._s(_vm.name) }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "cutter js-cutter",
            class: { "is-open": _vm.showMore }
          },
          [
            _c("section", { staticClass: "sect-bonus-program" }, [
              _c("div", {
                staticClass: "bonus-program__body",
                domProps: { innerHTML: _vm._s(_vm.content) }
              })
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }