<template>
	<div>
	
		<div v-if="arUser.profiles.count == 0" class="notice notice_warning animated fadeIn">
			<div class="notice__header">У Вас нет активных Юр. Лиц</div>
			<div class="notice__body">
				<div class="notice__text">Обратитесь к Вашему менеджеру для уточнения информации.</div>
			</div>
		</div>
		
		<div v-if="notification.id > 0" v-bind:class="'notice_' + notification.type" class="notice animated fadeIn">
			<div class="notice__header">{{ notification.name }}</div>
			<div class="notice__body">
				<div class="notice__text" v-html="notification.content"></div>
				<router-link v-if="notification.link" :to="notification.link" class="notice__read"><span>Подробнее</span></router-link>
			</div>
		</div>
		
		<div v-if="$root.arUser.notifications.items.length > 1" class="notice-link animated fadeIn" style="margin-top: -20px;margin-bottom: 20px;">
			<router-link :to="'/notifications/'" class="notice__read" style="justify-content: flex-end;"><span>Все уведомления</span></router-link>
		</div>
		
	</div>
</template>

<script>
export default {
	name: 'component-section',
	data: function() {
		var self = this;
		return {
			loading: true,
			arUser: self.$root.arUser,
		}
	},
	computed:{
		notifications(){ return this.$root.arUser.notifications; },
		notification(){
			if(this.$root.arUser.notifications.items[0]) return this.$root.arUser.notifications.items[0];
			else return {id: 0, name: "", content: "", type: "", link: false};
		}
	},
	mounted: function(){
		var self = this;
		
	},
	watch: {
		
	},
	methods:{
		
	},
};
</script>

<style scoped>
	
</style>