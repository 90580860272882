var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticStyle: { "max-width": "100%" } }, [
    _c("div", {
      staticClass: "component-loading",
      class: { show: _vm.loading }
    }),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "bonus-slider owl-carousel" },
      _vm._l(_vm.slides, function(slide) {
        return _c(
          "div",
          {
            staticClass: "bonus-slide",
            style: { "background-image": "url(" + slide.img + ")" }
          },
          [
            _c(
              "div",
              { staticClass: "bonus-slide__content" },
              [
                _c("div", { staticClass: "bonus-slide__text" }, [
                  _c("p", {
                    staticClass: "t_white",
                    style: { color: slide.color },
                    domProps: { innerHTML: _vm._s(slide.text) }
                  })
                ]),
                _vm._v(" "),
                slide.link
                  ? _c("router-link", {
                      staticClass: "btn btn_white",
                      style: {
                        color: slide.btn_text_color,
                        background: slide.btn_color
                      },
                      attrs: { to: slide.link },
                      domProps: { innerHTML: _vm._s(slide.btn_text) }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "bonus-info" }, [
      _c(
        "div",
        { staticClass: "bonus-info__header" },
        [
          _c("h2", { staticClass: "bonus-info__title" }, [
            _vm._v("Вы являетесь участником программы “Feron-бонус”")
          ]),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "bonus-info__read",
              attrs: { to: "/bonus/condition/" }
            },
            [_c("span", [_vm._v("Подробнее о программе лояльности")])]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bonus-info__stat" }, [
        _c("div", { staticClass: "bonus-info__label" }, [
          _vm._v("активные бонусы:")
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "bonus-info__value",
          domProps: { innerHTML: _vm._s(_vm.$root.arUser.balls) }
        })
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "sect-bonus-history" }, [
      _c("div", { staticClass: "bonus-history js-tab" }, [
        _c("div", { staticClass: "bonus-history__header" }, [
          _c("h2", { staticClass: "bonus-history__title" }, [
            _vm._v("История последних кэшбеков")
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "bonus-history__tabs js-tab-controls" }, [
            _c("li", { class: { "is-active": _vm.activeTab == "all" } }, [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.setAcrtiveTab("all")
                    }
                  }
                },
                [_vm._v("Все")]
              )
            ]),
            _vm._v(" "),
            _c("li", { class: { "is-active": _vm.activeTab == "debit" } }, [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.setAcrtiveTab("debit")
                    }
                  }
                },
                [_vm._v("Начисления")]
              )
            ]),
            _vm._v(" "),
            _c("li", { class: { "is-active": _vm.activeTab == "credit" } }, [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.setAcrtiveTab("credit")
                    }
                  }
                },
                [_vm._v("Списания")]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bonus-history__body js-tab-contents" }, [
          _c("div", { staticClass: "is-active" }, [
            _c("div", { staticClass: "resp-table-wrap" }, [
              _c("table", { staticClass: "t-bonus" }, [
                _vm._m(0),
                _vm._v(" "),
                _vm.transacts.length > 0
                  ? _c(
                      "tbody",
                      _vm._l(_vm.transacts, function(transact) {
                        return _c("tr", [
                          transact.debit
                            ? _c("td", [
                                _c("b", { staticClass: "t_green" }, [
                                  _vm._v("+ " + _vm._s(transact.sum))
                                ])
                              ])
                            : _c("td", [
                                _c("b", { staticClass: "t_red" }, [
                                  _vm._v("- " + _vm._s(transact.sum))
                                ])
                              ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(transact.date))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(transact.order_id))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(transact.order_sum))]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "detail-btn",
                                  attrs: {
                                    to:
                                      "/orders/order/" + transact.order_id + "/"
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "arrow",
                                      attrs: { width: "7", height: "12" }
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/images/sprite.svg#caret-right"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  : _c("tbody", [_vm._m(1)])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bonus-history__footer" }, [
          _c(
            "button",
            {
              staticClass: "btn js-open-modal",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  _vm.show = true
                }
              }
            },
            [_vm._v("Вся история")]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "bonus-program" }, [
      _c(
        "div",
        { staticClass: "bonus-program__header" },
        [
          _c("h2", { staticClass: "bonus-program__title" }, [
            _vm._v("О программе “Feron-бонус”")
          ]),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "btn btn_transp",
              attrs: { to: "/bonus/condition/" }
            },
            [_vm._v("Правила участия в программе ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cutter js-cutter", class: { "is-open": _vm.showMore } },
        [
          _c("section", {
            staticClass: "sect-bonus-program",
            domProps: { innerHTML: _vm._s(_vm.content) }
          })
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal modal_bonus-history",
        class: { "is-open ": _vm.show, loading: _vm.loading },
        attrs: { id: "modal-bonus-history" }
      },
      [
        _c("div", { staticClass: "modal__wrap" }, [
          _c("div", { staticClass: "modal__inner animated zoomIn" }, [
            _c("button", {
              staticClass: "modal__close",
              on: {
                click: function($event) {
                  _vm.to_hide = true
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "modal__container" }, [
              _c("div", {
                staticClass: "component-loading",
                class: { show: _vm.loading }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "bonus-history js-tab" }, [
                _c("div", { staticClass: "bonus-history__header" }, [
                  _c("h2", { staticClass: "bonus-history__title" }, [
                    _vm._v("История последних кэшбеков")
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "bonus-history__tabs js-tab-controls" },
                    [
                      _c(
                        "li",
                        { class: { "is-active": _vm.activeTab == "all" } },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setAcrtiveTab("all")
                                }
                              }
                            },
                            [_vm._v("Все")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { class: { "is-active": _vm.activeTab == "debit" } },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setAcrtiveTab("debit")
                                }
                              }
                            },
                            [_vm._v("Начисления")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { class: { "is-active": _vm.activeTab == "credit" } },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setAcrtiveTab("credit")
                                }
                              }
                            },
                            [_vm._v("Списания")]
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "bonus-history__body js-tab-contents" },
                  [
                    _c("div", { staticClass: "is-active" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "resp-table-wrap nice-v-scroll" },
                        [
                          _c("table", { staticClass: "t-bonus" }, [
                            _c("col", { staticStyle: { width: "20%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "20%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "20%" } }),
                            _vm._v(" "),
                            _c("col"),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "60px" } }),
                            _vm._v(" "),
                            _vm._m(3),
                            _vm._v(" "),
                            _vm.transacts.length > 0
                              ? _c(
                                  "tbody",
                                  _vm._l(_vm.transacts, function(transact) {
                                    return _c("tr", [
                                      transact.debit
                                        ? _c("td", [
                                            _c(
                                              "b",
                                              { staticClass: "t_green" },
                                              [
                                                _vm._v(
                                                  "+ " + _vm._s(transact.sum)
                                                )
                                              ]
                                            )
                                          ])
                                        : _c("td", [
                                            _c("b", { staticClass: "t_red" }, [
                                              _vm._v(
                                                "- " + _vm._s(transact.sum)
                                              )
                                            ])
                                          ]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(transact.date))]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(transact.order_id))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(transact.order_sum))
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "detail-btn",
                                              attrs: {
                                                to:
                                                  "/orders/order/" +
                                                  transact.order_id +
                                                  "/"
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "arrow",
                                                  attrs: {
                                                    width: "7",
                                                    height: "12"
                                                  }
                                                },
                                                [
                                                  _c("use", {
                                                    attrs: {
                                                      "xlink:href":
                                                        "/images/sprite.svg#caret-right"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  }),
                                  0
                                )
                              : _c("tbody", [_vm._m(4)])
                          ])
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { width: "20%" } }, [_vm._v("Бонусы")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "20%" } }, [_vm._v("Дата")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "20%" } }, [_vm._v("№ заказа")]),
        _vm._v(" "),
        _c("th", [_vm._v("Сумма заказа")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "60" } }, [_vm._v(" ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "5" } }, [
        _c(
          "p",
          { staticStyle: { "text-align": "center", "font-size": "14px" } },
          [
            _vm._v(
              "\n\t\t\t\t\t\t\t\t\t\t\tТранзакции отсутствуют\n\t\t\t\t\t\t\t\t\t\t"
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "t-bonus t-bonus_fix" }, [
      _c("thead", [
        _c("tr", [
          _c("th", { attrs: { width: "20%" } }, [_vm._v("Бонусы")]),
          _vm._v(" "),
          _c("th", { attrs: { width: "20%" } }, [_vm._v("Дата")]),
          _vm._v(" "),
          _c("th", { attrs: { width: "20%" } }, [_vm._v("№ заказа")]),
          _vm._v(" "),
          _c("th", [_vm._v("Сумма заказа")]),
          _vm._v(" "),
          _c("th", { attrs: { width: "60" } }, [_vm._v(" ")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Бонусы")]),
        _vm._v(" "),
        _c("th", [_vm._v("Дата")]),
        _vm._v(" "),
        _c("th", [_vm._v("№ заказа")]),
        _vm._v(" "),
        _c("th", [_vm._v("Сумма заказа")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "5" } }, [
        _c(
          "p",
          { staticStyle: { "text-align": "center", "font-size": "14px" } },
          [
            _vm._v(
              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tТранзакции отсутствуют\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
            )
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }