var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-detail" }, [
    _c("div", {
      staticClass: "component-loading",
      class: { show: _vm.loading }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "environ environ_hr" }, [
      _c(
        "div",
        {
          staticClass: "environ__group",
          class: { hidden: _vm.$root.arUser.profiles.count == 1 }
        },
        [
          _c("label", { staticClass: "environ__label" }, [
            _vm._v("Активный договор")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "environ__select environ__select--order" }, [
            _c(
              "select",
              {
                staticClass: "select-profile select-profile-home select",
                attrs: { name: "" }
              },
              _vm._l(_vm.$root.arUser.profiles.list, function(profile) {
                return _c("option", {
                  class: { active: profile.active },
                  domProps: {
                    value: profile.id,
                    selected: profile.active,
                    innerHTML: _vm._s(profile.props.ORDER_PROP_75.value)
                  }
                })
              }),
              0
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "environ__group ",
          class: { hidden: _vm.activeProfile.stores.count == 1 }
        },
        [
          _c("label", { staticClass: "environ__label" }, [
            _vm._v("Активный склад")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "environ__select" }, [
            _c(
              "select",
              {
                staticClass: "select-store select-store-home select",
                attrs: { name: "" }
              },
              _vm._l(_vm.activeProfile.stores.list, function(store) {
                return _c("option", {
                  class: { active: store.selected },
                  domProps: {
                    value: store.ID,
                    selected: store.selected,
                    innerHTML: _vm._s(store.TITLE)
                  }
                })
              }),
              0
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "product-one " }, [
      _c("div", { staticClass: "left-side", class: { loading: _vm.loading } }, [
        _c(
          "div",
          { staticClass: "product-one__image js-prod-photo" },
          _vm._l(_vm.productData.images, function(img) {
            return _c(
              "div",
              {
                staticClass: "photo-container animated",
                class: { "is-active": img.active }
              },
              [_c("img", { attrs: { src: img.big.src, alt: "" } })]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "product-one__gallery js-prod-gallery owl-carousel" },
          _vm._l(_vm.productData.images, function(img) {
            return _c(
              "li",
              {
                class: { "is-active": img.active },
                on: {
                  click: function($event) {
                    return _vm.setGalleryPhoto(img)
                  }
                }
              },
              [
                _c("a", { attrs: { href: "javascript:;" } }, [
                  _c("img", { attrs: { src: img.thumb.src, alt: "" } })
                ])
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.loaded
          ? _c(
              "div",
              { staticClass: "product-one__descr js-tab" },
              [
                _c(
                  "component-tabs",
                  _vm._b({}, "component-tabs", _vm.infoTabsData, false)
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "right-side" }, [
        _c(
          "div",
          { staticClass: "product-one__labels" },
          _vm._l(_vm.productData.labels, function(label) {
            return _c("span", {
              staticClass: "label",
              class: label.class,
              domProps: { innerHTML: _vm._s(label.text) }
            })
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "product-one__tools" }, [
          _c(
            "button",
            {
              staticClass: "js-fav",
              class: { "is-active": _vm.inFavorits },
              attrs: { title: "Избранное" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.favoriteAction.apply(null, arguments)
                }
              }
            },
            [
              _c("svg", { attrs: { width: "18", height: "18" } }, [
                _c("use", {
                  attrs: { "xlink:href": "/images/sprite.svg#star" }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "js-comp",
              class: { "is-active": _vm.inCompare },
              attrs: { title: "Сравнить" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.compareAction.apply(null, arguments)
                }
              }
            },
            [
              _c("svg", { attrs: { width: "22", height: "18" } }, [
                _c("use", {
                  attrs: { "xlink:href": "/images/sprite.svg#chart-bar" }
                })
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product-one__stock" }, [
          _vm.productData.can_buy
            ? _c(
                "div",
                {
                  staticClass: "stock-dropdown js-dropdown",
                  class: { "is-open": _vm.storesOpened }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "stock-dropdown__trigger js-dropdown__trigger",
                      on: {
                        click: function($event) {
                          _vm.storesOpened = !_vm.storesOpened
                        }
                      }
                    },
                    [_vm._v("В наличии на складах")]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "stock-dropdown__list" },
                    _vm._l(_vm.productData.amount, function(store) {
                      return _c("li", [
                        _vm._v(
                          _vm._s(store.store_title) +
                            " - " +
                            _vm._s(store.product_amount)
                        )
                      ])
                    }),
                    0
                  )
                ]
              )
            : _c("span", { staticClass: "stock stock_red" }, [
                _vm._v("Нет в наличии")
              ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product-one__art" }, [
          _vm._v("Арт. " + _vm._s(_vm.productData.article))
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "product-one__name",
          domProps: { innerHTML: _vm._s(_vm.productData.name) }
        }),
        _vm._v(" "),
        _vm.loaded
          ? _c(
              "form",
              { staticClass: "product-one__form" },
              [
                _vm.productData.has_offers
                  ? _c(
                      "div",
                      { staticClass: "options" },
                      _vm._l(_vm.productData.sku, function(prop) {
                        return _c("div", { staticClass: "dl" }, [
                          _c("div", { staticClass: "dt" }, [
                            _vm._v(_vm._s(prop.name) + ":")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "dd" }, [
                            _c(
                              "div",
                              { staticClass: "control_radio-option" },
                              _vm._l(prop.variants, function(variant) {
                                return _c(
                                  "router-link",
                                  {
                                    class: {
                                      "is-active": variant.active,
                                      disabled: variant.disabled
                                    },
                                    attrs: {
                                      to: variant.link,
                                      title: variant.title
                                    }
                                  },
                                  [_c("span", [_vm._v(_vm._s(variant.name))])]
                                )
                              }),
                              1
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "product-one__price" },
                  [
                    _vm.productData.can_buy
                      ? [
                          _vm.priceData.print_old
                            ? _c("span", {
                                staticClass: "old-price",
                                domProps: {
                                  innerHTML: _vm._s(_vm.priceData.print_old)
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "price",
                            domProps: { innerHTML: _vm._s(_vm.priceData.print) }
                          })
                        ]
                      : [
                          _c("span", {
                            staticClass: "last-price",
                            domProps: { innerHTML: _vm._s(_vm.priceData.print) }
                          })
                        ]
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.productData.ratio_info != ""
                  ? _c("div", { staticClass: "product__ratio_info" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.productData.ratio_info) +
                          "\n\t\t\t\t"
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.productData.basketDisabled
                  ? [
                      _vm.productData.can_buy
                        ? _c("div", { staticClass: "product-one__order" }, [
                            _c("div", { staticClass: "counter js-counter" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn decrease",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.counterMinus.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v("-")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "count",
                                attrs: {
                                  type: "text",
                                  name: "quantity",
                                  autocomplete: "off"
                                },
                                domProps: { value: _vm.curCount },
                                on: { input: _vm.counterInput }
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn increase",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.counterPlus.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v("+")]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.inCart
                              ? _c("div", { staticClass: "btn btn__submit" }, [
                                  _vm._v("Добавлено")
                                ])
                              : _c(
                                  "button",
                                  {
                                    staticClass: "btn btn__submit",
                                    attrs: {
                                      type: "submit",
                                      name: "action",
                                      value: "buy"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.basketAction.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("В корзину")]
                                )
                          ])
                        : _vm.productData.arhive
                        ? _c("div", { staticClass: "product-one__order" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn__analogs js-modal-analogs",
                                class: {
                                  disabled: _vm.productData.hide_analogs
                                },
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.analogsAction.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v("Аналоги")]
                            )
                          ])
                        : _c(
                            "div",
                            { staticClass: "product-one__order" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn__analogs js-modal-analogs",
                                  class: {
                                    disabled: _vm.productData.hide_analogs
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.analogsAction.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Аналоги")]
                              ),
                              _vm._v(" "),
                              _vm.inSubscribe
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass: "btn btn__submit",
                                      attrs: { to: "/waiting/" }
                                    },
                                    [_vm._v(_vm._s(_vm.subscribeText))]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass: "btn btn__submit",
                                      attrs: {
                                        type: "submit",
                                        value: "waiting-list",
                                        name: "action"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.subscribeAction.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.subscribeText))]
                                  )
                            ],
                            1
                          )
                    ]
                  : _vm._e()
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.loaded
          ? _c("div", { staticClass: "product-one__docs" }, [
              _c("h3", [_vm._v("Основная документация")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "files" },
                _vm._l(_vm.productData.files, function(fileItem) {
                  return _c(
                    "a",
                    {
                      staticClass: "file",
                      attrs: { href: fileItem.value, target: "_blank" }
                    },
                    [
                      _c("svg", { attrs: { width: "21", height: "21" } }, [
                        _c("use", {
                          attrs: { "xlink:href": "/images/sprite.svg#pdf-file" }
                        })
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(fileItem.name)),
                        _c("br"),
                        fileItem.size > 0
                          ? _c("span", [
                              _vm._v("Файл " + _vm._s(fileItem.size) + " Мб")
                            ])
                          : _vm._e()
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "product-one__alt-price" },
          _vm._l(_vm.productData.info_prices, function(price) {
            return _c("div", { staticClass: "dl" }, [
              _c("div", {
                staticClass: "dt",
                domProps: { innerHTML: _vm._s(price.name) }
              }),
              _c("div", {
                staticClass: "dd",
                domProps: { innerHTML: _vm._s(price.value) }
              })
            ])
          }),
          0
        )
      ])
    ]),
    _vm._v(" "),
    _vm.loaded
      ? _c(
          "section",
          { staticClass: "similar-products" },
          [
            _c(
              "component-tabs",
              _vm._b({}, "component-tabs", _vm.similarTabsData, false)
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }