<template>
	<div>
		<h1>Каталог</h1>
		<div class="notice notice_warning">
			<div class="notice__header">Отгрузки разрешены</div>
			<div class="notice__body">
				<div class="notice__text">
					Ваш баланс на <b class="t_strong">"дата"</b> составляет <b class="t_red t_strong">"сумма₽"</b><br>
					Просроченной задолженности по документам нет.
				</div>
				<a href="#" class="notice__read"><span>Подробнее</span></a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			name: "catalog",
			show: false,
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
				{ link: '/catalog/', name: 'Каталог' },
			]
		}
	},
	computed: {
		cartQty() {
			return this.$store.state.cartQty;
		}
	},
	mounted: function(){
		var self = this;
		setTimeout(function(){
			self.show = true;
		}, 300);
		
		self.$root.breadcrumbs = self.breadcrumbs;
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>