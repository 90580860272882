var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "b-content b-content_orders js-orders",
      attrs: { "data-source": "demo-data/orders.json" }
    },
    [
      _c("div", { staticClass: "b-content__header" }, [
        _c("h2", { staticClass: "b-content__title" }, [
          _vm._v("Список заказов")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "b-content__header-aside" }, [
          _c(
            "button",
            {
              staticClass: "btn js-open-modal",
              on: {
                click: function($event) {
                  _vm.creatOrderShow = true
                }
              }
            },
            [_vm._v("Новый заказ")]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "b-content__top" }, [
        _c("div", { staticClass: "first-line" }, [
          _c("div", { staticClass: "control_group lbl_top" }, [
            _c("label", [_vm._v("История заказов для")]),
            _vm._v(" "),
            _c("div", { staticClass: "control_select" }, [
              _c(
                "select",
                {
                  staticClass: "select js-owner-select",
                  attrs: { name: "owner" }
                },
                [
                  _c("option", { attrs: { value: "", selected: "" } }, [
                    _vm._v("Все организации")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.$root.arUser.profiles.list, function(profile) {
                    return _c("option", {
                      domProps: {
                        value: profile.name,
                        innerHTML: _vm._s(profile.name)
                      }
                    })
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "control_group lbl_top" }, [
            _c("label", [_vm._v("Диапазон дат")]),
            _vm._v(" "),
            _c("div", { staticClass: "control_period" }, [
              _c("input", {
                staticClass: "js-period-picker",
                attrs: { type: "text", name: "date_range", placeholder: "" },
                domProps: { value: _vm.startDate + " - " + _vm.endDate }
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "js-clear-input-btn",
                attrs: { type: "button" },
                on: { click: _vm.clearPeriodFilter }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "control_search" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.searchOrders.apply(null, arguments)
                  }
                }
              },
              [
                _c("input", {
                  attrs: {
                    type: "text",
                    value: "",
                    placeholder: "Поиск",
                    name: "text",
                    id: "searchOrders"
                  }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    attrs: { type: "submit" },
                    on: { click: _vm.searchOrders }
                  },
                  [
                    _c("svg", { attrs: { width: "17", height: "16" } }, [
                      _c("use", {
                        attrs: { "xlink:href": "/images//sprite.svg#search" }
                      })
                    ])
                  ]
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "b-content__body" }, [
        _c("div", {
          staticClass: "component-loading",
          class: { show: _vm.loading }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "resp-table-wrap orders__table" }, [
          _c("table", { staticClass: "t-orders" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [
                  _c(
                    "button",
                    {
                      staticClass: "sort",
                      attrs: { "data-field": "ACCOUNT_NUMBER" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setOrderSort.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("№ заказа")]
                  )
                ]),
                _vm._v(" "),
                _c("th", [
                  _c(
                    "button",
                    {
                      staticClass: "sort",
                      attrs: { "data-field": "DATE_INSERT" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setOrderSort.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Дата заказа")]
                  )
                ]),
                _vm._v(" "),
                _c("th", [
                  _c(
                    "button",
                    {
                      staticClass: "sort",
                      attrs: { "data-field": "PRICE" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setOrderSort.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Сумма заказа")]
                  )
                ]),
                _vm._v(" "),
                _c("th", [_vm._v("Резерв до")]),
                _vm._v(" "),
                _c("th", { attrs: { width: "130" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "sort",
                      attrs: { "data-field": "STATUS_ID" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setOrderSort.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Статус заказа")]
                  )
                ]),
                _vm._v(" "),
                _c("th", { attrs: { width: "28" } }, [_vm._v(" ")])
              ])
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              [
                _vm._l(_vm.orders, function(order) {
                  return _vm.showOrders
                    ? _c("tr", [
                        _c("td", [_vm._v(_vm._s(order.PROPS.BUH_NUM.VALUE))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(order.DATE_INSERT_FORMAT))]),
                        _vm._v(" "),
                        _c("td", [_c("b", {}, [_vm._v(_vm._s(order.PRICE))])]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(order.PROPS.srok_rezerva.VALUE))
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "status_tr" }, [
                          _c(
                            "span",
                            {
                              staticClass: "order-status-name",
                              style: "color:" + order.STATUS.COLOR
                            },
                            [_vm._v(_vm._s(order.STATUS.NAME))]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "detail-btn",
                                attrs: {
                                  to:
                                    "/orders/order/" +
                                    order.ACCOUNT_NUMBER +
                                    "/"
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "arrow",
                                    attrs: { width: "7", height: "12" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href":
                                          "/images/sprite.svg#caret-right"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                }),
                _vm._v(" "),
                !_vm.showOrders ? _c("tr", [_vm._m(0)]) : _vm._e()
              ],
              2
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "b-content__footer" },
        [_c("component-pagination")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal modal_place-order",
          class: { "is-open": _vm.creatOrderShow },
          attrs: { id: _vm.modalId }
        },
        [
          _c("div", { staticClass: "modal__wrap" }, [
            _c("div", { staticClass: "modal__inner animated zoomIn" }, [
              _c("button", {
                staticClass: "modal__close",
                on: {
                  click: function($event) {
                    _vm.to_hide = true
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "modal__container" }, [
                _c("div", { staticClass: "steps js-steps" }, [
                  _c("div", { staticClass: "step step_1 is-active" }, [
                    _c("h4", [_vm._v("Как вы хотите сформировать заказ?")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tools" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn_transp js-goto-step",
                          on: { click: _vm.importRedirect }
                        },
                        [_c("span", [_vm._v("Загрузить из Exсel")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          on: { click: _vm.catalogRedirect }
                        },
                        [_vm._v("Выбрать товары в каталоге")]
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "6" } }, [
      _c("span", { staticClass: "empty-orders" }, [
        _vm._v("Заказы отсутствуют")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }