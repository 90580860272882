var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal",
      class: [{ "is-open ": _vm.show, loading: _vm.loading }, _vm.modalClass],
      attrs: { id: _vm.modalId }
    },
    [
      _c("div", { staticClass: "modal__wrap" }, [
        _c("div", { staticClass: "modal__inner animated zoomIn" }, [
          _c("button", {
            staticClass: "modal__close",
            on: {
              click: function($event) {
                _vm.to_hide = true
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal__container" }, [
            _c("div", {
              staticClass: "component-loading",
              class: { show: _vm.loading }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "left-side" }, [
              _c(
                "div",
                { staticClass: "product-one__image js-prod-photo" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._l(_vm.productData.images, function(img) {
                    return _c(
                      "div",
                      {
                        staticClass: "photo-container animated",
                        class: { "is-active": img.active }
                      },
                      [_c("img", { attrs: { src: img.big.src, alt: "" } })]
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "product__gallery owl-carousel" },
                _vm._l(_vm.productData.images, function(img) {
                  return _c(
                    "li",
                    {
                      class: { "is-active": img.active },
                      on: {
                        click: function($event) {
                          return _vm.setGalleryPhoto(img)
                        }
                      }
                    },
                    [
                      _c("a", { attrs: { href: "javascript:;" } }, [
                        _c("img", { attrs: { src: img.thumb.src, alt: "" } })
                      ])
                    ]
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right-side" }, [
              _c("div", { staticClass: "product__header" }, [
                _c(
                  "div",
                  { staticClass: "product__labels" },
                  _vm._l(_vm.productData.labels, function(label) {
                    return _c("span", {
                      staticClass: "label",
                      class: label.class,
                      domProps: { innerHTML: _vm._s(label.text) }
                    })
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "product__stock" }, [
                  _vm.productData.can_buy
                    ? _c("span", { staticClass: "stock stock_green" }, [
                        _vm._v(_vm._s(_vm.productData.product.QUANTITY_TEXT))
                      ])
                    : _c("span", { staticClass: "stock stock_red" }, [
                        _vm._v("Нет в наличии")
                      ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "product__tools" }, [
                  _c(
                    "button",
                    {
                      staticClass: "js-fav",
                      class: {
                        "is-active": _vm.$root.itemInUserList(
                          "favorite",
                          _vm.productData.id
                        )
                      },
                      attrs: { title: "Избранное" },
                      on: {
                        click: function($event) {
                          return _vm.$root.userItemsListsAction(
                            "favorite",
                            _vm.productData.id
                          )
                        }
                      }
                    },
                    [
                      _c("svg", { attrs: { width: "18", height: "18" } }, [
                        _c("use", {
                          attrs: { "xlink:href": "/images/sprite.svg#star" }
                        })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "js-comp",
                      class: {
                        "is-active": _vm.$root.itemInUserList(
                          "compare",
                          _vm.productData.id
                        )
                      },
                      attrs: { title: "Сравнить" },
                      on: {
                        click: function($event) {
                          return _vm.$root.userItemsListsAction(
                            "compare",
                            _vm.productData.id
                          )
                        }
                      }
                    },
                    [
                      _c("svg", { attrs: { width: "22", height: "18" } }, [
                        _c("use", {
                          attrs: {
                            "xlink:href": "/images/sprite.svg#chart-bar"
                          }
                        })
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "product__art" }, [
                _vm._v("Арт. " + _vm._s(_vm.productData.article))
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "product__name",
                domProps: { innerHTML: _vm._s(_vm.productData.name) }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cutter js-cutter",
                  class: { "is-open": _vm.props_open }
                },
                [
                  _c("div", { staticClass: "cutter__target" }, [
                    _c(
                      "div",
                      { staticClass: "product__char" },
                      [
                        _c("h4", {}, [_vm._v("Характеристики:")]),
                        _vm._v(" "),
                        _vm._l(_vm.productData.display_props, function(prop) {
                          return _c("div", { staticClass: "dl" }, [
                            _c("div", {
                              staticClass: "dt",
                              domProps: { innerHTML: _vm._s(prop.name) }
                            }),
                            _c("div", {
                              staticClass: "dd",
                              domProps: { innerHTML: _vm._s(prop.value) }
                            })
                          ])
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "cutter__trigger js-cutter-trigger",
                      attrs: { to: _vm.productData.link }
                    },
                    [
                      _vm.props_open
                        ? _c("span", [_vm._v("Свернуть")])
                        : _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  _vm.to_hide = true
                                }
                              }
                            },
                            [_vm._v("Подробнее")]
                          )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("form", { staticClass: "product__form" }, [
                _c(
                  "div",
                  { staticClass: "product-one__price" },
                  [
                    _vm.productData.can_buy
                      ? [
                          _vm.priceData.print_old
                            ? _c("span", {
                                staticClass: "old-price",
                                domProps: {
                                  innerHTML: _vm._s(_vm.priceData.print_old)
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "price",
                            domProps: { innerHTML: _vm._s(_vm.priceData.print) }
                          })
                        ]
                      : [
                          _c("span", {
                            staticClass: "last-price",
                            domProps: { innerHTML: _vm._s(_vm.priceData.print) }
                          })
                        ]
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.productData.can_buy
                  ? _c("div", { staticClass: "product-one__order" }, [
                      _c("div", { staticClass: "counter js-counter" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn decrease",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.counterMinus.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("-")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "count" }, [
                          _vm._v(_vm._s(_vm.curCount))
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn increase",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.counterPlus.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("+")]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.$root.itemInUserList("basket", _vm.ELEMENT_ID)
                        ? _c(
                            "div",
                            { staticClass: "btn btn_transp btn__submit" },
                            [_vm._v("Добавлено")]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "btn btn__submit",
                              attrs: {
                                type: "submit",
                                name: "action",
                                value: "buy"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.$root.userItemsListsAction(
                                    "basket",
                                    _vm.ELEMENT_ID,
                                    _vm.curCount
                                  )
                                }
                              }
                            },
                            [_vm._v("В корзину")]
                          )
                    ])
                  : _vm.productData.arhive
                  ? _c("div", { staticClass: "product-one__order" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn__analogs js-modal-analogs",
                          class: { disabled: _vm.productData.hide_analogs },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.analogsAction.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("Аналоги")]
                      )
                    ])
                  : _c("div", { staticClass: "product-one__order" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn__analogs js-modal-analogs",
                          class: { disabled: _vm.productData.hide_analogs },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.analogsAction.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("Аналоги")]
                      ),
                      _vm._v(" "),
                      _vm.$root.itemInUserList("waiting", _vm.ELEMENT_ID)
                        ? _c(
                            "span",
                            { staticClass: "btn btn_transp btn__submit" },
                            [_vm._v("В листе ожидания")]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "btn btn__submit",
                              attrs: {
                                type: "submit",
                                value: "waiting-list",
                                name: "action"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.$root.userItemsListsAction(
                                    "waiting",
                                    _vm.ELEMENT_ID
                                  )
                                }
                              }
                            },
                            [_vm._v("В лист ожидания")]
                          )
                    ])
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "is-active photo-container animated loading-photo" },
      [_c("img", { attrs: { src: "/images/no-photo.png", alt: "" } })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }