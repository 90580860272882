var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "b-content b-content_notes" }, [
    _c("div", { staticClass: "b-content__header" }, [
      _c("h2", { staticClass: "b-content__title" }, [_vm._v("Уведомления")]),
      _vm._v(" "),
      _vm.new_count > 0
        ? _c("div", { staticClass: "b-content__header-aside" }, [
            _c(
              "button",
              { staticClass: "btn btn_transp", on: { click: _vm.readAll } },
              [
                _c("span", { staticClass: "btn-icon" }, [_vm._v("×")]),
                _vm._v(" Прочитать все")
              ]
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "b-content__top" }, [
      _c("div", { staticClass: "first-line" }, [
        _c("div", { staticClass: "control_group" }, [
          _c("div", { staticClass: "control_toggle-order" }, [
            _c(
              "label",
              { staticClass: "js-hidden-toggle", on: { click: _vm.setSort } },
              [
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "order",
                    "data-toggle": "asc|desc"
                  },
                  domProps: { value: _vm.sortOrder }
                }),
                _vm._v(" "),
                _c("i"),
                _c("span", [_vm._v("Дата")])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "control_checkbox" }, [
            _c("label", [
              _c("input", {
                attrs: { type: "checkbox", name: "status", value: "1" },
                on: { change: _vm.setFilter }
              }),
              _vm._v(" "),
              _c("i"),
              _c("span", [_vm._v("Только непрочитанные")])
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "control_search",
            attrs: { action: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.setSearch.apply(null, arguments)
              }
            }
          },
          [
            _c("input", {
              attrs: {
                type: "text",
                value: "",
                placeholder: "Искать",
                name: "seaech",
                id: "items_search"
              },
              on: {
                input: function($event) {
                  $event.preventDefault()
                  return _vm.setSearch.apply(null, arguments)
                }
              }
            }),
            _vm._v(" "),
            _c("button", { attrs: { type: "submit" } }, [
              _c("svg", { attrs: { width: "17", height: "16" } }, [
                _c("use", {
                  attrs: { "xlink:href": "/images/sprite.svg#search" }
                })
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "second-line" }, [
        _c("ul", { staticClass: "control_nav" }, [
          _c(
            "li",
            { class: { "is-active": _vm.type == "" } },
            [
              _c("router-link", { attrs: { to: "/notifications/" } }, [
                _vm._v("Все")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { class: { "is-active": _vm.type == "warning" } },
            [
              _c("router-link", { attrs: { to: "/notifications_warning/" } }, [
                _vm._v("Важные")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { class: { "is-active": _vm.type == "attention" } },
            [
              _c(
                "router-link",
                { attrs: { to: "/notifications_attention/" } },
                [_vm._v("Требуют внимания")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { class: { "is-active": _vm.type == "info" } },
            [
              _c("router-link", { attrs: { to: "/notifications_info/" } }, [
                _vm._v("Оповещения")
              ])
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "b-content__body" }, [
      _c("div", {
        staticClass: "component-loading",
        class: { show: _vm.loading }
      }),
      _vm._v(" "),
      _vm.emptyResult
        ? _c("div", { staticClass: "empty-result-container animated zoomIn" }, [
            !_vm.loading
              ? _c("div", { staticClass: "empty-result-content" }, [
                  _c("div", {
                    staticClass: "empty-result-text",
                    domProps: { innerHTML: _vm._s(_vm.emptyMess) }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "empty-result-btn" },
                    [
                      _c(
                        "router-link",
                        { staticClass: "btn", attrs: { to: "/catalog/" } },
                        [_vm._v("В каталог")]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.emptyResult
        ? _c(
            "div",
            { staticClass: "note-list" },
            _vm._l(_vm.notifications, function(notification) {
              return _vm.loaded
                ? _c(
                    "div",
                    {
                      staticClass: "note animated",
                      class: [
                        { note_new: notification.status == "1" },
                        "note_" + notification.type
                      ],
                      attrs: { "data-id": notification.id },
                      on: {
                        click: function($event) {
                          return _vm.read(notification)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "note__header" }, [
                        _c("div", {
                          staticClass: "note__title",
                          domProps: { innerHTML: _vm._s(notification.title) }
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "note__date",
                          domProps: {
                            innerHTML: _vm._s(notification.date_format)
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "note__body" },
                        [
                          _c("div", {
                            staticClass: "note__text",
                            domProps: {
                              innerHTML: _vm._s(notification.preview)
                            }
                          }),
                          _vm._v(" "),
                          notification.link
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "note__read",
                                  attrs: { to: notification.link }
                                },
                                [_c("span", [_vm._v("Подробнее")])]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            }),
            0
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    !_vm.emptyResult
      ? _c(
          "div",
          { staticClass: "b-content__footer" },
          [_c("component-pagination")],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }