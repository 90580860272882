var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$root.arUser.authorized
    ? _c(
        "div",
        {
          staticClass: "modal",
          class: [
            { "is-open ": _vm.show, loading: _vm.loading },
            _vm.modalClass
          ],
          attrs: { id: _vm.modalId }
        },
        [
          _c("div", { staticClass: "modal__wrap" }, [
            _c("div", { staticClass: "modal__inner animated zoomIn" }, [
              _c("div", { staticClass: "modal__header" }, [
                _c("div", {
                  staticClass: "modal__title",
                  domProps: { innerHTML: _vm._s(_vm.title) }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "modal__subtitle",
                  domProps: { innerHTML: _vm._s(_vm.subtitle) }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal__container" }, [
                _vm.profilesCnt > 0
                  ? _c(
                      "div",
                      { staticClass: "control_select select_profile" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              hidden: _vm.$root.arUser.profiles.count == 1
                            }
                          },
                          [
                            _c("label", [_vm._v("Активный договор")]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                staticClass: "select-profile js-entity-select",
                                attrs: { name: "entity" }
                              },
                              _vm._l(_vm.$root.arUser.profiles.list, function(
                                profile
                              ) {
                                return _c("option", {
                                  class: { active: profile.active },
                                  domProps: {
                                    value: profile.id,
                                    selected: profile.active,
                                    innerHTML: _vm._s(
                                      profile.props.ORDER_PROP_75.value
                                    )
                                  }
                                })
                              }),
                              0
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: {
                              hidden: _vm.activeProfile.stores.count == 1
                            }
                          },
                          [
                            _c("label", [_vm._v("Активный склад отгрузки")]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                staticClass: "select-store js-entity-select",
                                attrs: { name: "entity" }
                              },
                              _vm._l(_vm.activeProfile.stores.list, function(
                                store
                              ) {
                                return _c("option", {
                                  class: { active: store.selected },
                                  domProps: {
                                    value: store.ID,
                                    selected: store.selected,
                                    innerHTML: _vm._s(store.TITLE)
                                  }
                                })
                              }),
                              0
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn",
                            attrs: { href: "javascript:;" },
                            on: { click: _vm.setActiveProfile }
                          },
                          [
                            _c("span", { staticClass: "btn_text" }, [
                              _vm._v("Далее")
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "info_text" }, [
                  _vm._v(
                    "\n\t\t\t\t*Выбор склада и договора позволит показывать актуальные цены и остатки для вашей компании\n\t\t\t\t"
                  )
                ])
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }