<template>
	<section class="b-content b-content_comparison compare-page">
		
		<div v-if="emptyResult" class="empty-result-container">
			<div class="empty-result-content">
				<div class="empty-result-text" v-html="emptyMess"></div>
				<div class="empty-result-btn">
					<router-link :to="'/catalog/'" class="btn">В каталог</router-link>
				</div>
			</div>
		</div>
		
		<div v-else class="">
			<div class="b-content__header">
				<h2 class="b-content__title">{{title_text}}</h2>
			</div>
			<div class="b-content__top">
				<div class="first-line">
					<div class="control_group lbl_top">
						<label>Показать:</label>
						<div class="control_list">
							<div class="control_button">
								<button class="btn btn_lblue all_props" v-on:click="propsToShow = allProps.display; setActiveBtn('all_props')">Все характеристики</button>
							</div>
							<div class="control_button">
								<button class="btn diff_props" v-on:click="propsToShow = allProps.diff; setActiveBtn('diff_props')">Показать различия</button>
							</div>
						</div>
					</div>
					<div class="control_group lbl_top">
						<label>В категориях:</label>
						<div class="control_list">
							<div class="control_dropdown js-dropdown" v-bind:class="{'is-open': dropdownOpen}" data-mode="multiselect">
								<button class="js-dropdown__trigger" v-on:click="dropdownOpen = !dropdownOpen"><span>Все категории ({{compare_count}})</span></button>
								<ul class="list">
									<li class="control_checkbox">
										<label>
											<input type="checkbox" id="select-all-sections"v-bind:checked="allSelected" v-on:change="setSectionFilterAll">
											<span>Все категории ({{compare_count}})</span> <i></i>
										</label>
									</li>
									<li class="control_checkbox" v-for="section in sections">
										<label>
											<input type="checkbox" v-bind:checked="section.checked" v-on:change="setSectionFilter" v-bind:value="section.id" name="sections">
											<span>{{section.name}} ({{section.cnt}})</span><i></i>
										</label>
									</li>
								</ul>
							</div>
							<div class="control_button">
								<button class="btn btn_sb-filter js-open-modal" v-on:click="filterOpen = true">
									<svg width="22" height="19" >
										<use xlink:href="/images/sprite.svg#tune"></use>
									</svg>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="modal modal_sb-filter" id="modal-sb-filter" v-bind:class="{'is-open show': filterOpen}">
					<aside class="modal__aside right">
						<div class="modal__header">
							<div class="modal__title">Параметры сравнения</div>
							<button class="modal__close" v-on:click="filterOpen = false"></button>
						</div>
						<div class="modal__container">
							<div class="sb-filter js-sb-filter">
								<div v-for="prop in filterProps" class="control_checkbox-list" v-bind:id="'filter_block_' + prop.code">
									<div class="control_checkbox">
										<label>
											<input type="checkbox" v-bind:name="prop.code" v-on:change="setPropsToShow"  v-bind:checked="prop.checked" value="Y">
											<i></i><span v-html="prop.name"></span>
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="modal__footer">
							<button class="btn btn_white" v-on:click="setAllChecked">Сбросить</button>
							<button class="btn btn_blue" v-on:click="filterOpen = false">Применить</button>
						</div>
					</aside>
				</div>
			</div>
			<div class="b-content__body">
				<div v-bind:class="{ show: loading }" class="component-loading"></div>
			
				<div class="comparison-content">
					<div class="slider-wrapper">
						<div class="comparison-slider owl-carousel ">
						
							<div v-for="item in itemsShow" class="product-slider__item" v-bind:data-product-id="item.id">
								<router-link :to="item.link" class="product" v-bind:data-id="item.id">
									<div class="product__header">
										<div class="product__labels">
											<span v-for="label in item.labels" v-bind:class="label.class" class="label">{{ label.text }}</span>
										</div>
										<button v-on:click.prevent="$root.userItemsListsAction('compare', item.id); deletCompareItem(item.id)" title="Удалить из списка" class="product__remove"></button>
									</div>
									<div class="product__img">
										<img v-bind:src="item.image" alt="">
									</div>
									<div class="product__art">Арт. {{ item.article }}</div>
									<div class="product__name" v-html="item.name"></div>
									<div class="product__price">
										<span v-if="item.ratio_price.print_old" class="old-price" v-html="item.ratio_price.print_old"></span>
										<span class="price" v-html="item.ratio_price.print"></span>
									</div>
								</router-link>
							</div>
							
						</div>
					</div>
					<div v-if="showTable" class="table-wrapper animated fadeIn" >
						<table class="comparison-table js-comparison-table">
							<tfoot>
								<tr>
									<td>
										<button type="button" class="btn owl-prev"><svg role="img" height="13" width="14"><use xlink:href="/images/sprite.svg#caret-left"></use></svg></button>
									</td>
									<td v-for="itemID in displayIDs">
										<button v-on:click.prevent="$root.userItemsListsAction('compare', itemID); deletCompareItem(itemID)" v-bind:data-product-id="itemID" class="btn btn__remove js-comp-remove" title="Удалить из сравнения"></button>
									</td>
									<td>
										<button type="button" class="btn owl-next"><svg role="img" height="13" width="14"><use xlink:href="/images/sprite.svg#caret-right"></use></svg></button>
									</td>
								</tr>
							</tfoot>
							<tbody>
								<tr v-for="prop in propsTable">
									<th>{{prop.name}}</th>
									<td v-for="item in prop.items" v-html="item.value" v-bind:key="prop.code + '__' + item.id" v-bind:data-product-id="item.id" v-bind:class="{'change_state': propsCalculate}"></td>
									<td>&nbsp;</td>
									<td v-if="prop.items.length == 2">&nbsp;</td>
									<td v-if="prop.items.length == 1">&nbsp;</td>
									<td v-if="prop.items.length == 1">&nbsp;</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="comparison-content__footer">
						<span>{{title_text}}</span>
						<a href="" class="btn" v-on:click.prevent="userItemsListsClearAction">Удалить все товары</a>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'CompareComponent',
	data: function () {
		var self = this;
		return {
			name: "compare",
			loading: true,
			filter: {},
			items: {},
			sections: {},
			allProps: {all: [], names: [], similar: [], diff: [], empty: [], display: [], filter: []},
			displayIDs: [],
			arSectionIDs: [],
			itemsShow: {},
			allSelected: true,
			showTable: true,
			properties: {},
			firstProps: {},
			propsTable: {},
			propsToShow: {},
			emptyResult: false,
			dropdownOpen: false,
			filterOpen: false,
			propsCalculate: true,
			size: 50,
			timer: null,
			emptyMess: "У вас нет товаров в сравнении",
			showClass: "animated fadeInUp",
			hideClass: "animated fadeOutRight",
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
				{ link: '/compare/', name: 'Сравнение товаров' },
			],
		}
	},
	components: {
		
	},
	computed: {
		title_text() {
			var self = this;
			return self.compare_count + " " + self.$root.declOfNum(self.waiting_count, ['позиция', 'позиции', 'позиций']) + " в сравнении";
		},
		compare_ids() {return this.$root.arUser.compare.items},
		compare_count() {return this.$root.arUser.compare.count},
		filterProps() {
			var self = this;
			var arProps = [];
			var obVals = Object.values(self.allProps);
			var obKeys = Object.keys(self.allProps.filter);
			arProps = Object.values(self.allProps.filter);
			return arProps;
		},
	},
	watch: {
		propsToShow: function(newProps){
			this.setCompareTable();
		},
		itemsShow: function(itemsShow){
			var self = this;
			self.loading = true;
			var $compar_owl = $('.comparison-slider.owl-carousel');
			$compar_owl.owlCarousel('destroy');
			setTimeout(function(){
				self.initOwl();
				self.loading = false;
			}, 100)
		},
		compare_count: function(newProps){
			this.loadProducts();
		},
		arSectionIDs: function(ids){
			var self = this;
			self.filterItems();
		},
	},
	methods: {
		initOwl: function(){
			var self = this;
			var $compar_owl = $('.comparison-slider.owl-carousel');
			$compar_owl.owlCarousel('destroy');
			
			$compar_owl.owlCarousel({
				margin: 30,
				navText: [
					'<svg role="img" height="13" width="14"><use xlink:href="/images/sprite.svg#arrow-left"></use></svg>',
					'<svg role="img" height="13" width="14"><use xlink:href="/images/sprite.svg#arrow-right"></use></svg>'
				],
				nav: true,
				dots: false,
				responsive: {
					0: {
						margin: 10,
						items: 2
					},
					480: {
						margin: 10,
						items: 3
					},
					768: {
						items: 3,
					},
					992: {
						items: 2
					},
					1200: {
						items: 3
					}
				},
				onChanged: function () {
					setTimeout(function () {
						self.setCompareIDs($compar_owl.find('.owl-item.active'));
						self.setCompareTable();
					}, 1)
				},
			});
			$('.js-comparison-table .owl-next').click(function () {
				$compar_owl.trigger('next.owl.carousel');
			});
			$('.js-comparison-table .owl-prev').click(function () {
				$compar_owl.trigger('prev.owl.carousel');
			});
		
		},
		filterItems: function(){
			var self = this;
			var totalCnt = 0;
			
			var newItems = [];
			
			for(var i in self.items){
				var item = self.items[i];
				
				if(self.arSectionIDs.indexOf(item.parent) >= 0){
					newItems.push(item);
					totalCnt++;
				}
			}
			
			if(totalCnt > 0) self.showTable = true;
			else self.showTable = false;
			
			self.itemsShow = newItems;
		},
		setPropsToShow: function(){
			var self = this;
			var newPropsToShow = [];
			$(".sb-filter input:checked").each(function(){
				newPropsToShow.push(this.name);
			})
			self.propsToShow = newPropsToShow;
		},
		setAllChecked: function(){
			var self = this;
			var newPropsToShow = [];
			$(".sb-filter input").each(function(){
				this.checked = true;
				newPropsToShow.push(this.name);
			})
			self.propsToShow = newPropsToShow;
			self.filterOpen = false;
		},
		setCompareTable: function(){
			var self = this;
			self.propsCalculate = true;
			self.propsTable = [];
				
			for(var i in self.propsToShow){
				var prop = {};
				var propCode = self.propsToShow[i];
				
				prop.code = propCode;
				prop.name = self.allProps.names[propCode];
				prop.items = [];
				
				for(var y in self.displayIDs){
				
					var id = self.displayIDs[y];
					var value = self.properties[propCode][id];
					if(value == undefined) value = "-";
					prop.items.push({id: id, value: value});
				}
				self.propsTable.push(prop);
			}
			
			setTimeout(function(){
				self.propsCalculate = false;
			}, 200);
			
		},
		setCompareIDs: function(objects){
			var self = this;
			var num = 0;
			self.displayIDs = [];
			objects.each(function(){
				num++;
				if(parseInt($(this).find(".product").data("id")) > 0)
				{
					var id = $(this).find(".product").data("id");
					if(num <= 3) self.displayIDs.push(id);
				}
			})
		},
		setActiveBtn: function(btn_class){
			$("." + btn_class).parents(".control_list").find(".btn").removeClass("btn_lblue");
			$("." + btn_class).addClass("btn_lblue");
		},
		deletCompareItem: function(itemID){
			var self = this;
			//$("*[data-product-id=" + itemID + "]").addClass("deleted");
		},
		userItemsListsClearAction: function(){
			this.loading = true;
			this.$root.userItemsListsClearAction('compare');
		},
		setSectionFilter: function(e){
			var self = this;
			var arSectionIDs = [];
			$("input[name=sections]:checked").each(function(){ arSectionIDs.push($(this).val()); });
			self.arSectionIDs = arSectionIDs;
			self.allSelected = Object.keys(self.sections).length == self.arSectionIDs.length;
			
			$("#select-all-sections")[0].checked = self.allSelected
		},
		setSectionFilterAll: function(e){
			var self = this;
			var checked = e.target.checked;
			self.arSectionIDs = [];
			for(var i in self.sections) {
				self.sections[i].checked = checked;
			};
			setTimeout(function(){
				self.allSelected = Object.keys(self.sections).length == self.arSectionIDs.length;
				$("input[name=sections]").each(function(){ 
					this.checked = checked;
					if(checked) self.arSectionIDs.push(this.value);
				});
				
			}, 10);
		},
		toggleFilterBlock(item){
			var self = this;
			var timeout = 10;
			var block = $("#filter_block_" + item.code);
			
			block.toggleClass("is-open");
			
			if(!block.hasClass("is-open")){
				var elCount = block.find(".filter_control_item").length;
				block.find(".filter_control_item").each(function(){
					var controlEl = $(this);
					if(!controlEl.hasClass("checked")){
						setTimeout(function(){
							controlEl.removeClass(self.showClass);
							controlEl.addClass(self.hideClass);
							setTimeout(function(){controlEl.removeClass("animated")}, 300);
						}, timeout * controlEl.index());
					}
				});
			}else{
				block.find(".filter_control_item").each(function(){
					var controlEl = $(this);
					if(!controlEl.hasClass("checked")){
						controlEl.removeClass(self.hideClass);
						setTimeout(function(){
							controlEl.addClass(self.showClass);
						}, timeout * controlEl.index());
					}
				})
			}
		},
		loadProducts: function(){
			var self = this;
			
			if(self.compare_count <= 0) {
				self.loading = false;
				self.emptyResult = true;
				self.filter = {};
			}else {
				self.loading = true;
				self.filter = {"ID": this.compare_ids, IBLOCK_ID: 56};
				var urlData = {filter: self.filter, size: self.size};
				$.getJSON("/data/components/sectionFiltered.php", urlData, function(data){
					self.allProps = {all: [], names: [], similar: [], diff: [], empty: [], display: [], filter: []};
					/*
					self.properties = {};
					self.firstProps = {};
					self.propsTable = {};
					self.propsToShow = {};
					*/
					self.items = data.items;
					self.itemsShow = data.items;
					self.sections = data.sections;
					
					self.arSectionIDs = Object.keys(self.sections);
					
					self.allProps.diff.push("NAME");
					self.allProps.filter["NAME"] = {name: "Название", code: "NAME", checked: true, values: []};
					
					var num = 0;
					for(var i in self.items){
						num++;
						var item = self.items[i];
						
						var itemProps = item.display_props;
						
						itemProps["NAME"] = {
							name: "Название", 
							code: "NAME", 
							value: item.name
						}
						
						for(var y in itemProps){
							var prop = itemProps[y];
							
							if(self.properties[prop.code] == undefined) 
								self.properties[prop.code] = {};
								
							if(self.allProps.filter[prop.code] == undefined) 
								self.allProps.filter[prop.code] = {name: prop.name, code: prop.code, checked: true, values: [prop.value]};
								
							else if(prop.value != "" && prop.value != "-" && self.allProps.filter[prop.code].values.indexOf(prop.value) < 0){
								self.allProps.filter[prop.code].values.push(prop.value);
								self.allProps.filter[prop.code].values = self.allProps.filter[prop.code].values.sort();
							}
							
							if(self.allProps.all.indexOf(prop.code) < 0) self.allProps.all.push(prop.code);
							if(!self.allProps.names[prop.code]) self.allProps.names[prop.code] = prop.name;
						}
						
						for(var z in self.allProps.all){
							var propCode = self.allProps.all[z];
							var propValue = itemProps[propCode] != undefined ? itemProps[propCode].value : "-";
							if(self.allProps.empty.indexOf(propCode) < 0) self.allProps.empty[propCode] = true;
							self.properties[propCode][item.id] = propValue;
							if(propValue != "" && propValue != "-") self.allProps.empty[propCode] = false;
							if(propCode != "NAME"){
								if(num == 1) self.firstProps[propCode] = propValue;
								else if(propValue != self.firstProps[propCode] && self.allProps.diff.indexOf(propCode) < 0) self.allProps.diff.push(propCode);
							}
						}
					}
					
					self.allProps.display.push("NAME");
					for(var z in self.allProps.all){
						var propCode = self.allProps.all[z];
						if(self.allProps.diff.indexOf(propCode) < 0) self.allProps.similar.push(propCode);
						
						if(self.allProps.empty[propCode] == false && propCode != "NAME") self.allProps.display.push(propCode);
					}
					
					
					self.propsToShow = self.allProps.display;
					self.loading = false;
				});
				
			}
		},
	},
	mounted: function(){
		var self = this;
		self.$root.breadcrumbs = self.breadcrumbs;
		self.loadProducts();
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
*[data-product-id]{
    transition: all 0.2s ease;
}
.change_state{
	opacity:0;
}
#modal-sb-filter {
    transition: background 0.5s ease 0.2s, left 0.1s ease 0.7s, width 0.1s ease 0.7s;
    display: block;
    background: rgb(0 0 0 / 0%);
    overflow: hidden;
    width: 0;
    left: 100%;
    opacity: 1;
}
#modal-sb-filter.show {
    background: rgb(0 0 0 / 30%);
    width: 100%;
    left: 0;
    transition: background 0.5s ease;
}
.modal_sb-filter .modal__aside{
	transition:all 0.3s ease 0.1s;
}
.compare-page .product-slider__item .product__img{
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.compare-page .product-slider__item .product__img img{
	width: auto;
	max-height: 100%;
}
.compare-page .owl-stage{
	display: flex;
}
.compare-page .owl-item{
	display: flex;
}
.compare-page .product-slider__item {
    display: flex;
    height: 100%;
	width:100%;
}
.compare-page .comparison-slider .product {
    padding: 10px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
	width: 100%;
}

.compare-page .filter_control_item{
	display:none; 
	opacity:0;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}
.compare-page .filter_control_item.checked{
	display:block; 
	opacity:1;
}
.compare-page .control_checkbox-list{
	padding-bottom:0;
}
.compare-page .filter_control_item:last-child{
	padding-bottom:7px;
}
.compare-page .filter_control_item.animated{display:block;}
.compare-page .cat-filter__group.is-open .cat-filter__control{height:auto;}
</style>