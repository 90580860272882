var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pagination-center",
      class: { hidden: _vm.navPageCount == 1 }
    },
    [
      _c(
        "span",
        {
          staticClass: "btn btn_transp js-catalog-load-more",
          class: { loading: _vm.showMoreLoading, disabled: !_vm.showShowMore },
          attrs: {
            "data-next": _vm.navParam.nextPage,
            "data-total": _vm.navPageCount,
            "data-current": _vm.navPageNum
          },
          on: { click: _vm.showMore }
        },
        [_vm._v(_vm._s(_vm.textShowCnt))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pagination" }, [
        _vm.inited
          ? _c(
              "ul",
              _vm._l(_vm.navParam.navPages, function(page) {
                return _c(
                  "li",
                  {
                    class: { "is-active": page.active, disabled: page.disabled }
                  },
                  [
                    _c("router-link", {
                      attrs: { to: page.link },
                      domProps: { innerHTML: _vm._s(page.text) }
                    })
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "pages-info" }, [
        _vm._v(
          "Страница " +
            _vm._s(_vm.navPageNum) +
            " из " +
            _vm._s(_vm.navPageCount)
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }