<template>
	<div class="modal" v-bind:class="[{'is-open ': show}, modalClass]" v-bind:id="modalId">
		<div class="modal__wrap">
			<div class="modal__inner animated zoomIn">
				<div class="modal__header">
					<div class="modal__title" v-html="title"></div>
					<button class="modal__close" v-on:click="to_hide = true"></button>
				</div>
				<div class="modal__container">
					
					<component-product-list v-bind:emptyMess="emptyMess" v-bind:showPager="false" v-bind:filter="filter"></component-product-list>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import productListComponent from '../components/productListComponent';

export default {
	props: {
		modalClass: {type: String, default: 'modal_analog'},
		modalId: {type: String, default: 'modal-analog'},
	},
	data: function() {
		return {
			loading: true,
			show: false,
			to_hide: false,
			showClass: "zoomIn",
			hideClass: "zoomOut",
		}
	},
	computed: {
		popupListData() {return this.$root.popupListData},
		title() {return this.$root.popupListData.title},
		filter() {return this.$root.popupListData.filter},
		emptyMess() {return this.$root.popupListData.emptyMess},
	},
	components: {
		'component-product-list': productListComponent,
	},
	mounted: function(){
		var self = this;
		
	},
	watch: {
		filter: function(newFileter){
			var self = this;
			
			if(newFileter != false) self.show = true;
			
		},
		to_hide: function(to_hide){
			var self = this;
			
		},
		show: function(){
			var self = this;
			if(this.show) $("#modal-overlay").fadeIn();
			else {
				self.$root.popupListData.filter = false;
				$("#modal-overlay").fadeOut();
			}
		},
	},
	methods:{
		
	},
};
</script>

<style>
.modal{
    overflow-y: auto;
}
.modal__inner.animated{
	-webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}
#modal-analog .b-content__top{
    position: absolute;
    top: -35px;
    right: 0;
}
.modal .component-loading{
	z-index: 20;
    opacity: 0.8;
}
.modal .product-grid{
	width: 100%;
}

</style>