<template>
	<div class="modal" v-bind:class="[{'is-open ': show, 'loading': loading}, modalClass]" v-bind:id="modalId">
		<div class="modal__wrap">
			<div class="modal__inner animated zoomIn">
			
				<div v-bind:class="{ show: loading }" class="component-loading"></div>
				
				<div v-bind:class="{ show: processWait }" class="component-loading">
					<div class="component-loading-text" v-html="processWaitText"></div>
					<div class="component-loading-progress">
						<div class="bar">
							<span class="bar-title" v-html="process_text"></span>
							<span class="bar-progress"><span v-bind:style="'width:' + processData.percent + '%;'" class="bar-progress-ready"></span></span>
						</div>
					</div>
				</div>
				
				<div v-if="!success" class="modal__header">
					<div class="modal__title">Загрузка файла заказа</div> 
					<button class="modal__close" v-on:click="to_hide = true"></button>
				</div>
				<div class="modal__container">
					<form v-if="success" class="import-form b-content__top success" id="import_form" enctype="multipart/form-data">
						<h4>Заказ успешно загружен!</h4>
						<div class="import-result">{{count_added_text}}</div>
						<div v-if="!is_cart" class="success-text">Сейчас вы будете перенаправлены  в раздел “Корзина”<br>
						для дальнейшего оформления заказа</div>
						
						<div class="countdown-redirect">{{countdown}}</div>
					</form>
					<form v-else class="import-form b-content__top " id="import_form" enctype="multipart/form-data">
						<div v-bind:class="{'loaded': file.size}" class="control_file_import">
							<label for="import_file" class="btn_transp btn btn-file">
								<input v-on:change="setImportFile" name="import_file" id="import_file" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
								<span >{{btn_text}}</span>
							</label>
						</div>
						<div v-if="file_error !== false" class="notice notice_warning animated fadeIn">
							<div class="notice__body">
								<div class="notice__text" v-html="file_error"></div>
							</div>
						</div>
						<div v-if="file.size" class="product-one__docs">
							<div class="file">
								<svg width="21" height="21">
									<use xlink:href="/images/sprite.svg#file-excel"></use>
								</svg> 
								<span>{{file.name}}<br><span class="size">{{file.sizeKb}} Kб</span></span>
								<button class="modal__close" v-on:click="file = {}"></button>
							</div>
						</div>
						<div class="control_checkbox">
							<label for="clear_basket">
								<input name="clear_basket" id="clear_basket" type="checkbox" value="Y">
								<i></i><span>Очистить текущую корзину перед загрузкой файла</span>
							</label>
						</div>
					</form>
				</div>
				<div v-if="!success" class="modal__footer">
				
					<a v-on:click.prevent="downloadPrice" href="" class="btn btn_light"><span class="btn_text">Шаблон файла заказа</span>
						<svg class="svg-icon" width="13" height="13">
							<use xlink:href="/images/sprite.svg#download"></use>
						</svg>
					</a>
					
					<button v-on:click="importExcel" v-on:mousedown="importExcel" class="btn btn-import">Загрузить</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		modalClass: {type: String, default: 'modal_import modal_analog'},
		modalId: {type: String, default: 'modal-import'},
	},
	data: function() {
		var self = this;
		return {
			loading: true,
			processWait: false,
			processWaitText: "",
			process_text_win: "",
			loaded: false,
			show: false,
			to_hide: false,
			file_error: false,
			success: false,
			process: null,
			processData: {ready: 0, total: 0, percent: 0},
			count_added: 0,
			countdown: 10,
			file: {},
		}
	},
	computed: {
		is_cart() {return this.$route.name == "cart"},
		importExcelModal() {return this.$root.importExcelModal},
		btn_text() {return this.file.size ? "Выбрать другой файл" : "Выберите файл с таблицей"},
		count_added_text() {return "Добавлено " + this.count_added + " " + this.$root.declOfNum(this.count_added, ['позиция', 'позиции', 'позиций'])},
		process_text() {return "Обработано <strong>" + this.processData.ready + "</strong> " + this.$root.declOfNum(this.processData.ready, ['товар', 'товара', 'товаров']) + " из <strong>" + this.processData.total + "</strong>"},
	},
	mounted: function(){
		var self = this;
	},
	watch: {
		importExcelModal: function(val) {
			var self = this;
			if(val) {
				self.show = true;
				self.loading = false;
			}
		},
		show: function(){
			var self = this;
			if(this.show) $("#modal-overlay").fadeIn();
			else $("#modal-overlay").fadeOut();
		},
		to_hide:function(to_hide){
			var self = this;
			if(to_hide){
				$("#" + self.modalId + " .modal__inner").addClass("zoomOut");
				setTimeout(function(){
					self.show = false;
					self.to_hide = false;
					self.$root.importExcelModal = false;
					$("#" + self.modalId + " .modal__inner").removeClass("zoomOut");
					self.reset();
				}, 400);
			}
		},
	},
	methods:{
		downloadPrice: function(){
			var self = this;
			var link = "/data/ajax/import.php?action=importTemplate&uid=" + self.$root.arUser.id;
			self.loading = true;
			self.processWait = true;
			self.processWaitText = "Формируем прайс... <br>Загрузка начнется автоматически";
			self.process_text_win = self.process_text;
			
			if(!!self.process) clearInterval(self.process);
			self.process = setInterval(function(){
				$.get("/data/prices/import_price_" + self.$root.arUser.id + ".log", function(str){
					var arProgressData = str.split("|");
					self.processData = {
						ready: parseInt(arProgressData[0]), 
						total: parseInt(arProgressData[1]),
						percent: parseInt(self.processData.ready * 100 / self.processData.total)
					};
				})
			}, 100);
			
			$.get(link, function(file){
				self.loading = false;
				self.processWait = false;
				clearInterval(self.process);
				self.processData = {ready: 0, total: 0, percent: 0};
				
				window.location.href = file;
			})
		},
		importExcel: function(){
			var self = this;
			self.processWait = true;
			self.processWaitText = "Обработка файла...";
				
			if(self.file.size == undefined){
				self.file_error = "Выберите файл с таблицей";
				self.processWait = false;
			}else{
				self.file_error = false;
				var formData = new FormData();
				formData.append('file', import_file.files[0]);
				formData.append('action', "importFile");
				formData.append('clear_basket', $("#clear_basket").prop("checked") ? "Y" : "N");
				
				if(!!self.process) clearInterval(self.process);
				
				self.process = setInterval(function(){
					$.get("/data/prices/load_price_" + self.$root.arUser.id + ".log", function(str){
						var arProgressData = str.split("|");
						if(parseInt(arProgressData[0]) >= parseInt(arProgressData[1])) clearInterval(self.process);
						self.processData = {
							ready: parseInt(arProgressData[0]), 
							total: parseInt(arProgressData[1]),
							percent: parseInt(self.processData.ready * 100 / self.processData.total)
						};
					})
				}, 100);
				
				$.ajax({
					type: "POST",
					url: self.$root.ajaxUrl + 'import.php',
					cache: false,
					async: true,
					contentType: false,
					processData: false,
					data: formData,
					dataType : 'json',
					success: function(json){
						if(json.status){
							self.$root.arUser.basket = json.basket;
							self.count_added = json.count;
							self.success = true;
							self.processWait = false;
							clearInterval(self.process);
							
							self.processData = {ready: 0, total: 0, percent: 0};
							self.$root.$emit('basketImport', json.basket);
							self.startCountDown();
						}else{
							self.success = false;
							self.file_error = json.error;
						}
						self.processWait = false;
					}
				});
			}
		},
		startCountDown: function(){
			var self = this;
			if(self.is_cart) self.countdown = 4;
			var contdownInterval =  setInterval(function(){
				self.countdown--;
				if(self.countdown == 0){
					clearInterval(contdownInterval);
					self.to_hide = true;
					self.reset();
					if(!self.is_cart) self.$root.$router.push({ path: '/cart/' });
				}
			}, 1000);
		},
		reset: function(e){
			this.loading = true;
			this.loaded = false;
			this.file_error = false;
			this.success = false;
			this.count_added = 0;
			this.countdown = 10;
			this.file = {};
		},
		setImportFile: function(e){
			var self = this;
			if(import_file.files[0]){
				self.file = import_file.files[0];
				self.file.sizeKb = (self.file.size/1024).toFixed(1);
			}else self.file = {};
		},
	},
};
</script>

<style scoped>
.control_file_import.loaded{display:none;}
.modal__footer{
    display: flex;
    justify-content: space-between;
}
.modal_import .notice{
    margin-top: 10px;
    margin-bottom: 0;
    width: 226px;
	border:none;
}
.modal_import .modal__wrap{
	max-width: 600px;
}
.import-form{
	display: flex;
    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 200px;
}
.import-form.success{
	min-height: 300px;
}
.import-form.success h4{
	font-family: 'Museo Sans Cyrl 700', sans-serif;
	font-style: normal;
	font-size: 20px;
	line-height: 26px;
	text-align: center;
	letter-spacing: 0.2px;
	color: #000000;
}
.import-form.success .import-result{
	font-family: 'Museo Sans Cyrl 300', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 18px;

	text-align: center;
	letter-spacing: -0.408px;
	color: #52504C;
}
.import-form.success .success-text{
	font-family: 'Museo Sans Cyrl 300', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 18px;

	text-align: center;
	letter-spacing: -0.408px;
	color: #52504C;
    margin-top: 28px;
}
.btn {
	cursor:pointer;
}
#import_file{
	display: none;
}
.modal_import .control_checkbox{margin-top:20px;}
.modal_import .product-one__docs{margin-top: 10px;}
.modal_import .product-one__docs .file{width: 194px;position:relative;}
.modal_import .product-one__docs .file .size{font-family: 'Museo Sans Cyrl 700', sans-serif;font-size: 12px;}
.modal_import .product-one__docs .file .modal__close{
    top: -10px;
    right: -10px;
    background: transparent;
}
.modal_import .product-one__docs .file .modal__close:after,
.modal_import .product-one__docs .file .modal__close:before{
    background: #bfbfbf;
}
.modal_import .product-one__docs .file .modal__close:hover:after,
.modal_import .product-one__docs .file .modal__close:hover:before{
    background: #3363C9;
}
.countdown-redirect{
    font-family: 'Museo Sans Cyrl 700', sans-serif;
    font-size: 30px;
	color: #3363C9;
}
.modal_import .component-loading.show{
	opacity:1;
}
</style>