<template>
	<section class="" style="max-width: 100%;">
		<div v-bind:class="{ show: loading }" class="component-loading"></div>
	
		<section class="bonus-slider owl-carousel">
			<div v-for="slide in slides" class="bonus-slide" v-bind:style="{ 'background-image': 'url(' + slide.img + ')' }" >
				<div class="bonus-slide__content">
					<div class="bonus-slide__text">
						<p class="t_white" v-html="slide.text" v-bind:style="{ 'color': slide.color }"></p>
					</div>
					<router-link v-if="slide.link" :to="slide.link" v-bind:style="{ 'color': slide.btn_text_color, 'background' : slide.btn_color }" class="btn btn_white" v-html="slide.btn_text"></router-link>
				</div>
			</div>
		</section>

		<div class="bonus-info">
			 <div class="bonus-info__header">
				 <h2 class="bonus-info__title">Вы являетесь участником программы “Feron-бонус”</h2>
				 <router-link :to="'/bonus/condition/'" class="bonus-info__read"><span>Подробнее о программе лояльности</span></router-link>
			 </div>
			 <div class="bonus-info__stat">
				 <div class="bonus-info__label">активные бонусы:</div>
				 <div class="bonus-info__value" v-html="$root.arUser.balls"></div>
			 </div>
		</div>

		<section class="sect-bonus-history">
			<div class="bonus-history js-tab">
				<div class="bonus-history__header">
					<h2 class="bonus-history__title">История последних кэшбеков</h2>
					<ul class="bonus-history__tabs js-tab-controls">
						<li v-bind:class="{'is-active': activeTab == 'all'}"><a v-on:click.prevent="setAcrtiveTab('all')">Все</a></li>
						<li v-bind:class="{'is-active': activeTab == 'debit'}"><a v-on:click.prevent="setAcrtiveTab('debit')">Начисления</a></li>
						<li v-bind:class="{'is-active': activeTab == 'credit'}"><a v-on:click.prevent="setAcrtiveTab('credit')">Списания</a></li>
					</ul>
				</div>
				<div class="bonus-history__body js-tab-contents">
				
					<div class="is-active">
						<div class="resp-table-wrap">
							<table class="t-bonus">
								<thead>
									<tr>
										<th width="20%">Бонусы</th>
										<th width="20%">Дата</th>
										<th width="20%">№ заказа</th>
										<th>Сумма заказа</th>
										<th width="60">&nbsp;</th>
									</tr>
								</thead>
								<tbody v-if="transacts.length > 0">
									<tr v-for="transact in transacts">
										<td v-if="transact.debit"><b class="t_green">+ {{transact.sum}}</b></td>
										<td v-else><b class="t_red">- {{transact.sum}}</b></td>
										<td>{{transact.date}}</td>
										<td>{{transact.order_id}}</td>
										<td>{{transact.order_sum}}</td>
										<td>
											<router-link :to="'/orders/order/' + transact.order_id + '/'" class="detail-btn">
												<svg class="arrow" width="7" height="12">
													<use xlink:href="/images/sprite.svg#caret-right"></use>
												</svg>
											</router-link>
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="5">
											<p style="text-align: center;font-size: 14px;">
												Транзакции отсутствуют
											</p>
										</td>
									</tr>	
								</tbody>
							</table>
						</div>
					</div>
					
				</div>
				<div class="bonus-history__footer">
					<button v-on:click="show = true" type="button" class="btn js-open-modal">Вся история</button>
				</div>
			</div>
		</section>

		<!--div class="bonus-exchange">
			<div class="bonus-exchange__label">
				<svg class="svg-icon" width="25" height="25">
					<use xlink:href="/images/sprite.svg#sb_cashback"></use>
				</svg>
				<span>Обменивайте бонусы на ценные подарки в виртуальной витрине</span>
			</div>
			<form action="" class="bonus-exchange__form">
				<div class="control_text">
					<input type="text" name="" required placeholder="Укажите сумму списания...">
				</div>
				<button class="btn" type="submit">Списать бонусы</button>
			</form>
		</div-->
		
		<div class="bonus-program">
			<div class="bonus-program__header">
				<h2 class="bonus-program__title">О программе “Feron-бонус”</h2>
				<router-link class="btn btn_transp" :to="'/bonus/condition/'">Правила участия в программе </router-link>
			</div>
			<div v-bind:class="{'is-open' : showMore}" class="cutter js-cutter">
				<section class="sect-bonus-program" v-html="content"></section>
				<!--div class="bonus-program__footer">
					<button class="cutter__trigger js-cutter-trigger" @click="showMore = !showMore" data-open="Читать всё" data-close="Свернуть"> 
						<span v-if="showMore">Свернуть</span> 
						<span v-else="">Читать всё</span> 
					</button>
				</div-->
			</div>
		</div>
		
		<div v-bind:class="{'is-open ': show, 'loading': loading}" class="modal modal_bonus-history" id="modal-bonus-history">
			<div class="modal__wrap">
				<div class="modal__inner animated zoomIn">
					<button class="modal__close" v-on:click="to_hide = true"></button>
					<div class="modal__container">
						<div v-bind:class="{ show: loading }" class="component-loading"></div>
						<div class="bonus-history js-tab">
							<div class="bonus-history__header">
								<h2 class="bonus-history__title">История последних кэшбеков</h2>
								<ul class="bonus-history__tabs js-tab-controls">
									<li v-bind:class="{'is-active': activeTab == 'all'}"><a v-on:click.prevent="setAcrtiveTab('all')">Все</a></li>
									<li v-bind:class="{'is-active': activeTab == 'debit'}"><a v-on:click.prevent="setAcrtiveTab('debit')">Начисления</a></li>
									<li v-bind:class="{'is-active': activeTab == 'credit'}"><a v-on:click.prevent="setAcrtiveTab('credit')">Списания</a></li>
								</ul>
							</div>
							<div class="bonus-history__body js-tab-contents">
								<div class="is-active">
									<table class="t-bonus t-bonus_fix">
										<thead>
											<tr>
												<th width="20%">Бонусы</th>
												<th width="20%">Дата</th>
												<th width="20%">№ заказа</th>
												<th>Сумма заказа</th>
												<th width="60">&nbsp;</th>
											</tr>
										</thead>
									</table>
									<div class="resp-table-wrap nice-v-scroll">
										<table class="t-bonus">
											<col style="width:20%">
											<col style="width:20%">
											<col style="width:20%">
											<col>
											<col style="width:60px">
											<thead>
												<tr>
													<th>Бонусы</th>
													<th>Дата</th>
													<th>№ заказа</th>
													<th>Сумма заказа</th>
													<th>&nbsp;</th>
												</tr>
											</thead>
											<tbody v-if="transacts.length > 0">
												<tr v-for="transact in transacts">
													<td v-if="transact.debit"><b class="t_green">+ {{transact.sum}}</b></td>
													<td v-else><b class="t_red">- {{transact.sum}}</b></td>
													<td>{{transact.date}}</td>
													<td>{{transact.order_id}}</td>
													<td>{{transact.order_sum}}</td>
													<td>
														<router-link :to="'/orders/order/' + transact.order_id + '/'" class="detail-btn">
															<svg class="arrow" width="7" height="12">
																<use xlink:href="/images/sprite.svg#caret-right"></use>
															</svg>
														</router-link>
													</td>
												</tr>
											</tbody>
											<tbody v-else>
												<tr>
													<td colspan="5">
														<p style="text-align: center;font-size: 14px;">
															Транзакции отсутствуют
														</p>
													</td>
												</tr>	
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<!--div class="bonus-history__footer">
								<a href="#" class="btn btn_transp js-catalog-load-more" data-total="10" data-current="1">Показать ещё</a>
								<div class="pagination">
									<ul>
										<li class="prev"><a href="">
											<svg role="img" height="13" width="7">
												<use xlink:href="images/sprite.svg#caret-left"></use>
											</svg>
										</a></li>
										<li class="is-active is-loaded"><a href="">1</a></li>
										<li data-page="2" class="is-loaded"><a href="">2</a></li>
										<li><span>⋯</span></li>
										<li data-page="9"><a href="">9</a></li>
										<li data-page="10"><a href="">10</a></li>
										<li class="next"><a href="">
											<svg role="img" height="13" width="7">
												<use xlink:href="images/sprite.svg#caret-right"></use>
											</svg>
										</a></li>
									</ul>
								</div>
							</div-->
						</div>
					</div>

				</div>
			</div>
		</div>
		
	</section>
</template>

<script>
export default {
	data: function () {
		var self = this;
		return {
			content: "",
			slides: [],
			transacts: [],
			showMore: false,
			loading: false,
			show: false,
			to_hide: false,
			activeTab: "all",
			showClass: "zoomIn",
			hideClass: "zoomOut",
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
				{ link: '/bonus/', name: 'Feron-бонус' },
			],
		}
	},
	components: {
		
	},
	computed:{
		
	},
	watch: {
		show: function(){
			var self = this;
			if(this.show) $("#modal-overlay").fadeIn();
			else {
				self.$root.popupListData.filter = false;
				$("#modal-overlay").fadeOut();
			}
		},
		to_hide:function(to_hide){
			var self = this;
			if(to_hide){
				$("#modal-bonus-history .modal__inner").addClass("zoomOut");
				setTimeout(function(){
					self.show = false;
					self.to_hide = false;
					self.$root.popupQuickViewData.ELEMENT_ID = false;
					$("#modal-bonus-history .modal__inner").removeClass("zoomOut");
				}, 400);
			}
		},
	},
	methods: {
		loadContent: function(){
			var self = this;
			$.getJSON("/data/components/bonus.php", {page: ""}, function(json){
				if(json.status) {
					self.content = json.content;
					self.slides = json.slides;
				}
				
				self.$nextTick(function(){
					$('.bonus-slider.owl-carousel').owlCarousel({
						margin: 0,
						nav: true,
						dots: true,
						items: 1
					});
				})
				self.loading = false;
			});
			self.loadTransacts();
		},
		loadTransacts: function(){
			var self = this;
			self.loading = true;
			$.getJSON("/data/components/bonus.php", {act: "transacts", tab: self.activeTab}, function(json){
				if(json.status) {
					self.transacts = json.transacts;
					
				}
				self.loading = false;
			});
		},
		setAcrtiveTab: function(tab){
			var self = this;
			self.activeTab = tab;
			
			self.loadTransacts();
		},
	},
	mounted: function(){
		var self = this;
		self.loading = true;
		self.$root.breadcrumbs = self.breadcrumbs;
		
		self.loadContent();
		self.$root.$on('settingsUpdated', self.loadContent);
	}
};
</script>

<style scoped>
.bonus-slide{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.bonus-program__header {
    justify-content: space-between;
}
</style>