<template>
  <section class="catalog">
    <div class="search-result">
      <p class="search-result__text search-result__text_base">
        Каталог товаров   |   Результаты по запросу
        <span v-show="query.length > 0" class="search-result__text search-result__text_lg search-result__font_600">'{{ query }}'</span>
      </p>

      <div class="search-result__group">
        <p class="search-result__text search-result__text_sm search-result__font_400">
          найдено: <span class="search-result__text search-result__text_base search-result__font_600 search-result__text_blue">{{ NavRecordCount }}</span>
        </p>

        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="search-result__icon"
          @click="resetResult"
        >
          <path d="M1.00195 1L14.0019 13.9999" stroke="#3363C9" stroke-width="2"/>
          <path d="M14.0002 1.00012L1.00023 14" stroke="#3363C9" stroke-width="2"/>
        </svg>
      </div>
    </div>

    <div class="environ environ_hr">
      <div
        :class="{'hidden': profiles.count == 1}"
        class="environ__group"
      >
        <label class="environ__label">Активный договор</label>

        <div class="environ__select">
          <select class="select-profile select-profile-home select" name="">
            <option
              v-for="profile in profiles.list"
              :value="profile.id"
              :class="{ 'active': profile.active }"
              :selected="profile.active"
              v-html="profile.props.ORDER_PROP_75.value"
            ></option>
          </select>
        </div>
      </div>

      <div
        :class="[
          {'hidden': activeProfile.stores.count == 1},
          'environ__group'
        ]"
      >
        <label class="environ__label">Активный склад</label>

        <div class="environ__select">
          <select class="select-store select-store-home select" name="">
            <option
                v-for="store in activeProfile.stores.list"
                :value="store.ID"
                :class="{'active': store.selected}"
                :selected="store.selected"
                v-html="store.TITLE"></option>
          </select>
        </div>
      </div>
    </div>

    <div class="catalog__filter">
      <search-page-filters
        :article="article"
        :model="model"
        :is-list-view-mode="isListViewMode"
        :sort="sort"
        :checkboxes="checkboxes"
        @update:article="article = $event"
        @update:checkbox="onUpdateCheckbox($event)"
        @update:isListViewMode="isListViewMode = $event"
        @update:model="model = $event"
        @update:sort="updateSort($event)"
        @change:query="getData"
      />
    </div>

    <div class="product-list" style="margin-top: 45px;">
      <div class="component-loading"></div>

      <div class="catalog__grid">
        <div
          :class="[
            'product-grid',
            isListViewMode ? 'list-mode' : 'grid-mode'
          ]"
        >
          <list-item-component
            v-for="productItem in results"
            v-bind="productItem"
            :key="'list_product_'+productItem.id"
            :data-page="navigation"
          />
        </div>
      </div>
    </div>

    <div
      v-if="results.length"
      class="b-content__footer"
    >
      <div class="pagination-center">
        <span
            :class="[
              { 'loading': isLoadingMore, 'disabled': !isLastPage },
              'btn btn_transp js-catalog-load-more'
            ]"
            @click="loadMore"
        >
          Показать еще 12
        </span>

        <div
          v-if="navParam.navPages.length"
          class="pagination"
        >
          <ul>
            <li
              v-for="page in navParam.navPages"
              :class="{ 'is-active': page.active, 'disabled': page.disabled }"
              @click="goToPage(page.navPageNum)"
            >
              <span v-html="page.text"></span>
            </li>
          </ul>
        </div>

        <span class="pages-info">Страница {{ navPageNum }} из {{ navPageCount }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import ListItemComponent from '../components/listItemComponent'
import SearchPageFilters from '../components/searchPageFilters'

export default {
  name: 'search',
  components: {
    ListItemComponent,
    SearchPageFilters
  },
  data () {
    return {
      article: '',
      checkboxes: [
        {
          control_id: 'discount',
          control_name: 'discount',
          control_value: 'discount',
          value: 'Скидки',
          disabled: false,
          checked: false
        },
        {
          control_id: 'new_products',
          control_name: 'new_products',
          control_value: 'new_products',
          value: 'Новинки',
          disabled: false,
          checked: false
        },
        {
          control_id: 'in_stock',
          control_name: 'in_stock',
          control_value: 'in_stock',
          value: 'В наличии',
          disabled: false,
          checked: false
        },
        {
          control_id: 'waiting',
          control_name: 'waiting',
          control_value: 'waiting',
          value: 'Ожидаем поступление',
          disabled: false,
          checked: false
        },
        {
          control_id: 'discontinued',
          control_name: 'discontinued',
          control_value: 'discontinued',
          value: 'Снято с производства',
          disabled: false,
          checked: false
        }
      ],
      isListViewMode: true,
      isLoadingMore: false,
      query: '',
      model: '',
      navigation: null,
      results: [],
      sort: {
        by: null,
        order: null
      },

      navPageNum: 1,
      navPageSize: 12,
      navPageCount: 1,
      NavRecordCount: 0,
      navParam: {}
    }
  },
  computed: {
    isLastPage () {
      return parseInt(this.navPageNum) < parseInt(this.navPageCount)
    },
    profiles () {
      return this.$root.arUser.profiles
    },
    activeProfile() {
      if(this.$root.arUser.profile){
        return this.$root.arUser.profiles.active;
      } else {
        return {stores: 0};
      }
    },
    sortParam () {
      return this.sort.by && this.sort.order ? `${this.sort.by}_${this.sort.order}` : ''
    },
    requestOptions () {
      return {
        PAGEN_1: this.navPageNum,
        size: this.navPageSize,
        q: this.query,
        sort: this.sortParam,
        article: this.article,
        model: this.model,
        sale: this.checkboxes[0].checked ? 'y' : '',
        new: this.checkboxes[1].checked ? 'y' : '',
        available: this.checkboxes[2].checked ? 'y' : '',
        wait: this.checkboxes[3].checked ? 'y' : '',
        archive: this.checkboxes[4].checked ? 'y' : ''
      }
    }
  },
  mounted () {
    const url = new URLSearchParams(location.search)

    this.query = url.get('query')
    this.getData()

    var self = this;
    self.$root.breadcrumbs = self.breadcrumbs;

    self.$nextTick(function(){
      $('.select').select2({
        minimumResultsForSearch: -1,
        width: "100%",
        selectionCssClass: "select2-results__option--selected",
      }).on('select2:select', function (e) {
        setTimeout(function(){
          var selectedProfileId = $(".select-profile-home option:selected").attr("value");
          var selectedStoreId = $(".select-store-home option:selected").attr("value");

          self.$root.setActiveProfile(selectedProfileId, selectedStoreId);
          self.loading = true;
        }, 300);
      });
    })
  },
  methods: {
    getData () {
      try {
        this.isLoadingMore = true

        $.getJSON('/data/components/search.php', this.requestOptions, (data) => {
          this.results = data.items
          this.navParam = data.nav
          this.navPageCount = data.nav.NavPageCount
          this.navPageNum = data.nav.NavPageNomer
          this.navPageSize = data.nav.NavPageSize
          this.NavRecordCount = data.nav.NavRecordCount;

          this.$root.catalog.section = data
          this.$root.catalog.activeSections = data.section.active_sections;

          this.isLoadingMore = false
        })
      } catch (error) {
        this.isLoadingMore = false
        console.error(error)
      }
    },
    goToPage (page) {
      this.navPageNum = page
      this.getData()
    },
    loadMore () {
      this.navPageSize += 12;
      this.getData()
    },
    onUpdateCheckbox ({ value, index }) {
      this.$set(this.checkboxes[index], 'checked', value)
      this.getData()
    },
    resetResult () {
      this.results = []
      this.query = ''
      this.getData()
    },
    updateSort (property) {
      const order = this.sort.order === 'asc' ? 'desc' : 'asc'

      this.$set(this.sort, 'by', property)
      this.$set(this.sort, 'order', order)

      this.getData()
    }
  }
}
</script>

<style scoped>
.pagination-center.hidden{display:none;}
.pages-info{
  padding: 10px;
  font-size: 12px;
}
.pagination-center{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.pagination a:focus,
.js-catalog-load-more{
  position:relative;
}
.pagination a:focus:after,
.js-catalog-load-more.loading:after{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/images/preloader.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-color: #fff;
  transition: all 0.5s;
  opacity: 0.5;
}
.pagination .disabled a{
  pointer-events: none;
  background: #f6f8fb;
}

.is-active span {
  background-color: #3363C9;
  color: #fff;
}

.disabled {
  pointer-events: none;

  span {
    pointer-events: none;
    background: #f6f8fb;
  }
}
</style>
