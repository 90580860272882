<template>
	<section class="b-content b-content_order" id="printableArea">
	
		<div v-bind:class="{ show: loading }" class="component-loading"></div>
	
		<div class="b-content__header">
			<h2 class="b-content__title">Заказ {{result.ACCOUNT_NUMBER}} от {{result.DATE_INSERT_FORMATED}} <span v-if="result.CANCELED == 'Y'" class="order-canceled">(Отменен)</span></h2>
			<div class="b-content__header-aside">
				<div class="order-entity">
					<div class="order-entity__name" v-html="result.ORDER_PROPS.COMPANY.VALUE"></div>
					<div class="order-entity__user">Оформил  пользователь: <span v-html="result.ORDER_PROPS.CONTACT_PERSON.VALUE"></span></div>
				</div>
			</div>
		</div>
		<div class="b-content__body">
			<div class="order-detail">
				<div v-if="loaded" class="resp-table-wrap">
					<table class="t-orders">
						<thead>
							<tr>
								<th>№ заказа</th>
								<th>Дата заказа</th>
								<th>Сумма заказа</th>
								<th>Резерв до</th>
								<th width="130">Статус заказа</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td v-html="result.ACCOUNT_NUMBER"></td>
								<td v-html="result.DATE_INSERT_FORMATED"></td>
								<td v-html="result.PRICE_FORMATED"></td>
								<td v-html="result.ORDER_PROPS.srok_rezerva.VALUE">-</td>
								<td><span class="status" v-bind:style="'color:' + result.STATUS.COLOR" v-html="result.STATUS.NAME"></span></td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="order-section">
					<h2 class="order-section__title">Перечень товаров</h2>
					<div v-if="loaded" class="cutter js-cutter" v-bind:class="{'small-basket': result.BASKET.length < 8, 'is-open' : showAllOrder}">
						<div class="cutter__target">
							<div class="resp-table-wrap">
								<table class="product-table">
									<thead>
										<tr>
											<th>№</th>
											<th>Фото</th>
											<th>Арт.</th>
											<th>Наименование</th>
											<th>Склад</th>
											<th>Кол-во</th>
											<th>Цена за ед.</th>
											<th>Стоимость</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in result.BASKET">
											<td>{{item.NUM}}</td>
											<td><div class="item-img" style="width:40px;"><img width="40" class="t-prod-pic" v-bind:src="item.PREVIEW_PICTURE" alt="" /></div></td>
											<td><router-link class="t-prod-art" :to="item.DETAIL_PAGE_URL" v-html="item.ARTICLE"></router-link></td>
											<td v-html="item.NAME"></td>
											<td style="white-space:nowrap;" v-html="item.PROPS.STORE.VALUE">-</td>
											<td><b>{{item.QUANTITY}}{{item.MEASURE_TEXT}}</b></td>
											<td style="white-space:nowrap;"><b v-html="item.PRICE_FORMATED"></b></td>
											<td style="white-space:nowrap;"><b v-html="item.FORMATED_SUM"></b></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<button @click="showAllOrder = !showAllOrder" v-bind:class="{ 'is-open' : showAllOrder }" class="cutter__trigger js-cutter-trigger noPrint" data-open="Показать весь список" data-close="Свернуть">
							<span v-if="showAllOrder">Скрыть</span>
							<span v-else>Показать весь список</span>
						</button>
						<a href="javascript:;" onclick="app.printDiv('printableArea')" class="btn btn_light btn_download noPrint"><span class="btn_text">Бланк заказа</span>
							<svg class="svg-icon" width="13" height="13">
								<use xlink:href="/images/sprite.svg#download"></use>
							</svg>
						</a>
					</div>
				</div>
				
				<div v-if="loaded && result.DEL_BASKET.length > 0" class="order-section" style="background: #eee; border-bottom:0;">
					<h2 class="order-section__title">Отмененные товары</h2>
					<div v-if="loaded">
						<div class="">
							<div class="resp-table-wrap">
								<table class="product-table">
									<thead>
										<tr>
											<th>№</th>
											<th>Фото</th>
											<th>Арт.</th>
											<th>Наименование</th>
											<th>Склад</th>
											<th>Кол-во</th>
											<th>Цена за ед.</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in result.DEL_BASKET">
											<td>{{item.NUM}}</td>
											<td><div class="item-img" style="width:40px;"><img class="t-prod-pic" v-bind:src="item.PREVIEW_PICTURE" alt="" /></div></td>
											<td><router-link class="t-prod-art" :to="item.DETAIL_PAGE_URL" v-html="item.ARTICLE"></router-link></td>
											<td v-html="item.NAME"></td>
											<td v-html="item.PROPS.STORE.VALUE">-</td>
											<td><b>{{item.QUANTITY}}{{item.MEASURE_TEXT}}</b></td>
											<td style="white-space:nowrap;"><b v-html="item.PRICE_FORMATED"></b></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				
				<div class="order-section">
					<h2 class="order-section__title">Оплата</h2>
					<div v-if="loaded" class="bill">
						<div class="bill__sum">
							К оплате: <b v-html="result.PRICE_FORMATED"></b> <nobr>включая НДС</nobr>
						</div>
						
						<div v-if="result.ORDER_PROPS.BB.VALUE < 0" class="bill-bonus">
							<span class="bill-bonus__label">Списано:</span>
							<div class="bill-bonus__value bg_red t_red">
								<b>- {{result.ORDER_PROPS.BB.VALUE}}</b> <small>бонусов</small>
							</div>
						</div>
						<div v-if="result.ORDER_PROPS.BB.VALUE > 0" class="bill-bonus">
							<span class="bill-bonus__label">Начислено:</span>
							<div class="bill-bonus__value bg_green t_green">
								<b>+ {{result.ORDER_PROPS.BB.VALUE}}</b> <small>бонусов</small>
							</div>
						</div>
						
						<div class="noPrint">
						
							<template v-if="shipDateIsSet">
								<div class="btn btn_light disabled">
									<span class="btn_text" v-bind:style="'color:' + result.STATUS.COLOR" v-html="result.STATUS.NAME"></span>
								</div>
							</template>
							<template v-else>
								<div v-if="enableDate && (result.STATUS.ID == 'RE')" v-on:click.prevent="dateModalOpen = true;" class="btn btn_lblue">
									<span class="btn_text">Отправить на сборку</span>
								</div>
							</template>
							
							<!--
							<template>
								<div v-on:click.prevent="dateModalOpen = true;" class="btn btn_lblue">
									<span class="btn_text">Отправить на сборку</span>
								</div>
							</template>
							-->
							
							<div v-if="result.PAYED == 'Y'">
								<div class="btn btn_light disabled">
									<span class="btn_text">Счет оплачен</span>
								</div>
							</div>
							<div v-else-if="result.STATUS.ID == 'F'">
								<div class="btn btn_light disabled">
									<span class="btn_text">Заказ выполнен</span>
								</div>
							</div>
							<div v-else-if="result.STATUS.ID == 'N'">
								<div class="btn btn_light disabled">
									<span class="btn_text">Заказ обрабатывается</span>
								</div>
							</div>
							<a v-else-if="result.IS_ALLOW_PAY == 'Y' && result.PAYMENT" class="btn btn_light btn_bill " v-bind:href="result.PAYMENT.PAY_SYSTEM.PSA_ACTION_FILE + '&pdf=1&DOWNLOAD=Y'" target="_blank">
								<span class="btn_text">Счет на оплату</span>
								<svg class="svg-icon" width="13" height="13">
									<use xlink:href="/images/sprite.svg#download"></use>
								</svg>
							</a>
							<div class="noPrint" v-else>
								<div class="btn btn_light disabled">
									<span class="btn_text">Оплата недоступна</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="loaded" class="order-section order-section_shipment">
					<div class="state noPrint">Статус:  <span v-html="result.SHIPMENT.STATUS_NAME"></span></div>
					<h2 class="order-section__title">Отгрузка</h2>
					<div class="shipment">
						<div class="shipment__grid">
							<div class="shipment__col">
								<label>Способ доставки:</label>
								<div class="x-input" v-html="result.SHIPMENT.DELIVERY_NAME"></div>
							</div>
							<div class="shipment__col">
								<label>Адрес доставки:</label>
								<div class="x-input" v-html="result.ORDER_PROPS.ADDRESS.VALUE"></div>
							</div>
							<div v-if="result.ORDER_PROPS.PL.VALUE" class="shipment__col">
								<label>Дата отгрузки:</label>
								<div class="x-input" v-html="result.ORDER_PROPS.PL.VALUE"></div>
							</div>
						</div>
					</div>

					<div v-if="result.SHIPMENT.TRACKING_NUMBER != null" class="shipment-track">
						<div class="shipment-track__info">Треккод отслеживается на сайте транспортной компании</div>
						<div class="shipment-track__num" id="trackNum" v-html="result.SHIPMENT.TRACKING_NUMBER"></div>
						<div class="shipment-track__copy" @click="$root.copyToClipboard('trackNum')">
							<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M18.9215 11.6407V7.94434C18.9215 7.39205 18.4738 6.94434 17.9215 6.94434H7.99023C7.43795 6.94434 6.99023 7.39205 6.99023 7.94434V17.8121C6.99023 18.3644 7.43795 18.8121 7.99023 18.8121H11.5385" stroke="#3363C9"/>
							<rect x="13.0801" y="13.125" width="11.9308" height="11.9308" rx="1" stroke="#3363C9"/>
							</svg>
						</div>
					</div>

				</div>
				<div v-if="loaded && result.DOCS.length" class="order-section order-section_files noPrint">
					<h2 class="order-section__title">Документы</h2>
					<div class="files">
					
						<a v-for="document in result.DOCS" target="_blank" v-bind:href="document.url" class="file">
							<svg width="21" height="21">
								<use xlink:href="/images/sprite.svg#pdf-file"></use>
							</svg>
							<span>{{document.name}}<br> Файл {{document.size}}</span>
						</a>
						
					</div>
				</div>
				<div class="order-detail__footer noPrint">
					<router-link class="btn btn_back" :to="'/orders/'">
						<svg class="svg-icon" width="7" height="13">
							<use xlink:href="/images/sprite.svg#caret-left"></use>
						</svg>
						<span class="btn_text">Назад</span>
					</router-link>
					<!--button class="btn btn_lblue">
						<svg class="svg-icon" width="20" height="15">
							<use xlink:href="/images/sprite.svg#envelope"></use>
						</svg>
						<span class="btn_text">Написать менеджеру</span>
					</button-->
					<button class="btn btn_lblue" @click="copyOrder">
						<span class="btn_text">Повторить заказ</span>
					</button>
				</div>
			</div>
		</div>
		
		<div class="modal modal_date" id="modal-date" v-bind:class="{'is-open': dateModalOpen}">
			<div class="modal__wrap">
				<div class="modal__inner animated zoomIn">
					<button class="modal__close" v-on:click="to_hide = true"></button>
					<div class="modal__header">
						<h4>Выберите дату отгрузки</h4>
					</div>
					<div class="modal__container select-date__body __row" style="position: relative;" >
						<div class="col__50">
							<div class="__col">
								<h6>Внимание</h6>
								<!--span class="gray_text">Например, “приеду через 2 часа"</span>
								<div class="control_textarea">
									<textarea cols="30" rows="4" name="SHIP_DESCRIPTION" class="order-props"></textarea>
								</div-->
								<span class="subtext">
								Забрать заказ можно через 2,5 рабочих часа после отправки на сборку. Доступные даты самовывоза отмечены в календаре.<br>
								По всем вопросам обращайтесь к менеджеру: <span v-html="$root.arUser.manager.name"></span> - <span v-html="$root.arUser.manager.phone"></span>
								</span>
							</div>
						</div>
						<div class="col__50">
							<div id="shipment_date"></div>
							<input type="hidden" name="shipment_time" id="shipment_time" value="00:00">
						</div>
					</div>
					
					<div class="" style="justify-content: flex-end; text-align: right; padding-top:10px;">
						<!--button type="button" class="btn btn_transp" v-on:click.prevent="to_hide = true;">Отменить</button-->
						<button type="button" class="btn" v-on:click.prevent="setShipDate()">Отправить на сборку</button>
					</div>
				</div>
			</div>
		</div>
		
		<div class="modal modal_date_set " id="modal-date-set">
			<div class="modal__wrap">
				<div class="modal__inner animated zoomIn">
					<button class="modal__close" v-on:click="closeShipInfo()"></button>
					<div class="modal__header" style="text-align: center; ">
						<h4>Заказ успешно отправлен на сборку</h4>
					</div>
					<div style="text-align:center;">
						Дата самомывоза: <span v-html="result.ORDER_PROPS.SHIP_DATE.VALUE"></span><br>
						Время: <span v-html="result.ORDER_PROPS.SHIP_TIME.VALUE"></span><br><br>
						<!--span class="gray_text">Ваш комментарий: “<span v-html="result.ORDER_PROPS.SHIP_COMMENT.VALUE"></span>”</span-->
					</div>
					<div class="" style="justify-content: flex-end; text-align: center; padding-top:10px;">
						<button type="button" class="btn" v-on:click.prevent="closeShipInfo()">Понятно</button>
					</div>
				</div>
			</div>
		</div>
		
	</section>
</template>

<script>
import 'jquery-ui/ui/widgets/datepicker';
export default {
	data: function () {
		var self = this;
		return {
			name: "order_detail",
			order_id: parseInt(self.$route.params.order_id),
			enableDate: self.$root.arUser.select_date,
			loading: true,
			loaded: false,
			to_hide: false,
			dateModalOpen: false,
			showAllOrder: false,
			show: false,
			arHolidays: [],
			result: {
				ORDER_PROPS: {
					COMPANY: {}, 
					PL: {},
					ADDRESS: {},
					CONTACT_PERSON: {},
					SHIP_DATE: {},
					SHIP_TIME: "Самовывоз доступен не ранее, чем через 2,5 рабочих часа. Мы не гарантируем выдачу заказа при приезде на склад раньше. Напоминаем, что получатель заказа должен предоставить действующую доверенность и паспорт (если Генеральный директор - паспорт и печать, если ИП - паспорт и печать при наличии). По всем вопросам обращайтесь к своему персональному менеджеру",
					SHIP_COMMENT: {VALUE: ""},
				},
				ACCOUNT_NUMBER: "",
			},
			breadcrumbs: []
		}
	},
	computed: {
		shipDateIsSet() {
			var self = this;
			return self.result.ORDER_PROPS.SHIP_DATE.VALUE ? true : false;
		},
	},
	watch: {
		to_hide:function(to_hide){
			var self = this;
			if(to_hide){
				$("#modal-date .modal__inner").addClass("zoomOut");
				setTimeout(function(){
					self.dateModalOpen = false;
					self.to_hide = false;
					$("#modal-date .modal__inner").removeClass("zoomOut");
				}, 300);
			}
		},
		dateModalOpen(show) {
			if(show) {
				$("body").addClass('no-scroll');
				$("#modal-overlay").fadeIn();
			}else{
				$("body").removeClass('no-scroll');
				$("#modal-overlay").hide();
			}
		},
	},
	methods: {
		copyOrder: function(){
			var self = this;
			self.loading = true;
			var data = {
				copy_order: "Y",
				ID: self.order_id
			};
			$.getJSON("/data/components/order_detail.php", data, function(json){
				if(json.status) {
					self.$root.arUser.basket = json.basket;
					self.$nextTick(function(){
						self.loaded = true;
						self.$router.push({ 
							path: '/cart/'
						});
					});
				}else{
					alert(json.error);
				}
				self.loading = false;
			});
		},
		closeShipInfo: function(){
			var self = this;
			$(".modal_date_set").removeClass('is-open');
			$("body").removeClass('no-scroll');
			$("#modal-overlay").fadeOut();
		},
		setShipDate: function(){
			var self = this;
			var shipDateStr = "";
			var shipTime = $("#shipment_time").val();
			self.loading = true;
			
			var shipDate = new Date($( "#shipment_date" ).datepicker( "getDate" ));
			//shipDateStr = ("0" + shipDate.getDate()).slice(-2) + "." + ("0" + shipDate.getMonth()).slice(-2) + "." + shipDate.getFullYear();
			shipDateStr = $( "#shipment_date" )[0].value;
			
			var data = {
				set_ship_date: "Y",
				ID: self.order_id,
				date: shipDateStr,
				shipTime: shipTime,
				comment: $("textarea[name=SHIP_DESCRIPTION]").val()
			};
			self.to_hide = true;
			
			$.getJSON("/data/components/order_detail.php", data, function(json){
				if(json.status) {
					self.$nextTick(function(){
						self.result.ORDER_PROPS.SHIP_DATE.VALUE = shipDateStr;
						self.result.ORDER_PROPS.SHIP_COMMENT.VALUE = $("textarea[name=SHIP_DESCRIPTION]").val();
						
						setTimeout(function(){
							$("#modal-overlay").stop().fadeIn();
							$(".modal_date_set").addClass('is-open');
						}, 500);
						
						self.loadOrder();
					});
				}else{
					alert(json.error);
				}
				self.loading = false;
			});
		},
		loadOrder: function(){
			var self = this;
			$.datepicker.regional['ru'] = {
				closeText: 'Закрыть',
				prevText: 'Предыдущий',
				nextText: 'Следующий',
				currentText: 'Сегодня',
				monthNames: ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
				monthNamesShort: ['Янв','Фев','Мар','Апр','Май','Июн','Июл','Авг','Сен','Окт','Ноя','Дек'],
				dayNames: ['воскресенье','понедельник','вторник','среда','четверг','пятница','суббота'],
				dayNamesShort: ['вск','пнд','втр','срд','чтв','птн','сбт'],
				dayNamesMin: ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
				weekHeader: 'Не',
				dateFormat: 'dd.mm.yy',
				firstDay: 1,
				isRTL: false,
				showMonthAfterYear: false,
				yearSuffix: ''
			};
			$.datepicker.setDefaults( $.datepicker.regional[ "ru" ] );
			$.datepicker.formatDate( "dd.mm.yy", new Date(), {
				dayNamesShort: $.datepicker.regional[ "ru" ].dayNamesShort,
				dayNames: $.datepicker.regional[ "ru" ].dayNames,
				monthNamesShort: $.datepicker.regional[ "ru" ].monthNamesShort,
				monthNames: $.datepicker.regional[ "ru" ].monthNames
			});
			
			var urlData = {order_id: self.order_id};
			
			$.getJSON("/data/components/order_detail.php", urlData, function(json){
				if(json.status) {
					self.result = json.result;
					self.arHolidays = self.result.arHolidays;
					
					if(self.result.enableDate == false) self.enableDate = false;
					var arHolidays = self.result.arHolidays;
					
					self.$nextTick(function(){
						var ORDER_ID = parseInt(json.result.ID);
						$.getJSON("/data/components/order_detail.php", {read_order: ORDER_ID}, function(json){
							
						})
						
						self.loaded = true;
						self.breadcrumbs = [
							{ link: '/', name: 'Главная' },
							{ link: '/orders/', name: 'Мои заказы' },
							{ link: '/orders/order/' + self.$route.params.order_id + "/", name: 'Заказ #' + self.result.ACCOUNT_NUMBER },
						];
						
						self.$root.breadcrumbs = self.breadcrumbs;
						
						var now = new Date();
						var today = new Date();
						var enabledDays = 1;
						var hours = now.getHours();
						var minutes = now.getMinutes();
						
						while(enabledDays < 3){
							today.setDate(today.getDate() + 1);
							
							if(today.getDay() != 0 && today.getDay() != 6){
								var strDate = ("0" + today.getDate()).slice(-2) + "." + ("0" + (today.getMonth() + 1)).slice(-2);
								if(!self.in_array(strDate, arHolidays)){
									enabledDays++;
								}
							}
						}
						
						var plus = 0;
						if(now.getDay() == 6) plus = 2;
						else if(now.getDay() == 0) plus = 1;
						 
						var minDate = (hours <= 13 && minutes <= 30) ? new Date(now.getFullYear(), now.getMonth(), now.getDate() + plus) : new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
						
						if(minDate.getDay() == 6) minDate.setDate(minDate.getDate() + 2);
						if(minDate.getDay() == 0) minDate.setDate(minDate.getDate() + 1);
						
						$("#shipment_date").datepicker({
							minDate: minDate,
							maxDate: today,
							onSelect: function(dateText, instObj){
								var arSelectedDate = dateText.split(".");
								var shipTime = "8:30";
								var today = new Date();
								var curDay = today.getDate();
								var hours = today.getHours();
								var mins = today.getMinutes();
								
								if((hours < 13 || (hours == 13 && mins < 31)) && (hours > 8 || hours == 8 && minutes > 30)) {
									today.setTime(today.getTime() + (2.5*60*60*1000));
									shipTime = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2);
								}else if((hours > 13 || (hours == 13 && mins > 30)) && hours < 16){
									shipTime = "8:30";
								}else{
									shipTime = "10:30";
								}
								
								if((hours < 13 || (hours == 13 && mins < 31)) && (hours > 8 || hours == 8 && minutes > 30) && parseInt(arSelectedDate[0]) > curDay) {
									shipTime = "8:30";
								}
								if(hours > 15 && (hours > 8 || hours == 8 && minutes > 30) && parseInt(arSelectedDate[0]) > curDay) {
									shipTime = "10:00";
								}
								
								$("#shipment_time").val(shipTime);
								self.result.ORDER_PROPS.SHIP_TIME.VALUE = shipTime;
							},
							onUpdateDatepicker: function(instObj){
							
								var shipTime = "8:30";
								var today = new Date();
								var curDay = today.getDate();
								var hours = today.getHours();
								var mins = today.getMinutes();
							
								if((hours < 13 || (hours == 13 && mins < 31)) && (hours > 8 || hours == 8 && minutes > 30)) {
									today.setTime(today.getTime() + (2.5*60*60*1000));
									shipTime = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2);
								}else if((hours > 13 || (hours == 13 && mins > 30)) && hours < 16){
									shipTime = "8:30";
								}else{
									shipTime = "10:30";
								}
								
								if((hours < 13 || (hours == 13 && mins < 31)) && (hours > 8 || hours == 8 && minutes > 30) && instObj.selectedDay > curDay) {
									shipTime = "8:30";
								}
								if(hours > 15 && (hours > 8 || hours == 8 && minutes > 30) && instObj.selectedDay > curDay) {
									shipTime = "10:00";
								}
								
								$("#shipment_time").val(shipTime);
								self.result.ORDER_PROPS.SHIP_TIME.VALUE = shipTime;
								
								for(var d in arHolidays){
									var holiday = arHolidays[d];
									var arExpDate = holiday.split(".");
									var holidayDay = parseInt(arExpDate[0]);
									var holidayMouth = parseInt(arExpDate[1])-1;
									
									var obHolidayEl = $(".ui-datepicker-calendar td[data-month=" + holidayMouth + "] a[data-date=" + holidayDay + "]");
									if(obHolidayEl.length) obHolidayEl.parent().addClass("ui-datepicker-week-end");
									
								}
							}
						});
						$("#shipment_date").datepicker( "setDate", minDate );
					});
				}
				self.loading = false;
			});
		},
		in_array: function(value, array) {
			for(var i=0; i<array.length; i++){
				if(value == array[i]) return true;
			}
			return false;
		},
	},
	mounted: function(){
		var self = this;
		self.loadOrder();
	},
};
</script>

<style scoped>
#shipment_date{
    width: 100%;
}
#modal-date h6{
	line-height: 30px;
}
.modal_date_set .modal__wrap{
	max-width: 600px;
}
#modal-date .modal__wrap{
	max-width: 600px;
}
.modal_date .modal__header{
	padding: 13px 30px;
    border-radius: 4px 4px 0 0;
	text-align:center;
}
#modal-date textarea {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    height: auto;
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Museo Sans Cyrl 300', sans-serif;
    padding: 12px 12px;
}
.shipment-track__copy{
	cursor:pointer;
	margin-left:20px;
}
.cutter.js-cutter.small-basket .cutter__trigger{visibility: hidden;}
.b-content_order .order-detail .small-basket .cutter__target:after{visibility: hidden;}
.item-img{
	display: flex;
	width:40px;
	height:40px;
}
.b-content_order .order-detail .product-table .item-img img.t-prod-pic{
	width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
	height: auto;
	max-height: 90%;
}
.status{
    display: block;
    line-height: 14px;
}
.order-canceled{
    color: red;
    font-size: 16px;
    padding-left: 10px;
    vertical-align: text-top;
}
</style>