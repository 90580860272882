<template>
	<section class="b-content b-content_ordering">
		<div class="b-content__header">
			<h2 class="b-content__title"><small>Корзина</small><span>Оформление заказа</span></h2>
		</div>
		<div class="b-content__body">
			<div v-bind:class="{ show: loading }" class="component-loading"></div>
			
			<div class="order-result">
				<div v-if="send_error" class="order-result__header order-send_result">
					<div class="send_result-icon">
						<svg class="svg-icon" width="97" height="74">
							<use xlink:href="/images/sprite.svg#send_err"></use>
						</svg>
					</div>
					<span>Упс, не получилось доставить.<br>Проверьте корректность введенных данных</span>
				</div>
				<div v-else-if="send_success" class="order-result__header order-send_result">
					<div class="send_result-icon">
						<svg class="svg-icon" width="97" height="74">
							<use xlink:href="/images/sprite.svg#send_ok"></use>
						</svg>
					</div>
					<span>Заказ №12 успешно отправлен на ваш e-mail<br>{{email}}</span>
				</div>
				<div v-else class="order-result__header">
					<h2>Ваш заказ <a href="">№ {{order_id}}</a> успешно сформирован!</h2>
					<span>Скачайте заказ в Excel или оправьте на e-mail.</span>
				</div>
				<div class="order-result__body">
					<div class="send_form">
						<input type="email" required name="EMAIL" class="email_input" placeholder="Введите e-mail ..."/>
						<a class="send_btn" @click="sendEmailOrder">
							<span class="btn_text">Отправить заказ</span>
							<svg class="svg-icon" width="16" height="14">
								<use xlink:href="/images/sprite.svg#send"></use>
							</svg>
						</a>
					</div>
				</div>
				<div class="order-result__footer">
					<a v-bind:href="'/data/ajax/client_order.php?action=print&ID=' + order_id" target="_blank" class="btn btn_light btn_download">
						<svg class="svg-icon" width="22" height="20">
							<use xlink:href="/images/sprite.svg#excel"></use>
						</svg>
						<span class="btn_text">Скачать заказ в Exсel</span>
					</a>
					<router-link :to="'/orders_client/order/' + order_id + '/'" class="btn">Перейти к заказу</router-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'OrderСщташкьComponent',
	data() {
		var self = this;
		return {
			order_id: parseInt(self.$route.params.order_id),
			email: "",
			loading: true,
			to_hide: false,
			send_error: false,
			send_success: false,
			order_error: false,
			payment: {},
			result: {ORDER: {STATUS:{}}},
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
				{ link: '/cart/', name: 'Корзина' },
				{ link: '/order_client_confirm/' + self.$route.params.order_id + "/", name: 'Заказ оформлен' },
			],
		};
	},
	computed:{
		
	},
	components: {
		
	},
	watch: {
		
	},
	methods:{
		sendEmailOrder: function(){
			var self = this;
			self.email = $(".email_input").val();
			if(!app.checkEmail(self.email)){
				self.send_error = true;
			}else{
				self.loading = true;
				self.send_error = false;
				$.getJSON("/data/ajax/client_order.php?action=send&email=" + self.email + "&ID=" + self.order_id, function(data){
					self.send_success = true;
					self.loading = false;
				});
			}
			
		},
		loadOrderData: function(){
			var self = this;
			var urlData = {
				ORDER_ID: self.order_id
			};
			
			$.getJSON("/data/components/order.php", urlData, function(data){
				self.loading = false;
				self.result = data.result;
				self.order_error = data.order_error;
				self.payment = data.payment[0];
			});
		},
	},
	mounted: function(){
		var self = this;
		self.$root.breadcrumbs = self.breadcrumbs;
		self.loading = false;
		//self.loadOrderData();
	},
};
</script>

<style scoped>
.b-content_ordering .order-result__footer{
	display:flex;
}
.b-content_ordering .component-loading.show{opacity:1;}
.status-descr{
    clear: both;
    display: block;
    color: #3363c9;
    font-size: 12px;
}
.send_result-icon{
	margin-bottom: 12px;
}
.order-result__header{
	margin-bottom: 30px;
}
.order-result__body{
	margin-bottom: 50px;
	width: 467px;
	max-width: 100%;
}
.send_form{
	position:relative;
}
.send_form .email_input{
    width: 100%;
    line-height: 32px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
	padding:0 12px;
	font-weight: 300;
	font-size: 12px;
	letter-spacing: -0.408px;
	color: #C4C4C4;
}
.send_form .send_btn{
	position: absolute;
    right: 1px;
    top: 1px;
	background: #F6F8FB;
	padding: 0 20px;
    line-height: 32px;
	font-weight: 300;
	font-size: 12px;
	letter-spacing: -0.408px;
	color: #52504C;
}
</style>
