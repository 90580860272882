import Vue from 'vue';
import jQuery from 'jquery';
import VueRouter from 'vue-router';
import routes from './routes.js';
import Raphael from 'raphael/raphael.js';
import ymapPlugin from 'vue-yandex-maps';
//import openInEditor from 'launch-editor-middleware';

Vue.use(VueRouter);
Vue.use(ymapPlugin);

const router = new VueRouter({
	routes: routes,
	mode: 'history',
	scrollBehavior (to, from, savedPosition) {
		if(parseInt(to.params.navPageNum) > 1 && parseInt(from.params.navPageNum) != parseInt(to.params.navPageNum)){
			return new Promise(resolve => {
				this.app.$root.$once('triggerScroll', () => {
					var pos = { x: 0, y: 0};
					var scrollEl = $(".page" + to.params.navPageNum);
					if(scrollEl.length) pos = { x: 0, y: (scrollEl.offset().top - 90)};
					resolve(pos);
				})
			})
		}else return { x: 0, y: 0};
	},
});

window.isAuthorized = arUserData.authorized;
window.Raphael = Raphael;

router.beforeEach((to, from, next) => {
	if (!isAuthorized && to.name == 'cpass') next()
	else if (!isAuthorized && to.name !== 'login') next({ path: '/login/' })
	else if((to.name == 'login' || to.name == 'cpass') && isAuthorized && !to.query.logout) next({ name: 'home' })
	else next();
});

window.appComponent = require('./app').default;
Vue.config.productionTip = false;

window.app = new Vue({
	el: '#cabinet-application',
	components: {appComponent},
	template: '<appComponent/>',
	router: router,
	data: function () {
		return {
			arUser: arUserData,
			breadcrumbs: [],
			arSettings: [],
			tweenedPriceNumber: parseFloat(arUserData.basket.price),
			tweenedSelectedPriceNumber: parseFloat(arUserData.basket.selected_price),
			catalog: {activeSections: [], filterData: {}, section: {id: 0}},
			popupListData: {filter: {}, title: "TITLE", emptyMess: ""},
			popupQuickViewData: {ELEMENT_ID: false},
			page: this.$route.name,
			hash: this.$route.hash,
			menu: [],
			ws: {},
			ws_config: {},
			menuOpened: false,
			httpPromise: false,
			isShowSettings: false,
			importExcelModal: false,
			transitionName: 'blur',
			ajaxUrl: "/data/ajax/",
			deletedListItems: {
				favorite: [],
				waiting: [],
				compare: [],
				basket: [],
			},
			listActions: {
				favorite: this.favoriteAction,
				waiting: this.subscribeAction,
				compare: this.compareAction,
				basket: this.basketAction,
				basketRestoreDelayed: this.basketActionRestoreDelayed,
			},
		}
	},
	computed:{
		isCatalog(){ return (this.$route.meta.catalog === true); },
		cartQty(){ return this.arUser.basket.qty_positions; },
		cartSum(){ return this.arUser.basket.price; },
		cartSelectedSum(){ return this.arUser.basket.selected_price; },
		animatedPrice: function() {
			var self = this;
			var formatted = self.number_format(parseFloat(self.tweenedPriceNumber).toFixed(2), 2, ".", " ");
			return formatted;
		},
		animatedSelectedPrice: function() {
			var self = this;
			var formatted = self.number_format(self.tweenedSelectedPriceNumber.toFixed(2), 2, ".", " ");
			return formatted;
		},
		badges(){
			return {
				cart: parseInt(this.arUser.basket.qty_positions),
				fav: parseInt(this.arUser.favorite.count),
				wlist: parseInt(this.arUser.waiting.count),
				comp: parseInt(this.arUser.compare.count),
				notify: parseInt(this.arUser.notifications.count),
				message: parseInt(this.arUser.messages.count),
			}
		},
	},
	created: function () {
		this.page = this.$route.name;
	},
	methods:{
		printDiv: function(htmlId) {
			var self = this;
			var htmlText = $("#printableArea").clone();
			htmlText.find(".noPrint").remove();
			var html = htmlText.html();

			$.post(self.ajaxUrl, {action: "printPdf", html: html}, function(resp){
				window.open("https://cabinet.feron.ru/docs/blank.pdf");
			});

			/*
			var newWin = window.open();
			newWin.document.write($('html').html());

			setTimeout(function(){
				newWin.print();
				newWin.close();
			}, 500);
			*/
		},
		copyToClipboard: function(elId){
			const str = document.getElementById(elId).innerText;
			const el = document.createElement('textarea');
			el.value = str;
			el.setAttribute('readonly', '');
			el.style.position = 'absolute';
			el.style.left = '-9999px';
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);

			alert("Текст скопирован в буфер обмена");
		},
		initWS: function(){
			var self = this;
			if(self.arUser && self.arUser.ws_config.PATH_WS){
				var ws_path = self.arUser.ws_config.PATH_WS.replace("#DOMAIN#", "cabinet.feron.ru");
				self.ws = new WebSocket(ws_path);
				self.ws.onopen = function() {
					console.log("Соединение установлено.");
				};
				self.ws.onclose = function(){
					console.log("Соединение закрыто.");
					setTimeout(function(){
						app.initWS();
					}, 5000);
				};
				self.ws.onmessage = function(event) {
					var dataArray = event.data.replace("#!NGINXNMS!#", "");
					dataArray = dataArray.replace("#!NGINXNME!#", "");

					var text = event.data.match(/#!NGINXNMS!#(.*?)#!NGINXNME!#/gm);
					if (text != null)
					{
						for (var i = 0; i < text.length; i++)
						{
							text[i] = text[i].substring(12, text[i].length-12);
							if (text[i].length <= 0)
								continue;

							var message = self.parseJSON(text[i]);
							var data = null;
							if (message && message.text)
								data = message.text;
						}
					}

					var mess = data;
					//console.log(mess);
					self.$root.$emit('wsCommand', mess);
				};
			}
		},
		parseJSON: function(data){
			var result = null;

			try {
				if (data.indexOf("\n") >= 0)
					eval('result = ' + data);
				else
					result = (new Function("return " + data))();
			} catch(e) {

			}

			return result;
		},
		setActiveProfile: function(selectedProfileId, selectedStoreId){
			var self = this;
			return $.getJSON(self.ajaxUrl, {action: "setActiveProfile", id: selectedProfileId, store_id: selectedStoreId}, function(json){
				if(json.status){
					self.arUser.profiles = json.profiles;
					self.arUser.profile = selectedProfileId;
					self.arUser.store = selectedStoreId;
					window.location.reload();
				}else{
					console.error(json.error);
				}
			});
		},
		userItemsListsBascetAction: function(listType, clear){
			var self = this;
			if(listType && self.arUser[listType] != undefined){
				return $.getJSON(self.ajaxUrl, {action: "setBasketFromFavorite"}, function(json){
					if(json.status){
						self.arUser.basket = json.basket;
						self.$root.$emit('basketUpdated');
						self.$root.$emit('favoriteUpdated');
					}else{
						console.error(json.error);
					}
				});
			}
		},
		userItemsListsClearAction: function(listType){
			var self = this;
			if(listType && self.arUser[listType] != undefined){
				var list = self.arUser[listType],
					listAction = self.listActions[listType];

				if(typeof(listAction) == "function"){
					listAction.apply(self, ["clear"]).then(function(responses){
						self.$root.$emit('userItemsListsUpdated');
					});
				};
			}
		},
		itemInUserList: function(listType, product_id){
			var self = this;
			if(listType && self.arUser[listType] != undefined && parseInt(self.arUser[listType].count) > 0){
				var list = self.arUser[listType];
				if(list != undefined){
					var listItems = list.items,
						productIndex = listItems.findIndex(x => x == product_id);
					if(productIndex >= 0) return true;
					else return false;
				}else return false;
			}
		},
		userItemsListsAction: function(listType, product_id, qty){
			var self = this;
			if(qty == undefined) qty = 1;
			if(listType){
				var list = self.arUser[listType],
					listItems = list.items,
					listAction = self.listActions[listType];

				if(typeof(listAction) == "function"){

					var productIndex = listItems.findIndex(x => x == product_id);
					if(productIndex >= 0 && (listType != "basket" || qty == 0))
						self.deletedListItems[listType].push(product_id);
					else{
						var productDeletedIndex = self.deletedListItems[listType].findIndex(x => x == product_id);
						if(productDeletedIndex >= 0)  self.deletedListItems[listType].splice(productDeletedIndex, 1);
					}

					listAction.apply(self, [product_id, qty])
						.then(function(responses){
							self.$root.$emit('userItemsListsUpdated');
						});
				}
			}
		},
		quickViewAction: function(product_id){
			var self = this;

			self.popupQuickViewData = {
				ELEMENT_ID: product_id
			};
		},
		analogsAction: function(productData){
			var self = this;

			var analogArticles = productData.properties.ANALOGI_NOMENKLATURY.value.split(",");
			self.popupListData = {
				filter: {
					"!ID": productData.id,
					"PROPERTY_CML2_ARTICLE": analogArticles,
					"!=PROPERTY_ARCHIVE": "1",
					IBLOCK_ID: 56
				},
				title: "Аналоги товара Арт. " + productData.article,
				emptyMess: "К сожалению, аналоги найти не удалось..."
			};
		},
		basketAction: function(product_id, qty){
			var self = this;
			if(qty == undefined) qty = 1;
			qty = parseFloat(qty);
			return $.getJSON(self.ajaxUrl, {action: "setBasket", product_id: product_id, qty: qty}, function(json){
				if(json.status){
					self.arUser.basket = json.basket;
					self.$root.$emit('basketUpdated');
				}else{
					console.error(json.error);
				}

			});
		},
		basketActionRestoreDelayed: function(){

			return $.getJSON(self.ajaxUrl, {action: "setBasketRestoreDelayed"}, function(json){
				if(json.status){
					self.arUser.basket = json.basket;
					self.$root.$emit('basketUpdated');
				}else{
					
				}

			});
		},
		favoriteAction: function(product_id){
			var self = this;
			return $.getJSON(self.ajaxUrl, {action: "setFavorite", product_id: product_id}, function(json){
				if(json.status){
					self.arUser.favorite = json.favorite;
					self.$root.$emit('favoriteUpdated');
				}else{
					console.error(json.error);
				}

			})
		},
		subscribeAction: function(product_id){
			var self = this;
			return $.getJSON(self.ajaxUrl, {action: "setSubscribe", product_id: product_id}, function(json){
				if(json.status){
					self.arUser.waiting = json.waiting;
					self.$root.$emit('subscribeUpdated');
				}else{
					console.error(json.error);
				}

			})
		},
		compareAction: function(product_id){
			var self = this;
			return $.getJSON(self.ajaxUrl, {action: "setCompare", product_id: product_id}, function(json){
				if(json.status){
					self.arUser.compare = json.compare;
					self.$root.$emit('compareUpdated');
				}else{
					console.error(json.error);
				}
			})
		},
		toggleMenu: function(){
			var self = this;
			if(self.menuOpened) self.menuOpened = false;
			else self.menuOpened = true;
		},
		showSettings(){
			var self = this;
			if(self.arUser.is_admin){
				if(!self.isShowSettings){
					self.isShowSettings = true;
					setTimeout(function(){
						$.getJSON(self.ajaxUrl + "settings.php", {action: "getSettingsParams"}, function(json){
							if(json.status){
								self.arSettings = json.arSettings;
							}else{
								console.error(json.error);
							}

						})
					}, 700);
				}else self.isShowSettings = false;
			}
		},
		beforeLeave(element) {
			if(!this.isCatalog)
				this.menuOpened = false;
		},
		enter(element) {

		},
		afterEnter(element) {
			var self = this;

			$('.js-tab').each(function () {
				var $controls = $(this).find('.js-tab-controls > li');
				var $contents = $(this).find('.js-tab-contents > *');
				$controls.find('a').on('click', function (e) {
					e.preventDefault();
					$contents.removeClass('is-active').filter($(this).attr('href')).addClass('is-active');
					$controls.removeClass('is-active');
					$(this).parent('li').addClass('is-active');
				}).filter(':first').click();
			});
		},
		number_format( number, decimals, dec_point, thousands_sep ) {
			var i, j, kw, kd, km;
			if( isNaN(decimals = Math.abs(decimals)) ){
				decimals = 2;
			}
			if( dec_point == undefined ){
				dec_point = ",";
			}
			if( thousands_sep == undefined ){
				thousands_sep = ".";
			}

			i = parseInt(number = (+number || 0).toFixed(decimals)) + "";

			if( (j = i.length) > 3 ){
				j = j % 3;
			} else{
				j = 0;
			}

			km = (j ? i.substr(0, j) + thousands_sep : "");
			kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
			kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");

			return km + kw + kd;
		},
		declOfNum: function(n, text_forms) {
			n = Math.abs(n) % 100; var n1 = n % 10;
			if (n > 10 && n < 20) { return text_forms[2]; }
			if (n1 > 1 && n1 < 5) { return text_forms[1]; }
			if (n1 == 1) { return text_forms[0]; }
			return text_forms[2];
		},
		checkEmail: function(email) {
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
		showImagePreview: function(e){
			var self = this;
			var input = e.target;

			if (input.files && input.files[0]) {
				if (input.files[0].type.match('image.*')) {
					var reader = new FileReader();
					reader.onload = function (e) {
						$(input).parent().find("img").attr('src', e.target.result);
					}
					reader.readAsDataURL(input.files[0]);
				} else {
					console.log('ошибка, не изображение');
				}
			} else {
				console.log('хьюстон у нас проблема');
			}
		},
	},
	watch: {
		menuOpened: function () {
			if(this.menuOpened) $('body').addClass('no-scroll');
			else $('body').removeClass('no-scroll');
		},
		hash: function (val) {
			console.log(val);
		},
		$route(to, from) {
			this.page = to.name;
			const toDepth = to.path.split('/').length
			const fromDepth = from.path.split('/').length

			if(toDepth < fromDepth) this.transitionName = "slide-right";
			else if(toDepth > fromDepth) this.transitionName = "slide-left";
			else this.transitionName = "fade";
		},
		cartSum(to, from) {
			gsap.to(this.$data, { duration: 0.5, tweenedPriceNumber: to });
		},
		cartSelectedSum(to, from) {
			gsap.to(this.$data, { duration: 0.5, tweenedSelectedPriceNumber: to });
		},
		badges(to, from) {
			for(var i in to){
				if(to[i] != from[i])  $('.js-' + i + '-badge').animateCss('headShake faster');
			}
			// console.log(to);

		},
	},
	mounted: function () {
		var self = this;
		$('.header-search__trigger').on('click', function (e) {
			$(this).toggleClass('is-open');
			$('.header-search__form').toggleClass('is-open');
			e.preventDefault();
		});

	},
});

$.fn.extend({
    animateCss: function (animationName, callback) {
        var animationEnd = (function (el) {
            var animations = {
                animation: 'animationend',
                OAnimation: 'oAnimationEnd',
                MozAnimation: 'mozAnimationEnd',
                WebkitAnimation: 'webkitAnimationEnd',
            };

            for (var t in animations) {
                if (el.style[t] !== undefined) {
                    return animations[t];
                }
            }
        })(document.createElement('div'));

        this.addClass('animated ' + animationName).one(animationEnd, function () {
            $(this).removeClass('animated ' + animationName);

            if (typeof callback === 'function') callback();
        });

        return this;
    },
});

