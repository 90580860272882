<template>
	<section class="b-content b-content_ordering">
		<div class="b-content__header">
			<h2 class="b-content__title"><small>Корзина</small><span>Оформление заказа</span></h2>
		</div>
		<div class="b-content__body">
			<div v-bind:class="{ show: loading }" class="component-loading"></div>
			<div v-if="!order_error" class="order-result">
				<div v-if="result.ORDER.CANCELED != 'Y'" class="order-result__header">
					<h2>Ваш заказ успешно сформирован!</h2>
					<span>Статус заказа: <b>"{{result.ORDER.STATUS.NAME}}"</b>.</span>
					<span v-if="result.ORDER.STATUS.DESCRIPTION != ''" class="status-descr">{{result.ORDER.STATUS.DESCRIPTION}}</span>
					<br><br>
					<!--span>Скачайте счёт на оплату</span-->
				</div>
				<div v-else class="order-result__header">
					<h2>Ваш заказ <a href="">№ {{order_id}}</a> отменен!</h2>
					<span>Статус заказа: <b>"{{result.ORDER.STATUS.NAME}}"</b>.</span>
				</div>
				<div class="order-result__footer">
					<!--a v-if="result.ORDER.CANCELED != 'Y'" v-bind:href="payment.pdf" target="_blank" class="btn btn_light btn_download"><span class="btn_text">Счет на оплату</span>
						<svg class="svg-icon" width="13" height="13">
							<use xlink:href="/images/sprite.svg#download"></use>
						</svg>
					</a-->
					<router-link :to="'/orders/order/' + order_id + '/'" class="btn">Перейти к заказу</router-link>
				</div>
			</div>
			<div v-else class="order-result">
				<div class="order-result__header">
					<h2>Заказ <a href="">№ {{order_id}}</a> не найден!</h2>
					<span>Заказ был удален или оформлен не Вами.</span>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'OrderСщташкьComponent',
	data() {
		var self = this;
		return {
			order_id: parseInt(self.$route.params.order_id),
			loading: true,
			to_hide: false,
			order_error: false,
			payment: {},
			result: {ORDER: {STATUS:{}}},
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
				{ link: '/cart/', name: 'Корзина' },
				{ link: '/order/' + self.$route.params.order_id + "/", name: 'Заказ оформлен' },
			],
		};
	},
	computed:{
		
	},
	components: {
		
	},
	watch: {
		
	},
	methods:{
		loadOrderData: function(){
			var self = this;
			var urlData = {
				ORDER_ID: self.order_id
			};
			
			$.getJSON("/data/components/order.php", urlData, function(data){
				self.loading = false;
				self.result = data.result;
				self.order_error = data.order_error;
				self.payment = data.payment[0];
			});
		},
	},
	mounted: function(){
		var self = this;
		self.$root.breadcrumbs = self.breadcrumbs;
		
		self.loadOrderData();
	},
};
</script>

<style scoped>
.b-content_ordering .component-loading.show{opacity:1;}
.status-descr{
    clear: both;
    display: block;
    color: #3363c9;
    font-size: 12px;
}
</style>
