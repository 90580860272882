var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "b-content b-content_ordering" },
    [
      _vm.noProfileError
        ? _c("div", { staticClass: "empty-result-container" }, [_vm._m(0)])
        : [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "b-content__body" }, [
              _c("div", {
                staticClass: "component-loading",
                class: { show: _vm.loading }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "order-form" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      { staticClass: "control_group lbl_top person_type" },
                      [
                        _c("label", [_vm._v("Тип плательщика")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control_select" }, [
                          _c(
                            "select",
                            {
                              staticClass: "select js-owner-select order-props",
                              attrs: { name: "PERSON_TYPE" }
                            },
                            _vm._l(_vm.result.PERSON_TYPE, function(type) {
                              return _c("option", {
                                domProps: {
                                  selected: type.CHECKED == "Y",
                                  value: type.ID,
                                  innerHTML: _vm._s(type.NAME)
                                }
                              })
                            }),
                            0
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "control_group lbl_top" }, [
                      _c("label", [_vm._v("Юридическое лицо")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control_select" }, [
                        _c("div", { staticClass: "environ__select" }, [
                          _c(
                            "select",
                            {
                              staticClass:
                                "select-profile select-profile-home select order-props",
                              attrs: { name: "PROFILE_ID" }
                            },
                            _vm._l(_vm.$root.arUser.profiles.list, function(
                              profile
                            ) {
                              return _c("option", {
                                class: { active: profile.active },
                                domProps: {
                                  value: profile.id,
                                  selected: profile.active,
                                  innerHTML: _vm._s(
                                    profile.props.ORDER_PROP_75.value
                                  )
                                }
                              })
                            }),
                            0
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "environ__group hidden" }, [
                          _c("div", { staticClass: "environ__select" }, [
                            _c(
                              "select",
                              {
                                staticClass:
                                  "select-store select-store-home select order-props",
                                attrs: { name: "STORE_ID" }
                              },
                              _vm._l(_vm.activeProfile.stores.list, function(
                                store
                              ) {
                                return _c("option", {
                                  class: { active: store.selected },
                                  domProps: {
                                    value: store.ID,
                                    selected: store.selected,
                                    innerHTML: _vm._s(store.TITLE)
                                  }
                                })
                              }),
                              0
                            )
                          ])
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "control_group lbl_top" },
                      [
                        _c("label", [_vm._v("Способ оплаты")]),
                        _vm._v(" "),
                        _vm._l(_vm.result.PAY_SYSTEM, function(payment) {
                          return _c(
                            "div",
                            { staticClass: "control_radio control_xtext" },
                            [
                              _c("label", [
                                _c("input", {
                                  staticClass: "order-props",
                                  attrs: {
                                    name: "PAY_SYSTEM_ID",
                                    type: "radio"
                                  },
                                  domProps: {
                                    value: payment.ID,
                                    checked: payment.CHECKED == "Y"
                                  }
                                }),
                                _vm._v(" "),
                                _c("i"),
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(payment.NAME) }
                                })
                              ])
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.$root.arUser.loyalty && _vm.balls > 0
                          ? _c("div", { staticClass: "control_checkbox" }, [
                              _c("label", [
                                _c("input", {
                                  staticClass: "js-spend-bonus order-props",
                                  attrs: {
                                    type: "checkbox",
                                    value: "Y",
                                    name: "ORDER_PROP_84",
                                    "data-target": "#bonus-tip"
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.payBalls = !_vm.payBalls
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("i"),
                                _c("span", [_vm._v("Списать Feron-бонусы")])
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.payBalls
                          ? _c(
                              "small",
                              {
                                staticClass: "tip",
                                attrs: { id: "bonus-tip" }
                              },
                              [
                                _vm._v(
                                  "* бонусами можно оплатить до 10% заказа. В счете бонус будет отражен как скидка на товар. Счет со скидкой станет доступен для скачивания после подтверждения заказа менеджером"
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "control_group lbl_top" },
                      [
                        _c("label", [_vm._v("Контактное лицо:")]),
                        _vm._v(" "),
                        _vm._l(_vm.result.ORDER_PROP.properties, function(
                          property
                        ) {
                          return _vm.showContactPersonProperty(property)
                            ? _c(
                                "div",
                                {
                                  staticClass: "control_text",
                                  class: { required: property.REQUIRED == "Y" }
                                },
                                [
                                  _c("input", {
                                    staticClass: "order-props",
                                    class: {
                                      "is-email": property.IS_EMAIL == "Y",
                                      "is-phone": property.IS_PHONE == "Y"
                                    },
                                    attrs: {
                                      type: "text",
                                      name: "ORDER_PROP_" + property.ID,
                                      required: property.REQUIRED == "Y",
                                      placeholder: property.NAME
                                    },
                                    domProps: { value: property.VALUE[0] },
                                    on: { input: _vm.checkInput }
                                  })
                                ]
                              )
                            : _vm._e()
                        }),
                        _vm._v(" "),
                        _c("small", { staticClass: "tip" }, [
                          _vm._v("* Поля, обязательные для заполнения")
                        ])
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "control_group lbl_top" }, [
                      _c("label", [_vm._v("Реквизиты:")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control_xtextarea" }, [
                        _c(
                          "div",
                          [
                            _vm._l(_vm.result.ORDER_PROP.properties, function(
                              property
                            ) {
                              return _vm.showCompanyProperty(property)
                                ? [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(property.NAME) +
                                        ": "
                                    ),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(property.VALUE[0])
                                      }
                                    }),
                                    _c("br")
                                  ]
                                : _vm._e()
                            })
                          ],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control_xtextarea" }, [
                        _c(
                          "div",
                          [
                            _vm._l(_vm.result.ORDER_PROP.properties, function(
                              property
                            ) {
                              return _vm.showBankProperty(property)
                                ? [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(property.NAME) +
                                        ": "
                                    ),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(property.VALUE[0])
                                      }
                                    }),
                                    _c("br")
                                  ]
                                : _vm._e()
                            })
                          ],
                          2
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "control_group lbl_top" }, [
                      _c("label", [_vm._v("Ваш город")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "control_city-select",
                          attrs: { id: "city-select-btn" }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "js-open-modal",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.selectCityModalOpen = true
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                { attrs: { width: "11", height: "15" } },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href": "/images/sprite.svg#balloon"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.locationProp.LOCATION_NAME))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "order-props",
                            attrs: {
                              type: "hidden",
                              name: "ORDER_PROP_" + _vm.locationProp.ID
                            },
                            domProps: { value: _vm.locationProp.VALUE },
                            on: {
                              change: function($event) {
                                return _vm.submitOrder()
                              }
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "control_group lbl_top js-delivery-specify"
                      },
                      [
                        _c("label", [_vm._v("Способ доставки")]),
                        _vm._v(" "),
                        _vm._l(_vm.result.WARNING.DELIVERY, function(mess) {
                          return _vm.result.WARNING.DELIVERY
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "notice notice_warning animated fadeIn"
                                },
                                [
                                  _c("div", { staticClass: "notice__body" }, [
                                    _c("div", {
                                      staticClass: "notice__text",
                                      domProps: { innerHTML: _vm._s(mess) }
                                    })
                                  ])
                                ]
                              )
                            : _vm._e()
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.result.DELIVERY, function(delivery) {
                          return _c("div", { staticClass: "control_radio" }, [
                            _c(
                              "label",
                              {
                                staticClass: "delivery-item",
                                class: { "width-price": delivery.PRICE > 0 }
                              },
                              [
                                _c("input", {
                                  staticClass: "order-props",
                                  attrs: { name: "DELIVERY_ID", type: "radio" },
                                  domProps: {
                                    value: delivery.ID,
                                    checked: delivery.CHECKED == "Y"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.submitOrder()
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("i"),
                                _c("span", {
                                  staticClass: "delivery-name",
                                  domProps: { innerHTML: _vm._s(delivery.NAME) }
                                }),
                                delivery.PRICE > 0
                                  ? _c("abbr", {
                                      staticClass: "delivery-price",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          delivery.PRICE_FORMATED
                                        )
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          ])
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "control_group lbl_top" }, [
                      _c("label", [_vm._v("Комментарий к заказу")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control_textarea" }, [
                        _vm.result.ORDER_DESCRIPTION != false
                          ? _c("textarea", {
                              staticClass: "order-props",
                              attrs: {
                                cols: "30",
                                rows: "2",
                                name: "ORDER_DESCRIPTION"
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.result.ORDER_DESCRIPTION)
                              }
                            })
                          : _c("textarea", {
                              staticClass: "order-props",
                              attrs: {
                                cols: "30",
                                rows: "2",
                                name: "ORDER_DESCRIPTION"
                              }
                            })
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "control_group control_address lbl_top js-address-specify",
                        attrs: { lang: "ru" }
                      },
                      [
                        !_vm.isPickup
                          ? _c(
                              "div",
                              {
                                staticClass: "control_search",
                                attrs: { id: "address_search", lang: "ru" }
                              },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "address_search_select" } },
                                  [_vm._v("Адрес:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "address_search_label",
                                    attrs: { for: "address_search_select" }
                                  },
                                  [
                                    _c(
                                      "select",
                                      {
                                        staticClass: "js-data-ajax order-props",
                                        attrs: {
                                          id: "address_search_select",
                                          tabindex: "-1",
                                          "aria-hidden": "true",
                                          name:
                                            "ORDER_PROP_" + _vm.addressProp.ID
                                        }
                                      },
                                      [
                                        _c("option", {
                                          attrs: {
                                            html: _vm.addressProp.VALUE,
                                            selected: "selected"
                                          },
                                          domProps: {
                                            value: _vm.addressProp.VALUE
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      { attrs: { type: "button" } },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: { width: "17", height: "16" }
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "/images/sprite.svg#search"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.result.ERROR.PROPERTY, function(mess) {
                          return _vm.result.ERROR.PROPERTY
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "notice notice_warning animated fadeIn"
                                },
                                [
                                  _c("div", { staticClass: "notice__body" }, [
                                    _c("div", {
                                      staticClass: "notice__text",
                                      domProps: { innerHTML: _vm._s(mess) }
                                    })
                                  ])
                                ]
                              )
                            : _vm._e()
                        }),
                        _vm._v(" "),
                        _vm.isPickup
                          ? _c("div", { staticClass: "control_text" }, [
                              _c("input", {
                                staticClass: "order-props",
                                attrs: {
                                  type: "text",
                                  name: "ORDER_PROP_" + _vm.addressProp.ID,
                                  required: "",
                                  readonly: "",
                                  placeholder: "Выберите пункт самовывоза"
                                },
                                domProps: { value: _vm.addressProp.VALUE }
                              })
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn_lblue",
                          attrs: { to: "/cart/" }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "svg-icon",
                              attrs: { width: "7", height: "13" }
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href": "/images/sprite.svg#caret-left"
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "btn_text" }, [
                            _vm._v("Назад")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "consent-div" }, [
                      _c(
                        "label",
                        {
                          staticClass: "main-user-consent-request",
                          class: { "has-error": !_vm.consent.checked }
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              value: "Y",
                              name: "consent"
                            },
                            domProps: { checked: _vm.consent.checked }
                          }),
                          _vm._v(" "),
                          _c("a", {
                            staticClass:
                              "main-user-consent-request-announce-link",
                            attrs: { href: "javascript:;" },
                            domProps: { innerHTML: _vm._s(_vm.consent.LABEL) },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.consentModalOpen = true
                              }
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        class: { disabled: !_vm.consent.checked },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submitOrder("save")
                          }
                        }
                      },
                      [_vm._v("Сформировать счёт")]
                    )
                  ])
                ])
              ])
            ])
          ],
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal modal_city-select",
          class: { "is-open": _vm.selectCityModalOpen },
          attrs: { id: "modal-city-select" }
        },
        [
          _c("div", { staticClass: "modal__wrap" }, [
            _c("div", { staticClass: "modal__inner animated zoomIn" }, [
              _c("button", {
                staticClass: "modal__close",
                on: {
                  click: function($event) {
                    _vm.to_hide = true
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "modal__header" }, [
                _c("div", { staticClass: "control_search" }, [
                  _c("input", {
                    attrs: {
                      type: "text",
                      name: "city",
                      autocomplete: "off",
                      placeholder: "Введите название города доставки ... "
                    },
                    on: { input: _vm.searchLocation }
                  }),
                  _vm._v(" "),
                  _c("button", { attrs: { type: "button" } }, [
                    _c("svg", { attrs: { width: "17", height: "16" } }, [
                      _c("use", {
                        attrs: { "xlink:href": "/images/sprite.svg#search" }
                      })
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "modal__container",
                  staticStyle: { position: "relative" }
                },
                [
                  _c("div", {
                    staticClass: "component-loading",
                    class: { show: _vm.loading }
                  }),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "city-list nice-v-scroll " },
                    _vm._l(_vm.popup_locations, function(location) {
                      return _c(
                        "li",
                        {
                          staticClass: "city",
                          attrs: {
                            "data-id": location.ID,
                            "data-code": location.CODE
                          },
                          on: {
                            click: function($event) {
                              return _vm.setLocation(location)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "city__name" }, [
                            _vm._v(_vm._s(location.NAME))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "city__region" }, [
                            _vm._v(_vm._s(location.PARENT))
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal modal_consent",
          class: { "is-open": _vm.consentModalOpen },
          attrs: { id: "modal-consent" }
        },
        [
          _c("div", { staticClass: "modal__wrap" }, [
            _c(
              "div",
              { staticClass: "modal__inner bonus-program animated zoomIn" },
              [
                _c("button", {
                  staticClass: "modal__close",
                  on: {
                    click: function($event) {
                      _vm.to_hide = true
                    }
                  }
                }),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c("div", {
                  staticClass: "modal__container bonus-program__body ",
                  staticStyle: { position: "relative" },
                  domProps: { innerHTML: _vm._s(_vm.consent.CONFIG.text) }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "bonus-program__header",
                    staticStyle: { "justify-content": "flex-end" }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn_transp",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.consentModalOpen = false
                            _vm.consent.checked = false
                          }
                        }
                      },
                      [_vm._v("Отклонить")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.consentModalOpen = false
                            _vm.consent.checked = true
                          }
                        }
                      },
                      [_vm._v("Принять")]
                    )
                  ]
                )
              ]
            )
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-result-content" }, [
      _c("div", { staticClass: "empty-result-text" }, [
        _vm._v("У Вас нет активных Юр. лиц. "),
        _c("br"),
        _vm._v("Обратитесь к Вашему менеджеру.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b-content__header" }, [
      _c("h2", { staticClass: "b-content__title" }, [
        _c("small", [_vm._v("Корзина")]),
        _c("span", [_vm._v("Оформление заказа")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal__header bonus-program__header" }, [
      _c("h4", [_vm._v("Согласие на обработку персональных данных")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }