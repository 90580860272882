<template>
	<div v-bind:class="{'hidden':navPageCount == 1}" class="pagination-center">
		<span @click="showMore" v-bind:class="{ 'loading': showMoreLoading, 'disabled': !showShowMore }" class="btn btn_transp js-catalog-load-more" v-bind:data-next=navParam.nextPage v-bind:data-total=navPageCount v-bind:data-current="navPageNum">{{textShowCnt}}</span>
		<div class="pagination">
			<ul v-if=inited>
				<li v-for="page in navParam.navPages" v-bind:class="{ 'is-active': page.active, 'disabled': page.disabled }">
					<router-link v-bind:to=page.link  v-html=page.text></router-link>
				</li>
			</ul>
		</div>
		<span class="pages-info">Страница {{navPageNum}} из {{navPageCount}}</span>
	</div>
</template>

<script>
export default {
	name: 'paginationComponent',
	props: {

	},
	data() {
		return {
			inited: false,
			showMoreLoading: false,
			parent: this.$parent,
			navParam: {navPages:{}},
			btnText: "Показать ещё",
		};
	},
	computed: {
		navPageNum() {return this.parent.navPageNum || 1},
		navPageCount() {return this.parent.navPageCount},
		showShowMore() { return parseInt(this.navPageNum) < parseInt(this.navPageCount) },
		textShowCnt() {
			var self = this;
			var totalCnt = parseInt(self.navParam.NavRecordCount);
			var nextCount = totalCnt - parseInt(self.navParam.NavPageNomer) * parseInt(self.navParam.NavPageSize);
			if(self.navParam.NavPageSize < nextCount) nextCount = self.navParam.NavPageSize;
			var textShowCnt = self.btnText + " " + nextCount;
			if(parseInt(self.navParam.NavPageNomer) == self.navParam.NavPageCount) var textShowCnt = "Конец";
			return textShowCnt;
		},
	},
	watch: {
		navPageNum: function(num){
			var self = this;

		},
	},
	mounted: function(){
		var self = this;
		self.navParam = self.$parent.navParam;

		self.$parent.$on('sectionLoaded', self.init);
	},
	methods: {
		init: function(){
			var self = this;
			self.navParam = self.parent.navParam;
			self.inited = true;
			self.showMoreLoading = false;

			$(".pagination a").blur();
		},
		showMore: function(e){
			var self = this;
			self.$parent.showMore = true;
			self.showMoreLoading = true;

			self.$router.push(self.navParam.nextPage)
		},
	}
};
</script>

<style scoped>
	.pagination-center.hidden{display:none;}
	.pages-info{
		padding: 10px;
		font-size: 12px;
	}
	.pagination-center{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.pagination a:focus,
	.js-catalog-load-more{
		position:relative;
	}
	.pagination a:focus:after,
	.js-catalog-load-more.loading:after{
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url(/images/preloader.svg);
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 20px 20px;
		background-color: #fff;
		transition: all 0.5s;
		opacity: 0.5;
	}
	.pagination .disabled a{
		pointer-events: none;
		background: #f6f8fb;
	}
</style>
