<template>
	<section class="b-content b-content_messages">
		<div v-if="!emptyResult" class="b-content__header">
			<h2 class="b-content__title">Сообщения</h2>
		</div>
		<div v-if="!emptyResult" class="b-content__top">
			<div class="first-line filter-line">
				<div class="control_toggle-order">
					<label class="js-hidden-toggle">
						<input type="hidden" name="order" data-toggle="asc|desc" value="desc">
						<i></i><span>Дата</span>
					</label>
				</div>
				<div class="control_checkbox">
					<label>
						<input type="checkbox" name="STATUS" value="1" v-on:change="setFilter">
						<i></i><span>Только непрочитанные</span>
					</label>
				</div>
				<div class="control_dropdown js-dropdown" data-mode="multiselect" data-placeholder="По тегам">
					<button class="js-dropdown__trigger"><span>По тегам</span></button>
					<ul class="list">
						<li v-for="tag in tags" class="control_checkbox">
							<label>
								<input type="checkbox" name="TAGS" v-bind:value="tag" v-on:change="setFilter">
								<span>{{tag}}</span> <i></i>
							</label>
						</li>
					</ul>
				</div>
				<div class="control_search">
					<form v-on:submit.prevent="searchMess">
						<input type="text" value="" placeholder="Искать" name="text" id="searchMess">
						<button type="submit">
							<svg width="17" height="16">
								<use xlink:href="/images/sprite.svg#search"></use>
							</svg>
						</button>
					</form>
				</div>
			</div>
		</div>
		<div v-if="!emptyResult" class="b-content__body">
			<div v-bind:class="{ show: loading }" class="component-loading"></div>
		
			<div v-for="group in groups" v-if="group.items.length" v-bind:class="{'is-open': group.opened}" class="messages-group js-msg-group">
				<button class="messages-group__dropdown">
					<span>{{group.title}}</span>
				</button>
				<div class="messages-group__list">
					<div v-for="item in group.items" v-bind:class="{'message_new': item.status == '1'}" class="message">
						<div class="message__header">
							<div v-for="tag in item.tags" v-if="item.tags.length" class="message__tag">{{tag}}</div>
							<router-link :to="item.link" class="message__title" v-html="item.title"></router-link>
							<div class="message__datetime" v-html="item.date_format"></div>
						</div>
						<div class="message__body">
							<div class="message__text" v-html="item.preview"></div>
							<div v-if="item.files" class="message__attach">

							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
		<div v-if="!emptyResult" class="b-content__footer">
			<component-pagination></component-pagination>
		</div>
		
		<div v-if="emptyResult" class="empty-result-container">
			<div class="empty-result-content">
				<div class="empty-result-text" v-html="emptyMess"></div>
				<div class="empty-result-btn">
					<router-link :to="'/catalog/'" class="btn">В каталог</router-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import paginationComponent from '../components/paginationComponent';
export default {
	name: 'MessagesComponent',
	data() {
		var self = this;
		return {
			emptyResult: false,
			loading: true,
			showMore: false,
			emptyMess: "У вас нет сообщений",
			groups: [],
			grouped: [],
			navPageNum: self.$route.params.navPageNum || 1,
			navPageSize: self.$route.params.NavPageSize || 10,
			navPageCount: 1,
			navParam: {},
			filter: {},
			tags: {},
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
				{ link: '/messages/', name: 'Сообщения' },
			]
		};
	},
	computed: {
		messages_count() { return this.messages.length; },
		route_params(){
			return this.$route.params;
		},
	},
	watch: {
		route_params: function (newVal, oldVal) {
			var self = this;
			self.loading = true;
			self.navPageNum = newVal.navPageNum || 1;
			
			if(self.showMore) {
				self.loadMessages();
			}
			else{
				setTimeout(function(){
					self.loadMessages();
				}, 10);
			}
		},
	},
	components: {
		'component-pagination': paginationComponent,
	},
	methods:{
		searchMess: function(){
			var self = this;
			self.filter = {"%TITLE": $("#searchMess").val()};
			self.loadMessages();
		},
		setFilter: function(e){
			var self = this;
			var tags = [];
			
			if($("input[name=STATUS]").prop("checked")) self.filter.STATUS = "1";
			else delete self.filter["STATUS"];
			
			$("input[name=TAGS]:checked").each(function(){
				if($(this).prop("checked")) tags.push($(this).val());
			})
			if(tags.length) self.filter.TAGS = tags;
			else delete self.filter["TAGS"];
			/*
			if(e.target.type == "checkbox") {
				if(e.target.checked) self.filter[e.target.name] = e.target.value;
				else delete self.filter[e.target.name];
			}
			*/
			self.loadMessages();
		},
		checkEmpty: function(){
			var self = this;
			
			if(self.messages_count <= 0) {
				setTimeout(function(){
					self.loading = false;
					self.emptyResult = true;
					self.filter = {};
				}, 500);
			}else{
				setTimeout(function(){
					self.loading = false;
					self.emptyResult = false;
				}, 500);
			}
		},
		loadMessages: function(){
			var self = this;
			var urlData = {
				"filter": self.filter,
				"page": self.navPageNum,
			};
			$.getJSON("/data/components/messages.php", urlData, function(json){
				self.navParam = json.nav;
				self.navPageCount = json.nav.NavPageCount;
				
				var newData = [];
				var newgroupsData = [];
				
				if(!self.showMore){
					for(var i in json.messages) newData.push(json.messages[i]);
					for(var i in json.groups) newgroupsData.push(json.groups[i]);
					self.messages = newData;
					self.groups = newgroupsData;
					self.$root.$emit('triggerScroll');
				}else{
					for(var i in json.groups) newgroupsData.push(json.groups[i]);
					self.groups = self.groups.concat(newgroupsData);
					for(var i in json.messages) newData.push(json.messages[i]);
					self.messages = self.messages.concat(newData);
				}
				
				self.tags = json.tags;
				
				self.$nextTick(function(){
					setTimeout(function(){
						self.loading = false;
					}, 500)
					self.$emit('sectionLoaded', json);
					self.checkEmpty();
					
					$(document).find('.js-dropdown').each(function () {
						var $dropdown = $(this);
						
						$dropdown.find('.js-dropdown__trigger').on('click', function (e) {
							e.preventDefault();
							$dropdown.toggleClass('is-open');
						});
						
						if ($dropdown.is('[data-mode]') && $dropdown.data('mode') == "multiselect") {
							$dropdown.find('.control_checkbox label input[type=checkbox]').each(function () {
								$(this).on('change', function () {
									if ($(this).is('[type=checkbox][data-all]:checked')) {
										$dropdown.find('.control_checkbox label input[type=checkbox]:not([data-all])').prop('checked', false);
									} else {
										$dropdown.find('.control_checkbox label input[type=checkbox][data-all]').prop('checked', false);
									}
									if (
										!$dropdown.find('.control_checkbox label input[type=checkbox]:checked').length &&
										$dropdown.find('.control_checkbox label input[type=checkbox][data-all]').length) {
										$dropdown.find('.control_checkbox label input[type=checkbox][data-all]').prop('checked', true);
									}
									$dropdown.find('.js-dropdown__trigger span').text($.map(
										$dropdown.find('.control_checkbox label input[type=checkbox]:checked ~ span'),
										function (element) {
											return $(element).text()
										})
										.join(', ')
									);
									if ($dropdown.find('.js-dropdown__trigger span').text() == "") {
										$dropdown.find('.js-dropdown__trigger span').text($dropdown.data('placeholder'));
									}

								});
							});
						}
					});
				})
			});
		},
		onWebSocketCommand: function(data){
			var self = this;
			if(data.command == "user_list_action" && data.params.list == "messages"){
				self.loadMessages();
			}
		},
	},
	mounted: function(){
		var self = this;
		self.$root.breadcrumbs = self.breadcrumbs;
		self.loadMessages();
		
		self.$root.$on('messagesUpdated', self.checkEmpty);	
		self.$root.$on('wsCommand', self.onWebSocketCommand);
		//
	},
};
</script>

<style scoped>

</style>
