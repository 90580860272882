var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { position: "relative" } }, [
    _c("div", {
      staticClass: "component-loading",
      class: { show: _vm.loading }
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "large-product-slider owl-carousel",
        class: { inited: _vm.inited, loading: _vm.loading },
        attrs: { id: _vm.slider_id }
      },
      _vm._l(_vm.items, function(productItem) {
        return _c(
          "component-list-item",
          _vm._b(
            {
              key: productItem.id,
              attrs: {
                itemClass: "large-product-slider__item",
                "data-page": _vm.navPageNum
              }
            },
            "component-list-item",
            productItem,
            false
          )
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }