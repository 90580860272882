<template>
	<div class="card card_stats-lines" >
		<div v-bind:class="{ show: loading }" class="component-loading"></div>

		<div class="card__header">
            <h2 class="card__title">{{ title }}</h2>
<!--            <div class="graph-settings-button" @click="showGraphSettingsSidebar">-->
<!--                <img v-if="!graphSettingsSidebarVisible" :src="settingsIcon" alt="Настройки" />-->
<!--                <img v-if="graphSettingsSidebarVisible" :src="settingsIconActive" alt="Настройки" />-->
<!--            </div>-->
        </div>
        <div class="stats-legend">
            <div v-for="item in legendItems" class="legend">
                <i v-bind:style="{ background: item.color }"></i> {{ item.name }}
            </div>
        </div>
		<div class="card__body">
			<div :id="element_id" v-bind:style="'height:' + graph_height + 'px'"></div>
		</div>
	</div>
</template>

<script>
import SettingsGraphSideBar from "./SettingsGraphSideBar";
import ComponentUtils from "../mixins/ComponentUtils";
export default {
	name: 'graphComponentLines_NEW',
    props: {
		graph_id: {type: String, default: 'd3-lines'},
		graph_name: {type: String, default: 'Заголовок'},
		graph_source: {type: String, default: "/data/lines.json"},
		graph_height: {type: Number, default: 300},
	},
    mixins:[ComponentUtils],
	data: function() {
		return {
			title: this.graph_name,
			element_id: this.graph_id,
			dataUrl: "/data/" + this.graph_source,
			colors: ["#3363C9", "#00BB61"],
			svg: false,
			loading: true,
			legendItems: [],
			needUpdate: false,
			graphObject: {},
			month: [
				"Янв",
				"Фев",
				"Март",
				"Апр",
				"Май",
				"Июнь",
				"Июль",
				"Авг",
				"Сен",
				"Окт",
				"Ноя",
				"Дек"
			],
            settingsIcon: require("./../assets/images/settings-button.svg"),
            settingsIconActive: require("./../assets/images/settings-button-active.svg"),
            graphSettingsSidebarVisible: false,
		}
	},
	created: function () {
		this.$root.$on("updateGraphData", this.updateGraphDataLine);
	},
	beforeDestroy: function () {
		this.$root.$off("updateGraphData", this.updateGraphDataLine);
	},
	mounted: function(){
		var self = this;
		self.renderGraph();
	},
	methods:{
		updateGraphDataLine: function(event){
			var self = this;
			if(event.id === self.element_id){
				for(i in event){
					if(self[i] != undefined) self[i] = event[i];
				}
				self.renderGraph();
			}
		},
		renderGraph: function(){
			var self = this;
			self.loading = true;

			$.getJSON(self.dataUrl, function (json, status ) {

				var data = json.data;
				self.legendItems = json.legend;

				self.graphObject = new Morris.Line({
					element: self.element_id,
					data: data,
					lineWidth: 2,
					pointSize: 0,
					xLabels: 'month',
					xkey: 'stamp',
					ykeys: ['a', 'b'],
					smooth: true,
					resize: true,
					xLabelFormat: function (x) {
						var date = new Date(x);
						var mName = self.month[date.getMonth()];
						return mName;
					},
					yLabelFormat: function (y) {
						if(parseInt(y) <= 0)  return 0 + ' руб.';
						else if(parseInt(y) > 1000) return (parseInt(y)/1000).toFixed(2) + ' млн.';
						else return y.toString() + ' тыс.';
					},
					labels: [self.legendItems[0].name, self.legendItems[1].name],
					lineColors: [self.legendItems[0].color, self.legendItems[1].color],
					fillOpacity: 0.1,
					continuousLine: true,
					hoverCallback: function (index, options, content, row) {
						var cYear = new Date(row.stamp).getFullYear();
						var mName = self.month[new Date(row.stamp).getMonth()];
						var nYear = cYear + 1;

						var html = 	"<div class='morris-hover-row-label'></div><div class='morris-hover-point' style='color:" + self.legendItems[0].color + ";'>" + mName + ' ' + cYear + ': ';
							html += options.yLabelFormat(parseFloat(row.a).toFixed(2));
							html += '</div>';
							html += '<div class="morris-hover-row-label"></div><div class="morris-hover-point" style="color:' + self.legendItems[1].color + ';">' + mName + ' ' + nYear + ': ';
							html += options.yLabelFormat(parseFloat(row.b).toFixed(2));
							html += '</div>';

						return html;
					}
				});

				setTimeout(function(){
					self.loading = false;
				}, 500);
			})
		},

        showGraphSettingsSidebar() {
            let componentHome = this.getParentComponentByName("HomeComponent");
            if (componentHome) {
                console.log('this.graphSettingsSidebarVisible', this.graphSettingsSidebarVisible);
                this.graphSettingsSidebarVisible = !this.graphSettingsSidebarVisible;
                componentHome.$emit("show-hide-settings-sidebar");

            }
        },
	},
};
</script>
<style >
#d3-lines svg:not(:root) {
	overflow: visible;
}
#d3-lines .tick line{
	opacity: 0.4;
	transform: translateY(5px);
}
.card.card_stats-lines{ padding-bottom: 15px;}
.graph-settings-button {
    margin-left: auto;
    margin-top: -10px;
    cursor: pointer;
}
</style>
