var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "b-content b-content_messages" }, [
    !_vm.emptyResult
      ? _c("div", { staticClass: "b-content__header" }, [
          _c("h2", { staticClass: "b-content__title" }, [_vm._v("Сообщения")])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.emptyResult
      ? _c("div", { staticClass: "b-content__top" }, [
          _c("div", { staticClass: "first-line filter-line" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "control_checkbox" }, [
              _c("label", [
                _c("input", {
                  attrs: { type: "checkbox", name: "STATUS", value: "1" },
                  on: { change: _vm.setFilter }
                }),
                _vm._v(" "),
                _c("i"),
                _c("span", [_vm._v("Только непрочитанные")])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "control_dropdown js-dropdown",
                attrs: {
                  "data-mode": "multiselect",
                  "data-placeholder": "По тегам"
                }
              },
              [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list" },
                  _vm._l(_vm.tags, function(tag) {
                    return _c("li", { staticClass: "control_checkbox" }, [
                      _c("label", [
                        _c("input", {
                          attrs: { type: "checkbox", name: "TAGS" },
                          domProps: { value: tag },
                          on: { change: _vm.setFilter }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(tag))]),
                        _vm._v(" "),
                        _c("i")
                      ])
                    ])
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "control_search" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.searchMess.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("input", {
                    attrs: {
                      type: "text",
                      value: "",
                      placeholder: "Искать",
                      name: "text",
                      id: "searchMess"
                    }
                  }),
                  _vm._v(" "),
                  _c("button", { attrs: { type: "submit" } }, [
                    _c("svg", { attrs: { width: "17", height: "16" } }, [
                      _c("use", {
                        attrs: { "xlink:href": "/images/sprite.svg#search" }
                      })
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.emptyResult
      ? _c(
          "div",
          { staticClass: "b-content__body" },
          [
            _c("div", {
              staticClass: "component-loading",
              class: { show: _vm.loading }
            }),
            _vm._v(" "),
            _vm._l(_vm.groups, function(group) {
              return group.items.length
                ? _c(
                    "div",
                    {
                      staticClass: "messages-group js-msg-group",
                      class: { "is-open": group.opened }
                    },
                    [
                      _c(
                        "button",
                        { staticClass: "messages-group__dropdown" },
                        [_c("span", [_vm._v(_vm._s(group.title))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "messages-group__list" },
                        _vm._l(group.items, function(item) {
                          return _c(
                            "div",
                            {
                              staticClass: "message",
                              class: { message_new: item.status == "1" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "message__header" },
                                [
                                  _vm._l(item.tags, function(tag) {
                                    return item.tags.length
                                      ? _c(
                                          "div",
                                          { staticClass: "message__tag" },
                                          [_vm._v(_vm._s(tag))]
                                        )
                                      : _vm._e()
                                  }),
                                  _vm._v(" "),
                                  _c("router-link", {
                                    staticClass: "message__title",
                                    attrs: { to: item.link },
                                    domProps: { innerHTML: _vm._s(item.title) }
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "message__datetime",
                                    domProps: {
                                      innerHTML: _vm._s(item.date_format)
                                    }
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "message__body" }, [
                                _c("div", {
                                  staticClass: "message__text",
                                  domProps: { innerHTML: _vm._s(item.preview) }
                                }),
                                _vm._v(" "),
                                item.files
                                  ? _c("div", {
                                      staticClass: "message__attach"
                                    })
                                  : _vm._e()
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e()
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.emptyResult
      ? _c(
          "div",
          { staticClass: "b-content__footer" },
          [_c("component-pagination")],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.emptyResult
      ? _c("div", { staticClass: "empty-result-container" }, [
          _c("div", { staticClass: "empty-result-content" }, [
            _c("div", {
              staticClass: "empty-result-text",
              domProps: { innerHTML: _vm._s(_vm.emptyMess) }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "empty-result-btn" },
              [
                _c(
                  "router-link",
                  { staticClass: "btn", attrs: { to: "/catalog/" } },
                  [_vm._v("В каталог")]
                )
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "control_toggle-order" }, [
      _c("label", { staticClass: "js-hidden-toggle" }, [
        _c("input", {
          attrs: {
            type: "hidden",
            name: "order",
            "data-toggle": "asc|desc",
            value: "desc"
          }
        }),
        _vm._v(" "),
        _c("i"),
        _c("span", [_vm._v("Дата")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "js-dropdown__trigger" }, [
      _c("span", [_vm._v("По тегам")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }