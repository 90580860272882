var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "message-one" }, [
    _c("div", { staticClass: "message-one__header" }, [
      _c(
        "div",
        {
          staticClass: "btn",
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          }
        },
        [
          _c(
            "svg",
            { staticClass: "svg-icon", attrs: { width: "7", height: "13" } },
            [
              _c("use", {
                attrs: { "xlink:href": "/images/sprite.svg#caret-left" }
              })
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "btn_text" }, [_vm._v("Назад")])
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn_transp",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.downloadZip.apply(null, arguments)
            }
          }
        },
        [
          _c("span", { staticClass: "btn_text" }, [_vm._v("Скачать вложения")]),
          _vm._v(" "),
          _c(
            "svg",
            { staticClass: "svg-icon", attrs: { width: "13", height: "13" } },
            [
              _c("use", {
                attrs: { "xlink:href": "/images/sprite.svg#download" }
              })
            ]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "message-box" },
      [
        _c("div", {
          staticClass: "component-loading",
          class: { show: _vm.loading }
        }),
        _vm._v(" "),
        _vm.loaded
          ? _c(
              "div",
              { staticClass: "message-box__header" },
              [
                _vm._l(_vm.message.tags, function(tag) {
                  return _vm.message.tags.length
                    ? _c("div", { staticClass: "message-box__tag" }, [
                        _vm._v(_vm._s(tag))
                      ])
                    : _vm._e()
                }),
                _vm._v(" "),
                _c("div", { staticClass: "message-box__title" }, [
                  _vm._v("Тема: "),
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.message.title) }
                  })
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "message-box__datetime",
                  domProps: { innerHTML: _vm._s(_vm.message.date_format) }
                })
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.loaded
          ? _c(
              "div",
              { staticClass: "message-box__attach" },
              [
                _vm.message.files
                  ? _vm._l(_vm.message.files, function(file) {
                      return _c("div", { staticClass: "file" }, [
                        _c("div", { staticClass: "file__name" }, [
                          _c("svg", { attrs: { width: "13", height: "17" } }, [
                            _c("use", {
                              attrs: { "xlink:href": "/images/sprite.svg#file" }
                            })
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(file.ORIGINAL_NAME))])
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn file__download",
                            attrs: {
                              href: file.SRC,
                              target: "_blank",
                              download: ""
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "svg-icon",
                                attrs: { width: "13", height: "13" }
                              },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href": "/images/sprite.svg#download"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    })
                  : _vm._e()
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.loaded
          ? _c("div", {
              staticClass: "message-box__body",
              domProps: { innerHTML: _vm._s(_vm.message.text) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.loaded
          ? [
              _vm.message.element
                ? _c("div", { staticClass: "message-box__meta" }, [
                    _vm._v(
                      "\n\t\t\t\tОт: " + _vm._s(_vm.message.element.from_email)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n\t\t\t\tДата: " + _vm._s(_vm.message.date_format)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n\t\t\t\tТема: " +
                        _vm._s(_vm.message.title) +
                        "\n\t\t\t"
                    )
                  ])
                : _vm._e()
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "message-one__footer" }, [
      _c(
        "div",
        {
          staticClass: "btn btn_lblue",
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          }
        },
        [
          _c(
            "svg",
            { staticClass: "svg-icon", attrs: { width: "7", height: "13" } },
            [
              _c("use", {
                attrs: { "xlink:href": "/images/sprite.svg#caret-left" }
              })
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "btn_text" }, [_vm._v("Назад")])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }