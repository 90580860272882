<template>
  <section class="profile">
    <div class="profile__header">
      <h2 class="profile__title">Мой профиль</h2>

      <div class="environ">
        <div
          v-bind:class="{ hidden: $root.arUser.profiles.count == 1 }"
          class="environ__group"
        >
          <label class="environ__label">Активный договор</label>
          <div class="environ__select environ__select--order">
            <select class="select-profile select-profile-home select" name="">
              <option
                v-for="profile in $root.arUser.profiles.list"
                v-bind:value="profile.id"
                v-bind:class="{ active: profile.active }"
                v-bind:selected="profile.active"
                v-html="profile.props.ORDER_PROP_75.value"
              ></option>
            </select>
          </div>
        </div>
        <div
          v-bind:class="{ hidden: activeProfile.stores.count == 1 }"
          class="environ__group"
        >
          <label class="environ__label">Активный склад</label>
          <div class="environ__select">
            <select class="select-store select-store-home select" name="">
              <option
                v-for="store in activeProfile.stores.list"
                v-bind:value="store.ID"
                v-bind:class="{ active: store.selected }"
                v-bind:selected="store.selected"
                v-html="store.TITLE"
              ></option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="profile__grid">
      <div v-bind:class="{ show: loading }" class="component-loading"></div>
      <div class="profile__col">
        <div class="profile-widget">
          <div class="profile-widget__header">Данные профиля:</div>
          <div class="profile-widget__content">
            <div class="user-pic">
              <label>
                <input
                  type="file"
                  name="user_avatar"
                  id="user_avatar"
                  v-on:change="setUserAvatar"
                />
                <svg class="svg-icon" width="25" height="22">
                  <use xlink:href="/images/sprite.svg#photo"></use>
                </svg>
              </label>
              <img v-bind:src="$root.arUser.avatar" alt="" />
            </div>
            <div class="user-id">ID пользователя: {{ $root.arUser.id }}</div>
            <div class="user-manager">
              Ваш менеджер:<br />
              <b>{{ $root.arUser.manager.name }}</b
              ><br />
              <a v-bind:href="'mailto:' + $root.arUser.manager.email">{{
                $root.arUser.manager.email
              }}</a
              ><br />
              <a v-bind:href="'tel:' + $root.arUser.manager.phone">{{
                $root.arUser.manager.phone
              }}</a>
            </div>
            <div class="user-buttons">
              <a
                href="#modal-pass-change"
                class="btn btn_lblue js-open-modal"
                @click="showPassChange = true"
                >Сменить пароль</a
              >
              <router-link to="/login/?logout=yes" class="btn">
                <svg class="svg-icon" width="14" height="14">
                  <use xlink:href="/images/sprite.svg#sb-exit"></use>
                </svg>
                <span class="btn_text">Выйти</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="profile__col">
        <div class="profile-widget">
          <div class="profile-widget__header">Личные данные:</div>
          <div class="profile-widget__content">
            <form class="person-form" action="">
              <input type="hidden" name="action" value="setUserFields" />
              <div class="control_text">
                <label>Фамилия, имя</label>
                <input
                  type="text"
                  name="FIELDS[NAME]"
                  v-bind:value="$root.arUser.name"
                />
              </div>
              <div class="control_text">
                <label>E-mail</label>
                <div class="tip">Сменить возможно по запросу менеджеру</div>
                <input
                  type="text"
                  name="email"
                  v-bind:value="$root.arUser.email"
                  readonly
                />
              </div>
              <div class="control_text">
                <label>Контактный телефон</label>
                <input
                  type="text"
                  name="FIELDS[PERSONAL_PHONE]"
                  v-bind:value="$root.arUser.phone"
                />
              </div>
              <div class="control_text">
                <label>Должность</label>
                <input
                  type="text"
                  name="FIELDS[WORK_POSITION]"
                  v-bind:value="$root.arUser.position"
                />
              </div>
              <div class="control_button">
                <button
                  class="btn"
                  v-on:click.prevent="setUserFields"
                  type="submit"
                >
                  Обновить данные
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="profile__col" v-if="!$root.arUser.subclient">
        <div v-if="$root.arUser.profile" class="profile-widget">
          <div class="profile-widget__header">Активное Юр. лицо:</div>
          <div class="profile-widget__content">
            <div class="entity-active">
              <div
                class="entity-active__name"
                v-html="activeProfile.props.ORDER_PROP_75.value"
              ></div>
              <div class="entity-active__data">
                <div>ИНН: {{ activeProfile.props.ORDER_PROP_10.value }}</div>
                <div>КПП: {{ activeProfile.props.ORDER_PROP_11.value }}</div>
                <div>ОКПО: {{ activeProfile.props.ORDER_PROP_21.value }}</div>
                <div>
                  Юр. адрес: {{ activeProfile.props.ORDER_PROP_9.value }}
                </div>
                <br /><br />
                <div>Банк: {{ activeProfile.props.ORDER_PROP_25.value }}</div>
                <div>БИК: {{ activeProfile.props.ORDER_PROP_26.value }}</div>
                <div>К/С: {{ activeProfile.props.ORDER_PROP_27.value }}</div>
                <div>Р/С: {{ activeProfile.props.ORDER_PROP_28.value }}</div>
              </div>
            </div>
            <!--div class="entity-button">
							<a href="" class="btn btn_blue">Договор</a>
						</div-->
          </div>
        </div>
      </div>
    </div>

    <section v-if="$root.arUser.subuser == false || $root.arUser.fields.UF_B2B_ADMIN == 'Y'" class="users">
      <div class="users__header">
        <h3 class="users__title">
          Пользователи <b>{{ subusersCnt + 1 }}</b>
        </h3>
        <div class="users__text" v-html="content"></div>

        <section class="b-content b-content_notes">
          <div class="second-line second-line-tabs">
            <ul class="control_nav">
              <li v-bind:class="{ 'is-active': activTab == 'subuser' }">
                <a v-on:click="activTab = 'subuser'"
                  >Сотрудники
                  <b
                    >(<span>{{ subusersCnt }}</span
                    >)</b
                  ></a
                >
              </li>
              <li v-bind:class="{ 'is-active': activTab == 'subclient' }">
                <a v-on:click="activTab = 'subclient'"
                  >Клиенты
                  <b
                    >(<span>{{ subclientsCnt }}</span
                    >)</b
                  ></a
                >
              </li>
            </ul>

            <div class="users__add-btn">
              <button
                class="btn js-open-modal"
                data-target="#modal-add-partner"
                @click="editSubUser(0)"
              >
                Новый пользователь
              </button>
            </div>
          </div>
        </section>
      </div>

      <div v-if="activTab == 'subuser'" class="users__table">
        <div class="resp-table-wrap">
          <table class="users-table">
            <thead>
              <tr>
                <th>ФИО</th>
                <th>E-mail</th>
                <th>Телефон</th>
                <th width="100">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="$root.arUser.fields.UF_B2B_ADMIN != 'Y'">
                <td>{{ $root.arUser.name }}</td>
                <td>{{ $root.arUser.email }}</td>
                <td>{{ $root.arUser.phone }}</td>
                <td><span class="t_blue">Владелец</span></td>
              </tr>
			  <tr v-if="$root.arUser.fields.UF_B2B_ADMIN == 'Y'">
                <td>{{ $root.arUser.parentName }}</td>
                <td>{{ $root.arUser.parentEmail }}</td>
                <td>{{ $root.arUser.parentPhone }}</td>
                <td><span class="t_blue">Владелец</span></td>
              </tr>
              <tr v-for="subUser in $root.arUser.subusers">
                <td>
                  <span v-if="subUser.ACTIVE == 'Y'" class="user-active"></span>
                  <span v-else class="user-no-active"></span>
                  {{ subUser.NAME }}
                </td>
                <td>{{ subUser.EMAIL }}</td>
                <td>{{ subUser.PERSONAL_PHONE }}</td>
                <td>
					<span v-if="subUser.UF_B2B_ADMIN == 'Y'" class="t_blue">Администратор</span>
                  <button
                    type="button"
                    class="edit-btn js-open-modal"
                    @click="editSubUser(subUser.ID)"
                  >
                    <svg class="arrow" width="12" height="13">
                      <use xlink:href="/images/sprite.svg#pen"></use>
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="users__table subclients">
        <div class="resp-table-wrap">
          <table class="users-table">
            <thead>
              <tr>
                <th>ФИО</th>
                <th>E-mail</th>
                <th>Телефон</th>
                <th width="100">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="subUser in $root.arUser.subclients">
                <td>
                  <span v-if="subUser.ACTIVE == 'Y'" class="user-active"></span>
                  <span v-else class="user-no-active"></span>
                  {{ subUser.NAME }}
                </td>
                <td>{{ subUser.EMAIL }}</td>
                <td>{{ subUser.PERSONAL_PHONE }}</td>
                <td>
                  <button
                    type="button"
                    class="edit-btn js-open-modal"
                    @click="editSubUser(subUser.ID)"
                  >
                    <svg class="arrow" width="12" height="13">
                      <use xlink:href="/images/sprite.svg#pen"></use>
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <div
      v-bind:class="{ 'is-open': showPassChange }"
      class="modal modal_pass-change"
      id="modal-pass-change"
    >
      <div class="modal__wrap">
        <div class="modal__inner animated zoomIn">
          <button class="modal__close" v-on:click="to_hide = true"></button>
          <div class="modal__header">Сменить пароль</div>
          <div class="modal__container">
            <template v-if="passSuccessChange">
              <div
                class="forgot-send-info animated zoomIn"
                style="padding-bottom: 50px; text-align: center"
              >
                <img
                  src="/images/check.svg"
                  style="width: 70px; margin: 20px"
                />
                <div class="login-head-label" style="text-align: center">
                  <span>Пароль успешно изменен<br /><br /></span>
                </div>
                <router-link class="btn" :to="'/login/'">
                  <svg class="svg-icon" width="14" height="14">
                    <use xlink:href="/images/sprite.svg#login-btn"></use>
                  </svg>
                  <button
                    v-on:click="to_hide = true"
                    type="submit"
                    v-on:click.prevent="submitChangePass"
                    class="btn"
                  >
                    Закрыть
                  </button>
                </router-link>
              </div>
            </template>
            <form v-else action="" class="chpass-form">
              <div
                v-bind:class="{ error: errors.password }"
                class="control_group pass_old"
              >
                <label
                  ><span>Старый пароль</span>
                  <a class="remind-pass-link js-remind-pass-link" href=""
                    >Забыли пароль?</a
                  >
                </label>
                <div class="control_password">
                  <input
                    v-if="showPass.password"
                    v-on:input="checkFields"
                    v-bind:value="password"
                    id="password-input"
                    type="text"
                    name="PASSWORD"
                    required=""
                    placeholder="Пароль"
                  />
                  <input
                    v-else
                    id="password-input"
                    v-on:input="checkFields"
                    v-bind:value="password"
                    type="password"
                    name="PASSWORD"
                    required=""
                    placeholder="Пароль"
                  />

                  <button
                    class="pass-toggle"
                    type="button"
                    v-on:click.prevent="showPass.password = !showPass.password"
                  >
                    <svg width="14" height="8">
                      <use xlink:href="/images/sprite.svg#eye"></use>
                    </svg>
                  </button>
                </div>
                <small
                  v-if="errors.password"
                  class="tip control_error_info animated heartBeat"
                  >{{ errorText.password }}</small
                >
                <small class="tip"></small>
              </div>
              <div
                v-bind:class="{ error: errors.new_password }"
                class="control_group pass_new"
              >
                <label><span>Новый пароль</span></label>
                <div class="control_password">
                  <input
                    v-if="showPass.new_password"
                    v-on:input="checkFieldsChPass"
                    v-bind:value="new_password"
                    id="new_password-input"
                    type="text"
                    name="USER_PASSWORD"
                    required=""
                    placeholder="Пароль"
                  />
                  <input
                    v-else
                    id="new_password-input"
                    v-on:input="checkFieldsChPass"
                    v-bind:value="new_password"
                    type="password"
                    name="USER_PASSWORD"
                    required=""
                    placeholder="Пароль"
                  />

                  <button
                    class="pass-toggle"
                    type="button"
                    v-on:click.prevent="
                      showPass.new_password = !showPass.new_password
                    "
                  >
                    <svg width="14" height="8">
                      <use xlink:href="/images/sprite.svg#eye"></use>
                    </svg>
                  </button>
                </div>
                <small
                  v-if="errors.new_password"
                  class="tip control_error_info animated heartBeat"
                  >{{ errorText.new_password }}</small
                >
                <small v-else class="tip"
                  >Новый пароль должен содержать не менее 8 символов, 1
                  заглавную букву и 1 цифру</small
                >
              </div>
              <div
                v-bind:class="{ error: errors.confirm_password }"
                class="control_group pass_repeat"
              >
                <label><span>Повторите пароль</span></label>
                <div class="control_password">
                  <input
                    v-if="showPass.confirm_password"
                    v-on:input="checkFieldsChPass"
                    v-bind:value="confirm_password"
                    id="confirm_password-input"
                    type="text"
                    name="USER_CONFIRM_PASSWORD"
                    required=""
                    placeholder="Подтверждение пароля"
                  />
                  <input
                    v-else
                    v-on:input="checkFieldsChPass"
                    id="confirm_password-input"
                    v-bind:value="confirm_password"
                    type="password"
                    name="USER_CONFIRM_PASSWORD"
                    required=""
                    placeholder="Подтверждение пароля"
                  />

                  <button
                    class="pass-toggle"
                    type="button"
                    v-on:click.prevent="
                      showPass.confirm_password = !showPass.confirm_password
                    "
                  >
                    <svg width="14" height="8">
                      <use xlink:href="/images/sprite.svg#eye"></use>
                    </svg>
                  </button>
                </div>
                <small
                  v-if="errors.confirm_password"
                  class="tip control_error_info animated heartBeat"
                  >{{ errorText.confirm_password }}</small
                >
                <small class="tip"></small>
              </div>
              <span
                v-if="chPasswordError"
                class="auth_error_info animated fadeIn"
                v-html="chPasswordErrorText"
              ></span>
              <div class="control_button">
                <button
                  type="submit"
                  v-on:click.prevent="submitChangePass"
                  class="btn"
                >
                  Обновить пароль
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="$root.arUser.subuser == false || $root.arUser.fields.UF_B2B_ADMIN == 'Y'"
      v-bind:class="{ 'is-open': showUserEdit }"
      class="modal modal_edit-partner"
      id="modal-edit-partner"
    >
      <div class="modal__wrap">
        <div class="modal__inner animated zoomIn">
          <button class="modal__close" v-on:click="to_hide = true"></button>

          <div class="modal__header">Редактирование данных пользователя</div>

          <template v-if="passSuccessChangeSubUser">
            <div
              class="forgot-send-info animated zoomIn"
              style="padding-bottom: 50px; text-align: center"
            >
              <img src="/images/check.svg" style="width: 70px; margin: 20px" />
              <div class="login-head-label" style="text-align: center">
                <span>Пользователь успешно сохранен<br /><br /></span>
              </div>
              <router-link class="btn" :to="'/login/'">
                <svg class="svg-icon" width="14" height="14">
                  <use xlink:href="/images/sprite.svg#login-btn"></use>
                </svg>
                <button
                  v-on:click="to_hide = true"
                  type="submit"
                  v-on:click.prevent="submitChangePass"
                  class="btn"
                >
                  Закрыть
                </button>
              </router-link>
            </div>
          </template>
          <template v-else>
            <div class="modal__container">
              <form action="" id="edit_user_form">
                <div class="user_type_block">
                  <ul class="user_type">
                    <li
                      :class="{ 'is-active': activUserType === 'subuser' }"
                      @click="activUserType = 'subuser'"
                    >
                      Сотрудник
                    </li>

                    <li
                      :class="{ 'is-active': activUserType === 'subclient' }"
                      @click="activUserType = 'subclient'"
                    >
                      Клиент
                    </li>
                  </ul>
                </div>

                <div
                  v-if="activUserType == 'subuser'"
                  class="user_is_admin_block"
                >
                  <span>Администратор*</span>
                  <label class="checkbox-ios">
                    <input
                      v-if="subuser.is_admin"
                      checked
                      type="checkbox"
                      name="is_admin"
                      value="Y"
                    />
                    <input v-else type="checkbox" name="is_admin" value="Y" />
                    <span class="checkbox-ios-switch"></span>
                  </label>
                </div>

                <input
                  type="hidden"
                  name="ID"
                  :value="subuser.id"
                />

                <input
                  type="hidden"
                  name="ACTIVE"
                  :value="subuser.active"
                />

                <input
                  type="hidden"
                  name="GROUP"
                  :value="activUserType"
                />

                <div class="control_group">
                  <label>
                    <span>ФИО*</span>

                    <span
                      v-if="subuser.active === 'Y'"
                      class="partner-active"
                    >
                      Активен
                    </span>

                    <span v-else class="partner-no-active">Деактивирован</span>
                  </label>

                  <div class="control_text">
                    <input
                      v-model="subuser.name"
                      type="text"
                      name="NAME"
                      required
                    />
                  </div>
                </div>

                <div class="control_group">
                  <label>
                    <span>E-mail*</span>
                  </label>

                  <div class="control_text">
                    <input
                      v-model="subuser.email"
                      type="email"
                      name="EMAIL"
                      required
                    />
                  </div>
                </div>

                <div class="control_group">
                  <label>
                    <span>Телефон</span>
                  </label>

                  <div class="control_text">
                    <input
                      v-model="subuser.phone"
                      type="text"
                      name="PERSONAL_PHONE"
                    />
                  </div>
                </div>

                <div v-show="activUserType === 'subclient'" class="control_group">
                  <label>
                    <span>ИНН</span>
                  </label>

                  <div class="control_text">
                    <input
                      type="text"
                      name="UF_CLIENT_INN"
                      :value="subuser.inn"
                    />
                  </div>
                </div>

                <div
                  v-bind:class="{ 'is-active': activUserType == 'subclient' }"
                  class="manager-block"
                >
                  <div class="b-content__top">
                    <div class="modal__header">Персональный менеджер</div>
                    <label class="sm-description"
                      ><span
                        >Назначте менеджера из списка сотрудников</span
                      ></label
                    >
                    <div class="environ__select_manager">
                      <select class="select-manager" name="parent_manager">
                        <option
                          v-for="manager in subuser.managers"
                          v-bind:class="{ active: manager.selected }"
                          v-bind:selected="manager.selected"
                          v-bind:value="manager.ID"
                          v-html="manager.NAME"
                        ></option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="b-content__top">
                  <div class="modal__header">Доступные разделы кабинета</div>
                  <div
                    v-if="menuItem.checked"
                    v-for="menuItem in menuItems"
                    class="control_checkbox"
                    v-bind:class="{ disabled: menuItemsBlocked[menuItem.key] }"
                  >
                    <label v-bind:for="'menuItem_' + menuItem.key">
                      <input
                        v-bind:name="'CATS[' + menuItem.key + ']'"
                        type="hidden"
                        value="N"
                      />
                      <input
                        v-bind:name="'CATS[' + menuItem.key + ']'"
                        v-bind:id="'menuItem_' + menuItem.key"
                        type="checkbox"
                        value="Y"
                        v-bind="{
                          readonly: menuItemsBlocked[menuItem.key],
                          checked: subuser.cats[menuItem.key] != 'N',
                        }"
                      />
                      <i></i><span v-html="menuItem.name"></span>
                    </label>
                  </div>
                </div>

                <div v-if="infoPrices.length" class="b-content__top">
                  <div class="modal__header">Доступные цены</div>
                  <div
					v-if="activUserType == 'subuser'"
                    v-for="(infoPrice, index) in infoPrices"
                    class="control_checkbox"
                  >
                    <label v-bind:for="'infoPrice_' + index">
                      <input
                        v-bind:name="'PRICES[' + infoPrice + ']'"
                        type="hidden"
                        value="N"
                      />
                      <input
                        v-bind:name="'PRICES[' + infoPrice + ']'"
                        v-bind:id="'infoPrice_' + index"
                        type="checkbox"
                        value="Y"
                        v-bind="{
                          checked: subuser.infoPrices[infoPrice] == 'Y',
                        }"
                      />
                      <i></i><span v-html="infoPrice"></span>
                    </label>
                  </div>

				  <div
					v-if="activUserType == 'subclient'"
                    v-for="(infoPrice, index) in infoPrices"
                    class="control_checkbox"
                  >
                    <label v-bind:for="'infoPrice_' + index">
                      <input
                        v-bind:name="'PRICE'"
                        v-bind:id="'infoPrice_' + index"
                        type="checkbox"
                        v-bind:value="infoPrice"
                        v-bind="{
                          checked: subuser.infoPrices[infoPrice] == 'Y',
                        }"
                      />
                      <i></i><span v-html="infoPrice"></span>
                    </label>
                  </div>
				  
				  
					<div class="control_checkbox">
						<input name="PRICES[ИП]" id="infoPrice_9999" type="checkbox" value="Y" checked readonly>
						<i></i><span>РРЦ</span>
					</div>
					<div class="control_checkbox">
						<input name="PRICES[МИЦ (мин. интернет-цена)]" id="infoPrice_999" type="checkbox" value="Y" checked readonly>
						<i></i><span>МИЦ (мин. интернет-цена)</span>
					</div>
					
					
                </div>

                <div
                  class="errors"
                  v-html="subUserError"
                  style="color: red; font-size: 12px"
                ></div>

                <div class="control_buttons">
                  <button
                    v-if="subuser.active == 'Y'"
                    type="submit"
                    v-on:click.prevent="deactivateSubuser"
                    class="btn btn_lblue"
                  >
                    Деактивировать пользователя
                  </button>

                  <button
                    v-else
                    type="submit"
                    class="btn btn_lblue"
                    v-on:click.prevent="activateSubuser"
                  >
                    Активировать пользователя
                  </button>

                  <button
                    type="submit"
                    class="btn btn_blue"
                    v-on:click.prevent="saveSubuser"
                  >
                    Сохранить
                  </button>
                </div>
              </form>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
.manager-block {
  display: none;
}
.manager-block.is-active {
  display: flex;
}
.sm-description {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 18px;
  color: #989898;
  margin-bottom: 6px;
}
.environ__select_manager {
  width: 100%;
}
.user_type_block {
  background: #f6f8fb;
  border-radius: 4px;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 4px;
}
.user_type_block ul {
  display: flex;
  justify-content: stretch;
  width: 100%;
}
.user_type_block ul li {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  display: flex;
  width: 50%;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.user_type_block ul li.is-active {
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.second-line.second-line-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user_is_admin_block {
  margin-top: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.b-content_notes .second-line.second-line-tabs .control_nav {
  width: 50%;
}
</style>

<script>
export default {
  data: function () {
    var self = this;
    return {
      name: "personal",
      activTab: "subuser",
      activUserType: "subuser",
      loading: true,
      show: false,
      to_hide: false,
      showPassChange: false,
      showUserEdit: false,
      passSuccessChange: false,
      passSuccessChangeSubUser: false,
      password: "",
      new_password: "",
      confirm_password: "",
      showPass: {
        password: false,
        new_password: false,
        confirm_password: false,
      },
      errors: { password: false, new_password: false, confirm_password: false },
      errorText: { password: "", new_password: "", confirm_password: "" },
      chPasswordError: false,
      subUserError: "",
      chPasswordErrorText: "",
      profiles: self.$root.arUser.profiles.list,
      subuser: {
        id: 0,
        name: "",
        email: "",
        phone: "",
        active: "Y",
        cats: {},
        infoPrices: {},
        managers: [],
      },
      subusersCnt: Object.keys(self.$root.arUser.subusers).length,
      subclientsCnt: Object.keys(self.$root.arUser.subclients).length,
      infoPrices: self.$root.arUser.infoPrices,
      menuItems: self.$root.arUser.arSettings.menu.items.MENU.VALUES,
      menuItemsBlocked: self.$root.arUser.arMenuBlocked,
      showClass: "zoomIn",
      hideClass: "zoomOut",
      content: "",
      breadcrumbs: [
        { link: "/", name: "Главная" },
        { link: "/personal/", name: "Мой профиль" },
      ],
    };
  },
  computed: {
    activeProfile() {
      var self = this;
      return self.$root.arUser.profiles.active;
    },
  },
  watch: {
    activUserType: function (val1, val2) {
      var self = this;
      self.$nextTick(function () {
        if (!$("#edit_user_form").hasClass("select-inited")) {
          $("#edit_user_form").addClass("select-inited");
          $(".select-manager")
            .select2({
              minimumResultsForSearch: -1,
              width: "100%",
              selectionCssClass: "select2-results__option--selected",
            })
            .on("select2:select", function (e) {});
        }
      });
    },
    showPassChange: function () {
      var self = this;
      if (self.showPassChange) $("#modal-overlay").fadeIn();
      else {
        $("#modal-overlay").fadeOut();
      }
    },
    showUserEdit: function () {
      var self = this;
      if (self.showUserEdit) {
        $("#modal-overlay").fadeIn();
      } else {
        $("#modal-overlay").fadeOut();
      }
    },
    to_hide: function (to_hide) {
      var self = this;
      if (to_hide) {
        $("#modal-pass-change .modal__inner").addClass("zoomOut");
        setTimeout(function () {
          self.showPassChange = false;
          self.to_hide = false;

          $("#modal-pass-change .modal__inner").removeClass("zoomOut");
        }, 400);

        $("#modal-edit-partner .modal__inner").addClass("zoomOut");
        setTimeout(function () {
          self.showUserEdit = false;
          self.to_hide = false;

          $("#modal-edit-partner .modal__inner").removeClass("zoomOut");
        }, 400);

        self.passSuccessChangeSubUser = false;
      }
    },
  },
  methods: {
    deactivateSubuser: function () {
      var self = this;
      var form = $("#edit_user_form");
      form.find("input[name=ACTIVE]").val("N");
      self.subuser.active = "N";
      self.saveSubuser();
    },
    activateSubuser: function () {
      var self = this;
      var form = $("#edit_user_form");
      form.find("input[name=ACTIVE]").val("Y");
      self.subuser.active = "Y";
      self.saveSubuser();
    },
    saveSubuser: function () {
      var self = this;
      var cntErrors = 0;
      var form = $("#edit_user_form");

      form.find("input:required").each(function () {
        if ($(this).val() == "") {
          $(this).parents(".control_group").addClass("error");
          cntErrors++;
        } else {
          $(this).parents(".control_group").removeClass("error");
        }
      });

      if (cntErrors <= 0) {
        self.loading = true;
        $.getJSON(
          self.$root.ajaxUrl + "auth.php?saveSubuser=Y",
          form.serialize(),
          function (json) {
            self.loading = false;
            if (json.status) {
              self.$root.arUser = json.arUser;
              self.subusersCnt = Object.keys(self.$root.arUser.subusers).length;
              self.subclientsCnt = Object.keys(
                self.$root.arUser.subclients
              ).length;

              self.passSuccessChangeSubUser = true;
            } else {
              self.subUserError = json.error;
            }
            self.activUserType = "subuser";
          }
        );
      } else {
        const modal = document.getElementById('modal-edit-partner')
        const { top } = document.querySelector('.control_group.error').getBoundingClientRect()

        modal.scrollTo({
          top,
          behavior: 'smooth'
        })
      }
    },
    editSubUser: function (id) {
      console.log("debug edit");
      var self = this;
      self.showUserEdit = true;
      var managers;
      if (id > 0 && self.$root.arUser.subusers[id] != undefined) {
        var subUser = self.$root.arUser.subusers[id];

        self.activUserType = "subuser";
        self.subuser = {
          id: subUser.ID,
          name: subUser.NAME,
          email: subUser.EMAIL,
          phone: subUser.PERSONAL_PHONE,
          active: subUser.ACTIVE,
          cats: subUser.UF_CATS,
          is_admin: subUser.UF_B2B_ADMIN == "Y",
          infoPrices: subUser.UF_INFO_PRICE_CODE,
          parent_manager: subUser.UF_PARENT_MANAGER,
          inn: subUser.UF_CLIENT_INN,
          managers: [],
        };
      } else if (id > 0 && self.$root.arUser.subclients[id] != undefined) {
        var subUser = self.$root.arUser.subclients[id];
        self.activUserType = "subclient";
        self.subuser = {
          id: subUser.ID,
          name: subUser.NAME,
          email: subUser.EMAIL,
          phone: subUser.PERSONAL_PHONE,
          active: subUser.ACTIVE,
          cats: subUser.UF_CATS,
          is_admin: false,
          infoPrices: subUser.UF_INFO_PRICE_CODE,
          parent_manager: subUser.UF_PARENT_MANAGER,
          inn: subUser.UF_CLIENT_INN,
          managers: [],
        };
        for (var i in self.$root.arUser.subusers) {
          var manager = self.$root.arUser.subusers[i];
          if (subUser.UF_PARENT_MANAGER == manager.ID) manager.selected = true;
          else manager.selected = false;

          self.subuser.managers.push(manager);
        }
      } else {
        self.subuser = {
          id: 0,
          name: "",
          email: "",
          phone: "",
          active: "Y",
          cats: {},
          infoPrices: {},
          managers: [],
        };
        for (var i in self.$root.arUser.subusers) {
          var manager = self.$root.arUser.subusers[i];
          self.subuser.managers.push(manager);
        }
      }
    },
    checkFieldsChPass: function () {
      var self = this;
      self.setFields();
    },
    checkFields: function () {
      var self = this;
      self.setFields();

      if (self.password.length > 0) self.errors.password = false;
      if (self.new_password.length > 0) self.errors.new_password = false;
      if (self.confirm_password.length > 0)
        self.errors.confirm_password = false;
    },
    setFields: function () {
      var self = this,
        passInput = $("#password-input");

      self.password = passInput.val();
      self.new_password = $("#new_password-input").val();
      self.confirm_password = $("#confirm_password-input").val();
    },
    submitChangePass: function () {
      var self = this;
      var errorsCnt = 0;
      self.setFields();

      if (self.password.length < 3) {
        errorsCnt++;
        self.errors.password = true;
        self.errorText.password = "Укажите старый пароль";
      } else self.errors.password = false;

      if (self.new_password.length < 3) {
        errorsCnt++;
        self.errors.new_password = true;
        self.errorText.new_password = "Укажите пароль";
      } else self.errors.new_password = false;

      if (self.confirm_password.length < 3) {
        errorsCnt++;
        self.errors.confirm_password = true;
        self.errorText.confirm_password = "Укажите подтверждение пароля";
      } else self.errors.confirm_password = false;

      if (errorsCnt == 0) {
        self.loading = true;
        var data = $(".chpass-form").serialize();
        $.post(
          self.$root.ajaxUrl + "auth.php?change_pass=Y",
          data,
          function (json) {
            var json = JSON.parse(json);
            self.loading = false;
            if (json.status) {
              self.passSuccessChange = true;
            } else {
              self.chPasswordError = true;
              self.chPasswordErrorText = json.error;
            }
          }
        );
      }
    },
    setUserFields: function () {
      var self = this;
      var data = $(".person-form").serialize();
      self.loading = true;
      $.getJSON(self.$root.ajaxUrl, data, function (json) {
        self.loading = false;
        if (json.status) {
          for (var i in json.fields) self.$root.arUser[i] = json.fields[i];
        }
      });
    },
    setUserAvatar: function () {
      var self = this;
      if (user_avatar.files[0]) {
        var file = user_avatar.files[0];
        var formData = new FormData();
        self.loading = true;
        formData.append("file", file);
        formData.append("action", "setUserAvatar");

        $.ajax({
          type: "POST",
          url: self.$root.ajaxUrl,
          cache: false,
          async: false,
          contentType: false,
          processData: false,
          data: formData,
          dataType: "json",
          success: function (json) {
            if (json.status) {
              self.$root.arUser.avatar = json.avatar;
            } else {
            }
            self.loading = false;
          },
        });
      }
    },
  },
  mounted: function () {

    var self = this;
    self.loading = false;
    self.$root.breadcrumbs = self.breadcrumbs;
    $.getJSON(
      "/data/components/page_content.php",
      { page: "personal" },
      function (json) {
        if (json.status) {
          self.content = json.content;
        }
      }
    );

    self.$nextTick(function () {
      $(".select")
        .select2({
          minimumResultsForSearch: -1,
          width: "100%",
          selectionCssClass: "select2-results__option--selected",
        })
        .on("select2:select", function (e) {
          self.loading = true;
          setTimeout(function () {
            var selectedProfileId = $(
              ".select-profile-home option:selected"
            ).attr("value");
            var selectedStoreId = $(".select-store-home option:selected").attr(
              "value"
            );

            self.$root.setActiveProfile(selectedProfileId, selectedStoreId);
            self.loading = false;
          }, 300);
        });
    });
  },
};
</script>

<style scoped>
.profile {
  width: 100%;
}
.modal_add-partner .control_group .partner-no-active,
.modal_edit-partner .control_group .partner-no-active {
  font-size: 12px;
  line-height: 18px;
  color: red;
}
.user-active {
  background: #00bb61;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline;
  float: left;
  line-height: 20px;
  margin: 4px 20px 0 0;
}
.user-no-active {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline;
  float: left;
  line-height: 20px;
  margin: 4px 20px 0 0;
  background: red;
}
.b-content__top {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.b-content__top .modal__header {
  width: 100%;
  text-align: left;
}
.b-content__top .control_checkbox {
  width: 50%;
  float: left;
}
.b-content__top .control_checkbox.disabled {
  opacity: 0.3;
  pointer-events: none;
}
</style>
