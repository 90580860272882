var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticStyle: { "max-width": "100%" } }, [
    _c("section", { staticClass: "download-cats-section" }, [
      _c("div", { staticClass: "download-cats" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "download-cat-slider owl-carousel" },
          _vm._l(_vm.images, function(file) {
            return _c("div", { staticClass: "download-cat-slider__item" }, [
              _c("div", { staticClass: "download-cat" }, [
                _c("div", { staticClass: "download-cat__img" }, [
                  _c("img", { attrs: { src: file.img, alt: "" } })
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "download-cat__name",
                    attrs: { href: file.file, target: "_blank", download: "" }
                  },
                  [_vm._v(_vm._s(file.name))]
                )
              ])
            ])
          }),
          0
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "download-groups" },
      _vm._l(_vm.files, function(file) {
        return _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "download-group" }, [
            _c("div", { staticClass: "download-group__name" }, [
              _vm._v(_vm._s(file.name))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "download-group__content" },
              [
                file.files.length > 1
                  ? _c(
                      "div",
                      {
                        staticClass: "download-group__cutter cutter js-cutter"
                      },
                      [
                        _vm._m(1, true),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "download-group-list cutter__target" },
                          _vm._l(file.files, function(fileLink) {
                            return _c("div", { staticClass: "download-item" }, [
                              _c(
                                "div",
                                { staticClass: "download-item__name" },
                                [_vm._v(_vm._s(fileLink.type))]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "download-item__link",
                                  attrs: { download: "", href: fileLink.file }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "svg-icon",
                                      attrs: { width: "13", height: "13" }
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/images/sprite.svg#download"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ])
                          }),
                          0
                        )
                      ]
                    )
                  : [
                      file.files[0].type == "EXT"
                        ? _c(
                            "a",
                            {
                              staticClass: "download-group__link",
                              attrs: {
                                target: "_blank",
                                href: file.files[0].file
                              }
                            },
                            [
                              _c("span", [_vm._v("Посмотреть")]),
                              _vm._v(" "),
                              _c(
                                "svg",
                                {
                                  staticClass: "svg-icon",
                                  attrs: { width: "14", height: "14" }
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/images/sprite.svg#login-btn-black"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "download-group__link",
                              attrs: { download: "", href: file.files[0].file }
                            },
                            [
                              _c("span", [_vm._v("Скачать")]),
                              _vm._v(" "),
                              _c(
                                "svg",
                                {
                                  staticClass: "svg-icon",
                                  attrs: { width: "13", height: "13" }
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/images/sprite.svg#download"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                    ]
              ],
              2
            )
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "download-cats__header" }, [
      _c("h2", { staticClass: "download-cats__title" }, [_vm._v("Каталоги")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "cutter__trigger  js-cutter-trigger" }, [
      _c("span")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }