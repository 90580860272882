<template>
	<div class="card card_stats-donut" >
		<div v-bind:class="{ show: loading }" class="component-loading"></div>
		<div class="card__header">
			<h2 class="card__title">{{ title }}</h2>
		</div>
		<div class="card__body">
			<div :id="element_id" class="d3-donut-vertical"></div>
			<div class="stats-legend">
				<div v-for="item in legendItems" class="legend">
					<i v-bind:style="{ background: item.color }"></i> {{ item.name }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'graphComponentDiagram',
	props: {
		graph_id: {type: String, default: 'd3-donut'},
		graph_name: {type: String, default: 'Заголовок'},
		graph_source: {type: String, default: "/data/donut.json"},
		graph_radius: {type: Number, default: 50},
	},
	data: function() {
		return {
			title: this.graph_name,
			element_id: this.graph_id,
			radius: this.graph_radius,
			dataUrl: "/data/" + this.graph_source,
			colors: ["#3363C9", "#A5C0FA", "#E5E5E5"],
			svg: false,
			loading: true,
			legendItems: [],
			needUpdate: false,
		}
	},
	created: function () {
		this.$root.$on("updateGraphData", this.updateGraphData);
	},
	beforeDestroy: function () {
		this.$root.$off("updateGraphData", this.updateGraphData);
	},
	mounted: function(){
		var self = this;
		//self.createSVG();
		self.renderGraph();
	},
	methods:{
		updateGraphData: function(event){
			var self = this;
			if(event.id === self.element_id){
				for(var i in event){
					if(self[i] != undefined) self[i] = event[i];
				}
				//self.createSVG();
				self.renderGraph();
			}
		},
		createSVG(){
			var self = this;
			self.color = d3.scale.ordinal().range(self.colors);
			$("#" + self.element_id).empty();
			self.container = d3.select("#" + self.element_id).append("svg");
			self.svg = self.container.attr("width", self.radius * 2).attr("height", self.radius * 2)
				.append("g").attr("transform", "translate(" + self.radius + "," + self.radius + ")");
		},
		renderGraph(){
			var self = this;
			self.loading = true;
			
			$.getJSON(self.dataUrl, function(json){
				var data = json.data;
				var container = $("#" + self.element_id);
				for(var i in data) data[i].color = self.colors[i];
				self.legendItems = data;
				
				container.empty();
				
				for(var i in data) {
					var item = data[i];
					container.append('<div class="vertical-item" style="height:' + item.val + '%;background:' + item.color + ';"><span>' + item.valText + '</span></div>');
				}
				
				setTimeout(function(){
					self.loading = false;
				}, 500);
			});
			
			/*
			if(!self.svg) self.createSVG();
			
			d3.select("#" + self.element_id + " svg").attr("width", self.radius * 2).attr("height", self.radius * 2)
			d3.select("#" + self.element_id + " svg>g").attr("transform", "translate(" + self.radius + "," + self.radius + ")");
			
			var arc = d3.svg.arc()
				.outerRadius(self.radius)
				.innerRadius(self.radius / 1.25);
			
			var pie = d3.layout.pie()
				.sort(null)
				.value(function (d) {
					return d.val;
				});
				
			d3.json(self.dataUrl, function (error, json) {	
				var data = json.data;

				var g = d3.select("#" + self.element_id + " svg>g").selectAll(".d3-arc").data(pie(data)).enter().append("g").attr("class", "d3-arc");
				g.append("path").attr("d", arc).style("stroke", "#fff").style("fill", function (d) {
					return self.color(d.data.val);
				});
				for(var i in data) data[i].color = self.color(data[i].val);
				self.legendItems = data;
				
				setTimeout(function(){
					self.loading = false;
				}, 500);
			});
			*/
		}
	},
	watch: {
		dataUrl: function (newVal, oldVal) {
			//console.log(self.dataUrl);
		},
	},
	
};
</script>

<style>
.front-stats .card_stats-donut .stats-legend .legend{
	width: 100%;
}
.front-stats .card_stats-donut .card__body {
	display: flex;
    flex-direction: row;
    justify-content: stretch;
    position: relative;
    height: 100%;
    max-height: 80px;
}
.d3-donut-vertical{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 65%;
    justify-content: space-evenly;
    white-space: nowrap;
    align-items: flex-end;
}
.vertical-item{
    display: flex;
    width: 40px;
    bottom: 0;
	margin-right: 10px;
	position: relative;
	min-height: 5%;
}
.vertical-item span{
	position: absolute;
    bottom: 100%;
    text-align: center;
    display: block;
    width: 100%;
    font-size: 10px;
    line-height: 16px;
    font-family: "Museo Sans Cyrl 500";
}
</style>
