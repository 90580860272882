<template>
	<section class="" style="max-width: 100%;">
		<section class="download-cats-section">
                <div class="download-cats">
                    <div class="download-cats__header">
                        <h2 class="download-cats__title">Каталоги</h2>
                    </div>
                    <div class="download-cat-slider owl-carousel">
					
                        <div v-for="file in images" class="download-cat-slider__item">
                            <div class="download-cat">
                                <div class="download-cat__img">
                                    <img v-bind:src="file.img" alt="">
                                </div>
                                <a v-bind:href="file.file" target="_blank" download class="download-cat__name">{{file.name}}</a>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>

            <section class="download-groups">
                <div v-for="file in files" class="col">
                    <div class="download-group">
                        <div class="download-group__name">{{file.name}}</div>
						<div class="download-group__content">
                            <div v-if="file.files.length > 1" class="download-group__cutter cutter js-cutter">
                                <button class="cutter__trigger  js-cutter-trigger"><span></span></button>
                                <div class="download-group-list cutter__target">
                                    <div v-for="fileLink in file.files" class="download-item">
                                        <div class="download-item__name">{{fileLink.type}}</div>
                                        <a class="download-item__link" download v-bind:href="fileLink.file">
                                            <svg class="svg-icon" width="13" height="13">
                                                <use xlink:href="/images/sprite.svg#download"></use>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
							<template v-else>
								<a v-if="file.files[0].type =='EXT'" class="download-group__link" target="_blank" v-bind:href="file.files[0].file">
									<span>Посмотреть</span>
									<svg class="svg-icon" width="14" height="14">
										<use xlink:href="/images/sprite.svg#login-btn-black"></use>
									</svg>
								</a>
								<a v-else class="download-group__link" download v-bind:href="file.files[0].file">
									<span>Скачать</span>
									<svg class="svg-icon" width="13" height="13">
										<use xlink:href="/images/sprite.svg#download"></use>
									</svg>
								</a>
                            </template>
                        </div>
                    </div>
                </div>
				
                <!--div class="col">
                    <div class="download-group download-group_archive">
                        <div class="download-group__name">В архиве</div>
                        <div class="download-group__content">
                            <a class="download-group__read" href="">
                                <span>Подробнее</span>
                            </a>
                        </div>
                    </div>
                </div-->
            </section>

	</section>
</template>

<script>
export default {
	data: function () {
		var self = this;
		return {
			showMore: false,
			name: "Скачать",
			content: "",
			files: [],
			images: [],
			breadcrumbs: [
				{ link: '/', name: 'Главная' },
				{ link: '/download/', name: 'Скачать' },
			],
		}
	},
	components: {
		
	},
	computed:{
		
	},
	watch: {
		
	},
	methods: {
		
	},
	mounted: function(){
		var self = this;
		
		$.getJSON("/data/components/page_content.php", {page: "download"}, function(json){
			if(json.status) {
				self.name = json.name;
				self.content = json.content;
				self.files = json.files;
				self.images = json.images;
				
				self.breadcrumbs = [
					{ link: '/', name: 'Главная' },
					{ link: '/bonus/download/', name: self.name },
				];
				self.$root.breadcrumbs = self.breadcrumbs;
			}
			
			self.$nextTick(function(){
				$('.download-cat-slider.owl-carousel').owlCarousel({
					margin: 6,
					navText: [
						'<svg role="img" height="13" width="14"><use xlink:href="/images/sprite.svg#arrow-left"></use></svg>',
						'<svg role="img" height="13" width="14"><use xlink:href="/images/sprite.svg#arrow-right"></use></svg>'
					],
					nav: true,
					responsive: {
						0: {
							items: 2
						},
						480: {
							items: 3
						},
						768: {
							items: 4
						},
						1200: {
							items: 5
						}
					}
				});
				$(document).on('click', '.js-cutter-trigger', function (e) {
					$(this).parents('.js-cutter').toggleClass('is-open');
					$(this).toggleClass('is-open');
					if ($(this).hasClass('is-open')) {
						$(this).find('span').text($(this).data('close'));
					} else {
						$(this).find('span').text($(this).data('open'));
					}
				});
			})
		});
		
	}
};
</script>

<style>
.download-cats-section {
  margin-bottom: 40px;
}
.download-cats__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.download-cats__title {
  letter-spacing: -0.408px;
  color: #000000;
  font-size: 22px;
  line-height: 22px;
  font-family: 'Museo Sans Cyrl 700', sans-serif;
  margin-right: 15px;
  margin-top: 5px;
}
.download-cat-slider .owl-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
}
.download-cat-slider .owl-nav button.owl-prev,
.download-cat-slider .owl-nav button.owl-next {
  height: 32px;
  width: 32px;
  background-color: #3363C9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 40%;
}
.download-cat-slider .owl-nav button.owl-prev:hover,
.download-cat-slider .owl-nav button.owl-next:hover,
.download-cat-slider .owl-nav button.owl-prev:active,
.download-cat-slider .owl-nav button.owl-next:active {
  background-color: #4672d0;
  color: #ffffff;
}
.download-cat-slider .owl-nav button.owl-prev.disabled,
.download-cat-slider .owl-nav button.owl-next.disabled {
  display: none;
}
.download-cat-slider .owl-nav button.owl-prev {
  left: -15px;
}
@media screen and (max-width: 768px) {
  .download-cat-slider .owl-nav button.owl-prev {
    left: -5px;
  }
}
.download-cat-slider .owl-nav button.owl-next {
  right: -15px;
}
@media screen and (max-width: 768px) {
  .download-cat-slider .owl-nav button.owl-next {
    right: -5px;
  }
}
.download-cat-slider button.owl-dot {
  display: block;
  margin: 2px 5px;
}
.download-cat-slider button.owl-dot span {
  display: block;
  height: 6px;
  width: 6px;
  background-color: #C4C4C4;
  border-radius: 50%;
  margin: 5px;
  -webkit-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  transition: background-color 0.15s;
}
.download-cat-slider button.owl-dot.active span {
  background-color: #3363C9;
}
.download-groups {
  margin-bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
@media screen and (max-width: 768px) {
  .download-groups {
    margin-right: -5px;
    margin-left: -5px;
  }
}
@media screen and (max-width: 480px) {
  .download-groups {
    margin-right: -5px;
    margin-left: -5px;
  }
}
.download-groups .col {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding: 0 15px;
}
@media screen and (max-width: 768px) {
  .download-groups .col {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 5px;
  }
}
@media screen and (max-width: 480px) {
  .download-groups .col {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 5px;
  }
}
.download-cat {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
}
.download-cat__img {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0 0 10px 0;
  background: #C4C4C4;
}
.download-cat__img img {
  border-radius: 4px;
  display: block;
  width: 100%;
}
.download-cat__name {
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  letter-spacing: 0.2px;
  padding: 10px;
  color: #52504C;
}
.download-group {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 15px;
}
.download-group_archive {
  background: transparent;
}
.download-group_archive .download-group__name {
  color: #C4C4C4;
}
.download-group__link {
  font-size: 10px;
  line-height: 16px;
  position: absolute;
  right: 10px;
  top: -8px;
}
.download-group__link span {
  padding-right: 5px;
}
.download-group__read {
  font-size: 10px;
  line-height: 16px;
  position: absolute;
  right: 10px;
  top: -8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.download-group__read:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-width: 1px 1px 0 0;
  border-color: #52504C;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 5px;
}
.download-group__name {
  font-size: 12px;
  line-height: 22px;
  font-family: 'Museo Sans Cyrl 700', sans-serif;
  padding: 10px 20px 10px 10px;
}
.download-group__content {
  position: relative;
  min-height: 16px;
}
.download-group .cutter.is-open .cutter__target {
  display: block;
}
.download-group .cutter.is-open .cutter__trigger {
  top: -28px;
}
.download-group .cutter__target {
  display: none;
}
.download-group .cutter__trigger {
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  position: absolute;
  height: 16px;
  width: 16px;
  right: 14px;
  top: -8px;
  transition: top 0.3s;
}
.download-group .cutter__trigger:before {
  content: "";
  border-color: #52504C;
  border-style: solid;
  border-width: 0 1px 1px 0;
  height: 6px;
  margin-top: -3px;
  width: 6px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: rotate(45deg);
  display: block;
}
.download-group .cutter__trigger.is-open:before {
  margin-top: 0;
  transform: rotate(-135deg);
}
.download-item {
  padding: 2px 10px;
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.download-item:last-child {
  border: none;
  border-radius: 0 3px 3px 0;
}
.download-item:hover {
  background: #F6F8FB;
}
.download-item__link {
  flex: 0 0 13px;
}
.download-item__name {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
}
</style>