var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "profile" }, [
    _c("div", { staticClass: "profile__header" }, [
      _c("h2", { staticClass: "profile__title" }, [_vm._v("Мой профиль")]),
      _vm._v(" "),
      _c("div", { staticClass: "environ" }, [
        _c(
          "div",
          {
            staticClass: "environ__group",
            class: { hidden: _vm.$root.arUser.profiles.count == 1 }
          },
          [
            _c("label", { staticClass: "environ__label" }, [
              _vm._v("Активный договор")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "environ__select environ__select--order" },
              [
                _c(
                  "select",
                  {
                    staticClass: "select-profile select-profile-home select",
                    attrs: { name: "" }
                  },
                  _vm._l(_vm.$root.arUser.profiles.list, function(profile) {
                    return _c("option", {
                      class: { active: profile.active },
                      domProps: {
                        value: profile.id,
                        selected: profile.active,
                        innerHTML: _vm._s(profile.props.ORDER_PROP_75.value)
                      }
                    })
                  }),
                  0
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "environ__group",
            class: { hidden: _vm.activeProfile.stores.count == 1 }
          },
          [
            _c("label", { staticClass: "environ__label" }, [
              _vm._v("Активный склад")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "environ__select" }, [
              _c(
                "select",
                {
                  staticClass: "select-store select-store-home select",
                  attrs: { name: "" }
                },
                _vm._l(_vm.activeProfile.stores.list, function(store) {
                  return _c("option", {
                    class: { active: store.selected },
                    domProps: {
                      value: store.ID,
                      selected: store.selected,
                      innerHTML: _vm._s(store.TITLE)
                    }
                  })
                }),
                0
              )
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "profile__grid" }, [
      _c("div", {
        staticClass: "component-loading",
        class: { show: _vm.loading }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "profile__col" }, [
        _c("div", { staticClass: "profile-widget" }, [
          _c("div", { staticClass: "profile-widget__header" }, [
            _vm._v("Данные профиля:")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-widget__content" }, [
            _c("div", { staticClass: "user-pic" }, [
              _c("label", [
                _c("input", {
                  attrs: {
                    type: "file",
                    name: "user_avatar",
                    id: "user_avatar"
                  },
                  on: { change: _vm.setUserAvatar }
                }),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "svg-icon",
                    attrs: { width: "25", height: "22" }
                  },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "/images/sprite.svg#photo" }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("img", { attrs: { src: _vm.$root.arUser.avatar, alt: "" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "user-id" }, [
              _vm._v("ID пользователя: " + _vm._s(_vm.$root.arUser.id))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "user-manager" }, [
              _vm._v("\n              Ваш менеджер:"),
              _c("br"),
              _vm._v(" "),
              _c("b", [_vm._v(_vm._s(_vm.$root.arUser.manager.name))]),
              _c("br"),
              _vm._v(" "),
              _c(
                "a",
                { attrs: { href: "mailto:" + _vm.$root.arUser.manager.email } },
                [_vm._v(_vm._s(_vm.$root.arUser.manager.email))]
              ),
              _c("br"),
              _vm._v(" "),
              _c(
                "a",
                { attrs: { href: "tel:" + _vm.$root.arUser.manager.phone } },
                [_vm._v(_vm._s(_vm.$root.arUser.manager.phone))]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "user-buttons" },
              [
                _c(
                  "a",
                  {
                    staticClass: "btn btn_lblue js-open-modal",
                    attrs: { href: "#modal-pass-change" },
                    on: {
                      click: function($event) {
                        _vm.showPassChange = true
                      }
                    }
                  },
                  [_vm._v("Сменить пароль")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  { staticClass: "btn", attrs: { to: "/login/?logout=yes" } },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "svg-icon",
                        attrs: { width: "14", height: "14" }
                      },
                      [
                        _c("use", {
                          attrs: { "xlink:href": "/images/sprite.svg#sb-exit" }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "btn_text" }, [_vm._v("Выйти")])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "profile__col" }, [
        _c("div", { staticClass: "profile-widget" }, [
          _c("div", { staticClass: "profile-widget__header" }, [
            _vm._v("Личные данные:")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-widget__content" }, [
            _c("form", { staticClass: "person-form", attrs: { action: "" } }, [
              _c("input", {
                attrs: {
                  type: "hidden",
                  name: "action",
                  value: "setUserFields"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "control_text" }, [
                _c("label", [_vm._v("Фамилия, имя")]),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "text", name: "FIELDS[NAME]" },
                  domProps: { value: _vm.$root.arUser.name }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "control_text" }, [
                _c("label", [_vm._v("E-mail")]),
                _vm._v(" "),
                _c("div", { staticClass: "tip" }, [
                  _vm._v("Сменить возможно по запросу менеджеру")
                ]),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "text", name: "email", readonly: "" },
                  domProps: { value: _vm.$root.arUser.email }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "control_text" }, [
                _c("label", [_vm._v("Контактный телефон")]),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "text", name: "FIELDS[PERSONAL_PHONE]" },
                  domProps: { value: _vm.$root.arUser.phone }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "control_text" }, [
                _c("label", [_vm._v("Должность")]),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "text", name: "FIELDS[WORK_POSITION]" },
                  domProps: { value: _vm.$root.arUser.position }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "control_button" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: { type: "submit" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.setUserFields.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                  Обновить данные\n                "
                    )
                  ]
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      !_vm.$root.arUser.subclient
        ? _c("div", { staticClass: "profile__col" }, [
            _vm.$root.arUser.profile
              ? _c("div", { staticClass: "profile-widget" }, [
                  _c("div", { staticClass: "profile-widget__header" }, [
                    _vm._v("Активное Юр. лицо:")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "profile-widget__content" }, [
                    _c("div", { staticClass: "entity-active" }, [
                      _c("div", {
                        staticClass: "entity-active__name",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.activeProfile.props.ORDER_PROP_75.value
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "entity-active__data" }, [
                        _c("div", [
                          _vm._v(
                            "ИНН: " +
                              _vm._s(
                                _vm.activeProfile.props.ORDER_PROP_10.value
                              )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "КПП: " +
                              _vm._s(
                                _vm.activeProfile.props.ORDER_PROP_11.value
                              )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "ОКПО: " +
                              _vm._s(
                                _vm.activeProfile.props.ORDER_PROP_21.value
                              )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n                  Юр. адрес: " +
                              _vm._s(
                                _vm.activeProfile.props.ORDER_PROP_9.value
                              ) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "Банк: " +
                              _vm._s(
                                _vm.activeProfile.props.ORDER_PROP_25.value
                              )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "БИК: " +
                              _vm._s(
                                _vm.activeProfile.props.ORDER_PROP_26.value
                              )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "К/С: " +
                              _vm._s(
                                _vm.activeProfile.props.ORDER_PROP_27.value
                              )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "Р/С: " +
                              _vm._s(
                                _vm.activeProfile.props.ORDER_PROP_28.value
                              )
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.$root.arUser.subuser == false ||
    _vm.$root.arUser.fields.UF_B2B_ADMIN == "Y"
      ? _c("section", { staticClass: "users" }, [
          _c("div", { staticClass: "users__header" }, [
            _c("h3", { staticClass: "users__title" }, [
              _vm._v("\n          Пользователи "),
              _c("b", [_vm._v(_vm._s(_vm.subusersCnt + 1))])
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "users__text",
              domProps: { innerHTML: _vm._s(_vm.content) }
            }),
            _vm._v(" "),
            _c("section", { staticClass: "b-content b-content_notes" }, [
              _c("div", { staticClass: "second-line second-line-tabs" }, [
                _c("ul", { staticClass: "control_nav" }, [
                  _c(
                    "li",
                    { class: { "is-active": _vm.activTab == "subuser" } },
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              _vm.activTab = "subuser"
                            }
                          }
                        },
                        [
                          _vm._v("Сотрудники\n                  "),
                          _c("b", [
                            _vm._v("("),
                            _c("span", [_vm._v(_vm._s(_vm.subusersCnt))]),
                            _vm._v(")")
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { "is-active": _vm.activTab == "subclient" } },
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              _vm.activTab = "subclient"
                            }
                          }
                        },
                        [
                          _vm._v("Клиенты\n                  "),
                          _c("b", [
                            _vm._v("("),
                            _c("span", [_vm._v(_vm._s(_vm.subclientsCnt))]),
                            _vm._v(")")
                          ])
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "users__add-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn js-open-modal",
                      attrs: { "data-target": "#modal-add-partner" },
                      on: {
                        click: function($event) {
                          return _vm.editSubUser(0)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                Новый пользователь\n              "
                      )
                    ]
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.activTab == "subuser"
            ? _c("div", { staticClass: "users__table" }, [
                _c("div", { staticClass: "resp-table-wrap" }, [
                  _c("table", { staticClass: "users-table" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      [
                        _vm.$root.arUser.fields.UF_B2B_ADMIN != "Y"
                          ? _c("tr", [
                              _c("td", [_vm._v(_vm._s(_vm.$root.arUser.name))]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.$root.arUser.email))
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.$root.arUser.phone))
                              ]),
                              _vm._v(" "),
                              _vm._m(1)
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$root.arUser.fields.UF_B2B_ADMIN == "Y"
                          ? _c("tr", [
                              _c("td", [
                                _vm._v(_vm._s(_vm.$root.arUser.parentName))
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.$root.arUser.parentEmail))
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.$root.arUser.parentPhone))
                              ]),
                              _vm._v(" "),
                              _vm._m(2)
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.$root.arUser.subusers, function(subUser) {
                          return _c("tr", [
                            _c("td", [
                              subUser.ACTIVE == "Y"
                                ? _c("span", { staticClass: "user-active" })
                                : _c("span", { staticClass: "user-no-active" }),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(subUser.NAME) +
                                  "\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(subUser.EMAIL))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(subUser.PERSONAL_PHONE))]),
                            _vm._v(" "),
                            _c("td", [
                              subUser.UF_B2B_ADMIN == "Y"
                                ? _c("span", { staticClass: "t_blue" }, [
                                    _vm._v("Администратор")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "edit-btn js-open-modal",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editSubUser(subUser.ID)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "arrow",
                                      attrs: { width: "12", height: "13" }
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "/images/sprite.svg#pen"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ])
                          ])
                        })
                      ],
                      2
                    )
                  ])
                ])
              ])
            : _c("div", { staticClass: "users__table subclients" }, [
                _c("div", { staticClass: "resp-table-wrap" }, [
                  _c("table", { staticClass: "users-table" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.$root.arUser.subclients, function(subUser) {
                        return _c("tr", [
                          _c("td", [
                            subUser.ACTIVE == "Y"
                              ? _c("span", { staticClass: "user-active" })
                              : _c("span", { staticClass: "user-no-active" }),
                            _vm._v(
                              "\n                  " +
                                _vm._s(subUser.NAME) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(subUser.EMAIL))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(subUser.PERSONAL_PHONE))]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "edit-btn js-open-modal",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.editSubUser(subUser.ID)
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "arrow",
                                    attrs: { width: "12", height: "13" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": "/images/sprite.svg#pen"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ])
                ])
              ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal modal_pass-change",
        class: { "is-open": _vm.showPassChange },
        attrs: { id: "modal-pass-change" }
      },
      [
        _c("div", { staticClass: "modal__wrap" }, [
          _c("div", { staticClass: "modal__inner animated zoomIn" }, [
            _c("button", {
              staticClass: "modal__close",
              on: {
                click: function($event) {
                  _vm.to_hide = true
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "modal__header" }, [
              _vm._v("Сменить пароль")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal__container" },
              [
                _vm.passSuccessChange
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "forgot-send-info animated zoomIn",
                          staticStyle: {
                            "padding-bottom": "50px",
                            "text-align": "center"
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "70px", margin: "20px" },
                            attrs: { src: "/images/check.svg" }
                          }),
                          _vm._v(" "),
                          _vm._m(4),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            { staticClass: "btn", attrs: { to: "/login/" } },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "svg-icon",
                                  attrs: { width: "14", height: "14" }
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/images/sprite.svg#login-btn"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  attrs: { type: "submit" },
                                  on: {
                                    click: [
                                      function($event) {
                                        _vm.to_hide = true
                                      },
                                      function($event) {
                                        $event.preventDefault()
                                        return _vm.submitChangePass.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    ]
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                    Закрыть\n                  "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  : _c(
                      "form",
                      { staticClass: "chpass-form", attrs: { action: "" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "control_group pass_old",
                            class: { error: _vm.errors.password }
                          },
                          [
                            _vm._m(5),
                            _vm._v(" "),
                            _c("div", { staticClass: "control_password" }, [
                              _vm.showPass.password
                                ? _c("input", {
                                    attrs: {
                                      id: "password-input",
                                      type: "text",
                                      name: "PASSWORD",
                                      required: "",
                                      placeholder: "Пароль"
                                    },
                                    domProps: { value: _vm.password },
                                    on: { input: _vm.checkFields }
                                  })
                                : _c("input", {
                                    attrs: {
                                      id: "password-input",
                                      type: "password",
                                      name: "PASSWORD",
                                      required: "",
                                      placeholder: "Пароль"
                                    },
                                    domProps: { value: _vm.password },
                                    on: { input: _vm.checkFields }
                                  }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "pass-toggle",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.showPass.password = !_vm.showPass
                                        .password
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    { attrs: { width: "14", height: "8" } },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "/images/sprite.svg#eye"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.errors.password
                              ? _c(
                                  "small",
                                  {
                                    staticClass:
                                      "tip control_error_info animated heartBeat"
                                  },
                                  [_vm._v(_vm._s(_vm.errorText.password))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("small", { staticClass: "tip" })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "control_group pass_new",
                            class: { error: _vm.errors.new_password }
                          },
                          [
                            _vm._m(6),
                            _vm._v(" "),
                            _c("div", { staticClass: "control_password" }, [
                              _vm.showPass.new_password
                                ? _c("input", {
                                    attrs: {
                                      id: "new_password-input",
                                      type: "text",
                                      name: "USER_PASSWORD",
                                      required: "",
                                      placeholder: "Пароль"
                                    },
                                    domProps: { value: _vm.new_password },
                                    on: { input: _vm.checkFieldsChPass }
                                  })
                                : _c("input", {
                                    attrs: {
                                      id: "new_password-input",
                                      type: "password",
                                      name: "USER_PASSWORD",
                                      required: "",
                                      placeholder: "Пароль"
                                    },
                                    domProps: { value: _vm.new_password },
                                    on: { input: _vm.checkFieldsChPass }
                                  }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "pass-toggle",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.showPass.new_password = !_vm.showPass
                                        .new_password
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    { attrs: { width: "14", height: "8" } },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "/images/sprite.svg#eye"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.errors.new_password
                              ? _c(
                                  "small",
                                  {
                                    staticClass:
                                      "tip control_error_info animated heartBeat"
                                  },
                                  [_vm._v(_vm._s(_vm.errorText.new_password))]
                                )
                              : _c("small", { staticClass: "tip" }, [
                                  _vm._v(
                                    "Новый пароль должен содержать не менее 8 символов, 1\n                  заглавную букву и 1 цифру"
                                  )
                                ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "control_group pass_repeat",
                            class: { error: _vm.errors.confirm_password }
                          },
                          [
                            _vm._m(7),
                            _vm._v(" "),
                            _c("div", { staticClass: "control_password" }, [
                              _vm.showPass.confirm_password
                                ? _c("input", {
                                    attrs: {
                                      id: "confirm_password-input",
                                      type: "text",
                                      name: "USER_CONFIRM_PASSWORD",
                                      required: "",
                                      placeholder: "Подтверждение пароля"
                                    },
                                    domProps: { value: _vm.confirm_password },
                                    on: { input: _vm.checkFieldsChPass }
                                  })
                                : _c("input", {
                                    attrs: {
                                      id: "confirm_password-input",
                                      type: "password",
                                      name: "USER_CONFIRM_PASSWORD",
                                      required: "",
                                      placeholder: "Подтверждение пароля"
                                    },
                                    domProps: { value: _vm.confirm_password },
                                    on: { input: _vm.checkFieldsChPass }
                                  }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "pass-toggle",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.showPass.confirm_password = !_vm
                                        .showPass.confirm_password
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    { attrs: { width: "14", height: "8" } },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "/images/sprite.svg#eye"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.errors.confirm_password
                              ? _c(
                                  "small",
                                  {
                                    staticClass:
                                      "tip control_error_info animated heartBeat"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.errorText.confirm_password)
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("small", { staticClass: "tip" })
                          ]
                        ),
                        _vm._v(" "),
                        _vm.chPasswordError
                          ? _c("span", {
                              staticClass: "auth_error_info animated fadeIn",
                              domProps: {
                                innerHTML: _vm._s(_vm.chPasswordErrorText)
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "control_button" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              attrs: { type: "submit" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.submitChangePass.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  Обновить пароль\n                "
                              )
                            ]
                          )
                        ])
                      ]
                    )
              ],
              2
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _vm.$root.arUser.subuser == false ||
    _vm.$root.arUser.fields.UF_B2B_ADMIN == "Y"
      ? _c(
          "div",
          {
            staticClass: "modal modal_edit-partner",
            class: { "is-open": _vm.showUserEdit },
            attrs: { id: "modal-edit-partner" }
          },
          [
            _c("div", { staticClass: "modal__wrap" }, [
              _c(
                "div",
                { staticClass: "modal__inner animated zoomIn" },
                [
                  _c("button", {
                    staticClass: "modal__close",
                    on: {
                      click: function($event) {
                        _vm.to_hide = true
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal__header" }, [
                    _vm._v("Редактирование данных пользователя")
                  ]),
                  _vm._v(" "),
                  _vm.passSuccessChangeSubUser
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "forgot-send-info animated zoomIn",
                            staticStyle: {
                              "padding-bottom": "50px",
                              "text-align": "center"
                            }
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "70px", margin: "20px" },
                              attrs: { src: "/images/check.svg" }
                            }),
                            _vm._v(" "),
                            _vm._m(8),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              { staticClass: "btn", attrs: { to: "/login/" } },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "svg-icon",
                                    attrs: { width: "14", height: "14" }
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href":
                                          "/images/sprite.svg#login-btn"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn",
                                    attrs: { type: "submit" },
                                    on: {
                                      click: [
                                        function($event) {
                                          _vm.to_hide = true
                                        },
                                        function($event) {
                                          $event.preventDefault()
                                          return _vm.submitChangePass.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Закрыть\n                "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    : [
                        _c("div", { staticClass: "modal__container" }, [
                          _c(
                            "form",
                            { attrs: { action: "", id: "edit_user_form" } },
                            [
                              _c("div", { staticClass: "user_type_block" }, [
                                _c("ul", { staticClass: "user_type" }, [
                                  _c(
                                    "li",
                                    {
                                      class: {
                                        "is-active":
                                          _vm.activUserType === "subuser"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.activUserType = "subuser"
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Сотрудник\n                    "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      class: {
                                        "is-active":
                                          _vm.activUserType === "subclient"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.activUserType = "subclient"
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Клиент\n                    "
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.activUserType == "subuser"
                                ? _c(
                                    "div",
                                    { staticClass: "user_is_admin_block" },
                                    [
                                      _c("span", [_vm._v("Администратор*")]),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { staticClass: "checkbox-ios" },
                                        [
                                          _vm.subuser.is_admin
                                            ? _c("input", {
                                                attrs: {
                                                  checked: "",
                                                  type: "checkbox",
                                                  name: "is_admin",
                                                  value: "Y"
                                                }
                                              })
                                            : _c("input", {
                                                attrs: {
                                                  type: "checkbox",
                                                  name: "is_admin",
                                                  value: "Y"
                                                }
                                              }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkbox-ios-switch"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("input", {
                                attrs: { type: "hidden", name: "ID" },
                                domProps: { value: _vm.subuser.id }
                              }),
                              _vm._v(" "),
                              _c("input", {
                                attrs: { type: "hidden", name: "ACTIVE" },
                                domProps: { value: _vm.subuser.active }
                              }),
                              _vm._v(" "),
                              _c("input", {
                                attrs: { type: "hidden", name: "GROUP" },
                                domProps: { value: _vm.activUserType }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "control_group" }, [
                                _c("label", [
                                  _c("span", [_vm._v("ФИО*")]),
                                  _vm._v(" "),
                                  _vm.subuser.active === "Y"
                                    ? _c(
                                        "span",
                                        { staticClass: "partner-active" },
                                        [
                                          _vm._v(
                                            "\n                      Активен\n                    "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "partner-no-active" },
                                        [_vm._v("Деактивирован")]
                                      )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "control_text" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.subuser.name,
                                        expression: "subuser.name"
                                      }
                                    ],
                                    attrs: {
                                      type: "text",
                                      name: "NAME",
                                      required: ""
                                    },
                                    domProps: { value: _vm.subuser.name },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.subuser,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "control_group" }, [
                                _vm._m(9),
                                _vm._v(" "),
                                _c("div", { staticClass: "control_text" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.subuser.email,
                                        expression: "subuser.email"
                                      }
                                    ],
                                    attrs: {
                                      type: "email",
                                      name: "EMAIL",
                                      required: ""
                                    },
                                    domProps: { value: _vm.subuser.email },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.subuser,
                                          "email",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "control_group" }, [
                                _vm._m(10),
                                _vm._v(" "),
                                _c("div", { staticClass: "control_text" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.subuser.phone,
                                        expression: "subuser.phone"
                                      }
                                    ],
                                    attrs: {
                                      type: "text",
                                      name: "PERSONAL_PHONE"
                                    },
                                    domProps: { value: _vm.subuser.phone },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.subuser,
                                          "phone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.activUserType === "subclient",
                                      expression:
                                        "activUserType === 'subclient'"
                                    }
                                  ],
                                  staticClass: "control_group"
                                },
                                [
                                  _vm._m(11),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "control_text" }, [
                                    _c("input", {
                                      attrs: {
                                        type: "text",
                                        name: "UF_CLIENT_INN"
                                      },
                                      domProps: { value: _vm.subuser.inn }
                                    })
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "manager-block",
                                  class: {
                                    "is-active":
                                      _vm.activUserType == "subclient"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "b-content__top" }, [
                                    _c(
                                      "div",
                                      { staticClass: "modal__header" },
                                      [_vm._v("Персональный менеджер")]
                                    ),
                                    _vm._v(" "),
                                    _vm._m(12),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "environ__select_manager"
                                      },
                                      [
                                        _c(
                                          "select",
                                          {
                                            staticClass: "select-manager",
                                            attrs: { name: "parent_manager" }
                                          },
                                          _vm._l(_vm.subuser.managers, function(
                                            manager
                                          ) {
                                            return _c("option", {
                                              class: {
                                                active: manager.selected
                                              },
                                              domProps: {
                                                selected: manager.selected,
                                                value: manager.ID,
                                                innerHTML: _vm._s(manager.NAME)
                                              }
                                            })
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "b-content__top" },
                                [
                                  _c("div", { staticClass: "modal__header" }, [
                                    _vm._v("Доступные разделы кабинета")
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.menuItems, function(menuItem) {
                                    return menuItem.checked
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "control_checkbox",
                                            class: {
                                              disabled:
                                                _vm.menuItemsBlocked[
                                                  menuItem.key
                                                ]
                                            }
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for:
                                                    "menuItem_" + menuItem.key
                                                }
                                              },
                                              [
                                                _c("input", {
                                                  attrs: {
                                                    name:
                                                      "CATS[" +
                                                      menuItem.key +
                                                      "]",
                                                    type: "hidden",
                                                    value: "N"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "input",
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        name:
                                                          "CATS[" +
                                                          menuItem.key +
                                                          "]",
                                                        id:
                                                          "menuItem_" +
                                                          menuItem.key,
                                                        type: "checkbox",
                                                        value: "Y"
                                                      }
                                                    },
                                                    "input",
                                                    {
                                                      readonly:
                                                        _vm.menuItemsBlocked[
                                                          menuItem.key
                                                        ],
                                                      checked:
                                                        _vm.subuser.cats[
                                                          menuItem.key
                                                        ] != "N"
                                                    },
                                                    false
                                                  )
                                                ),
                                                _vm._v(" "),
                                                _c("i"),
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      menuItem.name
                                                    )
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.infoPrices.length
                                ? _c(
                                    "div",
                                    { staticClass: "b-content__top" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "modal__header" },
                                        [_vm._v("Доступные цены")]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(_vm.infoPrices, function(
                                        infoPrice,
                                        index
                                      ) {
                                        return _vm.activUserType == "subuser"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "control_checkbox"
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "infoPrice_" + index
                                                    }
                                                  },
                                                  [
                                                    _c("input", {
                                                      attrs: {
                                                        name:
                                                          "PRICES[" +
                                                          infoPrice +
                                                          "]",
                                                        type: "hidden",
                                                        value: "N"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "input",
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            name:
                                                              "PRICES[" +
                                                              infoPrice +
                                                              "]",
                                                            id:
                                                              "infoPrice_" +
                                                              index,
                                                            type: "checkbox",
                                                            value: "Y"
                                                          }
                                                        },
                                                        "input",
                                                        {
                                                          checked:
                                                            _vm.subuser
                                                              .infoPrices[
                                                              infoPrice
                                                            ] == "Y"
                                                        },
                                                        false
                                                      )
                                                    ),
                                                    _vm._v(" "),
                                                    _c("i"),
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          infoPrice
                                                        )
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      }),
                                      _vm._v(" "),
                                      _vm._l(_vm.infoPrices, function(
                                        infoPrice,
                                        index
                                      ) {
                                        return _vm.activUserType == "subclient"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "control_checkbox"
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "infoPrice_" + index
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "input",
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            name: "PRICE",
                                                            id:
                                                              "infoPrice_" +
                                                              index,
                                                            type: "checkbox"
                                                          },
                                                          domProps: {
                                                            value: infoPrice
                                                          }
                                                        },
                                                        "input",
                                                        {
                                                          checked:
                                                            _vm.subuser
                                                              .infoPrices[
                                                              infoPrice
                                                            ] == "Y"
                                                        },
                                                        false
                                                      )
                                                    ),
                                                    _vm._v(" "),
                                                    _c("i"),
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          infoPrice
                                                        )
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      }),
                                      _vm._v(" "),
                                      _vm._m(13),
                                      _vm._v(" "),
                                      _vm._m(14)
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "errors",
                                staticStyle: {
                                  color: "red",
                                  "font-size": "12px"
                                },
                                domProps: {
                                  innerHTML: _vm._s(_vm.subUserError)
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "control_buttons" }, [
                                _vm.subuser.active == "Y"
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn_lblue",
                                        attrs: { type: "submit" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.deactivateSubuser.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Деактивировать пользователя\n                  "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass: "btn btn_lblue",
                                        attrs: { type: "submit" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.activateSubuser.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Активировать пользователя\n                  "
                                        )
                                      ]
                                    ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn_blue",
                                    attrs: { type: "submit" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.saveSubuser.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Сохранить\n                  "
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ])
                      ]
                ],
                2
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("ФИО")]),
        _vm._v(" "),
        _c("th", [_vm._v("E-mail")]),
        _vm._v(" "),
        _c("th", [_vm._v("Телефон")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "100" } }, [_vm._v(" ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", { staticClass: "t_blue" }, [_vm._v("Владелец")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", { staticClass: "t_blue" }, [_vm._v("Владелец")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("ФИО")]),
        _vm._v(" "),
        _c("th", [_vm._v("E-mail")]),
        _vm._v(" "),
        _c("th", [_vm._v("Телефон")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "100" } }, [_vm._v(" ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "login-head-label",
        staticStyle: { "text-align": "center" }
      },
      [_c("span", [_vm._v("Пароль успешно изменен"), _c("br"), _c("br")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("span", [_vm._v("Старый пароль")]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "remind-pass-link js-remind-pass-link",
          attrs: { href: "" }
        },
        [_vm._v("Забыли пароль?")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("span", [_vm._v("Новый пароль")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("span", [_vm._v("Повторите пароль")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "login-head-label",
        staticStyle: { "text-align": "center" }
      },
      [
        _c("span", [
          _vm._v("Пользователь успешно сохранен"),
          _c("br"),
          _c("br")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("span", [_vm._v("E-mail*")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("span", [_vm._v("Телефон")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("span", [_vm._v("ИНН")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "sm-description" }, [
      _c("span", [_vm._v("Назначте менеджера из списка сотрудников")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "control_checkbox" }, [
      _c("input", {
        attrs: {
          name: "PRICES[ИП]",
          id: "infoPrice_9999",
          type: "checkbox",
          value: "Y",
          checked: "",
          readonly: ""
        }
      }),
      _vm._v(" "),
      _c("i"),
      _c("span", [_vm._v("РРЦ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "control_checkbox" }, [
      _c("input", {
        attrs: {
          name: "PRICES[МИЦ (мин. интернет-цена)]",
          id: "infoPrice_999",
          type: "checkbox",
          value: "Y",
          checked: "",
          readonly: ""
        }
      }),
      _vm._v(" "),
      _c("i"),
      _c("span", [_vm._v("МИЦ (мин. интернет-цена)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }