var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "cabinet-application" } },
    [
      this.$root.arUser.authorized
        ? _c("header", [
            _c("div", { staticClass: "container" }, [
              _c(
                "div",
                { staticClass: "header noPrint" },
                [
                  _c(
                    "button",
                    {
                      class: { "is-open": _vm.$root.menuOpened },
                      attrs: { id: "hamburger" },
                      on: { click: this.$parent.toggleMenu }
                    },
                    [_c("i"), _vm._v(" "), _c("i"), _vm._v(" "), _c("i")]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { staticClass: "header-logo", attrs: { to: "/" } },
                    [
                      _c("svg", { attrs: { width: "220", height: "36" } }, [
                        _c("use", {
                          attrs: { "xlink:href": "/images/sprite.svg#logo" }
                        })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  this.$root.arUser.authorized
                    ? _c("div", { staticClass: "header-search" }, [
                        _c(
                          "button",
                          { staticClass: "header-search__trigger" },
                          [
                            _c(
                              "svg",
                              { attrs: { width: "17", height: "16" } },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href": "/images/sprite.svg#search"
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "header-search__form",
                            attrs: { method: "get", action: "" }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.query,
                                  expression: "query"
                                }
                              ],
                              staticClass: "header-search__text",
                              attrs: {
                                type: "text",
                                placeholder:
                                  "Искать товар по артикулу, названию, модели...",
                                name: "q",
                                autocomplete: "off"
                              },
                              domProps: { value: _vm.query },
                              on: {
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.onSearch.apply(null, arguments)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.query = $event.target.value
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "header-search__submit",
                                attrs: { type: "button" },
                                on: { click: _vm.onSearch }
                              },
                              [
                                _c(
                                  "svg",
                                  { attrs: { width: "17", height: "16" } },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href":
                                          "/images/sprite.svg#search"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "header-cut" }),
                  _vm._v(" "),
                  _vm.$root.arUser.loyalty
                    ? _c(
                        "router-link",
                        {
                          staticClass: "header-bonus",
                          attrs: { to: "/bonus/" }
                        },
                        [
                          _c("div", { staticClass: "header-bonus__label" }, [
                            _vm._v("активные бонусы")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "header-bonus__amount" }, [
                            _c(
                              "svg",
                              { attrs: { width: "23", height: "23" } },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href": "/images/sprite.svg#cashback"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "header-bonus__value",
                              domProps: {
                                innerHTML: _vm._s(_vm.$root.arUser.balls)
                              }
                            })
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !this.$root.arUser.basketDisabled
                    ? [
                        this.$root.arUser.authorized
                          ? _c(
                              "router-link",
                              {
                                staticClass: "header-cart",
                                attrs: { to: "/cart/" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "header-cart__total-price" },
                                  [
                                    _vm._v(
                                      _vm._s(this.$root.animatedPrice) + " ₽"
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "header-cart__icon" },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "svg-icon",
                                        attrs: { width: "23", height: "25" }
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href":
                                              "/images/sprite.svg#header-basket"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    this.$root.badges.cart
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "badge js-cart-badge"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(this.$root.badges.cart)
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  this.$root.arUser.authorized
                    ? _c(
                        "router-link",
                        {
                          staticClass: "header-user",
                          attrs: { to: "/personal/" }
                        },
                        [
                          _c("img", {
                            attrs: { src: _vm.$root.arUser.avatar, alt: "" }
                          })
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        this.$root.arUser.authorized
          ? _c("main", { attrs: { id: "main" } }, [
              _c(
                "div",
                {
                  staticClass: "noPrint",
                  class: { "is-open": _vm.$root.menuOpened },
                  attrs: { id: "aside" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "absolute-menu" },
                    [_c("component-menu")],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { id: "content" } },
                [
                  _c("waiting-goods"),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "breadcrumbs noPrint" },
                    _vm._l(this.$parent.breadcrumbs, function(item) {
                      return _c(
                        "li",
                        [
                          _c("router-link", {
                            attrs: { to: item.link },
                            domProps: { innerHTML: _vm._s(item.name) }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "page-content b-content b-content_comparison"
                    },
                    [
                      _c(
                        "transition",
                        {
                          attrs: {
                            transitionMode: "out-in",
                            name: this.$parent.transitionName
                          },
                          on: {
                            beforeLeave: this.$parent.beforeLeave,
                            enter: this.$parent.enter,
                            afterEnter: this.$parent.afterEnter
                          }
                        },
                        [_c("router-view")],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "footer",
                    { staticClass: "footer noPrint" },
                    [
                      _c(
                        "router-link",
                        { staticClass: "footer-logo", attrs: { to: "/" } },
                        [
                          _c("svg", { attrs: { width: "220", height: "36" } }, [
                            _c("use", {
                              attrs: { "xlink:href": "/images/sprite.svg#logo" }
                            })
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _vm._m(1),
                      _vm._v(" "),
                      _c("div", { staticClass: "footer-col" }, [
                        _c("div", { staticClass: "footer-social" }, [
                          _c("a", { attrs: { href: "" } }, [
                            _c(
                              "svg",
                              { attrs: { width: "21", height: "21" } },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href": "/images/sprite.svg#soc-fb"
                                  }
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("a", { attrs: { href: "" } }, [
                            _c(
                              "svg",
                              { attrs: { width: "21", height: "21" } },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href": "/images/sprite.svg#soc-ig"
                                  }
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("a", { attrs: { href: "" } }, [
                            _c(
                              "svg",
                              { attrs: { width: "21", height: "21" } },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href": "/images/sprite.svg#soc-yt"
                                  }
                                })
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v("2021 © Feron")])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _c("main", { attrs: { id: "main" } }, [
              _c("div", { attrs: { id: "full_content" } }, [
                _c(
                  "div",
                  { staticClass: "page-content" },
                  [
                    _c(
                      "transition",
                      {
                        attrs: {
                          transitionMode: "out-in",
                          name: this.$parent.transitionName
                        },
                        on: {
                          beforeLeave: this.$parent.beforeLeave,
                          enter: this.$parent.enter,
                          afterEnter: this.$parent.afterEnter
                        }
                      },
                      [_c("router-view")],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
      ]),
      _vm._v(" "),
      this.$root.arUser.is_admin
        ? _c(
            "div",
            {
              staticClass: "noPrint",
              class: { show: _vm.isShowSettings },
              attrs: { id: "settings-modal" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "settings-btn",
                  on: { click: this.$root.showSettings }
                },
                [_c("img", { attrs: { src: "/images/settings.png" } })]
              ),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "settings-content settings-form",
                  attrs: { id: "settings_form" }
                },
                [
                  _c("div", {
                    staticClass: "component-loading",
                    class: { show: _vm.loading }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "settings-tabs" },
                    _vm._l(this.$parent.arSettings, function(tab) {
                      return _c(
                        "div",
                        {
                          staticClass: "settings-tab",
                          class: { "is-active": tab.opened },
                          attrs: { id: tab.id },
                          on: {
                            click: function($event) {
                              return _vm.toggleSetingTab(tab)
                            }
                          }
                        },
                        [_c("span", [_vm._v(_vm._s(tab.name))])]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "settings-tabs-content" },
                    _vm._l(this.$parent.arSettings, function(tab) {
                      return _c(
                        "div",
                        {
                          staticClass: "settings-tab-content",
                          class: [{ "is-open": tab.opened }, tab.id]
                        },
                        _vm._l(tab.items, function(item) {
                          return _c(
                            "div",
                            { staticClass: "setting-block", class: item.CLASS },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "block-title",
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleSetingItem(item)
                                    }
                                  }
                                },
                                [
                                  _vm._v(_vm._s(item.NUM) + ". "),
                                  _c("span", [_vm._v(_vm._s(item.NAME))])
                                ]
                              ),
                              _vm._v(" "),
                              item.TYPE == "LIST"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "block-content",
                                      class: { show: item.SHOW },
                                      attrs: { lang: "ru" }
                                    },
                                    [
                                      _c(
                                        "select",
                                        {
                                          staticClass:
                                            "select-settings js-settings-select settings-element",
                                          class: [item.CLASS, item.TYPE],
                                          attrs: {
                                            name: item.CODE,
                                            multiple: {
                                              multiple: item.MULTIPLE
                                            }
                                          }
                                        },
                                        _vm._l(item.VALUES, function(option) {
                                          return _c(
                                            "option",
                                            {
                                              domProps: {
                                                selected: option.selected,
                                                value: option.value
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(option.name)
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.TYPE == "SORT_BLOCK"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "block-content",
                                      class: { show: item.SHOW },
                                      attrs: { lang: "ru" }
                                    },
                                    [
                                      _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "settings-blocks-conainer",
                                          class: item.CLASS
                                        },
                                        _vm._l(item.VALUES, function(block) {
                                          return _c(
                                            "li",
                                            {
                                              key: "set_item_" + block.key,
                                              staticClass: "ui-state-default"
                                            },
                                            [
                                              _c("input", {
                                                staticClass: "settings-element",
                                                attrs: {
                                                  type: "hidden",
                                                  name: item.CODE
                                                },
                                                domProps: { value: block.key }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "settings-block-showed cat-filter"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "control_checkbox"
                                                    },
                                                    [
                                                      _c("label", [
                                                        _c("input", {
                                                          attrs: {
                                                            type: "checkbox",
                                                            name: block.CODE
                                                          },
                                                          domProps: {
                                                            checked:
                                                              block.checked,
                                                            value: block.value
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c("i"),
                                                        _c("span")
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass:
                                                  "settings-block-icon",
                                                domProps: {
                                                  innerHTML: _vm._s(block.icon)
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "settings-block-name"
                                                },
                                                [_vm._v(_vm._s(block.name))]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass:
                                                  "settings-block-link",
                                                domProps: {
                                                  innerHTML: _vm._s(block.link)
                                                }
                                              })
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.TYPE == "IMAGES_BLOCK"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "block-content",
                                      class: { show: item.SHOW },
                                      attrs: { lang: "ru" }
                                    },
                                    [
                                      _vm._l(item.VALUES, function(img_item) {
                                        return _c(
                                          "div",
                                          {
                                            staticClass: "settings_img_item",
                                            attrs: { value: img_item.value }
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: img_item.id + "_slideImg"
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  attrs: { src: img_item.img }
                                                }),
                                                _vm._v(" "),
                                                _c("input", {
                                                  staticClass: "img-prop",
                                                  attrs: {
                                                    id:
                                                      img_item.id + "_slideImg",
                                                    type: "file",
                                                    name:
                                                      "loyalSlider_" +
                                                      img_item.id
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.$root.showImagePreview
                                                  }
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "slide_content" },
                                              [
                                                _c("input", {
                                                  staticClass: "setting-prop",
                                                  attrs: {
                                                    type: "text",
                                                    name:
                                                      item.CODE +
                                                      "[" +
                                                      img_item.id +
                                                      "][text]",
                                                    placeholder: "Текст"
                                                  },
                                                  domProps: {
                                                    value: img_item.text
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("input", {
                                                  staticClass: "setting-prop",
                                                  attrs: {
                                                    type: "text",
                                                    name:
                                                      item.CODE +
                                                      "[" +
                                                      img_item.id +
                                                      "][link]",
                                                    placeholder: "Ссылка"
                                                  },
                                                  domProps: {
                                                    value: img_item.link
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("input", {
                                                  staticClass: "setting-prop",
                                                  attrs: {
                                                    type: "text",
                                                    name:
                                                      item.CODE +
                                                      "[" +
                                                      img_item.id +
                                                      "][btn_text]",
                                                    placeholder: "Текст кнопки"
                                                  },
                                                  domProps: {
                                                    value: img_item.btn_text
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "settings_img_item img_item_add btn btn_transp",
                                          on: { click: _vm.addLoyalSlide }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Добавить слайд\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        0
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "settings-footer" }, [
                    _c(
                      "div",
                      { staticClass: "btn", on: { click: _vm.saveSetings } },
                      [_vm._v("Сохранить")]
                    )
                  ])
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("component-popup-products", {
        attrs: { modalClass: "modal_analog", modalId: "modal-analog" }
      }),
      _vm._v(" "),
      _c("component-popup-quick-view"),
      _vm._v(" "),
      _c("component-popup-import-excel"),
      _vm._v(" "),
      _c("component-popup-profiles"),
      _vm._v(" "),
      _c(
        "div",
        {
          class: { show: _vm.searchOpened, visible: _vm.searchVisible },
          attrs: { id: "quick-search-result" }
        },
        [
          _c("div", { staticClass: "search-header" }, [
            _c("button", {
              staticClass: "modal__close",
              on: { click: _vm.closeSearch }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "component-loading",
              class: { show: _vm.loadingSearch }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "search-header-text",
              domProps: { innerHTML: _vm._s(_vm.searchTest) }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "search-header-count",
              class: { show: !_vm.loadingSearch },
              domProps: { innerHTML: _vm._s(_vm.countTest) }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "product-list" }, [
            _c("div", {
              staticClass: "component-loading",
              class: { show: _vm.loadingSearch }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "animated-page" }, [
              _c("div", { staticClass: "catalog__grid" }, [
                _c(
                  "div",
                  { staticClass: "product-grid list-mode" },
                  _vm._l(_vm.searchItems, function(productItem) {
                    return _c(
                      "component-list-item",
                      _vm._b(
                        {
                          key: "search_product_" + productItem.id,
                          attrs: {
                            can_delete: _vm.can_delete,
                            "data-page": _vm.navPageNum
                          }
                        },
                        "component-list-item",
                        productItem,
                        false
                      )
                    )
                  }),
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "b-content__footer",
              class: { hidden: _vm.searchItems.length == 0 }
            },
            [_c("component-pagination")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "modal-overlay" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-col" }, [
      _c("div", [_vm._v("г. Москва, ул. Дорожная, 48")]),
      _vm._v(" "),
      _c("div", [_vm._v("Режим работы: 08:00–17:00")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-col" }, [
      _c("div", [_vm._v("+7 (499) 519-30-18")]),
      _vm._v(" "),
      _c("div", [_vm._v("mail@feron.ru")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }