var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal",
      class: [{ "is-open ": _vm.show, loading: _vm.loading }, _vm.modalClass],
      attrs: { id: _vm.modalId }
    },
    [
      _c("div", { staticClass: "modal__wrap" }, [
        _c("div", { staticClass: "modal__inner animated zoomIn" }, [
          _c("div", {
            staticClass: "component-loading",
            class: { show: _vm.loading }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "component-loading",
              class: { show: _vm.processWait }
            },
            [
              _c("div", {
                staticClass: "component-loading-text",
                domProps: { innerHTML: _vm._s(_vm.processWaitText) }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "component-loading-progress" }, [
                _c("div", { staticClass: "bar" }, [
                  _c("span", {
                    staticClass: "bar-title",
                    domProps: { innerHTML: _vm._s(_vm.process_text) }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "bar-progress" }, [
                    _c("span", {
                      staticClass: "bar-progress-ready",
                      style: "width:" + _vm.processData.percent + "%;"
                    })
                  ])
                ])
              ])
            ]
          ),
          _vm._v(" "),
          !_vm.success
            ? _c("div", { staticClass: "modal__header" }, [
                _c("div", { staticClass: "modal__title" }, [
                  _vm._v("Загрузка файла заказа")
                ]),
                _vm._v(" "),
                _c("button", {
                  staticClass: "modal__close",
                  on: {
                    click: function($event) {
                      _vm.to_hide = true
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "modal__container" }, [
            _vm.success
              ? _c(
                  "form",
                  {
                    staticClass: "import-form b-content__top success",
                    attrs: { id: "import_form", enctype: "multipart/form-data" }
                  },
                  [
                    _c("h4", [_vm._v("Заказ успешно загружен!")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "import-result" }, [
                      _vm._v(_vm._s(_vm.count_added_text))
                    ]),
                    _vm._v(" "),
                    !_vm.is_cart
                      ? _c("div", { staticClass: "success-text" }, [
                          _vm._v(
                            "Сейчас вы будете перенаправлены  в раздел “Корзина”"
                          ),
                          _c("br"),
                          _vm._v(
                            "\n\t\t\t\t\tдля дальнейшего оформления заказа"
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "countdown-redirect" }, [
                      _vm._v(_vm._s(_vm.countdown))
                    ])
                  ]
                )
              : _c(
                  "form",
                  {
                    staticClass: "import-form b-content__top ",
                    attrs: { id: "import_form", enctype: "multipart/form-data" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "control_file_import",
                        class: { loaded: _vm.file.size }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "btn_transp btn btn-file",
                            attrs: { for: "import_file" }
                          },
                          [
                            _c("input", {
                              attrs: {
                                name: "import_file",
                                id: "import_file",
                                type: "file",
                                accept:
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              },
                              on: { change: _vm.setImportFile }
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.btn_text))])
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.file_error !== false
                      ? _c(
                          "div",
                          {
                            staticClass: "notice notice_warning animated fadeIn"
                          },
                          [
                            _c("div", { staticClass: "notice__body" }, [
                              _c("div", {
                                staticClass: "notice__text",
                                domProps: { innerHTML: _vm._s(_vm.file_error) }
                              })
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.file.size
                      ? _c("div", { staticClass: "product-one__docs" }, [
                          _c("div", { staticClass: "file" }, [
                            _c(
                              "svg",
                              { attrs: { width: "21", height: "21" } },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href":
                                      "/images/sprite.svg#file-excel"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.file.name)),
                              _c("br"),
                              _c("span", { staticClass: "size" }, [
                                _vm._v(_vm._s(_vm.file.sizeKb) + " Kб")
                              ])
                            ]),
                            _vm._v(" "),
                            _c("button", {
                              staticClass: "modal__close",
                              on: {
                                click: function($event) {
                                  _vm.file = {}
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._m(0)
                  ]
                )
          ]),
          _vm._v(" "),
          !_vm.success
            ? _c("div", { staticClass: "modal__footer" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn_light",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.downloadPrice.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "btn_text" }, [
                      _vm._v("Шаблон файла заказа")
                    ]),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        staticClass: "svg-icon",
                        attrs: { width: "13", height: "13" }
                      },
                      [
                        _c("use", {
                          attrs: { "xlink:href": "/images/sprite.svg#download" }
                        })
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-import",
                    on: { click: _vm.importExcel, mousedown: _vm.importExcel }
                  },
                  [_vm._v("Загрузить")]
                )
              ])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "control_checkbox" }, [
      _c("label", { attrs: { for: "clear_basket" } }, [
        _c("input", {
          attrs: {
            name: "clear_basket",
            id: "clear_basket",
            type: "checkbox",
            value: "Y"
          }
        }),
        _vm._v(" "),
        _c("i"),
        _c("span", [_vm._v("Очистить текущую корзину перед загрузкой файла")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }