<template>
	<div v-if="activeTab" style="position:relative;">
		<div class="popular js-tab">
			<div class="popular__header" v-bind:id="'active_' + activeTab.id">
				<h2 v-if="title" class="popular__title">{{ title }}</h2>
				<ul v-bind:class="classes.header" class="js-tab-controls">
					<li v-bind:id="'tab___' + tab.id" v-for="tab in tabs" v-bind:class="{'is-active': tab.active, 'disabled': tab.disabled }" v-on:click.prevent="setActiveTab(tab)">
						<a v-bind:href="'#' + tab.id">{{ tab.name }}</a>
					</li>
				</ul>
			</div>
			<div v-bind:class="classes.body" class="js-tab-contents">
				<div v-for="tab in tabs" v-bind:class="{'is-active': tab.active, 'disabled': tab.disabled}" class="popular__tab" v-bind:id="tab.id">
					<div v-if="tab.mode == 'product_slider'">
						<component-product-slider v-bind:id="tab.id" v-bind="tab.data"></component-product-slider>
					</div>
					<div v-else-if="tab.mode == 'product_slider_full'">
						<component-product-slider-full v-bind:id="tab.id" v-bind="tab.data"></component-product-slider-full>
					</div>
					<div v-else-if="tab.mode == 'property_list'">
						<div v-for="arProp in tab.data.properties" class="dl">
							<div class="dt" v-html="arProp.name"></div>
							<div class="dd" v-html="arProp.value"></div>
						</div>
					</div>
					<div v-else v-html="tab.html"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import productSliderComponent from '../components/productSliderComponent';
import productSliderFullComponent from '../components/productSliderFullComponent';
export default {
	props: {
		title: "",
		tabsData: {type: Object, default(){ return {}; } },
		classes: {type: Object, default(){return {header: 'popular__tabs',body: 'popular__body'}} },
	},
	data: function() {
		var self = this;
		return {
			loading: true,
			renderComponent: false,
			activeTab: {},
			tabs: self.tabsData,
		}
	},
	components: {
		'component-product-slider': productSliderComponent,
		'component-product-slider-full': productSliderFullComponent,
	},
	mounted: function(){
		var self = this;
		console.log(self);
	},
	watch: {
		activeTab: function(){
			var self = this;
			self.renderComponent = true;
		},
		tabs: function(){
			var self = this;
			
		},
	},
	methods:{
		setActiveTab: function(tab){
			var self = this;
			
			if(!tab){
				for(var i in self.tabs){
					if(self.tabs[i].disabled) self.tabs[i].active = false;
					else{
						self.tabs[i].active = true;
						self.tabs[i].init = true;
						self.activeTab = self.tabs[i];
						break;
					}
				}
			}else{
				for(var i in self.tabs) {
					if(self.tabs[i].disabled) self.tabs[i].disabled = true;
					self.tabs[i].active = false;
				}
				self.tabs[tab.id].active = true;
				self.tabs[tab.id].data.init = true;
				self.activeTab = self.tabs[tab.id];
				
			}
			
		},
		forceRerender() {
			var self = this;
			self.renderComponent = false;
			self.$nextTick(() => {
				self.renderComponent = true;
			});
		},
	},
};
</script>

<style scoped>
.js-tab-controls .disabled{display:none;}
</style>