// styles
import '../custom/vue/css/libs/normalize/reboot.css';
import '../custom/vue/css/libs/owl/assets/owl.carousel.min.css';
import '../custom/vue/css/style.css';
import '../custom/vue/css/style2.css';
import '../custom/vue/css/custom.css';
import '../custom/vue/css/animate.css';

import '../custom/vue/css/select2.css';

// js
// import '../custom/js/hello_webpack';

// vue
import '../custom/vue/main';