<template>
	<div class="modal" v-bind:class="[{'is-open ': show, 'loading': loading}, modalClass]" v-bind:id="modalId" v-if="$root.arUser.authorized">
		<div class="modal__wrap">
			<div class="modal__inner animated zoomIn">
				<div class="modal__header">
					<div class="modal__title" v-html="title"></div>
					<div class="modal__subtitle" v-html="subtitle"></div>
				</div>
				<div class="modal__container">
				
					<div v-if="profilesCnt > 0" class="control_select select_profile">
						<div v-bind:class="{'hidden': $root.arUser.profiles.count == 1}" >
							<label>Активный договор</label>
							<select class="select-profile js-entity-select" name="entity">
								<option 
									v-for="profile in $root.arUser.profiles.list" 
									v-bind:value="profile.id" 
									v-bind:class="{'active': profile.active}" 
									v-bind:selected="profile.active"  
									v-html="profile.props.ORDER_PROP_75.value"></option>
							</select>
						</div>
						<div v-bind:class="{'hidden': activeProfile.stores.count == 1}" >
							<label>Активный склад отгрузки</label>
							<select class="select-store js-entity-select" name="entity">
								<option 
									v-for="store in activeProfile.stores.list" 
									v-bind:value="store.ID" 
									v-bind:class="{'active': store.selected}" 
									v-bind:selected="store.selected"  
									v-html="store.TITLE"></option>
							</select>
						</div>
						
						<a v-on:click="setActiveProfile" href="javascript:;" class="btn"><span class="btn_text">Далее</span></a>
					</div>
					
					<div class="info_text">
					*Выбор склада и договора позволит показывать актуальные цены и остатки для вашей компании
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style>
.control_select .hidden{
	display:none;
}
</style>
<script>
export default {
	props: {
		modalClass: {type: String, default: 'modal_profiles modal_analog'},
		modalId: {type: String, default: 'modal-profiles'},
	},
	data: function() {
		var self = this;
		
		if(self.$root.arUser.authorized && (self.$root.arUser.profile === false || self.$root.arUser.store === false)) var show = true;
		else var show = false;
		
		return {
			loading: true,
			to_hide: false,
			show: show,
			profiles: self.$root.arUser.profiles.list,
		}
	},
	computed: {
		title() {
			var self = this;
			if(self.$root.arUser.authorized){
				var profilesCnt = parseInt(self.$root.arUser.profiles.count);
				var storesCnt = profilesCnt > 0 ? parseInt(self.activeProfile.stores.count) : 0;
				
				if(profilesCnt <= 0) return "В системе не зарегистрировано ни одного профиля для аккаунта.";
				
				if(profilesCnt > 1 && storesCnt == 1) return "В системе зарегестрировано " + profilesCnt + " активных договора";
				if(profilesCnt == 1 && storesCnt > 1) return "Для вашей компании доступно " + storesCnt + " склада отгрузки";
				else return "В системе зарегистрировано " + profilesCnt + " договора и " + storesCnt + " склада отгрузки";
			}
		},
		subtitle() {
			var self = this;
			if(self.profilesCnt <= 0) return "Вы не можете пользоваться личным кабинетом.";
			else return "Пожалуйста, выберите актуальный для заказа договор.<br>Сменить договор можно в настройках профиля.";
		},
		activeProfile() {
			var self = this;
			if(self.$root.arUser.profiles) return self.$root.arUser.profiles.active;
			else return {stores: 0};
		},
		profilesCnt() {
			var self = this;
			return self.$root.arUser.profiles ? parseInt(self.$root.arUser.profiles.count) : 0;
		},
	},
	mounted: function(){
		var self = this;
		if(self.show) $("#modal-overlay").fadeIn();
		
		$('.select-profile').select2({
			minimumResultsForSearch: -1,
			width: "100%"
		}).on('select2:select', function (e) {
			var selectedProfileId = $(e.target).find("option:selected").attr("value");
			self.$root.arUser.profiles.active = self.profiles.find(item => item.id == selectedProfileId);
			
			$('.select-store').select2("destroy");
			self.$nextTick(function () {
				$('.select-store').select2({
					minimumResultsForSearch: -1,
					width: "100%"
				})
			})
			
		});
		
		$('.select-store').select2({
			minimumResultsForSearch: -1,
			width: "100%"
		}).on('select2:select', function (e) {
			
		});
		
		self.$nextTick(function () {
			var profilesCnt = parseInt(self.$root.arUser.profiles.count);
			var storesCnt = profilesCnt > 0 ? parseInt(self.activeProfile.stores.count) : 0;
			if(self.$root.arUser.profile === false || self.$root.arUser.store === false){
				if(profilesCnt == 1 && storesCnt == 1) self.setActiveProfile();
			}
		})
	},
	watch: {
		show: function(){
			var self = this;
			if(this.show) $("#modal-overlay").fadeIn();
			else $("#modal-overlay").fadeOut();
		},
		to_hide:function(to_hide){
			var self = this;
			if(to_hide){
				$("#" + self.modalId + " .modal__inner").addClass("zoomOut");
				setTimeout(function(){
					self.show = false;
					self.to_hide = false;
					self.$root.importExcelModal = false;
					$("#" + self.modalId + " .modal__inner").removeClass("zoomOut");
					//self.reset();
				}, 400);
			}
		},
	},
	methods:{
		setActiveProfile: function(){
			var self = this;
			var selectedProfileId = $(".select-profile option:selected").attr("value");
			var selectedStoreId = $(".select-store option:selected").attr("value");
			
			self.$root.setActiveProfile(selectedProfileId, selectedStoreId);
			self.to_hide = true;
			
		},
	},
};
</script>

<style >
#modal-profiles .modal__wrap{
	max-width:600px;
}
#modal-profiles .modal__title{
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 26px;
	text-align: center;
	letter-spacing: 0.2px;
}
#modal-profiles .modal__subtitle{
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 16px;
	align-items: center;
	text-align: center;
	color: #52504C;
}
#modal-profiles .control_select{
	max-width: 300px;
    margin: 40px auto;
}
#modal-profiles label{
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 18px;
	align-items: center;
	color: #989898;
}
#modal-profiles .select_profile .btn{
    margin: 20px auto;
    display: block;
    width: 128px;
}
#modal-profiles .info_text{
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 16px;
	align-items: center;
	text-align: center;

	color: #52504C;
}
.select2-container--open{
	z-index:2001;
}
</style>