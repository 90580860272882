<template>
	<div style="position:relative;">
		<div v-bind:class="{ 'show': loading }" class="component-loading"></div>
		<div v-bind:id="slider_id" v-bind:class="{ inited: inited, 'loading': loading }" class="product-slider owl-carousel">
			<div v-for="item in items" class="product-slider__item">
				<router-link :to="item.link" class="product">
					<div class="product__header">
						<div class="product__labels">
							<span v-for="label in item.labels" v-if="label.class == labelClass" v-bind:class="label.class" class="label">{{ label.text }}</span>
						</div>
					</div>
					<div class="product__img">
						<img v-bind:src="item.image" alt="">
					</div>
					<div class="product__art">Арт. {{ item.article }}</div>
					<div class="product__name" v-html="item.name"></div>
					<div class="product__price">
						<span v-if="item.ratio_price.print_old" class="old-price" v-html="item.ratio_price.print_old"></span>
						<span class="price" v-html="item.ratio_price.print"></span>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import owlCarousel from 'owl.carousel';
export default {
	name: 'productSliderComponent',
	props: {
		slider_id: {type: String, default: 'product_slider'},
	},
	data: function() {
		return {
			init: true,
			loading: true,
			inited: false,
			active: false,
			labelClass: "",
			items: {},
			filter: {},
		}
	},
	computed: {
		activeTab() {return this.$parent.activeTab},
	},
	mounted: function(){
		var self = this;
		self.filter = self.$attrs.filter;
		self.init = self.$attrs.init;
		self.labelClass = self.$attrs.label;
		
		if(self.$attrs.init) self.loadProducts();
	},
	watch: {
		activeTab: function(){
			var self = this;
			
			self.init = self.activeTab.data.slider_id == self.slider_id;
			if(self.init) self.loadProducts();
		},
	},
	methods:{
		loadProducts: function(){
			var self = this;
			if(self.inited) return;
			
			var urlData = {filter: self.filter};
			$.getJSON("/data/components/sectionFiltered.php", urlData, function(data){
				self.items = data.items;
				self.$nextTick(function(){
					self.$parent.$emit('sliderItemsLoaded', data);
				
					self.initOwl();
					self.loading = false;
					self.inited = true;
					
					$("#" + self.slider_id + " .owl-item").each(function(){
						var el = $(this);
						setTimeout(function(){
							el.addClass("animated zoomIn showed");
						}, el.index() * 100);
						setTimeout(function(){
							el.removeClass("animated zoomIn");
						}, (el.index() * 100) + 1000);
					});
				})
			})
		},
		initOwl: function(){
			var self = this;
			$('#' + self.slider_id).owlCarousel({
				margin: 5,
				navText: [
					'<svg role="img" height="13" width="14"><use xlink:href="/images/sprite.svg#arrow-left"></use></svg>',
					'<svg role="img" height="13" width="14"><use xlink:href="/images/sprite.svg#arrow-right"></use></svg>'
				],
				nav: true,
				loadedClass: "products-loaded",
				hiddenClass: "products-loaded",
				responsive: {
					0: {
						items: 2
					},
					480: {
						items: 3
					},
					768: {
						items: 4
					},
					1200: {
						items: 5
					}
				}
			});
		},
	},
};
</script>

<style>
	
	.product-slider__item{
		width:160px;
	}
	.product-slider__item .product__img{
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.product-slider__item .product__img img{
		width: auto;
		max-height: 100%;
	}
	.owl-carousel .component-loading{
		z-index: 10;
	}
	.is-active .owl-carousel{
		min-height: 230px;
		display: block;
		flex-wrap: nowrap;
	}
</style>