var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.arUser.profiles.count == 0
      ? _c("div", { staticClass: "notice notice_warning animated fadeIn" }, [
          _c("div", { staticClass: "notice__header" }, [
            _vm._v("У Вас нет активных Юр. Лиц")
          ]),
          _vm._v(" "),
          _vm._m(0)
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.notification.id > 0
      ? _c(
          "div",
          {
            staticClass: "notice animated fadeIn",
            class: "notice_" + _vm.notification.type
          },
          [
            _c("div", { staticClass: "notice__header" }, [
              _vm._v(_vm._s(_vm.notification.name))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "notice__body" },
              [
                _c("div", {
                  staticClass: "notice__text",
                  domProps: { innerHTML: _vm._s(_vm.notification.content) }
                }),
                _vm._v(" "),
                _vm.notification.link
                  ? _c(
                      "router-link",
                      {
                        staticClass: "notice__read",
                        attrs: { to: _vm.notification.link }
                      },
                      [_c("span", [_vm._v("Подробнее")])]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$root.arUser.notifications.items.length > 1
      ? _c(
          "div",
          {
            staticClass: "notice-link animated fadeIn",
            staticStyle: { "margin-top": "-20px", "margin-bottom": "20px" }
          },
          [
            _c(
              "router-link",
              {
                staticClass: "notice__read",
                staticStyle: { "justify-content": "flex-end" },
                attrs: { to: "/notifications/" }
              },
              [_c("span", [_vm._v("Все уведомления")])]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "notice__body" }, [
      _c("div", { staticClass: "notice__text" }, [
        _vm._v("Обратитесь к Вашему менеджеру для уточнения информации.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }